import styled from 'styled-components';
export const Styledadmin = styled.div`
  .input {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.button2 : props.theme.accent3)};
    background-color: ${(props) => props.theme.bg2};

    outline: none;

    &:focus {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

      border-radius: 5px;
      border: 1px solid
        ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2};
    }
  }

  .admin-controls {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 1rem;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .admin-tabs {
    position: relative;
    padding: 1rem;

    .admin-tabs-header {
      position: relative;

      height: 3.2rem;

      margin-bottom: 0.4rem;
      padding: 0 3.2rem;

      @media screen and (min-width: 768px) {
        padding: 0 1.5rem;
        margin-bottom: 0;
        height: auto;
      }

      .admin-tabs-headers {
        overflow: hidden;
        height: 100%;

        @media screen and (min-width: 768px) {
          overflow: scroll;
          scroll-behavior: smooth;
        }
      }
      .admin-tabs-header-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        display: flex;
        justify-content: center;
        align-items: center;

        width: 2.4rem;
        height: 2.4rem;

        border-radius: 100px;

        background-color: ${(props) => props.theme.bg4};

        @media screen and (min-width: 768px) {
          width: 3.2rem;
          height: 3.2rem;
        }

        .icon {
          transform: translateX(1px);
        }

        &.left,
        &.right {
          @media screen and (min-width: 768px) {
            display: none;
          }
        }

        &.left {
          left: 0.4rem;

          .icon {
            transform: scale(-1) translateX(1px);
          }
        }

        &.right {
          right: 0.4rem;
        }

        &.switch {
          right: 0;
          display: none;

          &.start {
          }

          &.end {
            .icon {
              transform: scale(-1);
            }
          }
          @media screen and (min-width: 768px) {
            display: block;
          }
          @media screen and (min-width: 1024px) {
            display: none;
          }
        }
      }
      .admin-tabs-header__container {
        position: relative;
        height: 100%;

        transition: all 0.5s;

        display: flex;

        &.l-0 {
          left: 0;
        }

        &.l-100 {
          left: -100%;
        }
        &.l-200 {
          left: -200%;
        }

        &.l-300 {
          left: -300%;
        }

        @media screen and (min-width: 768px) {
          &.l-0,
          &.l-100,
          &.l-200,
          &.l-300 {
            left: initial;
          }
        }

        .admin-tabs-header__tab {
          flex-shrink: 0;
          width: 100%;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 150%;

          @media screen and (min-width: 768px) {
            font-size: 1.6rem;
            width: auto;
            padding: 1.3rem 0;

            color: ${(props) => props.theme.text2};

            .admin-tabs-header__tab__title {
              border-right: 1px solid ${(props) => props.theme.text2};
            }

            &:has(+ *.active) {
              .admin-tabs-header__tab__title {
                border-color: transparent;
              }
            }

            &:hover {
              .admin-tabs-header__tab__title {
                color: ${(props) => props.theme.accent1};
              }
            }

            &.active {
              position: relative;
              background-color: ${(props) => props.theme.bg2};
              border-radius: 10px 10px 0 0;
              border-color: transparent;

              .admin-tabs-header__tab__title {
                border-color: transparent;
                color: ${(props) => props.theme.text1};
              }
            }

            .admin-tabs-header__tab__title {
              @media screen and (min-width: 768px) {
                padding: 0 2.4rem;
              }
            }
          }

          @media screen and (min-width: 1440px) {
            font-size: 2rem;
          }
        }
      }
    }

    .admin-tabs-content {
      background-color: ${(props) => props.theme.bg2};
      border-radius: 12px;
    }
  }
`;
