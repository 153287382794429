import { Search } from 'components';
import { StyledWorkerControls } from './styles';
import { WorkersListFilters } from '../WorkersListFilters';
import { WorkerGroups } from '../WorkerGroups';

export const WorkerControls = () => {
  const searchHandler = (value: string) => {
    console.log('search value', value);
  };
  return (
    <StyledWorkerControls>
      <WorkerGroups />
      <WorkersListFilters />
      <Search onChange={searchHandler} />
    </StyledWorkerControls>
  );
};
