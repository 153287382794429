import { useContext } from 'react';
import { DefaultTheme, ThemeContext } from 'styled-components';
import { ReactComponent as DarkLogo } from 'assets/logos/logo-dark.svg';
import { ReactComponent as LightLogo } from 'assets/logos/logo-light.svg';
import { StyledLogoContainer } from './styled';

export const Logo = ({ dark }: { dark?: boolean }) => {
  const { id } = useContext(ThemeContext) as DefaultTheme;
  return (
    <StyledLogoContainer>
      {id === 'dark' || dark ? <DarkLogo /> : <LightLogo />}
    </StyledLogoContainer>
  );
};
