import { useTranslation } from 'react-i18next';
import {
  StyledSection,
  StyledInstructionArticle,
  StyledCalculatorArticle,
  StyledImgContainer,
  StyledInstructionsHeading,
  StyledAmountBlock,
  StyledCalculatorHeading,
  StyledInputBlock,
} from './styles';
import {
  SecondaryButton,
  PlayIcon,
  CurrencySelect,
  HashrateInput,
  PeriodSelector,
  Modal,
  VideoDialog,
} from 'components';
import { darkTheme } from 'themes';
import { DailyIncome } from 'components/DailyIncome';
import { useEffect, useState } from 'react';
import { ECalculationPeriods } from 'types';

export const CalculatorSection = () => {
  const { t } = useTranslation();
  const [thAmount, setThAmount] = useState<number>(1);
  const [period, setPeriod] = useState<ECalculationPeriods>(ECalculationPeriods.Week);

  const periodMultiplierMap = {
    [ECalculationPeriods.Day]: 1,
    [ECalculationPeriods.Week]: 7,
    [ECalculationPeriods.Month]: 30,
  };

  const [isMountModal, setIsMountModal] = useState(false);
  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const closeModalHandler = () => {
    setShowVideoDialog(false);
  };

  const showVideoHandler = () => {
    console.log('asdfasdf');
    setShowVideoDialog(true);
  };

  useEffect(() => {
    if (showVideoDialog) {
      setIsMountModal(true);
    }

    if (!showVideoDialog) {
      setTimeout(() => setIsMountModal(false), 500);
    }
  }, [showVideoDialog]);

  return (
    <StyledSection>
      <Modal show={isMountModal}>
        <VideoDialog onClose={closeModalHandler} show={isMountModal} />
      </Modal>
      <div className="calculator-background" />
      <StyledInstructionArticle>
        <StyledImgContainer>
          <img src="/images/photo-man-loptop.webp" alt="a man with a laptop" />
        </StyledImgContainer>
        <StyledInstructionsHeading>{t('mainPage.instruction.heading')}</StyledInstructionsHeading>
        <SecondaryButton
          icon={<PlayIcon secondaryColor={darkTheme.accent1} />}
          onClick={showVideoHandler}
        >
          {t('mainPage.instruction.watchButton')}
        </SecondaryButton>
      </StyledInstructionArticle>
      <StyledCalculatorArticle>
        <StyledCalculatorHeading>{t('calculator.heading')}</StyledCalculatorHeading>
        <StyledInputBlock>
          <StyledAmountBlock>
            <CurrencySelect labelText={t('calculator.currency')} />
            <div className="input-container">
              <HashrateInput
                labelText={t('calculator.hashrate')}
                units={t('calculator.hashrateUnits')}
                onChange={setThAmount}
              />
            </div>
            <PeriodSelector
              onChange={setPeriod}
              labelText={t('calculator.period')}
            ></PeriodSelector>
          </StyledAmountBlock>
        </StyledInputBlock>
        <DailyIncome thAmount={thAmount * periodMultiplierMap[period]} />
      </StyledCalculatorArticle>
    </StyledSection>
  );
};
