import { StyledWorkerGroupsDropdown } from './styles';
interface IWorkerGroupsDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export const WorkerGroupsDropdown = ({
  children,
  onBlur,
  onMouseLeave,
}: IWorkerGroupsDropdownProps) => {
  return (
    <StyledWorkerGroupsDropdown onBlur={onBlur} onMouseLeave={onMouseLeave}>
      {children}
    </StyledWorkerGroupsDropdown>
  );
};
