import styled from 'styled-components';
export const StyledAdminEditEquipmentGroups = styled.div`
  .admin-edit-groups-controls {
    display: flex;
    justify-content: space-between;

    &__right {
      display: flex;
      gap: 1.2rem;
    }
  }
`;
