import styled from 'styled-components';

export const StyledSection = styled.section`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  margin: auto;
  margin-bottom: 4rem;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 768px) {
    margin-bottom: 10rem;
  }

  @media screen and (min-width: 1024px) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media screen and (min-width: 1440px) {
    max-width: 132rem;
  }

  .benefits-background {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;

    transform: translate(-50%, -50%);

    display: ${(props) => (props.theme.id === 'dark' ? 'none' : 'block')};

    width: 100dvw;
    height: 26.7rem;

    background-image: url('/images/bg/lines-1.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: 1440px) {
      top: 10%;
    }
  }
`;
