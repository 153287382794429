import styled from 'styled-components';
export const StyledWorkerGroup = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;

  &.current {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.text1)};
  }

  &:hover:not(.current) {
    color: ${(props) => props.theme.accent1};
  }
`;
