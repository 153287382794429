import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import { StyledCustomSearch } from './styles';
interface ICustomSearchProps {
  children?: React.ReactNode;
}
export const CustomSearch = ({ children }: ICustomSearchProps) => {
  return (
    <StyledCustomSearch>
      <input className="search-input" placeholder="Search" />
      <button className="search-input-button">
        <SearchIcon className="search-input-button__icon" />
      </button>
    </StyledCustomSearch>
  );
};
