import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledButton = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: nowrap;

  border-radius: 500px;

  padding: 0.8rem 1.6rem;

  font-size: 1.4rem;
  line-height: 150%;
  font-weight: 600;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.button2 : props.theme.text2)};

  background-color: ${(props) => props.theme.button1};

  @media screen and (min-width: 768px) {
    font-weight: 700;
    &:has(div) {
      font-size: 2.5rem;
    }
  }

  .button_icon {
    width: 2.6rem;
    height: 2.6rem;

    @media screen and (min-width: 768px) {
      width: 4rem;
      height: 4rem;
    }

    > * {
      width: 100%;
      height: 100%;

      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.button2 : props.theme.text2)};

      transition: all 0.2s;
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};
    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.bg3)};
    box-shadow: none;

    .button_icon {
      > * {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};
      }
    }
  }

  &:hover {
    box-shadow: 0px 0px 19px 0px ${darkTheme.button1};
  }

  &:active {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};

    box-shadow: 0px 0px 19px 0px ${darkTheme.button2};

    background-color: ${(props) => props.theme.button2};
    .button_icon {
      > * {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};
      }
    }
  }
`;
