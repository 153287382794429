import { useTranslation } from 'react-i18next';
import { StyledFAQHeading, StyledFAQList, StyledFAQSection } from './styles';
import { FAQElement } from 'components';

export const FAQSection = () => {
  const { t } = useTranslation();
  return (
    <StyledFAQSection>
      <StyledFAQHeading>{t('mainPage.faq.heading')}</StyledFAQHeading>
      <StyledFAQList>
        <FAQElement
          title="How to start mining?"
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam dolores doloribus ab. Et quis, fugit animi explicabo dolor nam laboriosam impedit temporibus expedita quaerat consequuntur inventore, suscipit doloremque! Iste corporis debitis, assumenda officiis fugiat odit animi voluptas harum nam at eaque eum temporibus nobis quaerat voluptates deserunt suscipit dolores, mollitia vel recusandae officia similique sed aspernatur consequatur. Quis inventore qui autem consequuntur ipsum aliquid pariatur quia aut corrupti laboriosam voluptate soluta delectus, consectetur harum eveniet repudiandae totam similique at dicta numquam quos. Culpa numquam cupiditate optio, corrupti, ab atque vitae consequatur placeat quibusdam esse sequi porro perspiciatis, voluptates quas. Impedit beatae itaque enim ipsum non praesentium sint voluptatum quos, eius animi nihil laboriosam obcaecati voluptates temporibus fugit ab vel porro dolorem. Repellendus esse enim odit architecto non cumque eius dolores eum porro cupiditate excepturi sapiente sint magni dolor exercitationem quae soluta maxime ad, nulla, repudiandae amet et? Neque hic ut qui reprehenderit ipsa dolore, eum ullam omnis! Fugiat dolore doloremque nulla ut, eius dolorum accusamus earum veniam et, quae qui laborum cupiditate maiores tempore mollitia nesciunt dignissimos sit aut voluptas dolorem veritatis. Magni labore reprehenderit totam maxime rem officia dolorem eaque earum numquam recusandae! Ea reprehenderit odio delectus ipsa nihil."
        ></FAQElement>
        <FAQElement
          title="How to start mining?"
          text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae enim optio minus eveniet praesentium blanditiis! Nulla, optio. Inventore voluptates eaque doloribus architecto aut rem tenetur animi at, dolore, dignissimos hic minus ipsa laudantium fugiat modi blanditiis omnis dolor? Laudantium ullam quos dolorum fugiat pariatur voluptate reiciendis fugit voluptatem nam laborum."
        ></FAQElement>
        <FAQElement
          title="How to start mining?"
          text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Impedit atque repellat voluptatem excepturi illum! Necessitatibus dolorem officia aspernatur sunt doloremque, veniam molestias vel, maxime fugit sapiente repudiandae, ratione totam. Amet commodi nam, placeat tempore maxime rerum dicta voluptas sint ducimus assumenda non incidunt quidem culpa enim corrupti iste nemo? Aut itaque fugit tenetur! Recusandae nulla ratione possimus, labore quibusdam aperiam, dolores quisquam velit veritatis, ea voluptate magnam perspiciatis cum dolore inventore. Ratione perferendis officiis pariatur non, asperiores modi error inventore."
        ></FAQElement>
      </StyledFAQList>
    </StyledFAQSection>
  );
};
