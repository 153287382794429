import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  LoginButton,
  SignupButton,
  LogoutButton,
  Nvaigation,
  LocaleToggler,
  ThemeToggler,
} from 'components';
import { StyledControls, StyledMenu, StyledMobileMenu, StyledNavContainer } from './styles';
import { Routes } from 'lib';
import { AdminBarMenu } from 'components/Sidebar/components';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { signOut } from 'store/thunks/authThunk';

interface IMobileMenuProps {
  onClose: () => void;
}

export const MobileMenu = ({ onClose }: IMobileMenuProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { isAdmin, user } = useSelector((state: RootState) => state.auth);

  const logoutButtonHandler = () => {
    dispatch(signOut());
    onClose();
  };
  const loginButtonHandler = () => {
    navigate('/login');
    onClose();
  };
  const signupButtonHandler = () => {
    navigate('/registration');
    onClose();
  };
  console.log(pathname);
  return (
    <StyledMobileMenu>
      {user && (
        <StyledMenu>
          {pathname === '/admin' && isAdmin ? (
            <AdminBarMenu />
          ) : (
            <ul className="temp-list">
              <li>
                <Link to="/statistics">Statistics</Link>
              </li>
              <li>
                <Link to="/workers">Workers</Link>
              </li>
              <li>
                <Link to="/payout">Payout</Link>
              </li>
              <Link to="/profit">Profit</Link>
              <li>
                <Link to="/settings">Settings</Link>
              </li>
              <li>
                <Link to={Routes.referralsPath()}>Referals</Link>
              </li>
            </ul>
          )}
        </StyledMenu>
      )}

      <StyledNavContainer className={`${!user && 'vertical'}`}>
        <Nvaigation />
      </StyledNavContainer>
      <StyledControls>
        {!user && (
          <>
            <SignupButton onClick={signupButtonHandler} />
            <LoginButton onClick={loginButtonHandler} />
          </>
        )}
        {user && <LogoutButton onClick={logoutButtonHandler} />}
        <LocaleToggler top />
        <ThemeToggler />
      </StyledControls>
    </StyledMobileMenu>
  );
};
