import { BorderedWrapper, Button, Hint, Modal, ReferalCalculatorDialog } from 'components';
import { StyledReferrralLevels } from './styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
interface IReferrralLevelsProps {
  children?: React.ReactNode;
}
export const ReferrralLevels = ({ children }: IReferrralLevelsProps) => {
  const { t } = useTranslation();
  const currentRate = '200 TH/s';
  const maxRate = '2000 TH/s';
  const [isMountModal, setIsMountModal] = useState(false);
  const currentRateParagraphTexts = t('referralsPage.levels.currentRate', {
    returnObjects: true,
  }) as string[];

  const [showAddWorkerDialog, setShowAddWorkerDialog] = useState(false);
  const [dialogType, setDialogType] = useState<'calulator' | 'levelHint' | null>(null);
  const showReferralCalculatorHandler = () => {
    setShowAddWorkerDialog(true);
    setDialogType('calulator');
  };
  const showHintDialogHandler = () => {
    setShowAddWorkerDialog(true);
    setDialogType('levelHint');
  };
  const cloaseModalHandler = () => {
    setShowAddWorkerDialog(false);
    setDialogType(null);
  };

  useEffect(() => {
    if (showAddWorkerDialog) {
      setIsMountModal(true);
    }

    if (!showAddWorkerDialog) {
      setTimeout(() => setIsMountModal(false), 500);
    }
  }, [showAddWorkerDialog]);
  return (
    <StyledReferrralLevels>
      <Modal show={isMountModal}>
        <ReferalCalculatorDialog onClose={cloaseModalHandler} show={isMountModal} />
      </Modal>
      <BorderedWrapper>
        <div className="content">
          <h2 className="levels-heading">
            {t('referralsPage.levels.heading')}
            <div className="hint-container">
              <Hint>?</Hint>
            </div>
          </h2>
          <div className="level-value">1</div>
          <div className="current-hash-rate">
            <p className="current-hash-rate-title">{currentRateParagraphTexts[0]}</p>
            <p className="current-hash-rate-value">
              <span>{currentRate}</span> <span>{currentRateParagraphTexts[1]}</span>{' '}
              <span>{maxRate}</span>
            </p>
          </div>
          <div className="calc-button">
            <Button
              title={t('referralsPage.levels.calculatorModalButton')}
              onClick={showReferralCalculatorHandler}
            />
          </div>
        </div>
      </BorderedWrapper>
    </StyledReferrralLevels>
  );
};
