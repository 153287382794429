export interface ICharge {
  hashRate: string;
  date: number;
  generalAccural: string;
  chargesPerUnit: string;
}

export type TSortChargeByHeaderTitle = keyof ICharge;

export enum ECalculationPeriods {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
}
