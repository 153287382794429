import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { StyledAboutPlans } from './styles';
interface IAboutPlansProps {
  children?: React.ReactNode;
}
export const AboutPlans = ({ children }: IAboutPlansProps) => {
  const { t } = useTranslation();
  const [currentActiveTabId, setCurrentActiveTabId] = useState<string | null>(null);

  // TODO: Fetch plans from API
  // https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-8

  const tabs = t('aboutPage.plans.data', { returnObjects: true }) as {
    [id: string]: { heading: string; text: string }[];
  };

  const tabsIds = Object.keys(tabs);

  useEffect(() => {
    if (!currentActiveTabId && tabsIds.length > 0) setCurrentActiveTabId(tabsIds[0]);
  }, [tabsIds]);

  return (
    <StyledAboutPlans className="about-plans-container">
      <div className="about-plans-header">
        <h2 className="about-plans-header-heading">{t('aboutPage.plans.heading')}</h2>
      </div>
      <div className="about-plans-content">
        <div className="about-plans-content-tabs">
          {tabsIds.map((tabId, index) => (
            <button
              key={tabId + index}
              className={cn('about-plans-content-tab', tabId === currentActiveTabId && 'active')}
              onClick={() => setCurrentActiveTabId(tabId)}
            >
              {tabId}
            </button>
          ))}
        </div>
        <div className="about-plans-content-tabs-content">
          {tabsIds.map((tabId, index) => (
            <div
              key={tabId + index}
              className={cn(
                'about-plans-content-tab-content',
                tabId === currentActiveTabId && 'active',
              )}
            >
              {tabs[tabId].map((tabElement, index) => (
                <div
                  key={tabElement.heading + index}
                  className="about-plans-content-tab-content-element"
                >
                  <h3 className="about-plans-content-tab-content-heading">{tabElement.heading}</h3>
                  <p>{tabElement.text}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </StyledAboutPlans>
  );
};
