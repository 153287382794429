import styled from 'styled-components';
import { StyledLangListContainer } from './StyledLangListContainer';

export const StyledContainer = styled.div`
  position: relative;

  width: 3.5em;
  height: 3.5rem;
  min-height: 3.5rem;

  border-radius: 500px;

  background-color: ${(props) => {
    return props.theme.id === 'dark' ? props.theme.accent2 : props.theme.button2;
  }};

  transition: all 0.1s;

  &:hover,
  &:focus-within {
    ${StyledLangListContainer} {
      visibility: visible;
      opacity: 1;
    }
  }
`;
