import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccuralSchedule,
  CustomCalendar,
  DisplayItemsPerPageSelect,
  DownloadDataButton,
  Footer,
  Layout,
  Paginator,
  Search,
  ServicePageHero,
  UserDataLayoutContainer,
} from 'components';
import { ChargeList, ChargesListContainer, ChargesListControls } from './components';
import { filterCharges, getSortedCharges } from 'utils';
import { ICharge } from 'types';
import { generateTestCharges } from 'data/mockCharges';
import { StyledContent } from './StyledContent';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'lib';

const charges = generateTestCharges(300);

export const ProfitPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const DEFAULT_ITEMS_PER_PAGE_QUANTITY = 10;
  const DEFAULT_PAGE_NUMBER = 1;

  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE_QUANTITY);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [searchFilter, setSearchFilter] = useState('');
  const [dateFilter, setDateFilter] = useState<{ from: Date; to: Date } | null>(null);
  const [sortBy, setSortBy] = useState<{ sortBy: keyof ICharge; incr: boolean }>({
    sortBy: 'date',
    incr: true,
  });

  const filteredCharges = useMemo(
    () => filterCharges(charges, { date: dateFilter }),
    [dateFilter, charges],
  );

  const searchFilteredCharges = useMemo(
    () =>
      searchFilter.length === 0
        ? filteredCharges
        : filteredCharges.filter((charge) => charge.hashRate.includes(searchFilter)),
    [searchFilter, filteredCharges],
  );

  const sortedCharges = useMemo(
    () =>
      sortBy.sortBy === null
        ? searchFilteredCharges
        : getSortedCharges(searchFilteredCharges, sortBy.sortBy, sortBy.incr),
    [sortBy, searchFilteredCharges],
  );

  const paginatedCharges = useMemo(
    () =>
      sortedCharges.slice(
        currentItemsPerPage * (currentPage - 1),
        currentItemsPerPage * currentPage,
      ),
    [sortedCharges, currentItemsPerPage, currentPage],
  );

  const pagesQuanity = Math.ceil(sortedCharges.length / currentItemsPerPage);

  const changeWalletButton = () => {
    navigate(`${Routes.settingsPath()}?focus=walletaddress`);
  };

  const changeCurrentPageHandler = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const changeDateRangeHandler = (range: { from: Date; to: Date }) => {
    setDateFilter(range);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const setSearchFilterHandler = (value: string) => {
    setSearchFilter(value);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const setSortHandler = (sortBy: { sortBy: keyof ICharge; incr: boolean }) => {
    console.log('sortBy', sortBy);
    setSortBy(sortBy);
  };

  return (
    <Layout>
      <UserDataLayoutContainer pageName="profitPage">
        <StyledContent>
          <ServicePageHero
            title={t('serviceHero.changeWalletButton')}
            onClickButton={changeWalletButton}
          ></ServicePageHero>
          <AccuralSchedule />
          <ChargesListContainer>
            <ChargesListControls>
              <DownloadDataButton onClick={() => console.log('download')} />
              <CustomCalendar onChange={changeDateRangeHandler} />
              <Search onChange={setSearchFilterHandler} />
            </ChargesListControls>
            <ChargeList charges={paginatedCharges} onSort={setSortHandler} />
            {pagesQuanity > 0 && (
              <div className="pagination-container">
                <Paginator
                  currentPage={currentPage}
                  pageQuantity={pagesQuanity}
                  onChange={changeCurrentPageHandler}
                />
                <DisplayItemsPerPageSelect onChange={setCurrentItemsPerPage} />
              </div>
            )}
          </ChargesListContainer>

          <Footer smaller />
        </StyledContent>
      </UserDataLayoutContainer>
    </Layout>
  );
};
