import { css } from 'styled-components';

export const StyledAdminListHeaders = css`
  display: flex;

  margin-bottom: 0.9rem;

  background-color: ${(props) => props.theme.bg1};

  .admin-list-header-cell-container {
    flex-grow: 1;
    flex-basis: 10%;
    border-right: 1px solid ${(props) => props.theme.stroke2};
    &:last-child {
      border-color: transparent;
    }
    &.select {
      flex-grow: initial;
      flex-basis: initial;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > * {
      height: 100%;
      width: 100%;
    }
  }
`;
