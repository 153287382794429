import styled from 'styled-components';
export const StyledPeriodSelector = styled.div`
  .label {
    display: block;

    padding: 0 1rem;

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: ${(props) => props.theme.text4};

    @media screen and (min-width: 768px) {
      margin-bottom: 0.5rem;

      font-size: 1.4rem;
    }
  }

  .select-container {
    border: 1px solid ${(props) => (props.theme.id === 'dark' ? props.theme.bg6 : props.theme.bg5)};
    border-radius: 7px;

    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.bg6 : props.theme.accent3};

    @media screen and (min-width: 768px) {
      border-radius: 94px;
    }
    @media screen and (min-width: 1440px) {
      flex-shrink: 0;
      flex-grow: 1;

      width: 41.3rem;
    }

    .select {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0.5rem;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        padding: 0;
      }

      .select-element {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 3.2rem;

        padding: 0.5rem 1rem;

        font-size: 1.4rem;
        line-height: 150%;
        font-weight: 700;
        text-align: center;

        border-radius: 94px;

        cursor: pointer;

        &:hover {
          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2};
        }

        &.selected {
          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent3};

          background: ${(props) =>
            props.theme.id === 'dark' ? props.theme.text1 : props.theme.bg6};
        }

        @media screen and (min-width: 768px) {
          height: 3.9rem;

          font-size: 1.8rem;
          font-weight: 600;
        }
      }
    }
  }
`;
