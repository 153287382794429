import styled from 'styled-components';
import { Layout } from 'components';
import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledContent = styled.div`
  max-width: 153rem;
  margin: auto;
  text-align: center;
  padding: 0 1rem 5rem;
`;

const StyledHeading = styled.h1`
  font-size: 14rem;
  font-weight: 700;
  line-height: 150%;
  font-style: normal;
  background: linear-gradient(100deg, #68c3d0 12.26%, #743dd7 62.83%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (min-width: 768px) {
    font-size: 37.3rem;
  }
`;

const StyledH3 = styled.h3`
  margin-bottom: 1rem;
`;

const StyledText = styled.p`
  max-width: 45rem;
  margin: auto;
`;

export const Page404 = () => {
  const error = useRouteError() as { statusText: string; message: string };
  console.error(error);

  const { t } = useTranslation();

  return (
    <Layout home>
      <StyledContent>
        <StyledHeading>{t('errorPage.404')}</StyledHeading>
        <StyledH3>{t('errorPage.heading1')}</StyledH3>
        <StyledText className="p1 semibold">{t('errorPage.text1')}</StyledText>
      </StyledContent>
    </Layout>
  );
};
