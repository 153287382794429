import styled from 'styled-components';
import { StyledLogoContainer } from 'components/shared/Logo/styled';

export const StyledConfirmation = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.bg1};
  padding-top: 17.8rem;
  text-align: center;

  .to-main-button {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }
`;

export const StyledContainer = styled.div`
  text-align: center;
  margin-bottom: 6.6rem;
  @media screen and (min-width: 768px) {
    margin-bottom: 12.6rem;
  }
  ${StyledLogoContainer} {
    margin: auto;
    width: 20rem;
    height: 7rem;
    @media screen and (min-width: 768px) {
      width: 33rem;
      height: 11.1rem;
    }
  }
`;

export const StyledMessage = styled.p`
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text2)};
  max-width: 40.8rem;
  margin: auto;
  margin-top: 1.6rem;
`;
