import { IReferralPayout, IReferralReward, IReferralUser } from 'types';
import { v4 as uuid } from 'uuid';

export const generateTestReferalRewards = (quantity: number = 20): IReferralReward[] => {
  const rewards: IReferralReward[] = [...Array(quantity)].map((_) => ({
    id: uuid(),
    date: Date.now() + Math.floor(Math.random() * 10000000000),
    sum: (Math.random() * 100).toFixed(10),
  }));
  return rewards;
};
export const generateTestReferalPayout = (quantity: number = 20): IReferralPayout[] => {
  const payouts: IReferralPayout[] = [...Array(quantity)].map(() => ({
    id: uuid(),
    date: Date.now() + Math.floor(Math.random() * 10000000000),
    sum: (Math.random() * 100).toFixed(10),
  }));
  return payouts;
};
export const generateTestReferalUsers = (quantity: number = 20): IReferralUser[] => {
  const users: IReferralUser[] = [...Array(quantity)].map(() => ({
    id: uuid(),
    email: `text${Math.floor(Math.random() * 20)}@email-somewhetw.com`,
    registrationDate: Date.now() + Math.floor(Math.random() * 10000000000),
    currnetHashRate: Math.floor(Math.random() * 100000),
    monthlyHashRate: Math.floor(Math.random() * 100000),
  }));
  return users;
};
