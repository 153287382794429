import { ICharge } from 'types/charges';

export const generateTestCharges = (quantity: number = 20): ICharge[] => {
  const charges = [...Array(quantity)].map<ICharge>(() => ({
    date: Date.now() + Math.floor(Math.random() * 10000000000),
    hashRate: String(Math.random() * 100),
    generalAccural: (Math.random() + 1).toFixed(4),
    chargesPerUnit: (Math.random() + 1).toFixed(4),
  }));

  return charges;
};
