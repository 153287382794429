import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Calendar from 'react-calendar';
import { ReactComponent as ArrowBack } from 'assets/icons/pagination-arrow-back-icon.svg';
import { ReactComponent as ArrowForward } from 'assets/icons/pagination-arrow-forward-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-cross.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';
import { formateDate } from 'utils';
import {
  StyledCalendarContainer,
  StyledCalenderInputGroup,
  StyledCustomCalendar,
  StyledDateRangeInput,
} from './styles';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
interface ICustomCalendarProps {
  onChange?: (dateRange: { from: Date; to: Date }) => void;
}

export const CustomCalendar = ({ onChange }: ICustomCalendarProps) => {
  const { t } = useTranslation();
  const shortWeekdays = t('calendar.weekdaysShort', { returnObjects: true }) as string[];

  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [valueCalendar, onCalendarChange] = useState<Value>(new Date());
  const [rangeInputValue, setRangeInputValue] = useState('');

  const toggleCalendar = () => setIsShowCalendar((prev) => !prev);

  const closeCalendar = () => setIsShowCalendar(false);

  const calendarChangeHandler = (value: Value) => {
    if (!value) return;
    onCalendarChange(value);

    if (value instanceof Date) {
      onChange && onChange({ from: value, to: value });
      return;
    }

    if (!value[0] || !value[1]) return;

    const from = value[0];
    const to = value[1];

    onChange && onChange({ from, to });
  };

  const changeInputHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setRangeInputValue(value);
  };

  const setPastRange = (dayPrior: number) => () => {
    const msPerday = 1000 * 60 * 60 * 24;
    const now = new Date();
    const end = new Date(now.getTime() - msPerday);
    const start = new Date(now.getTime() - msPerday * dayPrior);
    calendarChangeHandler([start, end]);
    console.log(start, end);
  };

  useEffect(() => {
    if (Array.isArray(valueCalendar) && valueCalendar[0] && valueCalendar[1]) {
      const dateStart = formateDate(valueCalendar[0]);
      const dateEnd = formateDate(valueCalendar[1]);
      setRangeInputValue(`${dateStart}-${dateEnd}`);
    } else if (valueCalendar instanceof Date) {
      const date = formateDate(valueCalendar);
      setRangeInputValue(`${date}-${date}`);
    }
  }, [valueCalendar]);
  return (
    <StyledCalendarContainer>
      <StyledCalenderInputGroup className={cn('calendar-input-group', isShowCalendar && 'active')}>
        <StyledDateRangeInput
          className={cn(isShowCalendar && 'active')}
          type="text"
          value={rangeInputValue}
          onChange={changeInputHandler}
          onFocus={() => setIsShowCalendar(true)}
        ></StyledDateRangeInput>
        <button onClick={toggleCalendar} className="calendar-toggle-button">
          <CalendarIcon className="calendar-icon" />
        </button>
      </StyledCalenderInputGroup>
      {isShowCalendar && (
        <div className="calendar-back-layer" onMouseLeave={closeCalendar}>
          <StyledCustomCalendar>
            <Calendar
              value={valueCalendar}
              onChange={calendarChangeHandler}
              formatShortWeekday={(_, date) => shortWeekdays[date.getDay()]}
              nextLabel={<ArrowForward />}
              prevLabel={<ArrowBack />}
              selectRange
            />
            <button className="periodic" onClick={setPastRange(30)}>
              {t('calendar.pastMonth')}
            </button>
            <button className="periodic" onClick={setPastRange(7)}>
              {t('calendar.pastWeek')}
            </button>
            <button className="close-button" onClick={closeCalendar}>
              <CloseIcon />
            </button>
          </StyledCustomCalendar>
        </div>
      )}
    </StyledCalendarContainer>
  );
};
