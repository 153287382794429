import { Button, NewPasswordInput, PasswordInput } from 'components';
import { StyledChangePassword } from './styles';
import { useTranslation } from 'react-i18next';
import { MainSettingsComponent } from '../MainSettingsComponent';
interface IChangePasswordProps {
  children?: React.ReactNode;
}
export const ChangePassword = ({ children }: IChangePasswordProps) => {
  const { t } = useTranslation();
  return (
    <StyledChangePassword>
      <MainSettingsComponent heading={t('settingsPage.changePassword.heading')}>
        <form className="change-password-form">
          <div className="change-password-form__input-groups">
            <div className="change-password-form__input-group">
              <PasswordInput
                labelText={t('settingsPage.changePassword.oldPassword.label')}
                placeholder={t('settingsPage.changePassword.oldPassword.placeholder')}
              />
            </div>
            <div className="change-password-form__input-group">
              <NewPasswordInput
                labelText={t('settingsPage.changePassword.oldPassword.label')}
                placeholder={t('settingsPage.changePassword.newPassword.placeholder')}
              />
            </div>
          </div>
          <Button
            className="change-password-form__submit-button"
            title={t('settingsPage.changePassword.submitButton')}
          ></Button>
        </form>
      </MainSettingsComponent>
    </StyledChangePassword>
  );
};
