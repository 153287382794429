import styled from 'styled-components';

export const StyledFooter = styled.footer`
  position: relative;
  background-color: ${(props) => props.theme.bgFooter};

  &.smaller {
    border-radius: 15px;
  }
`;
