import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Formik, FormikHelpers } from 'formik';
import { Button, Confirmation, Form, Input, Layout, Modal } from 'components';
import { EFromStates, IRecoverPasswordData } from 'types';
import { validationSchemaRecovery } from 'lib';
import { AccountRepository } from 'repositories/AccountRepository';
import { EApiResponseStatus } from 'types/api';

const StyledContent = styled.div`
  max-width: 60.6rem;
  margin: auto;
  padding: 2rem 1rem 8.8rem;
  text-align: center;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 768px) {
    padding: 5rem 1rem 12.1rem;
  }
  .recovery-form-container {
    margin-bottom: 1.5rem;

    .login-form_buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 0.1rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 0.8rem;
      }
    }
  }

  .register-link-container,
  .login-link-container {
    padding: 0.7rem 0;
    width: max-content;
    margin: auto;
    display: flex;
    gap: 1.5rem;
  }
  .register-link,
  .login-link {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text1)};
  }
`;

const StyledFormHeader = styled.p`
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  margin-bottom: 0.5rem;

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
    font-weight: 600;
  }
`;

const StyledFormSubheading = styled.p`
  max-width: 28rem;
  margin: auto;
  margin-bottom: 2.5rem;
`;

const StyledInputsGroup = styled.div`
  max-width: 39rem;
  margin: auto;
  margin-bottom: 2rem;
`;

export const RecoveryPage = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState<EFromStates>(EFromStates.initial);

  interface IRecoveryFormValue {
    userEmailPhone: string;
    formState: EFromStates;
    formErrors: '';
  }

  const formikSubmitHandler = async (
    values: IRecoveryFormValue,
    helpers: FormikHelpers<IRecoveryFormValue>,
  ) => {
    const { userEmailPhone } = values;
    const recoverPasswordData: IRecoverPasswordData = {
      emailPhone: userEmailPhone,
    };
    try {
      setFormState(EFromStates.submitting);
      const result = await AccountRepository.recoverPassword(recoverPasswordData);
      if (result.status === EApiResponseStatus.FAILURE) throw Error(result.error);
      setShowModal(true);
      setFormState(EFromStates.initial);
    } catch (error) {
      setFormState(EFromStates.failure);
      console.error(error);
      if (error instanceof Error) {
        helpers.setErrors({ formErrors: error.message });
      }
      helpers.setErrors({ formErrors: 'submit error' });
    }
  };

  return (
    <Layout home>
      {showModal && (
        <Modal show={showModal}>
          <Confirmation type="passwordRecovery" />
        </Modal>
      )}
      <StyledContent>
        <div className="recovery-form-container">
          <Formik
            initialValues={{
              userEmailPhone: '',
              formState: EFromStates.initial,
              formErrors: '',
            }}
            onSubmit={formikSubmitHandler}
            validationSchema={validationSchemaRecovery}
          >
            {({
              errors,
              touched,
              isSubmitting,
              values,
              handleSubmit,
              handleBlur,
              handleChange,
            }) => (
              <Form
                header={<StyledFormHeader>{t('forms.recovery.header')}</StyledFormHeader>}
                onSubmit={handleSubmit}
                formState={formState}
                setFormState={setFormState}
                errorMessage={errors.formErrors}
              >
                <StyledFormSubheading className="p3">
                  {t('forms.recovery.subheader')}
                </StyledFormSubheading>
                <StyledInputsGroup>
                  <Input
                    name="userEmailPhone"
                    placeholder={t('forms.recovery.loginPlaceHolder')}
                    inValid={!!errors.userEmailPhone && touched.userEmailPhone}
                    error={errors.userEmailPhone}
                    disabled={isSubmitting}
                    value={values.userEmailPhone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </StyledInputsGroup>

                <div className="login-form_buttons">
                  <Button type="submit" title={t('forms.recovery.submitButton')}></Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="register-link-container">
          <p className="p3">{t('forms.login.notRegister')}</p>
          <Link className="register-link" to="/registration">
            <span className="p2">{t('forms.login.register')}</span>
          </Link>
        </div>
        <div className="login-link-container">
          <p className="p3">{t('forms.recovery.knowPassword')}</p>
          <Link className="login-link" to="/login">
            <span className="p2">{t('forms.recovery.login')}</span>
          </Link>
        </div>
      </StyledContent>
    </Layout>
  );
};
