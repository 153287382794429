import { css } from 'styled-components';

export const StyledAdminUsersRowsWidth = css`
  &:not(.comment) {
    flex-grow: 0;
    flex-basis: initial;
  }

  &.id {
    width: 7.6rem;
  }

  &.email {
    width: 9.4rem;
  }

  &.cost {
    width: 8rem;
  }

  &.adjust {
    width: 9.8rem;
  }

  &.link {
    width: 5.2rem;
  }
  &.wallet {
    width: 14.4rem;
  }
  &.vip {
    width: 6.4rem;
  }
  &.daily {
    width: 17.2rem;
  }
  &.weekly {
    width: 17.2rem;
  }
  &.created {
    width: 16.4rem;
  }
  &.updated {
    width: 16.4rem;
  }
  &.comment {
    flex-grow: 1;
  }
`;
