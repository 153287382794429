import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledCreateWorkersGroupContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCreateWorkersGroupDialog = styled.dialog`
  max-height: 100dvh;
  overflow: scroll;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  position: relative;
  border-radius: 15px;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  background-color: ${(props) => props.theme.bg3};
  width: 63rem;

  padding: 2rem 3rem;

  .submit-error {
    position: absolute;
    right: 10%;

    font-size: 1.8rem;
    color: ${darkTheme.errorRed};
  }

  .submit-success {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 0.95;
    background-color: ${(props) => props.theme.bg1};
    color: ${(props) => props.theme.button1};
  }

  .heading {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    margin-bottom: 3rem;
  }
`;

export const StyledCreateGroupForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .submit-button-container {
    display: flex;
    justify-content: center;
  }
`;

export const StyledInputGroup = styled.div`
  .new-group-label {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1)};

    font-size: 1.6rem;
    font-weight: 400;
    line-height: 150%; /* 24px */
    display: block;
    margin-bottom: 0.5rem;
  }

  .new-group-name-input {
    border-radius: 8px;
    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};
    border-radius: 8px;
    color: ${(props) => props.theme.text1};

    padding: 1rem;
    width: 100%;

    font-size: 1.8rem;
    font-weight: 700;
    line-height: 150%; /* 27px */
    &::placeholder {
      color: ${(props) => props.theme.text3};
    }
  }
  .add-worker-select-button-container {
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.accent2};
    background: ${(props) => props.theme.bg3};
    color: ${(props) => props.theme.text1};

    padding: 1rem;
    width: 100%;

    .add-worker-select-button {
      display: block;
      width: 3rem;
      height: 3rem;
      margin: auto;

      > * {
        width: 100%;
        height: 100%;

        fill: ${(props) => props.theme.button1};

        *[fill] {
          fill: ${(props) => props.theme.text1};
        }
      }
    }
  }
`;

export const StyledAddWorkerToGroupSelectContainer = styled.div``;
export const StyledAddWorkerToGroupSelect = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-accent-2, #7456ca);
  background: var(--Dark-mode-bg-secondary-2, #240f59);

  color: var(--Dark-mode-font-1, #fcfaff);

  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  padding: 1rem;

  .select-dropdown-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);

    width: 2.4rem;
    height: 2.4rem;

    > * {
      width: 100%;
      height: 100%;
    }
  }
`;
export const StyledAddWorkerToGroupSelectDropdown = styled.div``;

export const StyledCloseButton = styled.button`
  position: absolute;
  right: 0.8rem;
  top: 0.6rem;

  display: block;
  width: 3rem;
  height: 3rem;

  @media screen and (min-width: 768px) {
    right: 2rem;
    top: 2rem;
  }

  > * {
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.button2)};
  }

  &:hover,
  &:active {
    > * {
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5)};
    }
  }
`;
