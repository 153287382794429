import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as StatisticsIcon } from 'assets/icons/statistics-icon.svg';
import { ReactComponent as WorkersIcon } from 'assets/icons/workers-icon.svg';
import { ReactComponent as PayoutIcon } from 'assets/icons/payout-icon.svg';
import { ReactComponent as ProfitIcon } from 'assets/icons/profit-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings-icon.svg';
import { ReactComponent as ReferralIcon } from 'assets/icons/referral-icon.svg';

import { StyledUserBarMenu } from './styles';
import { Routes } from 'lib';
import { useTranslation } from 'react-i18next';
import { UserBarNavElement } from '../UserBarNavElement';
interface IUserBarMenuProps {
  children?: React.ReactNode;
  open?: boolean;
}
export const UserBarMenu = ({ open }: IUserBarMenuProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigationHandler = (path: string) => {
    navigate(path);
  };

  const statisticsPath = Routes.statisticsPath();
  const workersPath = Routes.workersPath();
  const payoutPath = Routes.payoutPath();
  const profitPath = Routes.profitPath();
  const settingsPath = Routes.settingsPath();
  const referalPath = Routes.referralsPath();

  return (
    <StyledUserBarMenu>
      <ul className={cn('sidebar-nav-list', open && 'open')}>
        <UserBarNavElement
          onClick={() => navigationHandler(statisticsPath)}
          active={statisticsPath === pathname}
          icon={<StatisticsIcon />}
        >
          {t('navigation.statistics')}
        </UserBarNavElement>
        <UserBarNavElement
          onClick={() => navigationHandler(workersPath)}
          active={workersPath === pathname}
          icon={<WorkersIcon />}
        >
          {t('navigation.workers')}
        </UserBarNavElement>
        <UserBarNavElement
          onClick={() => navigationHandler(payoutPath)}
          active={payoutPath === pathname}
          icon={<PayoutIcon />}
        >
          {t('navigation.payout')}
        </UserBarNavElement>
        <UserBarNavElement
          onClick={() => navigationHandler(profitPath)}
          active={profitPath === pathname}
          icon={<ProfitIcon />}
        >
          {t('navigation.profit')}
        </UserBarNavElement>
        <UserBarNavElement
          onClick={() => navigationHandler(settingsPath)}
          active={settingsPath === pathname}
          icon={<SettingsIcon />}
        >
          {t('navigation.settings')}
        </UserBarNavElement>
        <UserBarNavElement
          onClick={() => navigationHandler(referalPath)}
          active={referalPath === pathname}
          icon={<ReferralIcon />}
        >
          {t('navigation.referral')}
        </UserBarNavElement>
      </ul>
    </StyledUserBarMenu>
  );
};
