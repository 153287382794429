import styled from 'styled-components';
export const StyledCustomCheckbox = styled.div`
  position: relative;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};

  &:hover {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2)};
  }
  .checkbox-input {
    position: absolute;
    width: 0;
    height: 0;
    border: none;
    visibility: hidden;
    &:checked ~ {
      .checkbox-label {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2)};

        .checkbox-label__icon {
          *[fill] {
            fill: currentColor;
          }
        }
      }
    }
  }
  .checkbox-label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    width: 1.6rem;
    height: 1.6rem;

    .checkbox-label__icon {
      width: 100%;
      height: 100%;
      *[stroke] {
        stroke: currentColor;
      }
    }
  }
`;
