import styled from 'styled-components';
export const StyledWorkerGroupsDropdown = styled.div`
  position: absolute;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

  border-radius: 5px;
  padding: 0.7rem 1.5rem;
  padding-right: 3.2rem;
  width: 100%;

  top: 0;
  left: 0;
  z-index: 0;
  padding: 1.5rem;
  padding-top: calc(4.1rem + 0.5rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
