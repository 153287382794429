import styled from 'styled-components';

export const StyledSocialMediaIcon = styled.div`
  width: 2rem;
  height: 2rem;

  > * {
    transition: all 0.2s;
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
    width: 100%;
    height: 100%;
  }
`;

export const StyledSocialMediaLink = styled.a`
  display: block;
  width: 4.1rem;
  height: 4.1rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.button3 : props.theme.accent3};

  &:hover {
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.button1};

    ${StyledSocialMediaIcon} {
      > * {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.font2)};
      }
    }
  }
`;
