import cn from 'classnames';
import { StyledVideoDialog, StyledVideoDialogContainer, StyledVideoDialogOverlay } from './styles';
import { useEffect, useState } from 'react';
interface IVideoDialogProps {
  children?: React.ReactNode;
  onClose: () => void;
  show?: boolean;
}
export const VideoDialog = ({ onClose, show }: IVideoDialogProps) => {
  const [showContent, setShowContent] = useState(show);
  const closeHandler = () => {
    onClose();
    setShowContent(false);
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowContent(() => true), 100);
    }
  }, [show]);
  return (
    <StyledVideoDialogContainer className="dialod">
      <StyledVideoDialogOverlay onClick={closeHandler} className={cn(showContent && 'show')} />
      <StyledVideoDialog open={showContent}>
        <iframe
          src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=X18JgUECEnLve6f8&autoplay=1&color=white"
          title="Rick Roll"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </StyledVideoDialog>
    </StyledVideoDialogContainer>
  );
};
