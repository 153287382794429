import styled from 'styled-components';

export const StyledArrowIcon = styled.div`
  width: 1.2rem;
  display: flex;
  align-items: center;
  position: relative;

  > * {
    stroke: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
    position: relative;
    top: 1px;
    transition: all 0.2s;
  }
`;
