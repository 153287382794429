import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledUserBarNavElement = styled.li`
  position: relative;

  width: 5rem;
  height: 5rem;

  margin: auto;

  border-radius: 10px;

  font-size: 2rem;
  line-height: 150%;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text5 : props.theme.text3)};

  &:hover,
  &.active {
    color: ${darkTheme.accent1};

    background-color: ${(props) => props.theme.button2};
    box-shadow: 1px 15px 19px 0px rgba(0, 0, 0, 0.15);
  }

  @media screen and (min-width: 1440px) {
    width: auto;
    height: auto;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .user-bar-nav-element-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    width: 100%;
    height: 100%;

    font-size: inherit;
    line-height: inherit;

    @media screen and (min-width: 1440px) {
      padding: 1.3rem 1.6rem;
      justify-content: flex-start;
    }

    .user-bar-nav-element-icon-container {
      display: flex;
      align-items: center;

      width: 3rem;
      height: 3rem;

      color: inherit;

      @media screen and (min-width: 1440px) {
        width: 2.4rem;
        height: 2.4rem;
      }

      > * {
        width: 100%;

        fill: currentColor;
        stroke: currentColor;
      }
    }

    .user-bar-nav-element-title {
      display: none;

      @media screen and (min-width: 1440px) {
        display: block;
      }
    }
  }
`;
