import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledLanguageSelect = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  margin-bottom: 1.3rem;

  .language-select-label {
    display: block;
    padding: 0 1rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
  }

  .language-select-value-container {
    position: relative;
    z-index: 1;

    padding: 0.7rem 1rem;
    padding-right: 3rem;

    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    border-radius: 8px;

    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

    .language-select-value {
      display: block;

      width: 100%;

      font-size: 1.2rem;
      font-weight: 400;
      line-height: 150%;
      text-align: start;

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;

        font-size: 1.4rem;
        line-height: 150%; /* 18px */
      }

      &__hint {
        display: none;

        color: ${({ theme }) => (theme.id === 'dark' ? theme.accent2 : darkTheme.accent2)};

        @media screen and (min-width: 768px) {
          display: inline-block;
        }
      }
    }

    .language-select-dropdown-button {
      position: absolute;
      top: 0;
      right: 1rem;

      display: flex;
      align-items: center;

      height: 100%;

      .icon {
        width: 100%;
        fill: currentColor;
      }
    }
  }

  .language-select-dropdown-container {
    position: absolute;
    bottom: 1rem;
    z-index: 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    overflow: hidden;

    width: 100%;
    height: 0;

    padding: 0.8rem 1rem;
    padding-top: 0;

    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    border-radius: 8px;

    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

    transform: translateY(100%);

    &.active {
      height: auto;

      padding-top: 1.5rem;
    }

    .language-select-dropdown-element {
      display: block;
      text-align: start;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 150%;

      &:hover:not(.selected) {
        color: ${({ theme }) => (theme.id === 'dark' ? theme.accent1 : theme.accent5)};
      }
      &.selected {
        color: ${({ theme }) => (theme.id === 'dark' ? theme.accent2 : theme.text5)};
      }

      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
        line-height: 150%;
      }
    }
  }
`;
