import { memo } from 'react';
import { BorderedWrapper, StatisticsDataComponent } from 'components';
import { StyledMiner, StyledMinerData, StyledMinerFooterDataElement } from './styles';
import { useTranslation } from 'react-i18next';
import { Routes } from 'lib';
import { useAppSelector } from 'store/store';
import { selectMiner } from 'store/slices/userStatisticsSlice';
import { ESliceDataFetchingStatus } from 'types/common';

const renderData = (value: number | null, heading: string) => (
  <StyledMinerData>
    <div className="miner-value">
      <h4 className="miner-value_header">{heading}</h4>
      <p className="miner-value_data">{value !== null ? String(value) : 'n/a'}</p>
    </div>
  </StyledMinerData>
);
const renderFooter = (data: { heading: string; value: number | null }[]) => {
  return (
    <>
      {data.map((el, index) => (
        <StyledMinerFooterDataElement key={el.heading + index} className="row">
          <div className="data">{el.value !== null ? String(el.value) : 'n/a'}</div>
          <div className="reference">{el.heading}</div>
        </StyledMinerFooterDataElement>
      ))}
    </>
  );
};
const MinerComponent = () => {
  const { t } = useTranslation();
  const { all, offline, inactive, online, status } = useAppSelector(selectMiner);

  const footerData = [
    { heading: t('statisticsPage.miner.all'), value: all },
    { heading: t('statisticsPage.miner.offline'), value: offline },
    { heading: t('statisticsPage.miner.inactive'), value: inactive },
  ];

  return (
    <StyledMiner>
      <BorderedWrapper>
        {status === ESliceDataFetchingStatus.initial ? (
          'loading...'
        ) : (
          <StatisticsDataComponent
            heading={t('statisticsPage.miner.heading')}
            link={{ to: Routes.settingsPath(), title: t('statisticsPage.miner.settingsLink') }}
            data={renderData(online, t('statisticsPage.miner.online'))}
            footer={renderFooter(footerData)}
          />
        )}
      </BorderedWrapper>
    </StyledMiner>
  );
};

export const Miner = memo(MinerComponent);
