import styled from 'styled-components';
export const StyledAboutHero = styled.section`
  margin-bottom: 4rem;
  padding-top: 1.7rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 10rem;
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 10rem;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 12rem;
  }
  .about-hero-content {
    .about-hero-heros {
      position: relative;

      overflow: hidden;

      width: 30rem;
      height: 23rem;

      margin: auto;
      margin-bottom: 1.2rem;

      padding: 3.6rem 1.5rem 0;

      background-image: url('/images/bg/about-folder-yellow-dark-sx.svg');
      background-repeat: no-repeat;

      @media screen and (min-width: 768px) {
        width: 70rem;
        height: 47rem;

        margin-bottom: 4rem;
        padding-top: 7.5rem;

        background-image: url('/images/bg/about-folder-yellow-dark-sm.svg');
      }

      @media screen and (min-width: 1024px) {
        width: 95rem;
        height: 37rem;

        padding: 4rem;
        padding-top: 4.5rem;

        background-image: url('/images/bg/about-folder-yellow-dark-md.svg');
      }

      @media screen and (min-width: 1440px) {
        width: 132rem;
        padding: 7.5rem;

        margin-bottom: 5rem;

        padding-top: 9.8rem;

        background-image: url('/images/bg/about-folder-yellow-dark-lg.svg');
      }

      .heading {
        font-size: 3.2rem;
        line-height: 110%;
        font-weight: 600;
        letter-spacing: -0.32px;
        color: ${(props) => (props.theme.id === 'dakr' ? props.theme.text3 : props.theme.text3)};

        @media screen and (min-width: 768px) {
          font-size: 5.5rem;
          text-align: center;
        }

        @media screen and (min-width: 1024px) {
          font-size: 8rem;
          letter-spacing: -0.8px;
          text-align: start;
        }
      }

      .about-hero-heros-team {
        position: absolute;
        bottom: -2rem;
        left: 50%;
        transform: translateX(-50%);

        width: 24.4rem;

        @media screen and (min-width: 768px) {
          width: 55.3rem;
          bottom: -10.3rem;
        }

        @media screen and (min-width: 1024px) {
          left: unset;
          transform: unset;
          right: 3.8rem;
        }

        @media screen and (min-width: 1440px) {
          right: 7rem;
          width: 71.9rem;
          bottom: -13rem;
        }
      }
    }

    .about-hero-texts {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      margin: auto;

      max-width: 30rem;

      font-size: 1.4rem;
      line-height: 150%;
      font-weight: 500;

      @media screen and (min-width: 768px) {
        gap: 2rem;

        max-width: 70rem;

        font-size: 1.8rem;
      }

      @media screen and (min-width: 1024px) {
        flex-direction: row;
        gap: 6rem;

        max-width: 95rem;

        .about-hero-text {
          flex-basis: 50%;
        }
      }

      @media screen and (min-width: 1440px) {
        max-width: 132rem;
      }
    }
  }
`;
