import styled from 'styled-components';

export const StyledData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-grow: 1;

  padding: 1rem 0;

  border-top: 1px solid ${(props) => props.theme.bg5};
`;
