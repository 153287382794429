import styled from 'styled-components';
import { StyledAdminUsersRowsWidth } from './StyledAdminUsersRowsWidth';
import { StyledAdminListRow } from '../../AdminListHeaderCell/styles/StyledAdminListRow';

export const StyledAdminUsersListRow = styled.div`
  ${StyledAdminListRow}

  .admin-list-cell-container {
    ${StyledAdminUsersRowsWidth}
  }
`;
