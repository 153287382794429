import { useTranslation } from 'react-i18next';
import { StyledAdminList } from './styles';
interface IAdminListProps {
  children?: React.ReactNode;
  renderControls: () => React.ReactNode;
  renderPaginator?: () => React.ReactNode;
}
export const AdminList = ({ children, renderControls, renderPaginator }: IAdminListProps) => {
  const { t } = useTranslation();
  return (
    <StyledAdminList>
      <header className="admin-list-header">
        <div className="admin-list-header__controls">{renderControls()}</div>

        <button className="refresh-button">{t('adminPage.refresh')}</button>
      </header>
      <div className="admin-list-content">{children}</div>
      <footer className="admin-list-footer">{renderPaginator?.()}</footer>
    </StyledAdminList>
  );
};
