import cn from 'classnames';
import { StyledSecondaryButton } from './styled';

interface ISecondaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  icon?: React.ReactNode;
  outline?: boolean;
  iconFirst?: boolean;
}
export const SecondaryButton = (props: ISecondaryButtonProps) => {
  const { children, icon, outline, onClick, title, iconFirst } = props;
  return (
    <StyledSecondaryButton
      className={cn(outline && 'outline', iconFirst && 'icon-first')}
      onClick={onClick}
    >
      {title}
      {children}
      {icon && <div className="button-icon">{icon}</div>}
    </StyledSecondaryButton>
  );
};
