import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledSelect = styled.div`
  width: 3.2rem;
  height: 3.2rem;

  border-radius: 80px;
  background: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent1)};
  padding: 0.3rem;

  @media screen and (min-width: 768px) {
    width: 11.4rem;
    height: 5rem;
    padding: 0.6rem;
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    width: 5rem;
    justify-content: center;
  }
`;

export const StyledSelectOption = styled.div`
  width: 2.6rem;
  height: 2.6rem;

  @media screen and (min-width: 768px) {
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    gap: 1rem;
  }

  .value {
    display: none;

    color: ${darkTheme.accent1};
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 37.5px */

    @media screen and (min-width: 768px) {
      display: block;
    }
    @media screen and (min-width: 1024px) {
      display: none;
    }
    @media screen and (min-width: 1440px) {
      display: block;
    }
  }
`;

export const StyledIcon = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  > * {
    width: 100%;
    height: 100%;
  }
`;
