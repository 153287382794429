import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledDailyIncome = styled.div`
  .daily-income_label {
    text-align: start;
    color: ${darkTheme.text2};

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }
  }
`;

export const StyledAmountContainer = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
    font-weight: 600;
  }
`;
