import { useTranslation } from 'react-i18next';
import { StyledUserDataLayoutContainer } from './styles';
interface IUserDataLayoutContainerProps {
  children?: React.ReactNode;
  pageName?: string;
}
export const UserDataLayoutContainer = ({ children, pageName }: IUserDataLayoutContainerProps) => {
  return (
    <StyledUserDataLayoutContainer>
      <div className="content-container">
        <div className="bg"></div>
        <div className="bg-header"></div>
        <UserDataLayoutContainerHeading pageName={pageName} />
        <div className="content">{children}</div>
      </div>
    </StyledUserDataLayoutContainer>
  );
};

const UserDataLayoutContainerHeading = ({ pageName }: { pageName?: string }) => {
  const { t } = useTranslation();
  return (
    <h1 className="heading">
      <span className="dot"></span>
      {t(`${pageName}.heading`)}
    </h1>
  );
};
