import styled from 'styled-components';
import { darkTheme } from 'themes';

const StyledSvg = styled.svg`
  .play-triangle {
    fill: ${darkTheme.accent1};
  }
`;

export const PlayIcon = ({
  secondaryColor = '#483480',
  bgColor = '#483480',
}: {
  secondaryColor?: string;
  bgColor?: string;
}) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      className="play-icon"
    >
      <circle cx="15.5" cy="15.5" r="15.5" fill={bgColor} />
      <path
        className="play-triangle"
        d="M23.9687 15.4964L11.2593 22.8342L11.2593 8.1586L23.9687 15.4964Z"
        // fill={secondaryColor}
      />
    </StyledSvg>
  );
};
