import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledInformationField = styled.div``;

export const StyledLabel = styled.div`
  font-size: 1.2rem;
  line-height: 150%;
  font-weight: 400;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1)};

  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
  }
`;
export const StyledField = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 0.6rem;

  font-size: 1.2rem;
  font-weight: 600;
  line-height: 150%;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  border-radius: 4px;

  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent3};

  @media screen and (min-width: 768px) {
    padding: 1rem 2.5rem;

    border-radius: 1rem;

    font-size: 1.6rem;
  }

  .copy-success-message {
    position: absolute;
    right: 5.5rem;

    padding: 0.5rem 1rem;

    border-radius: 10px;

    color: ${(props) => props.theme.accent1};

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    background-color: ${darkTheme.accent2};
  }

  .worker-name-input-container {
    width: 100%;
    display: flex;
  }
`;

export const StyledWorkerInput = styled.input`
  background-color: transparent;
  color: inherit;
  border: none;
  outline: none;
  font: inherit;
  width: 80%;
  padding-right: 1rem;
  cursor: pointer;

  &::placeholder {
    padding: 0 1rem;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;
export const StyledCopyButton = styled.button`
  width: 2.4rem;
  height: 2.4rem;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  &:disabled {
    color: grey;
  }

  &:hover,
  &:active {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5)};
  }

  > * {
    width: 100%;
    height: 100%;
    stroke: currentColor;
  }
`;
