import { useTranslation } from 'react-i18next';
import { StyledEditGroupButton } from './styles';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
interface IEditGroupButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}
export const EditGroupButton = (props: IEditGroupButtonProps) => {
  const { t } = useTranslation();
  return (
    <StyledEditGroupButton {...props}>
      <div className="icon">
        <EditIcon />
      </div>
      <span className="title">{t('workersPage.groups.editGroupButton')}</span>
    </StyledEditGroupButton>
  );
};
