import styled from 'styled-components';
export const StyledAdminListRowCell = styled.div`
  padding: 0.3rem 0.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 150%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
