import {
  AdminElementsWrapper,
  Button,
  DisplayItemsPerPageSelect,
  Paginator,
  SecondaryButton,
} from 'components';
import { StyledAdminEditUsersList } from './styles';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../SearchInput';
import { DataExportButton } from '../DataExportButton';
import { UsersList } from './components';
import { useMemo, useState } from 'react';
import { generateMockUsersList } from 'data/mockUserList';
interface IAdminEditUsersListProps {
  children?: React.ReactNode;
}
const usersList = generateMockUsersList(200);
export const AdminEditUsersList = ({ children }: IAdminEditUsersListProps) => {
  const { t } = useTranslation();
  const DEFAULT_ITEMS_PER_PAGE_QUANTITY = 10;
  const DEFAULT_PAGE_NUMBER = 1;

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE_QUANTITY);
  const [searchFilter, setSearchFilter] = useState('');

  const filteredUsers = usersList;
  const searchFilteredUsers = useMemo(
    () =>
      searchFilter.length === 0
        ? filteredUsers
        : filteredUsers.filter((user) =>
            user.email.toLowerCase().includes(searchFilter.toLowerCase()),
          ),
    [searchFilter, filteredUsers],
  );

  const sortedUsersList = searchFilteredUsers;
  const paginatedUsersList = useMemo(
    () => sortedUsersList.slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage),
    [currentPage, itemsPerPage, sortedUsersList],
  );
  const pagesQuanity = Math.ceil(sortedUsersList.length / itemsPerPage);

  const changeCurrentPageHandler = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const changeItemsPerPageQuantityHAndler = (itemsQuantity: number) => {
    setItemsPerPage(itemsQuantity);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const setSearchFilterHandler = (value: string) => {
    setSearchFilter(value);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  return (
    <StyledAdminEditUsersList>
      <AdminElementsWrapper>
        <header className="admin-user-list-header">
          <h3 className="heading">{t('adminPage.editUsers.heading')}</h3>
          <button className="refresh-button">{t('adminPage.refresh')}</button>
        </header>

        <div className="list-controls">
          <div className="list-controls-group">
            <Button title={t('adminPage.editUsers.massEdit')} />
            <SecondaryButton outline title={t('adminPage.editUsers.ban')} />
          </div>
          <div className="list-controls-group">
            <SearchInput onSearch={setSearchFilterHandler} />
            <DataExportButton />
          </div>
        </div>
        <div className="list-body">
          <UsersList usersList={paginatedUsersList} />
          <div className="list-body-pagination">
            <DisplayItemsPerPageSelect
              onChange={changeItemsPerPageQuantityHAndler}
              currentItemsPerPageQuantity={itemsPerPage}
              itemsPerPageRates={[10, 15, 25, 50]}
            />
            <Paginator
              onChange={changeCurrentPageHandler}
              pageQuantity={pagesQuanity}
              currentPage={currentPage}
            />
          </div>
        </div>
      </AdminElementsWrapper>
    </StyledAdminEditUsersList>
  );
};
