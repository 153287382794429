import { Button } from 'components';
import { StyledBTCAddress } from './styles';
import { useTranslation } from 'react-i18next';
import { BTCInput } from 'components/shared/Input/BTCInput';
import { MainSettingsComponent } from '../MainSettingsComponent';
interface IBTCAddressProps {
  children?: React.ReactNode;
}
export const BTCAddress = ({ children }: IBTCAddressProps) => {
  const { t } = useTranslation();

  const isFocus = Boolean(new URLSearchParams(window.location.search).get('focus'));

  return (
    <StyledBTCAddress>
      <MainSettingsComponent heading={t('settingsPage.btcAddressForm.heading')}>
        <form className="btc-address-form">
          <BTCInput
            className="btc-address-form__input"
            placeholder={t('settingsPage.btcAddressForm.placeholder')}
            isFocus={isFocus}
          />
          <Button
            className="btc-address-form__button"
            title={t('settingsPage.btcAddressForm.submitButton')}
          ></Button>
        </form>
        {children}
      </MainSettingsComponent>
    </StyledBTCAddress>
  );
};
