import {
  StyldBlogCardImage,
  StyledBlogCard,
  StyledBlogCardDate,
  StyledBlogCardHeading,
  StyledBlogCardInfo,
  StyledBlogCardText,
} from './styles';

interface IBlogCardProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  header: string;
  text: string;
  date: string;
}
export const BlogCard = (props: IBlogCardProps) => {
  const { image, header, text, date, onClick } = props;
  const truncateString = (str: string) => `${str.slice(0, 98)}...`;
  return (
    <StyledBlogCard onClick={onClick}>
      <StyldBlogCardImage>
        <img src={image} alt={header} />
      </StyldBlogCardImage>
      <StyledBlogCardInfo>
        <StyledBlogCardHeading>{header}</StyledBlogCardHeading>
        <StyledBlogCardText>{truncateString(text)}</StyledBlogCardText>
        <StyledBlogCardDate>{date}</StyledBlogCardDate>
      </StyledBlogCardInfo>
    </StyledBlogCard>
  );
};
