import { Link } from 'react-router-dom';
import { StyledData, StyledFooter, StyledHeader, StyledStatisticsDataComponent } from './styles';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-sharp.svg';
interface IStatisticsDataComponentProps {
  heading: string;
  link?: { to: string; title: string };
  data?: React.ReactElement;
  children?: React.ReactNode;
  footer?: React.ReactElement;
}
export const StatisticsDataComponent = ({
  link,
  heading,
  data,
  footer,
}: IStatisticsDataComponentProps) => {
  return (
    <StyledStatisticsDataComponent>
      <StyledHeader>
        <h2 className="h2">{heading}</h2>
        {link && (
          <Link className="payout-link" to={link.to}>
            <span className="payout-link__text">{link.title}</span>
            <ArrowRightIcon className="arrow" />
          </Link>
        )}
      </StyledHeader>
      <StyledData>{data}</StyledData>
      <StyledFooter>{footer}</StyledFooter>
    </StyledStatisticsDataComponent>
  );
};
