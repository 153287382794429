import styled from 'styled-components';
export const StyledChargeListElement = styled.div`
  display: flex;

  margin-bottom: 0.2rem;

  .charges-list-element-col {
    flex-basis: 7rem;
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.4rem;

    border-right: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};

    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    word-break: break-all;

    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.bg1)};

    &:last-child {
      border-right: none;
    }

    &.date-time {
      .time {
        display: none;

        @media screen and (min-width: 768px) {
          display: inline;
        }
      }
    }
    @media screen and (min-width: 768px) {
      flex-basis: 17rem;
      flex-shrink: 0;

      padding: 1rem;

      &.hashrate {
        flex-basis: 11.9rem;
      }

      &.general-accruals {
        flex-basis: 14.9rem;
      }
    }

    @media screen and (min-width: 1024px) {
      font-size: 1.4rem;
    }

    @media screen and (min-width: 1440px) {
      &.date-time,
      &.hashrate,
      &.general-accruals {
        flex-basis: 36.1rem;
        flex-shrink: 1;
      }
    }
  }
  &:hover,
  &.selected {
    .charges-list-element-col {
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.bg6 : props.theme.accent4};
    }
  }
`;
