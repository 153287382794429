import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  StyledFooter,
  StyledFooterContent,
  StyledFooterNavLink,
  StyledFooterNavigation,
  StyledFooterNavigationList,
  StyledFooterNavigationListElement,
  StyledSocialMediaIcon,
  StyledSocialMediaLink,
  StyledStoresContainer,
  StyledStoresLink,
} from './styles';
import { Logo } from 'components';
import { ReactComponent as AppStoreIcon } from 'assets/icons/apple-store.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as VKIcon } from 'assets/icons/vk.svg';
import { ReactComponent as DiscordIcon } from 'assets/icons/discord.svg';
import { ExternalRoute, Routes } from 'lib';

export const Footer = ({ smaller }: { smaller?: boolean }) => {
  const { t } = useTranslation();
  return (
    <StyledFooter className={cn(smaller && 'smaller')}>
      <StyledFooterContent className={`${smaller && 'smaller'}`}>
        <StyledFooterNavigation>
          {!smaller && (
            <div className="footer-logo">
              <Link to="/">
                <Logo dark />
              </Link>
            </div>
          )}
          <StyledFooterNavigationList>
            <StyledFooterNavLink to="/about">
              <StyledFooterNavigationListElement className="p2">
                {t('footer.navigation.about')}
              </StyledFooterNavigationListElement>
            </StyledFooterNavLink>
            <StyledFooterNavLink to={Routes.termsOfUse()}>
              <StyledFooterNavigationListElement className="p2">
                {t('footer.navigation.terms')}
              </StyledFooterNavigationListElement>
            </StyledFooterNavLink>
            <StyledFooterNavLink to={Routes.supportPath()}>
              <StyledFooterNavigationListElement className="p2">
                {t('footer.navigation.feedback')}
              </StyledFooterNavigationListElement>
            </StyledFooterNavLink>
            <StyledFooterNavLink to={Routes.privacyPolicyPath()}>
              <StyledFooterNavigationListElement className="p2">
                {t('footer.navigation.privacy')}
              </StyledFooterNavigationListElement>
            </StyledFooterNavLink>
          </StyledFooterNavigationList>
        </StyledFooterNavigation>

        <div className="flex flex-center gap-15 mb-30">
          <StyledSocialMediaLink
            href={ExternalRoute.telegramPath()}
            target="_blank"
            rel="noreferrer"
          >
            <StyledSocialMediaIcon>
              <TelegramIcon />
            </StyledSocialMediaIcon>
          </StyledSocialMediaLink>
          <StyledSocialMediaLink href={ExternalRoute.vkPath()} target="_blank" rel="noreferrer">
            <StyledSocialMediaIcon>
              <VKIcon />
            </StyledSocialMediaIcon>
          </StyledSocialMediaLink>
          <StyledSocialMediaLink
            href={ExternalRoute.discordPath()}
            target="_blank"
            rel="noreferrer"
          >
            <StyledSocialMediaIcon>
              <DiscordIcon />
            </StyledSocialMediaIcon>
          </StyledSocialMediaLink>
        </div>
        <StyledStoresContainer>
          <StyledStoresLink
            href="https://www.apple.com/app-store/"
            target="_blank"
            rel="noreferrer"
          >
            <AppStoreIcon />
          </StyledStoresLink>
          <StyledStoresLink
            className="google"
            href="https://play.google.com/"
            target="_blank"
            rel="noreferrer"
          ></StyledStoresLink>
        </StyledStoresContainer>

        <p className="p3 center">{t('footer.copyright')}</p>
      </StyledFooterContent>
    </StyledFooter>
  );
};
