import cn from 'classnames';
import { StyledUserBarNavElement } from './styles';
interface IUserBarNavElementProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
}
export const UserBarNavElement = ({ children, icon, onClick, active }: IUserBarNavElementProps) => {
  return (
    <StyledUserBarNavElement className={cn(active && 'active')}>
      <button onClick={onClick} className="user-bar-nav-element-btn">
        {icon && <div className="user-bar-nav-element-icon-container">{icon}</div>}
        <div className="user-bar-nav-element-title">{children}</div>
      </button>
    </StyledUserBarNavElement>
  );
};
