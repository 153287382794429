import { BorderedWrapper } from 'components';
import { StyledPayoutListContainer } from './styles';
interface IPayoutListContainerProps {
  children?: React.ReactNode;
}
export const PayoutListContainer = ({ children }: IPayoutListContainerProps) => {
  return (
    <StyledPayoutListContainer>
      <BorderedWrapper>{children}</BorderedWrapper>
    </StyledPayoutListContainer>
  );
};
