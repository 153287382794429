import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledStatus = styled.div`
  &.disabled {
    opacity: 0.5;
  }
`;

export const StyledStatusString = styled.div`
  text-align: start;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.accent1)};

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
`;
export const StyledStatusbar = styled.div`
  overflow: hidden;
  height: 4px;
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.text2 : props.theme.accent1};
  width: 100%;
  margin-bottom: 0.5rem;

  &.danger {
    background-color: ${darkTheme.errorRed};
  }
`;

export const StyledStatusbarFiller = styled.div`
  background-color: ${darkTheme.button1};
  height: 4px;
  width: 0;

  &.danger {
    background-color: ${darkTheme.errorRed};
  }
  &.insecure {
    background-color: yellow;
  }
  &.secure {
    background-color: ${darkTheme.accent1};
  }
`;
