import styled from 'styled-components';
export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  margin: auto;
  height: 5rem;

  background-color: ${(props) => props.theme.bg1};

  @media screen and (min-width: 768px) {
    position: relative;
    left: 8rem;

    max-width: calc(100% - 8rem);
    height: 6.5rem;

    margin: 0;

    padding-right: 3rem;

    &.home {
      left: 0;

      max-width: 100%;

      padding: 0 3.4rem;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 6rem;

    padding: 0 1.5rem;
    &.home {
      margin: auto;
      left: 0;

      max-width: 95rem;

      padding: 0rem;
      margin-bottom: 0.4rem;
    }
  }

  @media screen and (min-width: 1440px) {
    left: 30rem;

    max-width: calc(100% - 30rem);

    padding: 0 3rem;
    &.home {
      margin: auto;
      left: 0;

      max-width: 132rem;

      padding: 0rem;
      margin-bottom: 0.4rem;
    }
  }
`;
