import React, { useEffect, useId, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { EWorkerStatus, IWorker, TWorkerGroupId, TWorkerId } from 'types';
import { StyledActionsDialog, StyledWorkersListElement } from './styles';
import { ReactComponent as CheckBoxIcon } from 'assets/icons/check-box-checked.svg';
import { ReactComponent as OkStatusIcon } from 'assets/icons/ok-status-button.svg';
import { ReactComponent as NotOkStatusIcon } from 'assets/icons/not-ok-status-icon.svg';
import { formateHashUnits, formateLastActivityDate } from 'utils';
import { Button, Modal, OutlinedButton } from 'components';
import { WorkersGroupsSelect } from '../../WorkersGroupsSelect';
import { selectWorkers } from 'store/slices/userWorkersSlice';
import { UserRepository } from 'repositories/UserRepository';
import { selectAuth } from 'store/slices/authSlice';
import { EApiResponseStatus } from 'types/api';
import { useAppDispatch } from 'store/store';
import { fetchWorkersList } from 'store/thunks/userWorkersThunks';

interface IWorkersListElementProps extends IWorker {
  onSelect: (id: IWorker['id'], selected: boolean) => void;
  selected?: boolean;
}

type TWorkerActionType = 'delete' | 'move' | 'remove';

export const WorkersListElement = ({
  name,
  hashRate24h,
  hashRate1hour,
  hashRate10min,
  rejected,
  lastActivity,
  status,
  groupId,
  id,
  onSelect,
  selected,
}: IWorkersListElementProps) => {
  const [isShowActionsMenu, setIsShowActionsMenu] = useState(false);
  const [showModal, setShowModal] = useState<TWorkerActionType | null>(null);
  const [isSubmittingAction, setIsSubmittingAction] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState<string | null>(null);
  const [groupIdToMove, setGroupIdToMove] = useState<IWorker['groupId'] | null>(null);

  const { normalizedGroups } = useSelector(selectWorkers);
  const { token } = useSelector(selectAuth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const selectElementInputId = useId();
  const selectChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onSelect(id, event.target.checked);
  };

  const showActionsMenuHandler = () => {
    setIsShowActionsMenu(true);
  };
  const hideActionsMenuHandler = () => {
    setIsShowActionsMenu(false);
  };

  const deleteWorkerHandler = () => {
    hideActionsMenuHandler();
    setShowModal('delete');
  };

  const moveWorkerHandler = () => {
    hideActionsMenuHandler();
    setShowModal('move');
  };

  const removeFromGroupHandler = () => {
    hideActionsMenuHandler();
    setShowModal('remove');
  };

  const cancelActionHandler = () => {
    if (!isSubmittingAction) {
      setShowModal(null);
      setSubmitError(null);
    }
  };

  const selectGroupIdToMoveHandler = (groupId: TWorkerGroupId | null) => {
    setGroupIdToMove(groupId);
  };

  const submitActionHandler =
    (type: TWorkerActionType, workerId: TWorkerId, groupId?: IWorker['groupId']) => async () => {
      console.log('submit', type);
      try {
        setIsSubmittingAction(true);
        if (!token) throw Error('unauthorized');

        if (type === 'delete') {
          const response = await UserRepository.deleteWorker(workerId, token);
          if (response.status === EApiResponseStatus.FAILURE) throw Error(response.error);
          dispatch(fetchWorkersList());
          setSubmitSuccess('delete success');
          return;
        }

        if (type === 'remove' && groupId) {
          const response = await UserRepository.removeWorkerFromGroup(workerId, groupId, token);
          if (response.status === EApiResponseStatus.FAILURE) throw Error(response.error);
          dispatch(fetchWorkersList());
          setSubmitSuccess('remove success');

          return;
        }
        if (type === 'move' && groupId) {
          console.log('move', groupId);
          const response = await UserRepository.moveWorkerToGroup(workerId, groupId, token);
          if (response.status === EApiResponseStatus.FAILURE) throw Error(response.error);
          dispatch(fetchWorkersList());
          setSubmitSuccess('move success');

          return;
        }
      } catch (error) {
        console.error('submit', type, error);
        if (error instanceof Error) {
          setSubmitError(t(`errors.${error.message}`));
        } else {
          setSubmitError(t('errors.unknownError'));
        }
      } finally {
        setIsSubmittingAction(false);
        setTimeout(() => {
          setShowModal(null);
          setSubmitError(null);
          setSubmitSuccess(null);
        }, 2000);
      }
    };

  const formatedLastActivityDate = lastActivity
    ? formateLastActivityDate(new Date(lastActivity))
    : 'n/a';
  const isOk = status && status === EWorkerStatus.ONLINE;

  useEffect(() => {
    if (!showModal) {
      setGroupIdToMove(null);
    }
  }, [showModal]);

  return (
    <StyledWorkersListElement className={cn(selected && 'selected')}>
      <Modal show={!!showModal}>
        <StyledActionsDialog>
          <div className="overlay" onClick={cancelActionHandler}></div>
          {showModal && (
            <dialog className="actions-dialog" open={!!showModal}>
              {submitError && (
                <div className="action-error">
                  {t(`workersPage.workersList.actions.${showModal}.error`)}
                </div>
              )}
              {submitSuccess && (
                <div className="action-success">
                  {t(`workersPage.workersList.actions.${showModal}.success`)}
                </div>
              )}
              <h3 className="heading">
                {t(`workersPage.workersList.actions.${showModal}.title`)}
                {groupId &&
                  showModal === 'move' &&
                  `${t(`workersPage.workersList.actions.${showModal}.from`)} "${normalizedGroups
                    .byID[groupId]?.name}"?`}
              </h3>
              {groupId && showModal === 'remove' && (
                <p className="subtitle">
                  {t(`workersPage.workersList.actions.${showModal}.subTitle`)}{' '}
                  {`"${normalizedGroups.byID[groupId]?.name}"`}
                </p>
              )}
              {showModal === 'move' && (
                <div className="groups-selector">
                  <p className="subtitle">
                    {t(`workersPage.workersList.actions.${showModal}.subTitle`)}
                  </p>

                  <WorkersGroupsSelect
                    groups={normalizedGroups}
                    currentGroupId={groupId}
                    onSelect={selectGroupIdToMoveHandler}
                  />
                </div>
              )}
              <div className="controls">
                {showModal && (
                  <Button
                    onClick={submitActionHandler(
                      showModal,
                      id,
                      showModal === 'remove' ? groupId : groupIdToMove,
                    )}
                    disabled={isSubmittingAction || (showModal === 'move' && !groupIdToMove)}
                  >
                    {t(`workersPage.workersList.actions.${showModal}.button`)}
                  </Button>
                )}
                <OutlinedButton onClick={cancelActionHandler} disabled={isSubmittingAction}>
                  Cancel
                </OutlinedButton>
              </div>
            </dialog>
          )}
        </StyledActionsDialog>
      </Modal>
      <div className="worker-list-element-col select-element">
        <input
          id={selectElementInputId}
          className="select-element-input"
          type="checkbox"
          onChange={selectChangeHandler}
          checked={selected}
        />
        <label htmlFor={selectElementInputId} className="select-element-label">
          <CheckBoxIcon className="select-element-label-icon" />
        </label>
      </div>
      <div className="worker-list-element-col">{name}</div>
      <div className="worker-list-element-col">{formateHashUnits(hashRate10min)}</div>
      <div className="worker-list-element-col">{formateHashUnits(hashRate1hour)}</div>
      <div className="worker-list-element-col">{formateHashUnits(hashRate24h)}</div>
      <div className="worker-list-element-col reject">{rejected}%</div>
      <div className="worker-list-element-col last-activity">{formatedLastActivityDate}</div>
      <div className={cn('worker-list-element-col status', status)}>
        <div className="status-text">{t(`workersPage.listFilter.${status}`)}</div>
        <div className="status-icon">{isOk ? <OkStatusIcon /> : <NotOkStatusIcon />}</div>
      </div>
      <div className="worker-list-element-col">
        {groupId ? normalizedGroups.byID[groupId]?.name : '-'}
      </div>
      <div className="worker-list-element-col actions">
        <button className="worker-list-element-actions-button" onClick={showActionsMenuHandler}>
          ***
        </button>
        {isShowActionsMenu && (
          <div
            className="worker-list-element-actions-dropdown"
            onMouseLeave={hideActionsMenuHandler}
          >
            <button
              className="worker-list-element-actions-dropdown-button"
              onClick={deleteWorkerHandler}
              disabled={status === EWorkerStatus.DELETED}
            >
              {t('workersPage.workersList.actions.delete.menuTitle')}
            </button>
            <button
              className="worker-list-element-actions-dropdown-button"
              onClick={removeFromGroupHandler}
              disabled={!groupId}
            >
              {t('workersPage.workersList.actions.remove.menuTitle')}
            </button>
            <button
              className="worker-list-element-actions-dropdown-button"
              onClick={moveWorkerHandler}
              // disabled={!groupId}
            >
              {t('workersPage.workersList.actions.move.menuTitle')}
            </button>
          </div>
        )}
      </div>
    </StyledWorkersListElement>
  );
};
