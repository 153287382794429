import styled from 'styled-components';
export const StyledIncomeCalculator = styled.div`
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 1440px) {
    padding: 1rem 0.8rem;
  }
`;

export const StyledHeader = styled.div`
  margin-bottom: 0.5rem;

  .h2 {
    font-size: 1.8rem;
    line-height: 150%;
    font-weight: 500;

    opacity: 0.87;

    @media screen and (min-width: 1440px) {
      margin-bottom: 1.6rem;
    }
  }
`;

export const StyledInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.7rem;

  margin-bottom: 2.4rem;

  @media screen and (min-width: 768px) {
    gap: 1rem 2.5rem;

    max-width: 46.1rem;
  }

  @media screen and (min-width: 1440px) {
    flex-direction: row;

    max-width: 100%;

    margin-bottom: 4rem;
  }
  @media screen and (min-width: 1920px) {
    flex-direction: column;

    max-width: 48rem;
  }
`;

export const StyledAmountBlock = styled.div`
  display: flex;
  gap: 3rem;

  @media screen and (min-width: 768px) {
    gap: 2rem;
  }
`;
