import styled from 'styled-components';

export const StyledContent = styled.div`
  padding: 0 1rem;

  @media screen and (min-width: 768px) {
    padding: 2rem 3.4rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 3.7rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 6rem;
  }
`;
