import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledToggler = styled.button`
  width: 6rem;
  height: 2.8rem;
  border-radius: 500px;
  background-color: ${({ theme }) => (theme.id === 'dark' ? theme.bg1 : theme.button2)};
  color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : darkTheme.text2)};
  position: relative;

  @media screen and (min-width: 768px) {
    width: 7.8rem;
    height: 3.3rem;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 350px;
    background-color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : darkTheme.text2)};
  }

  .toggler_label {
    position: absolute;
    display: block;
    text-align: start;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: currentColor;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */

    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
      width: 3rem;
    }
  }

  &.active {
    background-color: ${({ theme }) => (theme.id === 'dark' ? theme.accent1 : theme.accent5)};
    color: ${({ theme }) => (theme.id === 'dark' ? theme.button2 : theme.button2)};

    &::before {
      right: 0.5rem;
      left: auto;
      background-color: ${({ theme }) => (theme.id === 'dark' ? theme.button2 : theme.button2)};
    }

    .toggler_label {
      right: auto;
      left: 1rem;
    }
  }
`;
