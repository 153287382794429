import { Input, Modal, OutlinedButton, QRCodeDialog, Toggler } from 'components';
import { StyledEditablePhoneInput } from './styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as EditButton } from 'assets/icons/edit-icon.svg';

interface IEditablePhoneInputProps {
  children?: React.ReactNode;
  labelText?: string;
  placeholderText?: string;
}
export const EditablePhoneInput = ({
  children,
  labelText,
  placeholderText,
}: IEditablePhoneInputProps) => {
  const currentPhone = '+10000000000';
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const [isMountModal, setIsMountModal] = useState(false);
  const [showQRDialog, setShowQRDialog] = useState(false);

  const getQRHandler = () => {
    setShowQRDialog(true);
  };
  const cloaseModalHandler = () => {
    setShowQRDialog(false);
  };

  const editModeButtonHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (isEditing) {
      setIsEditing(false);
      return;
    }

    setIsEditing(true);
  };

  useEffect(() => {
    if (isEditing) inputRef.current?.focus();
  }, [isEditing]);

  useEffect(() => {
    if (showQRDialog) {
      setIsMountModal(true);
    }

    if (!showQRDialog) {
      setTimeout(() => setIsMountModal(false), 500);
    }
  }, [showQRDialog]);
  return (
    <StyledEditablePhoneInput>
      <Modal show={isMountModal}>
        <QRCodeDialog onClose={cloaseModalHandler} show={isMountModal} />
      </Modal>
      <div className="phone-input-group">
        {labelText && <label className="phone-input-label">{labelText}</label>}
        <div className="phone-input-container">
          <Input
            ref={inputRef}
            placeholder={isEditing ? placeholderText : currentPhone}
            className="phone-editable-input"
            disabled={!isEditing}
          />
          <button
            className={cn('phone-editable-edit-button', isEditing && 'editing')}
            onClick={editModeButtonHandler}
          >
            <EditButton className="phone-editable-edit-button__icon" />
          </button>
        </div>
      </div>
      <div className="phone-input-controls">
        <Toggler />
      </div>
      <OutlinedButton onClick={getQRHandler} className="phone-editable-qr-button" square>
        {t('settingsPage.twoFactorAuth.getQR')}
      </OutlinedButton>
    </StyledEditablePhoneInput>
  );
};
