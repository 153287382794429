import { createContext, useContext, useState } from 'react';

interface IUserContext {
  isLoggedIn: boolean;
  logIn: () => void;
  logOut: () => void;
}
export const userContextInitialValue: IUserContext = {
  isLoggedIn: true,
  logIn: () => undefined,
  logOut: () => undefined,
};

export const UserContext = createContext<IUserContext>(userContextInitialValue);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<IUserContext['isLoggedIn']>(
    userContextInitialValue.isLoggedIn,
  );

  const logIn = () => setIsLoggedIn(true);
  const logOut = () => setIsLoggedIn(false);
  const value: IUserContext = {
    isLoggedIn,
    logIn,
    logOut,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
