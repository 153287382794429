import { useContext } from 'react';
import { DefaultTheme, ThemeContext } from 'styled-components';
import { StyledMoon, StyledSun, StyledThemeToggler } from './styles';

export const ThemeToggler = () => {
  const { setTheme, id } = useContext(ThemeContext) as DefaultTheme;
  return (
    <StyledThemeToggler onClick={() => setTheme()}>
      <StyledMoon hidden={id !== 'dark'} />
      <StyledSun hidden={id !== 'light'} />
    </StyledThemeToggler>
  );
};
