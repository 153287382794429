import { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store/store';
import Cookies from 'universal-cookie';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import 'i18n';
import {
  MainPage,
  FAQPage,
  AboutPage,
  LoginPage,
  RegistrationPage,
  RecoveryPage,
  ChangePasswordPage,
  StatisticsPage,
  PrivateRoute,
  WorkersPage,
  PayoutPage,
  ProfitPage,
  SettingsPage,
  ReferralsPage,
  ArticlesPage,
  AdminPage,
  PrivacyPolicyPage,
} from 'routes';
import { Page404 } from '404';
import { lightTheme, darkTheme } from 'themes';
import { UserContextProvider } from 'context';
import { Routes } from 'lib';
import { SupportPage } from 'routes/support';
import { BlogPage } from 'routes/blog';
import { fetchUser } from 'store/thunks/authThunk';
import { fetchBtcRates } from 'store/thunks/commonDataThunks';
import { useTranslation } from 'react-i18next';
import { TermsOfUse } from 'routes/TermsOfUse';
import { setToken } from 'store/slices/authSlice';

const GlobalStyle = createGlobalStyle<{ $lang?: string }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; /* 10px */
  }
  
  body {
    position: relative;
    background-color: ${(props) => props.theme.mainBackground};
    color: ${(props) => props.theme.text1};
    font-family: ${(props) => {
      switch (props.$lang) {
        case 'ru':
          return 'Mulish, sans-serif';
        default:
          return 'Outfit, sans-serif';
      }
    }};
    overflow-x: hidden;
  }
  button {
    font-family: inherit;
    color: inherit;
    border: none;
    background: none;
    cursor: pointer;
    transition: all 0.2s;

    &:disabled {
      cursor: not-allowed;
    }
  }

  input {
    font-family: Outfit, sans-serif;
    
    &::placeholder {
      font-family: Outfit, sans-serif;
    }
  }
  
  a, a:visited {
    cursor: pointer;
    transition: all 0.2s;

    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
    text-decoration: none;

    &:hover, &:active {
      color: ${(props) => props.theme.button1};

    }

    &.disabled {
color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};
    }
  }

  ul {
    list-style: none;
  }
  
  h3 {
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 52.5px */
  }

  .p1 {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 37.5px */

    &.semibold {
      font-weight: 600;
    }
    &.extrabold {
      font-weight: 800;
    }
  }

  .p2 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%; /* 27px */
    font-style: normal;
  }
  .p3 {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 150%; /* 27px */
    font-style: normal;
  }
  .center {
    text-align: center;
  }

  .mb-30 {
    margin-bottom: 3rem;
  }
  .flex {
    display: flex;

    &.flex-center {
      justify-content: center;
    }
    &.align-center {
      align-items: center;
    }
    &.gap-10 {
      gap: 1rem;
    }
    &.gap-15 {
      gap: 1.5rem;
    }
  }

  .overflow-hidden {
    overflow: hidden;
  }
`;

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
    errorElement: <Page404 />,
  },
  { path: '/faq', element: <FAQPage /> },
  { path: '/about', element: <AboutPage /> },
  { path: Routes.privacyPolicyPath(), element: <PrivacyPolicyPage /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/registration', element: <RegistrationPage /> },
  { path: '/password-recovery', element: <RecoveryPage /> },
  { path: '/change-password', element: <ChangePasswordPage /> },
  { path: Routes.supportPath(), element: <SupportPage /> },
  { path: Routes.blogPath(), element: <BlogPage /> },
  { path: Routes.articlePath(), element: <ArticlesPage /> },
  {
    path: '/statistics',
    element: <PrivateRoute element={<StatisticsPage />} />,
  },
  {
    path: '/workers',
    element: <PrivateRoute element={<WorkersPage />} />,
  },
  {
    path: '/payout',
    element: <PrivateRoute element={<PayoutPage />} />,
  },
  {
    path: '/profit',
    element: <PrivateRoute element={<ProfitPage />} />,
  },
  {
    path: '/settings',
    element: <PrivateRoute element={<SettingsPage />} />,
  },
  { path: '/admin', element: <AdminPage /> },
  {
    path: Routes.referralsPath(),
    element: <PrivateRoute element={<ReferralsPage />} />,
  },
  { path: Routes.termsOfUse(), element: <TermsOfUse /> },
]);

export const App = () => {
  const { i18n } = useTranslation();

  const dispatch = store.dispatch;
  const [theme, setTheme] = useState(darkTheme);
  const toggleThemeHandler = () => {
    setTheme((prev) => {
      return prev.id === 'light' ? darkTheme : lightTheme;
    });
  };

  useEffect(() => {
    dispatch(fetchBtcRates());
  }, []);
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('jwt');
    if (token) {
      store.dispatch(fetchUser(token));
      store.dispatch(setToken(token));
    }
  }, []);

  return (
    <ReduxProvider store={store}>
      <UserContextProvider>
        <ThemeProvider theme={{ ...theme, setTheme: toggleThemeHandler, lang: i18n.language }}>
          <RouterProvider router={router} />
          <GlobalStyle $lang={i18n.language} />
        </ThemeProvider>
      </UserContextProvider>
    </ReduxProvider>
  );
};
