export const Routes = {
  rootPath() {
    return '/';
  },
  statisticsPath() {
    return '/statistics';
  },
  workersPath() {
    return '/workers';
  },
  payoutPath() {
    return '/payout';
  },
  profitPath() {
    return '/profit';
  },
  settingsPath() {
    return '/settings';
  },
  referralsPath() {
    return '/referrals';
  },
  faqPath() {
    return '/faq';
  },
  supportPath() {
    return '/support';
  },
  privacyPolicyPath() {
    return '/privacy-policy';
  },
  blogPath() {
    return '/blog';
  },
  articlePath(articleId?: string) {
    return `/articles${articleId ? 'id=' + articleId : ''}`;
  },
  loginPath() {
    return '/login';
  },
  adminPath() {
    return '/admin';
  },
  termsOfUse() {
    return '/terms';
  },
};
