import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledOutlinedButton = styled.button`
  display: block;

  padding: 0.7rem 1.7rem;
  border: 1px solid currentColor;
  border-radius: 500px;

  font-size: 1.4rem;
  line-height: 150%;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => (theme.id === 'dark' ? theme.accent1 : theme.text4)};

  &:hover {
    color: ${darkTheme.accent1};

    background-color: ${({ theme }) => (theme.id === 'dark' ? theme.accent2 : theme.text4)};
  }
  &:active {
    color: ${({ theme }) => (theme.id === 'dark' ? theme.accent2 : darkTheme.accent1)};

    border-color: ${darkTheme.accent1};

    background-color: ${({ theme }) => (theme.id === 'dark' ? theme.accent1 : theme.text4)};
  }

  &:disabled {
    border-color: ${({ theme }) => (theme.id === 'dark' ? theme.bg2 : theme.bg3)};

    color: ${({ theme }) => (theme.id === 'dark' ? theme.text3 : theme.accent1)};

    background-color: ${({ theme }) => (theme.id === 'dark' ? theme.bg2 : theme.bg3)};
  }

  &.square {
    border-radius: 6px;
  }
`;
