import { BorderedWrapper } from 'components';
import { StyledMainSettingsComponent } from './styles';
interface IMainSettingsComponentProps {
  children?: React.ReactNode;
  heading?: string;
}
export const MainSettingsComponent = ({ children, heading }: IMainSettingsComponentProps) => {
  return (
    <StyledMainSettingsComponent>
      <BorderedWrapper>
        <h2 className="h2">{heading}</h2>
        {children}
      </BorderedWrapper>
    </StyledMainSettingsComponent>
  );
};
