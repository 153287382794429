import { StyledBurgerButton } from 'components/shared/BurgerButton/styles/StyledBurgerButton';
import styled from 'styled-components';

export const StyledContent = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.1rem;

  width: 100%;
  height: 100%;

  padding: 0.4rem 1rem;

  background-color: inherit;

  @media screen and (min-width: 1024px) {
    max-width: 153rem;
    height: 6rem;

    margin: auto;
  }

  @media screen and (min-width: 1024px) {
    ${StyledBurgerButton} {
      display: none;
    }
  }

  @media screen and (min-width: 1440px) {
    gap: 6.2rem;
    padding: 0;
  }

  > * {
    flex-shrink: 0;
  }

  .controls {
    display: none;

    > *:nth-child(3) {
      display: none;

      @media screen and (min-width: 1024px) {
        display: block;
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      margin-left: auto;
    }
  }
`;
