import styled from 'styled-components';

export const Content = styled.main`
  position: relative;
  background-color: ${(props) => props.theme.bg1};

  margin-top: 5rem;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    margin-left: 8rem;
    padding-top: 2.5rem;

    &.home {
      margin-left: 0;
      padding-top: 0;
    }
  }

  @media screen and (min-width: 1440px) {
    margin-left: 30rem;
    &.home {
      margin-left: 0;
    }
  }
`;
