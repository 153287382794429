import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid4 } from 'uuid';
import { FilterRadioElement, Hint } from 'components';
import { ReactComponent as FiltesIcon } from 'assets/icons/filters-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon-small.svg';
import { StyledPayoutsListFilter } from './styles';
import { EPayoutStatus } from 'types';
import { useSelector } from 'react-redux';
import { selectPayouts, setFilterByConfirmationStatus } from 'store/slices/userPayoutSlice';
import { useAppDispatch } from 'store/store';
import { fetchPayoutsList } from 'store/thunks/userPayoutThunks';

export const PayoutsListFilter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const payouts = useSelector(selectPayouts);

  type TFilterConditions = EPayoutStatus | 'all';

  const [isShowHint, setIsShowHint] = useState(false);
  const toggleHintHandler = () => setIsShowHint((prev) => !prev);
  const hideHindHandler = () => setIsShowHint(false);

  const [selectedFilter, setSelectedFilter] = useState<TFilterConditions>('all');

  const payoutslistFilterElements: TFilterConditions[] = Object.values(EPayoutStatus);

  const filterChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value as TFilterConditions;

    setSelectedFilter(value);
    dispatch(setFilterByConfirmationStatus(value === 'all' ? null : value));
    dispatch(fetchPayoutsList());
  };

  const hintTexts = t('payoutPage.controls.filters.hint', { returnObjects: true }) as {
    heading: string;
    text: string;
  }[];

  const quantity = useMemo(
    () =>
      payouts.reduce<{
        all: number;
        CONFIRMED: number;
        UNCONFIRMED: number;
      }>(
        (acc, val) => {
          if (val.confirmed) {
            acc['CONFIRMED'] += 1;
          } else acc['UNCONFIRMED'] += 1;
          return acc;
        },
        { all: payouts.length, CONFIRMED: 0, UNCONFIRMED: 0 },
      ),
    [payouts],
  );

  return (
    <StyledPayoutsListFilter>
      <input id="filterTogglerId" type="checkbox" className="filters-list-toggle" />
      <label htmlFor="filterTogglerId" className="filters-list-toggle-label">
        <FiltesIcon className="open" />
        <CloseIcon className="close" />
      </label>
      <div className="filters-container smallest">
        {payoutslistFilterElements.map((element) => (
          <FilterRadioElement
            key={uuid4()}
            quantity={quantity[element]}
            value={element}
            label={t(`payoutPage.controls.filters.${element}`)}
            checked={element === selectedFilter}
            onChange={filterChangeHandler}
          />
        ))}
        <FilterRadioElement
          key={uuid4()}
          quantity={quantity['all']}
          value={'all'}
          label={t(`payoutPage.controls.filters.${'all'}`)}
          checked={'all' === selectedFilter}
          onChange={filterChangeHandler}
        />
      </div>
      <div className="filters-container">
        {payoutslistFilterElements.map((element) => (
          <FilterRadioElement
            key={uuid4()}
            quantity={quantity[element]}
            value={element}
            label={t(`payoutPage.controls.filters.${element}`)}
            checked={element === selectedFilter}
            onChange={filterChangeHandler}
          />
        ))}
        <FilterRadioElement
          key={uuid4()}
          quantity={quantity['all']}
          value={'all'}
          label={t(`payoutPage.controls.filters.${'all'}`)}
          checked={'all' === selectedFilter}
          onChange={filterChangeHandler}
        />
      </div>
      <div className="hint-container" onClick={toggleHintHandler}>
        <Hint>?</Hint>
        {isShowHint && (
          <div className="hint-dropdown" onMouseLeave={hideHindHandler}>
            {hintTexts.map(({ heading, text }) => (
              <div key={uuid4}>
                <div className="hint-dropdown-heading">{heading}</div>
                <div className="hint-dropdown-text">{text}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </StyledPayoutsListFilter>
  );
};
