import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/authSlice';
import { commonDataReducer } from './slices/commonDataSlice';
import { userStatisticsReducer } from './slices/userStatisticsSlice';
import { userWorkersReducer } from './slices/userWorkersSlice';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { userPayoutsReducer } from './slices/userPayoutSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    commonData: commonDataReducer,
    userStatistics: userStatisticsReducer,
    userWorkers: userWorkersReducer,
    userPayouts: userPayoutsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
