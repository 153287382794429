import styled from 'styled-components';
export const StyledAdminListCheckbox = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  .checkbox-input {
    position: absolute;

    visibility: hidden;

    width: 0;
    height: 0;

    &:checked {
      & ~ .checkbox-label {
        background-color: ${(props) => props.theme.accent6};
        border-color: ${(props) => props.theme.accent6};

        .checkbox-label-icon {
          display: block;
        }
      }
    }
  }

  .checkbox-label {
    display: block;

    width: 1.8rem;
    height: 1.8rem;

    border: 2px solid ${(props) => props.theme.stroke2};

    border-radius: 4px;

    &:hover {
      border-color: ${(props) => props.theme.accent6};
    }

    &.checked {
      background-color: ${(props) => props.theme.accent6};
      border-color: ${(props) => props.theme.accent6};

      .checkbox-label-icon {
        display: block;
        width: 1.3rem;
        height: 1.2rem;
      }
    }

    cursor: pointer;

    .checkbox-label-icon {
      display: none;
    }
  }
`;
