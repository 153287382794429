import styled from 'styled-components';
export const StyledHint = styled.button`
  position: relative;
  width: 1.3rem;
  height: 1.3rem;
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1};
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.accent2)};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 15px */
`;
