import styled from 'styled-components';

export const StyledUserActionButton = styled.button`
  font-size: 1.8rem;
  line-height: 150%;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  &:hover {
    color: ${(props) => props.theme.button1};
  }
`;
