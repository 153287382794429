import { RoundButton } from 'components/shared';
import { ReactComponent as SignupIcon } from 'assets/icons/signup-button.svg';
import styled from 'styled-components';

const StyledIconContainer = styled.div`
  width: 2.5rem;
  height: 2.4rem;
  padding-right: 0.1rem;
`;

interface ISignupButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
export const SignupButton = (props: ISignupButtonProps) => {
  return (
    <RoundButton {...props}>
      <StyledIconContainer>
        <SignupIcon />
      </StyledIconContainer>
    </RoundButton>
  );
};
