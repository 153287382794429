import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { RootState } from 'store/store';
import { ReactComponent as AdminIcon } from 'assets/icons/person-icon.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/support-icon.svg';
import { ReactComponent as ExitIcon } from 'assets/icons/exit-icon.svg';
import { StyledAdminBarMenu } from './styles';

interface IAdminBarMenuProps {
  children?: React.ReactNode;
}
export const AdminBarMenu = ({ children }: IAdminBarMenuProps) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const [selected, setSelected] = useState<string | null>(null);
  const menuButtonHandler =
    (param: string): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      console.log('click admin button', param);
      setSelected(param);
    };

  const exitButtonHandler = () => {
    console.log('click admin exit button');
  };

  return (
    <StyledAdminBarMenu>
      <ul className="sidebar-nav-list">
        <li className={cn('sidebar-nav-list-element', selected === 'admin' && 'selected')}>
          <button className="sidebar-nav-list-element-button" onClick={menuButtonHandler('admin')}>
            <AdminIcon className="button-icon" />
            <span className="sidebar-item-label">{t('adminPage.sidebarMenu.admin')}</span>
          </button>
        </li>
        <li className={cn('sidebar-nav-list-element', selected === 'support' && 'selected')}>
          <button
            className="sidebar-nav-list-element-button"
            onClick={menuButtonHandler('support')}
          >
            <SupportIcon className="button-icon" />
            <span className="sidebar-item-label">{t('adminPage.sidebarMenu.support')}</span>
          </button>
        </li>
      </ul>
      <div className="user-data">
        <span className="sidebar-item-label">{user?.name}</span>
        <button onClick={exitButtonHandler}>
          <ExitIcon className="button-icon" />
        </button>
      </div>
    </StyledAdminBarMenu>
  );
};
