import styled from 'styled-components';

export const StyledSearch = styled.div`
  position: relative;

  .input {
    position: relative;

    width: 3.2rem;
    height: 3.2rem;

    padding: 0.4rem;

    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    border-radius: 5px;

    font-size: 1.4rem;
    font-weight: 400;
    line-height: 150%; /* 24px */
    color: ${(props) => props.theme.text1};

    outline: none;
    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

    &.largest {
      display: none;

      @media screen and (min-width: 1920px) {
        display: block;
      }
    }

    &:hover:not(.active) {
      border-color: ${(props) => props.theme.accent1};

      color: ${(props) => props.theme.accent1};
    }

    &.active {
      width: 17rem;
      padding-right: 7.4rem;
      @media screen and (min-width: 768px) {
        width: 28rem;
        padding-right: 9.2rem;
      }
      &::placeholder {
        color: ${(props) => props.theme.text3};
      }
    }

    &::placeholder {
      color: transparent;
    }

    @media screen and (min-width: 768px) {
      width: 4.1rem;
      height: 4.1rem;

      padding: 1rem;

      font-size: 1.6rem;
    }

    @media screen and (min-width: 1920px) {
      display: none;
    }

    @media screen and (max-width: 1919px) {
      &:focus {
        border-color: ${(props) => props.theme.text1};

        & ~ .toggle-search-button {
          > * {
            *[fill] {
              fill: ${(props) => props.theme.text1};
            }
          }
        }

        & ~ .search-submit-botton {
          border: 1px solid ${(props) => props.theme.text1};
        }
      }
    }
  }

  .search-submit-botton {
    position: absolute;
    right: 3.2rem;

    width: 3.2rem;
    height: 3.2rem;

    @media screen and (min-width: 768px) {
      right: 4.1rem;

      width: 4.1rem;
      height: 4.1rem;
    }

    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    border-left-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5};
    border-right-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5};

    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5};

    @media screen and (min-width: 1920px) {
      display: none;
    }
  }

  .toggle-search-button {
    position: absolute;
    top: 50%;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 3.2rem;
    height: 3.2rem;

    border-radius: 5px;
    transform: translateY(-50%);

    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent1)};

    &:not(.active) {
      border: 1px solid
        ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent1)};
    }

    &:hover:not(.active) {
      color: ${(props) => props.theme.accent1};
      border: 1px solid ${(props) => props.theme.accent1};
    }

    &:hover {
      color: ${(props) => props.theme.accent1};
    }

    > * {
      width: 2rem;
      height: 2rem;
      *[stroke] {
        stroke: currentColor;
      }
    }

    @media screen and (min-width: 768px) {
      &:not(.active) {
        border: 1px solid
          ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
      }
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent4)};
      width: 4.1rem;
      height: 4.1rem;
      > * {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
`;
