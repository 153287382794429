import { Layout } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SupportRefs } from './components';

const StyledContent = styled.div`
  max-width: 32rem;
  margin: auto;
  padding: 0.5rem 1rem 4rem;

  .suppprt-heading {
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 27.5px */
    letter-spacing: -0.25px;
    text-align: center;
    margin-bottom: 1.2rem;
  }

  @media screen and (min-width: 768px) {
    max-width: 76.8rem;
    padding: 4rem 3.4rem 18rem;
    .support-heading {
      font-size: 5.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 126%; /* 69.3px */
      margin-bottom: 2rem;
      text-align: center;
    }
  }
  @media screen and (min-width: 1024px) {
    max-width: 102.4rem;
    padding: 4rem 3.7rem 20rem;
    .support-heading {
      font-size: 6rem;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 88px */
      letter-spacing: -0.8px;
      margin-bottom: 3.6rem;
    }
  }
  @media screen and (min-width: 1440px) {
    max-width: 144rem;
    padding: 5rem 6rem 24rem;
    .support-heading {
      margin-bottom: 4.6rem;
    }
  }
`;
export const SupportPage = () => {
  const { t } = useTranslation();
  return (
    <Layout home>
      <StyledContent>
        <h1 className="support-heading">{t('supportPage.heading')}</h1>
        <SupportRefs />
      </StyledContent>
    </Layout>
  );
};
