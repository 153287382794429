import styled from 'styled-components';
import { darkTheme } from 'themes';

export const getTransitionTime = () => '0.2s';
export const StyledSidebarMenu = styled.nav`
  position: relative;
  .sidebar-nav-list {
    padding: 1rem;
    width: 7rem;

    @media screen and (min-width: 1440px) {
      width: 27rem;
    }

    &:hover {
      width: 29rem;
      .link {
        left: 1.5rem;
        .sidebar-nav-list-element {
          display: flex;
          width: 27rem;
          gap: 1.5rem;
          align-items: center;
          .nav-text {
            visibility: visible;
            width: 18rem;
          }
        }
        &.active {
          left: 0;
          padding: 0.5rem;
          .sidebar-nav-list-element {
            padding: 1.5rem;
            height: 6rem;
          }
        }
      }
    }

    &:has(> *:hover) .link.active:not(:hover) {
      left: 1.5rem;
      padding: 1rem 0;
      .sidebar-nav-list-element {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.text5 : props.theme.text3)};
        background-color: initial;
        box-shadow: none;
        height: 5rem;
        padding: 1rem;

        .nav-icon {
          > * {
            fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text5 : props.theme.text3)};
            stroke: ${(props) =>
              props.theme.id === 'dark' ? props.theme.text5 : props.theme.text3};
          }
        }
      }
    }

    .link {
      position: relative;
      display: block;
      left: 0;
      height: 7rem;
      padding: 1rem 0;

      @media screen and (min-width: 1440px) {
        left: 1.5rem;
      }

      .sidebar-nav-list-element {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.text5 : props.theme.text3)};
        border-radius: 10px;
        width: 5rem;
        height: 5rem;
        padding: 1rem;
        display: flex;
        transition: all ${getTransitionTime()};

        .nav-icon {
          width: 3rem;
          height: 3rem;

          > * {
            transition: all ${getTransitionTime()};
            width: 100%;
            height: 100%;
            fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text5 : props.theme.text3)};
            stroke: ${(props) =>
              props.theme.id === 'dark' ? props.theme.text5 : props.theme.text3};
          }
        }

        .nav-text {
          display: block;
          visibility: hidden;
          font-size: 2.5rem;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 33px */
          width: 0;
          overflow: hidden;
          @media screen and (min-width: 1440px) {
            visibility: visible;
            width: 18rem;
          }
        }

        @media screen and (min-width: 1440px) {
          width: 27rem;
          gap: 1.5rem;
          align-items: center;
          padding: 1.5rem 1rem;
        }
      }

      &.active,
      &:hover {
        .sidebar-nav-list-element {
          background-color: ${(props) => props.theme.button2};
          box-shadow: 1px 15px 19px 0px rgba(0, 0, 0, 0.15);
          color: ${darkTheme.accent1};

          .nav-icon {
            > * {
              fill: ${darkTheme.accent1};
              stroke: ${darkTheme.accent1};
            }
          }
        }
      }

      &:hover {
        left: 0;
        padding: 0.5rem;
        .sidebar-nav-list-element {
          padding: 1.5rem;
          height: 6rem;
          height: 6.8rem;
        }
      }
      @media screen and (min-width: 1440px) {
        &.active {
          left: 0;
          padding: 0rem;
          .sidebar-nav-list-element {
            padding: 1.5rem;
            height: 6.8rem;
          }
        }
      }
    }
  }
`;
