import styled from 'styled-components';
export const StyledReferrralLevels = styled.div`
  flex-grow: 1;
  flex-basis: 50%;

  .levels-heading {
    position: relative;

    width: fit-content;

    margin: auto;
    margin-bottom: 0.6rem;

    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 700;

    @media screen and (min-width: 768px) {
      margin-bottom: 1.6rem;

      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 500;
    }

    .hint-container {
      position: absolute;
      top: 0;
      right: -0.6rem;

      transform: translateX(100%);
    }
  }

  .level-value {
    margin-bottom: 1.2rem;

    font-size: 2.2rem;
    line-height: 145%;
    font-weight: 600;
    text-align: center;

    @media screen and (min-width: 768px) {
      margin-bottom: 1.6rem;

      font-size: 4rem;
      font-weight: 700;
      line-height: 150%;
    }
  }

  .current-hash-rate {
    margin-bottom: 2.4rem;

    font-family: Mulish;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 130%;

    @media screen and (min-width: 768px) {
      margin-bottom: 1.6rem;

      font-size: 1.6rem;
      line-height: 150%;
    }

    @media screen and (min-width: 1920px) {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
    }
    .current-hash-rate-title,
    .current-hash-rate-value {
      text-align: center;
    }
    .current-hash-rate-value {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text1)};
    }
  }
  .calc-button {
    display: flex;
    justify-content: center;
  }
`;
