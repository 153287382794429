export const lightTheme = {
  id: 'light',
  mainBackground: '#F4F2FF',
  mainText: '#A68AF3',
  bg1: '#F4F2FF',
  bg2: '#F8F2FF',
  bg3: '#E5E1FF',
  bg4: '#FAF6FF',
  bg5: '#E0D4FF',
  bg6: '#B5A2F0',
  bg7: '#7456CA',
  bgFooter: '#6045A0',
  text1: '#A68AF3',
  text2: '#523B93',
  text3: '#5659B6',
  text4: '#7456CA',
  text5: '#B1ADFF', // not set in figma, use same as in dark mode
  accent1: '#9D82E8',
  accent2: '#322555',
  accent3: '#fff',
  accent4: '#CFBEFF',
  accent5: '#BAFF63',
  accent6: '#6F33FF',
  button1: '#9DF359',
  button2: '#7456CA',
  button3: '#7456CA',
  errorRed: '#ff8787',
  stroke2: '#401E93',
};
