import { BorderedWrapper } from 'components';
import { StyledWell } from './styles';
import { ReactComponent as BitCoinIcon } from 'assets/icons/bitcoin-icon.svg';
import { useEffect, useState } from 'react';
export const Well = () => {
  const [btcPrice, setBtcPrice] = useState<string>('n/a');
  const crypto = 'BTC';
  const fiat = '$';

  useEffect(() => {
    fetch('https://mempool.space/api/v1/prices')
      .then((res) => res.json())
      .then((data) => setBtcPrice(Number(data?.USD).toLocaleString('us-en')));
  }, []);
  return (
    <StyledWell>
      <div className="content">
        <div className="status">
          <div className="icon">
            <BitCoinIcon />
          </div>
          <p>Well</p>
        </div>
        <div className="value">
          {crypto} = {btcPrice} {fiat}
        </div>
      </div>
    </StyledWell>
  );
};
