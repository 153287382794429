import styled from 'styled-components';
export const StyledWorkerGroups = styled.div`
  position: relative;
  z-index: 500;
  display: flex;
  gap: 0.5rem;
  margin-right: auto;

  @media screen and (min-width: 1024px) {
    gap: 0.7rem;
  }
`;
