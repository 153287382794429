import styled from 'styled-components';

export const StyledContent = styled.div`
  position: relative;

  .add-worker-dialog-container {
    margin-bottom: 1.8rem;
  }

  .info-elements-container {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    margin-bottom: 4rem;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1.8rem;

      margin-bottom: 3.4rem;
    }
  }

  .aux-data-container {
    margin-bottom: 6rem;

    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

    @media screen and (min-width: 1440px) {
      display: flex;
      gap: 5rem;
    }

    .h2 {
      margin-bottom: 1.2rem;

      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 700;
      text-align: center;

      @media screen and (min-width: 768px) {
        margin-bottom: 2.5rem;

        font-size: 2.5rem;
        line-height: 150%;
        font-weight: 600;
      }

      @media screen and (min-width: 1024px) {
        text-align: center;
      }

      @media screen and (min-width: 1440px) {
        margin-bottom: 1.5rem;

        font-size: 1.8rem;
        font-weight: 600;
        text-align: left;
      }
    }

    .aux-data-group {
      flex-grow: 1;

      &:not(:last-child) {
        margin-bottom: 3.6rem;

        @media screen and (min-width: 768px) {
          margin-bottom: 7rem;
        }

        @media screen and (min-width: 1440px) {
          margin-bottom: unset;
        }
      }

      .aux-data-element {
        font-size: 1.4rem;
        line-height: 150%;
        font-weight: 500;

        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }

        @media screen and (min-width: 768px) {
          display: flex;

          font-size: 1.6rem;
        }

        @media screen and (min-width: 1024px) {
          gap: 2rem;

          padding-left: 15%;
        }
        @media screen and (min-width: 1200px) {
          padding-left: 20%;
        }

        @media screen and (min-width: 1440px) {
          display: block;

          padding-left: 0;
        }

        &__heading {
          flex-shrink: 0;

          font-size: 1.2rem;
          font-weight: 400;
          line-height: 150%;

          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.text2 : props.theme.accent1};

          @media screen and (min-width: 768px) {
            width: 23rem;
            font-size: 1.6rem;
          }

          @media screen and (min-width: 1024px) {
            width: 25rem;
          }
          @media screen and (min-width: 1440px) {
            width: max-content;
          }

          font-size: 1.4rem;
          line-height: 150%;

          margin-bottom: 0.4rem;
        }
      }
    }
  }
`;
