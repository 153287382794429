import styled from 'styled-components';
export const StyledTransactionsListHeader = styled.div`
  display: flex;
  background-color: green;
  border-radius: 6px;
  background-color: ${(props) => props.theme.bg1};

  margin-bottom: 0.9rem;

  .list-col-element {
    &.header {
      padding: 0.4rem 0.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.2rem;
      overflow: visible;

      &.link {
        padding-right: 0;
      }
    }

    .list-col-element-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .list-col-element-filter {
      &:hover,
      &.active {
        color: ${(props) => props.theme.button3};

        .list-col-element-filter-button {
          color: ${(props) => props.theme.button3};
        }
      }
      display: flex;
      align-items: center;
      .list-col-element-filter-button {
        width: 1.8rem;
        height: 1.8rem;
        display: block;

        .button-icon {
          width: 100%;
          height: 100%;
          * [fill] {
            fill: currentColor;
          }
        }
      }

      .list-col-element-filter-body {
        position: absolute;
        z-index: 1000;
        border-radius: 8px;
        border: 2px solid ${(props) => props.theme.button3};

        background-color: ${(props) => props.theme.bg4};
        box-shadow: 0px 17px 44.8px -12px #000;
        color: white;

        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 100%);
        &.left {
          left: 0;
          transform: translate(0, 100%);
        }

        .list-col-element-filter-body-element {
          cursor: pointer;
          max-width: 23rem;
          padding: 6px 16px;

          &:not(:last-child) {
            border-bottom: 1px solid ${(props) => props.theme.button2};
          }
          overflow: hidden;
          font-feature-settings:
            'clig' off,
            'liga' off;
          text-overflow: ellipsis;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            background: var(--accent-20, rgba(74, 36, 165, 0.2));
          }
        }
      }
    }
  }

  .hashid {
    flex-grow: 1;
  }
  .id {
    width: 10.4rem;
  }
  .confirmation {
    width: 13.4rem;
  }
  .created-at {
    width: 23.8rem;
  }
  .updated-at {
    width: 23.8rem;
  }
`;
