import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledFooterNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  margin-bottom: 3.4rem;

  padding: 0 1rem;

  @media screen and (min-width: 768px) {
    gap: 5.7rem;

    margin-bottom: 8rem;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;

    margin-bottom: 4.4rem;
  }
  @media screen and (min-width: 1440px) {
    gap: 12rem;
  }
`;

export const StyledFooterNavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 6.5rem;
  }
  @media screen and (min-width: 1440px) {
    gap: 12rem;
  }
`;

export const StyledFooterNavigationListElement = styled.li`
  position: relative;
  top: 0;

  padding: 0.7rem 0;

  text-align: center;

  transition: all 0.5s;

  &::after {
    content: '';
    position: absolute;
    left: 2.3rem;

    display: block;
    visibility: hidden;

    width: 5px;
    height: 5px;

    border-radius: 100%;

    transform: translateY(-50%);
    transition: all 0.2s;
  }

  &:hover {
    top: -1px;

    &::after {
      visibility: visible;

      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.accent1 : props.theme.button1};
    }
  }
`;

export const StyledFooterNavLink = styled(Link)`
  font-weight: 700;

  color: ${darkTheme.text1} !important;
  &:hover {
    color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.button1} !important;
  }
`;
