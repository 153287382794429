import { useRef, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Button, CheckBox, Form, Input, Layout, PasswordInput } from 'components';
import { StyledInputGroup } from 'components/shared/Input/styles';
import { AppDispatch, useAppSelector } from 'store/store';
import { signIn } from 'store/thunks/authThunk';
import { selectUser } from 'store/slices/authSlice';
import { Routes, validationSchemaSignIn } from 'lib';
import { EFromStates, ISignInUserData } from 'types';
import styled from 'styled-components';

const StyledContent = styled.div`
  padding: 2rem 1rem 8.8rem;
  max-width: 153rem;
  margin: auto;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  @media screen and (min-width: 768px) {
    padding: 5rem 1rem 12.1rem;
  }

  .login-form-container {
    max-width: 606px;
    margin: auto;
    margin-bottom: 2.4rem;
    @media screen and (min-width: 768px) {
      margin-bottom: 4.2rem;
    }

    .login-form_checkbox {
      display: flex;
      justify-content: center;
      max-width: max-content;
      margin: auto;
      margin-bottom: 2rem;
      @media screen and (min-width: 768px) {
        margin-bottom: 1.5rem;
      }
    }

    .login-form_buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 5.2rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 4rem;
      }
    }
  }
  .register-link-container {
    width: max-content;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  .recovery-link,
  .register-link {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text1)};
  }
`;

const StyledInputsGroup = styled.div`
  max-width: 39rem;
  margin: auto;
  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
  ${StyledInputGroup} {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;

const StyledFormHeader = styled.p`
  margin-bottom: 2.5rem;
`;

export const LoginPage = () => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const remeberChechboxRef = useRef<HTMLInputElement>(null);

  const [formState, setFormState] = useState<EFromStates>(EFromStates.initial);

  interface ISignInFormValue {
    userName: string;
    userPassword: string;
    formState: EFromStates;
    formErrors: string;
  }

  const formikSubmitHandler = async (
    values: ISignInFormValue,
    helpers: FormikHelpers<ISignInFormValue>,
  ) => {
    const { userName, userPassword } = values;
    const signInFormData: ISignInUserData = {
      name: userName,
      password: userPassword,
      isRemember: remeberChechboxRef.current?.checked,
    };
    try {
      setFormState(EFromStates.submitting);
      await dispatch(signIn({ userData: signInFormData })).unwrap();
      navigate(Routes.statisticsPath());
    } catch (error) {
      setFormState(EFromStates.failure);
      const { message } = error as { message: string };
      if (message) {
        helpers.setErrors({ formErrors: t(`errors.${message}`) });
        return;
      }
      helpers.setErrors({ formErrors: t('errors.unknownError') });
    }
  };

  if (user) return <Navigate to={Routes.statisticsPath()} />;

  return (
    <Layout home>
      <StyledContent>
        <div className="login-form-container">
          <Formik
            onSubmit={formikSubmitHandler}
            initialValues={{
              userName: '',
              userPassword: '',
              formState: EFromStates.initial,
              formErrors: '',
            }}
            validationSchema={validationSchemaSignIn}
          >
            {({
              handleSubmit,
              isSubmitting,
              isValid,
              submitCount,
              handleBlur,
              errors,
              touched,
              handleChange,
              values,
            }) => (
              <Form
                onSubmit={handleSubmit}
                header={
                  <StyledFormHeader className="p1 semibold">
                    {t('forms.login.header')}
                  </StyledFormHeader>
                }
                formState={formState}
                setFormState={setFormState}
                errorMessage={errors.formErrors}
              >
                <StyledInputsGroup>
                  <Input
                    name="userName"
                    placeholder={t('forms.login.loginPlaceHolder')}
                    inValid={!!errors.userName && touched.userName}
                    disabled={isSubmitting}
                    error={errors.userName}
                    value={values.userName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <PasswordInput
                    name="userPassword"
                    placeholder={t('forms.login.passwordPlaceholder')}
                    inValid={!!errors.userPassword && touched.userPassword}
                    disabled={isSubmitting}
                    value={values.userPassword}
                    error={errors.userPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </StyledInputsGroup>
                <div className="login-form_checkbox">
                  <CheckBox
                    ref={remeberChechboxRef}
                    label={t('forms.login.remember')}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="login-form_buttons">
                  <Button
                    type="submit"
                    title={t('forms.login.submitButton')}
                    disabled={isSubmitting || (!isValid && submitCount > 0)}
                  ></Button>
                </div>
                <Link className="recovery-link" to="/password-recovery">
                  <p className="p2">{t('forms.login.forgotPassword')}</p>
                </Link>
              </Form>
            )}
          </Formik>
        </div>
        <div className="register-link-container">
          <p className="p3">{t('forms.login.notRegister')}</p>
          <Link className="register-link" to="/registration">
            <span className="p2">{t('forms.login.register')}</span>
          </Link>
        </div>
      </StyledContent>
    </Layout>
  );
};
