import styled from 'styled-components';

export const StyledAuxButtons = styled.div`
  position: sticky;
  top: calc(100% - 8.7rem);
  transform: translateY(-100%);
  z-index: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  width: 100%;

  @media screen and (min-width: 1440px) {
    left: 0;
    width: 30rem;
  }
`;
