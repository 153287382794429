import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { fetchUser, signIn, signOut } from 'store/thunks/authThunk';
import { IUser } from 'types/users';

export interface IAuthState {
  token: string | null;
  loading: boolean;
  user: IUser | null;
  isAdmin: boolean;
}

const initialState: IAuthState = {
  token: null,
  loading: false,
  user: null,
  isAdmin: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = initialState.token;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.user = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        const { token } = action.payload;
        //TODO: https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-32
        // save roken to seesion storage
        state.token = token ? token : null;
      })
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.rejected, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null;
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.user = payload.data ? payload.data : null;
        state.loading = false;
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.user = null;
        state.loading = false;
        state.user = null;
      }),
});

export const selectUser = (state: RootState) => state.auth.user;
export const selectAuth = (state: RootState) => state.auth;

export const { setToken, clearToken } = authSlice.actions;

export const authReducer = authSlice.reducer;
