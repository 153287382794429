import { StyledButton } from 'components/shared/Button/styles';
import { StyledSecondaryButton } from 'components/shared/SecondaryButton/styled';
import styled from 'styled-components';
export const StyledAdminList = styled.div`
  ${StyledButton} {
    padding: 0.7rem 2.8rem;
    border-radius: 9px;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 150%; /* 18px */
    text-transform: capitalize;

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
    @media screen and (min-width: 1440px) {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }
  }

  ${StyledSecondaryButton} {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2)};
    border-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
    padding: 0.8rem 1.6rem;
    border-radius: 9px;

    font-size: 1.2rem;
    font-weight: 600;
    line-height: normal;

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
    }

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
    @media screen and (min-width: 1440px) {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
  }

  padding: 2.4rem;
  .admin-list-header {
    display: flex;

    margin-bottom: 2.8rem;

    .admin-list-header__controls {
      flex-grow: 1;
    }

    .refresh-button {
      display: flex;
      align-items: center;
      margin-left: auto;

      padding: 0.5rem;
      padding-left: 3.5rem;

      font-size: 2rem;
      line-height: 150%;
      color: ${(props) => props.theme.button1};
    }
  }

  .admin-list-content {
  }

  .admin-list-footer {
    background-color: red;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 3.2rem;
  }
`;
