import { Layout } from 'components';
import { FAQContainer, FAQHero } from './components';
import styled from 'styled-components';

const StyledContent = styled.div`
  max-width: 32rem;
  margin: auto;

  padding-bottom: 4rem;

  @media screen and (min-width: 768px) {
    max-width: 76.8rem;

    padding-top: 2.6rem;
    padding-bottom: 12rem;
  }

  @media screen and (min-width: 1024px) {
    max-width: 102.4rem;
  }

  @media screen and (min-width: 1440px) {
    max-width: 132rem;
  }
`;

export const FAQPage = () => {
  return (
    <Layout home>
      <StyledContent>
        <FAQHero />
        <FAQContainer></FAQContainer>
      </StyledContent>
    </Layout>
  );
};
