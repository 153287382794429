import { Button, ModalOverlay } from 'components/shared';
import {
  StyledEditWorkersGroupContainer,
  StyledEditWorkersGroupDialog,
  StyledGroupEditorsContainer,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { StyledCloseButton } from 'components/MobileMenu/styles';
import { ReactComponent as CloseIcon } from 'assets/icons/close-cross.svg';
import { IWorker, IWorkerGroup } from 'types';
import { GroupEditor } from './GroupEditor';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { useSelector } from 'react-redux';
import { selectWorkers } from 'store/slices/userWorkersSlice';
import { UserRepository } from 'repositories/UserRepository';
import { selectAuth } from 'store/slices/authSlice';
import { EApiResponseStatus } from 'types/api';
import { fetchWorkersGroupsList, fetchWorkersList } from 'store/thunks/userWorkersThunks';
import { useAppDispatch } from 'store/store';
interface IEditWorkersGroupDialogProps {
  children?: React.ReactNode;
  show?: boolean;
  onClose: () => void;
}
export const EditWorkersGroupDialog = ({ onClose, show }: IEditWorkersGroupDialogProps) => {
  const { groups, workers, normalizedGroups } = useSelector(selectWorkers);
  const { token } = useSelector(selectAuth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedGroupToMoveToId, setSelectedGroupToMoveToId] = useState<IWorkerGroup['id'] | null>(
    null,
  );
  const [showSelectGroupToMoveDropDown, setShowSelectGroupToMoveDropDown] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const toggleSelectGroupToMoveDropDownHanlder = () =>
    setShowSelectGroupToMoveDropDown((prev) => !prev);

  const [groupsToEdit, setGroupsToEdit] = useState<
    { groupId: IWorkerGroup['id']; newName: IWorkerGroup['name'] }[]
  >([]);

  const [groupsToDeleteId, setGroupToDeleteId] = useState<Set<IWorkerGroup['id']>>(new Set());
  const groupsToMoveToIds = normalizedGroups.allIDs.filter((id) => !groupsToDeleteId.has(id));
  const closeHandler = () => {
    onClose();
    setShowContent(false);
  };

  const onEditingGroupsHandler = (editingGroup: {
    groupId: IWorkerGroup['id'];
    newName?: IWorkerGroup['name'];
  }) => {
    setIsEditMode(true);
    const editingGroupToCheck = groupsToEdit.find(
      ({ groupId }) => groupId === editingGroup.groupId,
    );
    const newName = editingGroup.newName;

    if (!editingGroupToCheck) {
      if (newName) setGroupsToEdit((prev) => [...prev, { ...editingGroup, newName }]);
      setIsEditMode(true);
      setGroupToDeleteId((prev) => {
        prev.delete(editingGroup.groupId);
        if (prev.size === 0) setIsDeleteMode(false);
        return prev;
      });
    } else {
      setGroupsToEdit((prev) => {
        const filteredPrev = prev.filter((group) => group.groupId !== editingGroup.groupId);
        if (newName) {
          return [...filteredPrev, { ...editingGroup, newName }];
        }
        if (filteredPrev.length === 0) setIsEditMode(false);
        return filteredPrev;
      });
    }
  };

  const onDeletingGroupsHandler = (groupToDelete: {
    groupId: IWorkerGroup['id'];
    toDelete: boolean;
  }) => {
    setIsDeleteMode(true);
    if (groupToDelete.toDelete) {
      setGroupToDeleteId((prev) => prev.add(groupToDelete.groupId));
      setGroupsToEdit((prev) => {
        const filtered = prev.filter(({ groupId }) => groupId !== groupToDelete.groupId);
        if (filtered.length === 0) setIsEditMode(false);
        return filtered;
      });
    } else {
      setGroupToDeleteId((prev) => {
        prev.delete(groupToDelete.groupId);
        if (prev.size === 0) setIsDeleteMode(false);
        return prev;
      });
    }
  };

  const submitEditFromHandler: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    setSubmitSuccess(false);

    try {
      if (!token) throw Error('unauthorised');

      if (selectedGroupToMoveToId) {
        const workerIdsToAssignGroup = workers.reduce<IWorker['id'][]>((acc, worker) => {
          if (!!worker.groupId && groupsToDeleteId.has(worker.groupId)) acc.push(worker.id);
          return acc;
        }, []);

        const assignGroupToWorkersFromDeletedGroupsResult =
          await UserRepository.assignWorkersGroupToMultipleWorker(
            selectedGroupToMoveToId,
            workerIdsToAssignGroup,
            token,
          );

        const failedassignGroupToWorkersResult = assignGroupToWorkersFromDeletedGroupsResult.find(
          (res) => res.status === EApiResponseStatus.FAILURE,
        );

        if (failedassignGroupToWorkersResult) throw Error(failedassignGroupToWorkersResult.error);
      }

      const editGroupsResult = await UserRepository.editWorkersMultipleGroups(groupsToEdit, token);
      const failedEditGroupsResult = editGroupsResult.find(
        (res) => res.status === EApiResponseStatus.FAILURE,
      );
      if (failedEditGroupsResult) throw Error(failedEditGroupsResult.error);

      const groupsToDeleteIds = Array.from(groupsToDeleteId);
      const groupsToDelete: IWorkerGroup[] = groupsToDeleteIds.map(
        (id) => normalizedGroups.byID[id],
      );
      const deleteGroupsResults = await UserRepository.removeWorkersMultipleGroups(
        groupsToDelete,
        token,
      );
      const failedDeleteGroupsResult = deleteGroupsResults.find(
        (res) => res.status === EApiResponseStatus.FAILURE,
      );
      if (failedDeleteGroupsResult) throw Error(failedDeleteGroupsResult.error);

      setSubmitSuccess(true);
      dispatch(fetchWorkersGroupsList());
      dispatch(fetchWorkersList());
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.error(error, String(error));
      if (String(error).includes('server')) {
        setFormError(String(error) + ' or groupname exists');
        return;
      }

      setFormError(String(error));
    }
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowContent(() => true), 100);
    }
  }, [show]);

  const sameNamesGroupsIds = groupsToEdit.reduce<Record<IWorkerGroup['name'], number>>(
    (acc, val) => {
      if (!acc[val.newName]) {
        acc[val.newName] = 1;
      } else acc[val.newName] += 1;
      return acc;
    },
    {},
  );

  const maxNameToEditQuantity = Math.max(...Object.values(sameNamesGroupsIds));

  return (
    <StyledEditWorkersGroupContainer>
      <ModalOverlay showContent onClick={onClose}></ModalOverlay>
      <StyledEditWorkersGroupDialog open={showContent}>
        <StyledCloseButton onClick={closeHandler}>
          <CloseIcon />
        </StyledCloseButton>
        {formError && <div className="submit-error">{formError}</div>}
        <div className="heading">{t('workersPage.groups.editGroupModal.heading')}</div>
        {submitSuccess ? (
          <div className="submit-success">{t('workersPage.groups.editGroupModal.success')}</div>
        ) : (
          <form onSubmit={submitEditFromHandler}>
            <StyledGroupEditorsContainer>
              {groups.map((group) => (
                <GroupEditor
                  groupId={group.id}
                  key={group.id}
                  groupName={group.name}
                  workersQuantity={group.workersQuantity}
                  onEditing={onEditingGroupsHandler}
                  onDeleting={onDeletingGroupsHandler}
                  error={
                    sameNamesGroupsIds[
                      groupsToEdit.find(({ groupId }) => groupId === group.id)?.newName ?? ''
                    ] > 1
                  }
                />
              ))}
            </StyledGroupEditorsContainer>
            {!isDeleteMode && (
              <Button
                disabled={!isEditMode || maxNameToEditQuantity > 1}
                className="edit-groups-save-button"
                title={t('workersPage.groups.editGroupModal.saveButton')}
              ></Button>
            )}

            {isDeleteMode && (
              <div className="delete-mode-controls">
                <div className="select-group-to-move-workers">
                  {t('workersPage.groups.editGroupModal.deleteAndMove')}
                  <div
                    className="groups-to-move-selector"
                    onClick={toggleSelectGroupToMoveDropDownHanlder}
                  >
                    <div className="selected-group-to-move-to">
                      {selectedGroupToMoveToId
                        ? normalizedGroups.byID[selectedGroupToMoveToId].name
                        : 'none'}
                    </div>
                    <div className="arrow-down-icon">
                      <ArrowDownIcon />
                    </div>
                    {showSelectGroupToMoveDropDown && (
                      <div className="groups-to-move-selector-dropdown">
                        <div className="groups-to-move-selector-dropdown_items">
                          <div
                            className="groups-to-move-selector-dropdown_item"
                            onClick={() => setSelectedGroupToMoveToId(null)}
                          >
                            {'none'}
                          </div>
                          {groupsToMoveToIds.map((id) => (
                            <div
                              key={id}
                              className="groups-to-move-selector-dropdown_item"
                              onClick={() => setSelectedGroupToMoveToId(id)}
                            >
                              {normalizedGroups.byID[id].name}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  disabled={maxNameToEditQuantity > 1}
                  title={`${t('workersPage.groups.editGroupModal.submitDeleteButton')}${
                    isEditMode
                      ? ' and ' +
                        t('workersPage.groups.editGroupModal.saveButton').toLocaleLowerCase()
                      : ''
                  }`}
                />
              </div>
            )}
          </form>
        )}
      </StyledEditWorkersGroupDialog>
    </StyledEditWorkersGroupContainer>
  );
};
