import { ThemeToggler } from 'components';
import { StyledThemeSelect } from './styles';
import { useTranslation } from 'react-i18next';
interface IThemeSelectProps {
  children?: React.ReactNode;
}
export const ThemeSelect = ({ children }: IThemeSelectProps) => {
  const { t } = useTranslation();
  return (
    <StyledThemeSelect>
      {<label className="theme-select-label">{t('settingsPage.themeSelector.label')}</label>}
      <div className="theme-select-toggler-container">
        <span className="theme-select-toggler-hint">{t('settingsPage.themeSelector.hint')}</span>
        <ThemeToggler />
      </div>
    </StyledThemeSelect>
  );
};
