import styled from 'styled-components';

export const StyledSection = styled.section`
  position: relative;
  z-index: 0;

  max-width: 147rem;
  margin: auto;
  margin-bottom: 4rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 14rem;
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 10rem;
  }

  @media screen and (min-width: 1440px) {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .calculator-background {
    position: absolute;
    top: -40%;
    left: 50%;
    z-index: 0;
    transform: translateX(-50%);

    display: ${(props) => (props.theme.id === 'dark' ? 'none' : 'block')};

    width: 100dvw;
    height: 140rem;

    background-image: url('/images/bg/lines-2.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: 1440px) {
      top: -70%;
    }
  }
`;
