import cn from 'classnames';
import { StyledWorkerGroup } from './styles';
interface IWorkerGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  current?: boolean;
}
export const WorkerGroup = ({ children, current, onClick }: IWorkerGroupProps) => {
  return (
    <StyledWorkerGroup onClick={onClick} className={cn(current && 'current')}>
      {children}
    </StyledWorkerGroup>
  );
};
