import styled from 'styled-components';

export const StyledLangButton = styled.button`
  position: relative;
  z-index: 1;

  display: block;

  width: inherit;
  aspect-ratio: 1;

  font-size: 1.6rem;
  font-weight: 500;
  text-transform: capitalize;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};

  &:hover {
    color: ${(props) => props.theme.button1};
  }

  transition: all 0.5s;
`;
