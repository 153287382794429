import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledOverallHashRateChart = styled.section`
  position: relative;

  padding-bottom: 4rem;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 768px) {
    padding-bottom: 14rem;
  }

  @media screen and (min-width: 1024px) {
    padding-bottom: 10rem;
  }

  .content {
    margin: auto;

    @media screen and (min-width: 1024px) {
      width: 95rem;
    }

    @media screen and (min-width: 1440px) {
      width: 132rem;
    }
  }

  .heading {
    margin-bottom: 1rem;

    font-size: 2.5rem;
    line-height: 150%;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.25px;

    @media screen and (min-width: 768px) {
      margin-bottom: 3rem;

      font-size: 3.5rem;
    }
  }

  .hashrate-chart-container {
    margin-bottom: 1.2rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 4rem;
    }

    .hashrate-chart {
      margin-bottom: 1rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 3rem;
      }
    }
    .hashrate-chart-legend {
      display: flex;
      align-items: center;
      gap: 1rem;

      width: max-content;

      margin: auto;

      font-size: 1.2rem;
      font-weight: 600;
      line-height: 150%;

      @media screen and (min-width: 768px) {
        font-size: 1.6rem;
      }

      &-element {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        &::before {
          content: '';
          display: block;
          border-radius: 100%;
          width: 1.5rem;
          height: 1.5rem;

          &.secondary {
            stroke: ${(props) => props.theme.accent2};
          }
        }

        &.hashrate::before {
          background-color: ${(props) => props.theme.accent1};
        }
        &.reject::before {
          background-color: ${(props) => props.theme.accent2};
        }
      }

      &-dot {
        width: 1.5rem;
        height: 1.5rem;

        border-radius: 100%;

        background-color: ${(props) =>
          props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent1};
      }

      &-text {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 150%;

        @media screen and (min-width: 768px) {
          font-size: 1.6rem;
        }
      }
    }
  }

  .start-mining-button {
    margin: auto;
  }
`;

export const StyledOverallHashRateChartElement = styled.article`
  .svg-chart-container {
    display: block;

    margin: auto;

    .chart-unit-y-text {
      font-size: 1.4rem;
      font-weight: 600;

      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

      @media screen and (min-width: 768px) {
        font-size: 1.6rem;
      }
    }

    .chart-unit-x {
      cursor: pointer;

      &-text {
        font-size: 1.2rem;
        font-weight: 600;

        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};
        @media screen and (min-width: 768px) {
          font-size: 1.6rem;
        }
      }

      .selected {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.bg3)};
        @media screen and (min-width: 1024px) {
          fill: transparent;
        }
      }
    }

    .rotate {
      transform-box: fill-box;
      transform-origin: center;
      transform: rotate(-28deg);

      @media screen and (min-width: 768px) {
        transform: none;
      }
    }

    .hashrate-popup {
      .hashrate-popup-body {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : darkTheme.text3)};
      }
      &-date,
      &-value {
        font-size: 1.2rem;
        line-height: 150%;
        font-weight: 600;
        fill: ${darkTheme.text1};
      }
    }
  }

  .svg-chart {
    .chart-line {
      &-fill {
        fill: ${(props) =>
          props.theme.id === 'dark'
            ? 'url(#paint0_linear_1_13955)'
            : 'url(#paint0_linear_1_17096)'};
      }

      &-stroke {
        filter: ${(props) =>
          props.theme.id === 'dark'
            ? 'drop-shadow(0px 0px 11px rgba(212, 246, 156, 0.74))'
            : 'drop-shadow(0px 0px 11px #9D82E8)'};
        stroke: ${(props) =>
          props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent1};
      }
    }
    .chart-unit-y-line {
      stroke: ${darkTheme.text3};
      stroke-opacity: 0.5;
    }
  }
`;
