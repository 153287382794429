import styled from 'styled-components';
export const StyledChargeListHeader = styled.div`
  display: flex;

  margin-bottom: 0.2rem;
  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }

  .charges-list-header-element {
    flex-basis: 7rem;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    padding: 0.4rem;

    border-right: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};

    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    text-align: center;

    background-color: ${(props) => props.theme.bg1};

    & *:first-child {
      flex-grow: 1;

      display: flex;
      align-items: center;
    }

    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-right: none;
    }

    @media screen and (min-width: 768px) {
      flex-basis: 17rem;
      flex-shrink: 0;

      max-width: unset;

      padding: 1rem;

      font-size: 1.2rem;

      &.hashrate {
        flex-basis: 11.9rem;
      }

      &.general-accruals {
        flex-basis: 14.9rem;
      }
    }

    @media screen and (min-width: 1024px) {
      font-size: 1.4rem;
    }

    @media screen and (min-width: 1440px) {
      &.date-time,
      &.hashrate,
      &.general-accruals {
        flex-basis: 36.1rem;
        flex-shrink: 1;
      }
    }

    .sort-by-button {
      display: flex;
      align-items: center;

      width: 1rem;
      height: 0.7rem;

      margin: auto;
      margin-top: auto;

      color: inherit;

      > * {
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }
  }
`;
