import styled, { css } from 'styled-components';

export const StyledStoresLink = styled.a`
  /* width: 10.6rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 4.2rem;
  border-radius: 5px;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent2)};
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.bg1 : props.theme.button2};
  transition: all 0.2s;

  &.google {
    background-image: ${(props) =>
      props.theme.id === 'dark'
        ? css`url('/images/google-play-dark.svg')`
        : css`url('/images/google-play-light.svg')`};
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      background-image: ${(props) =>
        props.theme.id === 'dark'
          ? css`url('/images/google-play-dark-hover.svg')`
          : css`url('/images/google-play-dark-hover.svg')`};
    }
  }
  > * {
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent2)};
  }

  &:hover {
    border-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5};

    > * {
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5)};
    }
  }
`;
