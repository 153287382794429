import { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import {
  UserAccess,
  ThemeToggler,
  LocaleToggler,
  Logo,
  BurgerButton,
  Nvaigation,
  BurgerDropDown,
  MobileMenu,
} from 'components';
import { StyledContent, StyledHeader, StyledNavContainer } from './styles';

export const Header = ({ home }: { home?: boolean }) => {
  const [isShowMenu, setIsShowMenu] = useState(false);

  const closeMenu = () => setIsShowMenu(false);
  const toggleMenu = () => setIsShowMenu((prev) => !prev);

  return (
    <StyledHeader className={cn(home && 'home')}>
      <BurgerDropDown show={isShowMenu}>
        <MobileMenu onClose={closeMenu}></MobileMenu>
      </BurgerDropDown>
      <StyledContent>
        {home && (
          <Link to="/">
            <Logo />
          </Link>
        )}
        <StyledNavContainer>
          <Nvaigation />
        </StyledNavContainer>
        <BurgerButton onClick={toggleMenu} close={isShowMenu}></BurgerButton>
        <div className="controls">
          <UserAccess onAction={closeMenu} />
          <LocaleToggler />
          <ThemeToggler />
        </div>
      </StyledContent>
    </StyledHeader>
  );
};
