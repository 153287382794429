import { Button, ModalOverlay } from 'components/shared';
import { ReactComponent as CloseIcon } from 'assets/icons/close-cross.svg';
import {
  StyledCloseButton,
  StyledCreateGroupForm,
  StyledCreateWorkersGroupContainer,
  StyledCreateWorkersGroupDialog,
  StyledInputGroup,
} from './styles';
import React, { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkersSelect } from './components';
import { IWorker } from 'types';
import { UserRepository } from 'repositories/UserRepository';
import { selectAuth } from 'store/slices/authSlice';
import { useSelector } from 'react-redux';
import { EApiResponseStatus } from 'types/api';
import { fetchWorkersGroupsList, fetchWorkersList } from 'store/thunks/userWorkersThunks';
import { useAppDispatch } from 'store/store';
interface ICreateWorkersGroupDialogProps {
  children?: React.ReactNode;
  show?: boolean;
  onClose: () => void;
  workers: IWorker[];
}

export const CreateWorkersGroupDialog = ({
  onClose,
  show,
  workers,
}: ICreateWorkersGroupDialogProps) => {
  const [showContent, setShowContent] = useState(false);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [selectedWorkersIds, setSelectedWorkerIds] = useState<IWorker['id'][]>([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { t } = useTranslation();
  const { token } = useSelector(selectAuth);
  const dispatch = useAppDispatch();

  const groupNameInputref = useRef<HTMLInputElement>(null);

  const closeHandler = () => {
    onClose();
    setShowContent(false);
  };

  const selectWorkerHandler = (workersIds: IWorker['id'][]) => {
    setSelectedWorkerIds(workersIds);
  };

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    try {
      setSubmitSuccess(false);
      setIsFormSubmitting(true);
      setFormError(null);
      if (!token) throw Error('unauthorised');
      const formData = new FormData(event.currentTarget);
      const groupName = formData.get('newGroupName') as string;

      if (groupName.length === 0) throw Error('empty group name');

      const createNewGroupResult = await UserRepository.addWorkersGroup(groupName, token);
      if (createNewGroupResult.status === EApiResponseStatus.FAILURE)
        throw Error(createNewGroupResult.error);

      const assignResult = await UserRepository.assignWorkersGroupToMultipleWorker(
        createNewGroupResult.data.id,
        selectedWorkersIds,
        token,
      );

      const failedAssignResult = assignResult.find(
        (res) => res.status === EApiResponseStatus.FAILURE,
      );

      if (failedAssignResult) throw Error(failedAssignResult.error);

      setSubmitSuccess(true);

      dispatch(fetchWorkersGroupsList());
      dispatch(fetchWorkersList());
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.error(error, String(error));
      if (String(error).includes('server')) {
        setFormError(String(error) + ' or groupname exists');
        return;
      }

      setFormError(String(error));
    } finally {
      setIsFormSubmitting(false);
    }
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowContent(() => true), 100);
    }
  }, [show]);

  const newGroupNameInputId = useId();

  return (
    <StyledCreateWorkersGroupContainer>
      <ModalOverlay showContent onClick={onClose}></ModalOverlay>
      <StyledCreateWorkersGroupDialog open={showContent}>
        <StyledCloseButton onClick={closeHandler}>
          <CloseIcon />
        </StyledCloseButton>
        {formError && <div className="submit-error">{formError}</div>}

        <div className="heading">{t('workersPage.groups.createGroupModal.heading')}</div>
        {submitSuccess ? (
          <div className="submit-success">{t('workersPage.groups.createGroupModal.success')}</div>
        ) : (
          <StyledCreateGroupForm onSubmit={submitHandler}>
            <StyledInputGroup>
              <label className="new-group-label" htmlFor={newGroupNameInputId}>
                {t('workersPage.groups.createGroupModal.newGroupInput.label')}
              </label>
              <input
                id={newGroupNameInputId}
                className="new-group-name-input"
                placeholder={t('workersPage.groups.createGroupModal.newGroupInput.placeholder')}
                ref={groupNameInputref}
                name="newGroupName"
              />
            </StyledInputGroup>
            <WorkersSelect
              label={t('workersPage.groups.createGroupModal.workerToAddSelect.label')}
              workersList={workers}
              onSelect={selectWorkerHandler}
            />
            <div className="submit-button-container">
              <Button
                type="submit"
                title={t('workersPage.groups.createGroupModal.submitButton')}
                disabled={isFormSubmitting}
              ></Button>
            </div>
          </StyledCreateGroupForm>
        )}
      </StyledCreateWorkersGroupDialog>
    </StyledCreateWorkersGroupContainer>
  );
};
