import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon-small.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-sharp-long.svg';
import { StyledSearch } from './styles';
interface ISearchProps {
  children?: React.ReactNode;
  onChange: (value: string) => void;
}
export const Search = ({ onChange }: ISearchProps) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const changeInputHandler =
    (instant?: boolean): React.ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      const { value } = event.target;
      setInputValue(value);
      if (instant) onChange(value);
    };
  const toggleSearch = () => {
    if (isActive) {
      setInputValue('');
      onChange('');
    }
    setIsActive((prev) => !prev);
  };

  const submitSearch = () => {
    onChange(inputValue);
  };

  return (
    <StyledSearch ref={containerRef}>
      <input
        className={cn('input', isActive && 'active', 'largest')}
        placeholder={t('workersPage.search.placeholder')}
        value={inputValue}
        onChange={changeInputHandler(true)}
      />
      <input
        className={cn('input', isActive && 'active')}
        placeholder={t('workersPage.search.placeholder')}
        value={inputValue}
        onChange={changeInputHandler()}
      />
      {isActive && (
        <button
          type="button"
          ref={submitButtonRef}
          className={cn('search-submit-botton', isActive && 'active')}
          onClick={submitSearch}
        >
          <ArrowRight />
        </button>
      )}
      <button className={cn('toggle-search-button', isActive && 'active')} onClick={toggleSearch}>
        {isActive ? <CloseIcon /> : <SearchIcon />}
      </button>
    </StyledSearch>
  );
};
