import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';
import {
  fetchUserHashrateStatistics,
  fetchUserMinersStatistics,
  fetchUserStat,
} from 'store/thunks/userStatisticsSliceThunks';
import { EWorkerStatus, IUserBalanceData, IUserMinersStatisticsData } from 'types';
import { ESliceDataFetchingStatus, TNullable } from 'types/common';
import { IUserHashrateData } from 'types/hashrate';

type TUserStatisticsWithStatus<T> = {
  status: ESliceDataFetchingStatus;
} & T;
interface IUserStatistics {
  accountInformation: TUserStatisticsWithStatus<IUserBalanceData>;
  averageHashrate: {
    average10MinutesHashrate: TNullable<string>;
    averageHourlyHashrate: TNullable<string>;
    averageDailyHashrate: TNullable<string>;
    status: ESliceDataFetchingStatus;
  };
  hashrateStatistics: {
    hashRateDailyStatistics: TNullable<IUserHashrateData[]>;
    hashRateWeeklyStatistics: TNullable<IUserHashrateData[]>;
    hashRateMonthlyStatistics: TNullable<IUserHashrateData[]>;
    status: ESliceDataFetchingStatus;
  };
  miner: TUserStatisticsWithStatus<IUserMinersStatisticsData>;
}

const initialState: IUserStatistics = {
  accountInformation: {
    last24HoursProfit: null,
    averageDailyProfit: null,
    totalIncome: null,
    balance: null,
    status: ESliceDataFetchingStatus.initial,
  },
  averageHashrate: {
    average10MinutesHashrate: null,
    averageDailyHashrate: null,
    averageHourlyHashrate: null,
    status: ESliceDataFetchingStatus.initial,
  },
  hashrateStatistics: {
    hashRateDailyStatistics: null,
    hashRateWeeklyStatistics: null,
    hashRateMonthlyStatistics: null,
    status: ESliceDataFetchingStatus.initial,
  },
  miner: {
    status: ESliceDataFetchingStatus.initial,
    online: null,
    offline: null,
    all: null,
    inactive: null,
  },
};

export const userStatisticsSlice = createSlice({
  name: 'userStatistics',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchUserStat.pending, (state) => {
        state.averageHashrate.status = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchUserStat.rejected, (state, { error }) => {
        console.error(error);
        state.averageHashrate.status = ESliceDataFetchingStatus.error;
      })
      .addCase(fetchUserStat.fulfilled, (state, { payload }) => {
        const { hashRate10min, hashRate1hour, hashRate24, balance, profit24, totalIncome } =
          payload[0];

        state.accountInformation.status = ESliceDataFetchingStatus.success;
        state.accountInformation.balance = balance;
        state.accountInformation.last24HoursProfit = profit24;
        state.accountInformation.totalIncome = totalIncome;

        state.averageHashrate.status = ESliceDataFetchingStatus.success;
        state.averageHashrate.average10MinutesHashrate = String(hashRate10min);
        state.averageHashrate.averageDailyHashrate = String(hashRate1hour);
        state.averageHashrate.averageHourlyHashrate = String(hashRate24);
      })
      .addCase(fetchUserHashrateStatistics.fulfilled, (state, { payload }) => {
        if (payload.some((el) => el.status === 'failed')) {
          state.hashrateStatistics.status = ESliceDataFetchingStatus.error;
          return;
        }
        state.hashrateStatistics.status = ESliceDataFetchingStatus.success;

        state.hashrateStatistics.hashRateDailyStatistics = payload[0].data;
        state.hashrateStatistics.hashRateWeeklyStatistics = payload[1].data;
        state.hashrateStatistics.hashRateMonthlyStatistics = payload[2].data;
      })
      .addCase(fetchUserHashrateStatistics.rejected, (state) => {
        state.hashrateStatistics.status = ESliceDataFetchingStatus.error;
      })
      .addCase(fetchUserHashrateStatistics.pending, (state) => {
        state.hashrateStatistics.status = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchUserMinersStatistics.fulfilled, (state, { payload }) => {
        //TODO: move to selectors?
        // https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20Dev%20Issues?issues=THASH-T-30
        const res: { online: number; offline: number; inactive: number } = payload.reduce(
          (acc, val) => {
            if (val.status === EWorkerStatus.ONLINE) {
              acc.online += 1;
            }
            if (val.status === EWorkerStatus.OFFLINE) {
              acc.offline += 1;
            }
            if (val.status === EWorkerStatus.INACTIVE) {
              acc.inactive += 1;
            }
            return acc;
          },
          { online: 0, offline: 0, inactive: 0 },
        );

        state.miner.status = ESliceDataFetchingStatus.success;
        state.miner.online = res.online;
        state.miner.all = payload.length;
        state.miner.inactive = res.inactive;
        state.miner.offline = res.offline;
      })
      .addCase(fetchUserMinersStatistics.pending, (state) => {
        state.miner.status = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchUserMinersStatistics.rejected, (state, { payload }) => {
        state.miner.status = ESliceDataFetchingStatus.error;
        state.miner.online = null;
        state.miner.all = null;
        state.miner.inactive = null;
        state.miner.offline = null;
      }),
});

export const selectAccountInformation = (state: RootState) =>
  state.userStatistics.accountInformation;

export const selectAverageHashrate = (state: RootState) => state.userStatistics.averageHashrate;
export const selectHashrateStatistics = (state: RootState) =>
  state.userStatistics.hashrateStatistics;
export const selectMiner = (state: RootState) => state.userStatistics.miner;

export const userStatisticsReducer = userStatisticsSlice.reducer;
