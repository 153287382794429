import { CommonDataRepository } from 'repositories/CommonDataRepository';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EApiResponseStatus, IApiResponse } from 'types/api';
import { IHashRateResponse } from 'types/hashrate';

export const fetchBtcRates = createAsyncThunk<
  { btcToUsd: IApiResponse; btcToTh: IApiResponse } | IApiResponse
>('commonData/fetchBtcRates', async () => {
  try {
    const btcToThRateResponse = CommonDataRepository.btcToThRate();
    const btcToUsdRateResponse = CommonDataRepository.btcToUsdRate();
    const response = await Promise.all([btcToUsdRateResponse, btcToThRateResponse]);
    return { btcToUsd: response[0], btcToTh: response[1] };
  } catch (error) {
    console.error('signIn error', error);
    if (error instanceof Error) {
      return { status: 'failed', error: error.message };
    }
    return { status: 'failed', error: 'unknown error' };
  }
});

export const fetchCommonHashrate = createAsyncThunk<
  IHashRateResponse | IApiResponse,
  { from: number; to: number }
>('commonData/fetchCommonHashrate', async ({ from, to }) => {
  const response = (await CommonDataRepository.hashRate(from, to)) as IHashRateResponse;
  if (response.status === EApiResponseStatus.FAILURE) throw Error(response.error);
  return response;
});
