import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Button,
  Confirmation,
  Form,
  Layout,
  Modal,
  NewPasswordInput,
  PasswordInput,
} from 'components';
import { Routes } from 'lib';
import { Formik, FormikHelpers } from 'formik';
import { EFromStates, IChangePasswordData } from 'types';
import { validationSchemaChangePassword } from 'lib/validataion';
import { AccountRepository } from 'repositories/AccountRepository';
import { EApiResponseStatus } from 'types/api';

const StyledContent = styled.div`
  max-width: 60.6rem;
  margin: auto;
  padding: 1.2rem 1rem 4rem;
  @media screen and (min-width: 768px) {
    padding: 5rem 1rem 18.7rem;
  }

  .login-link {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text1)};
  }
`;

const StyledFormHeader = styled.p`
  margin-bottom: 1.2rem;
  @media screen and (min-width: 768px) {
    margin-bottom: 2.4rem;
  }
`;

const StyledInputsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  max-width: 39rem;
  margin: auto;
  margin-bottom: 2.4rem;
`;

const StyledButtonsContainer = styled.div`
  margin-bottom: 3.6rem;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 768px) {
    margin-bottom: 4.1rem;
  }
`;

export const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState<EFromStates>(EFromStates.initial);

  interface IRecoveryFormValue {
    userNewPassword: string;
    userRepeatPassword: string;
    formState: EFromStates;
    formErrors: string;
  }

  const formikSubmitHandler = async (
    values: IRecoveryFormValue,
    helpers: FormikHelpers<IRecoveryFormValue>,
  ) => {
    const changePasswordData: IChangePasswordData = {
      password: values.userNewPassword,
    };

    try {
      setFormState(EFromStates.submitting);
      const result = await AccountRepository.changePassword(changePasswordData);
      if (result.status === EApiResponseStatus.FAILURE) throw Error(result.error);
      setShowModal(true);
      setFormState(EFromStates.initial);
    } catch (error) {
      setFormState(EFromStates.failure);
      console.error(error);
      if (error instanceof Error) {
        helpers.setErrors({ formErrors: error.message });
      }
      helpers.setErrors({ formErrors: 'submit error' });
    }
  };
  return (
    <Layout home>
      {showModal && (
        <Modal show={showModal}>
          <Confirmation type="passwordChange" />
        </Modal>
      )}
      <StyledContent>
        <Formik
          initialValues={{
            userNewPassword: '',
            userRepeatPassword: '',
            formState: EFromStates.initial,
            formErrors: '',
          }}
          onSubmit={formikSubmitHandler}
          validationSchema={validationSchemaChangePassword}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting,
            submitCount,
            isValid,
          }) =>
            (() => {
              // console.log(errors);
              return <></>;
            })() && (
              <Form
                header={
                  <StyledFormHeader className="p1">
                    {t('forms.changePassword.header')}
                  </StyledFormHeader>
                }
                onSubmit={handleSubmit}
                formState={formState}
                setFormState={setFormState}
                errorMessage={errors.formErrors}
              >
                <StyledInputsGroup>
                  <NewPasswordInput
                    name="userNewPassword"
                    placeholder={t('forms.changePassword.passwordPlaceholder')}
                    inValid={!!errors.userNewPassword && touched.userNewPassword}
                    disabled={isSubmitting}
                    value={values.userNewPassword}
                    error={errors.userNewPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <PasswordInput
                    name="userRepeatPassword"
                    placeholder={t('forms.changePassword.passwordRepeatePlaceholder')}
                    onBlur={handleBlur}
                    inValid={!!errors.userRepeatPassword && touched.userRepeatPassword}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.userRepeatPassword}
                    error={errors.userRepeatPassword}
                  />
                </StyledInputsGroup>
                <StyledButtonsContainer>
                  <Button
                    type="submit"
                    title={t('forms.changePassword.submitButton')}
                    disabled={isSubmitting || (!isValid && submitCount > 0)}
                  ></Button>
                </StyledButtonsContainer>

                <Link className="login-link" to={Routes.loginPath()}>
                  <p className="p2">{t('forms.changePassword.remember')}</p>
                </Link>
              </Form>
            )
          }
        </Formik>
      </StyledContent>
    </Layout>
  );
};
