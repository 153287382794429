import { StyledInput } from 'components/shared/Input/styles';
import styled from 'styled-components';
export const StyledEditableEmailInput = styled.div`
  position: relative;

  display: flex;
  flex-wrap: wrap;

  margin: auto;
  max-width: 43rem;

  @media screen and (min-width: 768px) {
    align-items: end;
    gap: 1rem;

    margin: 0;
  }

  ${StyledInput} {
    padding: 0.7rem 1rem;

    border-radius: 6px;

    font-size: 1.4rem;

    background-color: ${({ theme }) => (theme.id === 'dark' ? theme.text3 : theme.accent3)};
    &::placeholder {
      color: ${({ theme }) => (theme.id === 'dark' ? theme.bg3 : theme.text4)};
    }
  }

  .email-input-group {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 100%;

    max-width: 43rem;

    @media screen and (min-width: 768px) {
      flex-grow: 1;
      flex-basis: 50%;

      max-width: 31rem;
    }
    @media screen and (min-width: 1100px) {
      max-width: 32rem;
    }
  }

  .email-input-label {
    display: block;

    padding: 0 1rem;

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};

    @media screen and (min-width: 768px) {
      margin-bottom: 0.5rem;

      font-size: 1.6rem;
    }
  }
  .email-input-container {
    position: relative;

    margin-bottom: 0.6rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  .email-editable-edit-button {
    position: absolute;
    right: 1rem;
    bottom: 50%;
    transform: translateY(50%);

    display: flex;
    align-items: center;

    width: 2.5rem;

    &:hover:not(.editing) {
      color: ${({ theme }) => (theme.id === 'dark' ? theme.button1 : theme.button1)};
    }
    &.editing {
      color: ${({ theme }) => (theme.id === 'dark' ? theme.accent1 : theme.button1)};
    }

    &__icon {
      display: block;
      *[fill] {
        fill: currentColor;
      }
    }
  }

  .email-input-controls {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
