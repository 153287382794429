import styled from 'styled-components';
export const StyledCreateGroupButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  width: 3.2rem;
  height: 3.2rem;

  border-radius: 5px;

  font-size: 1.6rem;
  font-weight: 500;
  line-height: 150%;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};

  background-color: ${(props) => props.theme.button2};

  &:hover {
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.button1};
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text2)};
  }

  .icon {
    width: 2rem;
    height: 2rem;
    > * {
      width: 100%;
      height: 100%;

      > *[fill] {
        fill: currentColor;
      }
    }
  }

  .title {
    display: none;
  }

  @media screen and (min-width: 768px) {
    width: 4.1rem;
    height: auto;
    padding: 1rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 0.7rem 2rem;
    padding-left: 1.5rem;
    min-width: fit-content;
    .title {
      display: inline;
    }
  }
`;
