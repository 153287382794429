import { StyledDisplayItemsPerPageSelect } from 'components/DisplayItemsPerPageSelect/styles';
import { StyledBorderedWrapper } from 'components/shared/BorderedWrapper/styles';
import styled from 'styled-components';
export const StyledChargesListContainer = styled.section`
  ${StyledBorderedWrapper} {
    padding: 1.2rem;
  }

  margin-bottom: 4rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 6rem;
  }

  .pagination-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
      justify-content: space-between;
    }

    ${StyledDisplayItemsPerPageSelect} {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
      }
    }
  }
`;
