import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { fetchBtcRates, fetchCommonHashrate } from 'store/thunks/commonDataThunks';
import { IHashrateData } from 'types/hashrate';

interface ICommonDataState {
  btcToThRate: number | null;
  btcToUsdRate: number | null;
  commonHashRate: IHashrateData[];
  loading: boolean;
}

const initialState: ICommonDataState = {
  btcToThRate: null,
  btcToUsdRate: null,
  commonHashRate: [],
  loading: false,
};
export const commonDataSlice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchBtcRates.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBtcRates.rejected, (state, { error }) => {
        state.loading = false;
        state.btcToThRate = null;
        console.error(error);
      })
      .addCase(fetchBtcRates.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!('status' in payload)) {
          const { btcToTh, btcToUsd } = payload;
          state.btcToThRate = btcToTh.data as number;
          state.btcToUsdRate = btcToUsd.data as number;
        }
      })
      .addCase(fetchCommonHashrate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCommonHashrate.rejected, (state, { error }) => {
        state.loading = false;
        state.commonHashRate = initialState.commonHashRate;
        // console.log(error);
      })
      .addCase(fetchCommonHashrate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.commonHashRate = payload.data as IHashrateData[];
      }),
});

export const selectCommonRates = (state: RootState) => {
  const { btcToThRate, btcToUsdRate } = state.commonData;
  return { btcToThRate, btcToUsdRate };
};
export const commonDataReducer = commonDataSlice.reducer;
