import styled from 'styled-components';
export const StyledAdminEditPayments = styled.div`
  .admin-edit-payments-controls {
    display: flex;
    justify-content: space-between;

    &__right {
      display: flex;
      gap: 1.2rem;
    }
  }
`;
