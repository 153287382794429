import {
  BorderedWrapper,
  CustomCalendar,
  DisplayItemsPerPageSelect,
  Paginator,
  Search,
} from 'components';
import { StyledReferralPayoutsList } from './styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { generateTestReferalPayout } from 'data/mockReferals';
import { filterReferralPayouts, formateDate, formateTime, getSortedReferralPayouts } from 'utils';
import { IReferralPayout, TSortIReferralPayoutByHeaderTitle } from 'types';
import { useMemo, useState } from 'react';
interface IReferralPayoutsListProps {
  children?: React.ReactNode;
}

export const ReferralPayoutsList = ({ children }: IReferralPayoutsListProps) => {
  const fetchedPayouts = generateTestReferalPayout();
  const { t } = useTranslation();

  const DEFAULT_ITEMS_PER_PAGE_QUANTITY = 10;
  const DEFAULT_PAGE_NUMBER = 1;

  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE_QUANTITY);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);

  const [sortIncr, setSortIncr] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');
  const [dateFilter, setDateFilter] = useState<{ from: Date; to: Date } | null>(null);
  const [sortBy, setSortBy] = useState<{ sortBy: keyof IReferralPayout; incr: boolean }>({
    sortBy: 'date',
    incr: true,
  });

  const changeCurrentPageHandler = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const sortHandler = (sortBy: TSortIReferralPayoutByHeaderTitle) => {
    setSortBy({ sortBy, incr: !sortIncr });
    setSortIncr((prev) => !prev);
  };

  const setSearchFilterHandler = (value: string) => {
    setSearchFilter(value);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const changeDateRangeHandler = (range: { from: Date; to: Date }) => {
    setDateFilter(range);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const filteredPayouts = useMemo(
    () => filterReferralPayouts(fetchedPayouts, { date: dateFilter }),
    [dateFilter, fetchedPayouts],
  );
  const searchFilteredPayouts = useMemo(
    () =>
      searchFilter.length === 0
        ? filteredPayouts
        : filteredPayouts.filter((payout) =>
            payout.sum.toLowerCase().includes(searchFilter.toLowerCase()),
          ),
    [searchFilter, filteredPayouts],
  );

  const sortedPayouts = useMemo(
    () =>
      sortBy.sortBy === null
        ? searchFilteredPayouts
        : getSortedReferralPayouts(searchFilteredPayouts, sortBy.sortBy, sortBy.incr),
    [sortBy, searchFilteredPayouts],
  );

  const pagesQuanity = Math.ceil(sortedPayouts.length / currentItemsPerPage);

  const paginatedPayouts = useMemo(
    () =>
      sortedPayouts.slice(
        currentItemsPerPage * (currentPage - 1),
        currentItemsPerPage * currentPage,
      ),
    [sortedPayouts, currentItemsPerPage, currentPage],
  );

  return (
    <StyledReferralPayoutsList>
      <BorderedWrapper>
        <div className="referral-list-controls-container">
          <h2 className="referral-list-heading">
            {t('referralsPage.referralPayoutsList.heading')}
          </h2>
          <div className="referral-list-controls">
            <CustomCalendar onChange={changeDateRangeHandler} />
            <Search onChange={setSearchFilterHandler} />
          </div>
        </div>
        <div className="referral-list-container">
          <div className="referral-list-header">
            <div className="referral-list-header-element">
              <div>{t('referralsPage.referralPayoutsList.header.dateTime')}</div>
              <button className="sort-by-button" onClick={() => sortHandler('date')}>
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
            <div className="referral-list-header-element">
              <div>{t('referralsPage.referralPayoutsList.header.sum')}</div>
              <button className="sort-by-button" onClick={() => sortHandler('sum')}>
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
          </div>
          <div className="referral-list-body">
            {paginatedPayouts.map((payout) => {
              const elementdate = new Date(payout.date);
              return (
                <div key={payout.id} className="referral-list-row">
                  <div className="referral-list-element date">
                    <span>{formateDate(elementdate)}</span>
                    <span className="time">
                      &nbsp;-&nbsp;
                      {`${formateTime(elementdate)}`}
                    </span>
                  </div>
                  <div className="referral-list-element sum">{payout.sum}</div>
                </div>
              );
            })}
          </div>
          {pagesQuanity > 0 && (
            <div className="pagination-container">
              <Paginator
                currentPage={currentPage}
                pageQuantity={pagesQuanity}
                onChange={changeCurrentPageHandler}
              />
              <DisplayItemsPerPageSelect onChange={setCurrentItemsPerPage} />
            </div>
          )}
        </div>
      </BorderedWrapper>
    </StyledReferralPayoutsList>
  );
};
