import { Layout, Paginator } from 'components';
import { BlogCard } from 'components/BlogCard';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { darkTheme } from 'themes';

const StyledContent = styled.div`
  padding: 2rem 1rem 4rem;
  width: 32rem;
  margin: auto;
  color: ${darkTheme.text1};
  .blog-container {
    .blog-header {
      background-image: url('/images/bg/blog-folder-xs.svg');
      padding: 1rem 1rem 0;
      background-repeat: no-repeat;

      .blog-heading {
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 37.5px */
        letter-spacing: 0.25px;
      }
    }
    .blog-content {
      background-color: ${darkTheme.button3};
      padding: 1.2rem 1rem 1.5rem;
      border-radius: 0 0 10px 10px;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      margin-bottom: 1.2rem;
    }
    .blog-paginator {
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (min-width: 768px) {
    width: 76.8rem;
    padding: 3rem 3.4rem;
    .blog-container {
      .blog-header {
        background-image: url('/images/bg/blog-folder-sm.svg');
        padding: 3rem 3.5rem 0;

        .blog-heading {
          font-size: 5.5rem;
          font-weight: 700;
          line-height: 126%; /* 69.3px */
        }
      }
      .blog-content {
        padding: 6rem 3.5rem;
        gap: 2.5rem;
        margin-bottom: 3rem;
      }
      .blog-paginator {
      }
    }
  }
  @media screen and (min-width: 1024px) {
    width: 102.4rem;
    padding: 4rem 3.7rem 12rem;
    .blog-container {
      .blog-header {
        background-image: url('/images/bg/blog-folder-md.svg');
        padding: 2.5rem 3rem 0;
        .blog-heading {
          font-size: 6rem;
          font-weight: 500;
          line-height: 110%; /* 88px */
          letter-spacing: -0.8px;
        }
      }
      .blog-content {
        padding: 8rem 6rem;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3.5rem;
        margin-bottom: 3rem;
      }
    }
  }
  @media screen and (min-width: 1440px) {
    width: auto;
    max-width: 132rem;
    padding: 4rem 0rem 12rem;
    .blog-container {
      .blog-header {
        background-image: url('/images/bg/blog-folder-lg.svg');
        padding: 2.5rem 4rem 0;
      }
      .blog-content {
        padding: 2rem 3.6rem 6.2rem;
        gap: 2.5rem;
      }
    }
  }
`;

export const BlogPage = () => {
  const { t } = useTranslation();
  //TODO: mock to get form api
  const blogArticles: { image: string; header: string; text: string; date: string }[] = [
    {
      image: '/images/blog/43edae8886dd8f20ff1cffb903d153b4.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/2f9cebceaad4f5d22ca9c7fb1151c86f.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
  ];
  const navigate = useNavigate();

  const DEFAULT_ITEMS_PER_PAGE_QUANTITY = 6;
  const DEFAULT_PAGE_NUMBER = 1;

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [unitsPerPageQuantity, setUnitPerPageQuantity] = useState(DEFAULT_ITEMS_PER_PAGE_QUANTITY);

  const changeCurrentPageHandler = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const pagesQuanity = Math.ceil(blogArticles.length / unitsPerPageQuantity);

  const paginatedBlogArticles = useMemo(
    () =>
      blogArticles.slice(
        unitsPerPageQuantity * (currentPage - 1),
        unitsPerPageQuantity * currentPage,
      ),
    [blogArticles, currentPage],
  );

  const blogArticleHandler = (articleId: string) => () => {
    navigate(`/articles?id=${articleId}`);
  };

  useEffect(() => {
    setUnitPerPageQuantity(window.innerWidth < 1420 ? 6 : 9);
  }, [window.innerWidth]);

  return (
    <Layout home>
      <StyledContent>
        <div className="blog-container">
          <div className="blog-header">
            <h1 className="blog-heading">{t('blogPage.heading')}</h1>
          </div>
          <div className="blog-content">
            {paginatedBlogArticles.map((blogArticle, index) => (
              <BlogCard
                key={blogArticle.header + index}
                {...blogArticle}
                onClick={blogArticleHandler(String(index))}
              />
            ))}
          </div>
          <div className="blog-paginator">
            <Paginator
              currentPage={currentPage}
              pageQuantity={pagesQuanity}
              onChange={changeCurrentPageHandler}
            />
          </div>
        </div>
      </StyledContent>
    </Layout>
  );
};
