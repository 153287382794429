import { useTranslation } from 'react-i18next';
import { StyledFAQHero } from './styles';
interface IFAQHeroProps {
  children?: React.ReactNode;
}
export const FAQHero = ({ children }: IFAQHeroProps) => {
  const { t } = useTranslation();
  return (
    <StyledFAQHero>
      <div className="faq-hero-content">
        <div className="faq-hero-heros">
          <h1 className="heading">{t('faqPage.hero.heading')}</h1>
          <img
            className="faq-hero-image"
            src="/images/faq-photo.webp"
            alt={t('faqPage.hero.headingPhotoAlt')}
          />
        </div>
      </div>
    </StyledFAQHero>
  );
};
