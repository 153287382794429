import styled from 'styled-components';
export const StyledCustomSearch = styled.div`
  position: relative;
  width: 23.5rem;
  height: 4.2rem;

  font-size: 1.8rem;

  color: ${(props) => props.theme.text1};

  .search-input {
    height: 100%;
    width: 100%;

    padding: 0 4rem 0 1.1rem;

    border: 1px solid ${(props) => props.theme.text4};
    border-radius: 9px;

    font: inherit;
    color: currentColor;

    outline: none;

    background-color: ${(props) => props.theme.bg4};

    &::placeholder {
      color: ${(props) => props.theme.text2};
    }
  }

  .search-input-button {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 4rem;
    max-height: 100%;
    height: 100%;

    color: ${(props) => props.theme.text1};

    &:hover {
      color: ${(props) => props.theme.accent1};
    }

    .search-input-button__icon {
      width: 2.4rem;
      height: 2.4rem;

      *[stroke] {
        stroke: currentColor;
      }
    }
  }
`;
