import styled from 'styled-components';
export const StyledBorderedWrapper = styled.div`
  padding: 1rem;

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.bg6 : props.theme.accent3)};
  border-radius: 15px;

  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg4)};
  box-shadow: 0px 6px 15.100000381469727px 0px
    ${(props) => (props.theme.id === 'dark' ? '#150736' : '#aaa5ccb2')};

  @media screen and (min-width: 768px) {
    height: 100%;

    padding: 2rem 3rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 2rem 2rem;
  }
`;
