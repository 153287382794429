import styled from 'styled-components';

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 0.7rem;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent3)};
  border-radius: 6px;

  font-size: 1.4rem;
  line-height: 150%;
  font-weight: 400;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.bg2)};

  &:focus {
    outline: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.text1)};
  }

  &::placeholder {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1)};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 150%;
  }

  &.valid {
    border: 1px solid ${(props) => props.theme.button1};
  }

  &.invalid {
    border: 1px solid ${(props) => props.theme.errorRed};
  }

  &.password {
    padding-right: 4.5rem;
  }

  &.status {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};

    cursor: not-allowed;
    &::placeholder {
      color: currentColor;
    }
    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.bg3)};
    box-shadow: none;

    .button_icon {
      > * {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};
      }
    }
  }
`;

export const StyledInputGroup = styled.div`
  position: relative;
  flex-grow: 1;

  .password-input-label {
    display: block;
    padding: 0 1rem;

    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 400;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};

    @media screen and (min-width: 768px) {
      margin-bottom: 0.2rem;
    }
  }

  .new-password-input-label {
    display: block;
    padding: 0 1rem;

    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 400;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};

    @media screen and (min-width: 768px) {
      margin-bottom: 0.2rem;
    }
  }
  .input-error-message {
    position: absolute;
    bottom: 0;
    transform: translateY(85%);

    font-size: 1.2rem;
    font-weight: 600;
    line-height: 150%;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.accent1)};
  }
`;

export const StyledHideButton = styled.button`
  position: absolute;
  right: 0.5rem;
  bottom: 50%;
  transform: translateY(50%);

  display: flex;
  align-items: center;

  width: 3rem;
  height: 3rem;

  > * {
    width: 100%;
    height: 100%;
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  }

  &:disabled {
    opacity: 0.3;
  }
`;
