import { AdminElementsWrapper, Button } from 'components';
import { StyledAdminCalculatePayments } from './styles';
import { useTranslation } from 'react-i18next';
import { getFormatedServerDateTime } from 'utils';
import { memo } from 'react';
interface IAdminCalculatePaymentsProps {
  children?: React.ReactNode;
}
export const AdminCalculatePaymentsComponent = ({ children }: IAdminCalculatePaymentsProps) => {
  const { t } = useTranslation();

  const serverTime = getFormatedServerDateTime(new Date());
  return (
    <StyledAdminCalculatePayments>
      <AdminElementsWrapper>
        <div className="content">
          <div>
            <h3 className="heading">{t('adminPage.calculatePayment.heading')}</h3>
            <p className="server-time">
              {t('adminPage.calculatePayment.serverTime')}
              {serverTime.toLocaleString()}
            </p>
          </div>
          <Button title={t('adminPage.calculatePayment.startCalculation')} />
        </div>
      </AdminElementsWrapper>
    </StyledAdminCalculatePayments>
  );
};

export const AdminCalculatePayments = memo(AdminCalculatePaymentsComponent);
