import { useRef, useState } from 'react';
import cn from 'classnames';
import {
  StyledArrowButton,
  StyledCountryCode,
  StyledCountyCodeSelect,
  StyledInput,
  StyledInputGroup,
  StyledPhoneDropdown,
  StyledPhoneInput,
  StyledSelectedCode,
} from './styles';
import { ReactComponent as ArrowDownFilled } from 'assets/icons/arrow-down-full.svg';
import countries from 'assets/country-codes.json';
import { useTranslation } from 'react-i18next';
interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  inValid?: boolean;
  error?: string;
}

export const PhoneInput = (props: IInputProps) => {
  // TODO: get current code from locale
  const defaultCurrentCode = '7';

  const { t } = useTranslation();
  const [valid] = useState(false);
  const [phonenumber, setPhoneNumber] = useState('');
  const [currentCode, setCurrentCode] = useState<string>(defaultCurrentCode);
  const [showCodeList, setShowCodeList] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputId = 'userPhone';

  const countriesEntries = Object.entries(countries) as Array<[string, string]>;
  countriesEntries.sort((a, b) => a[0].localeCompare(b[0]));

  const selectCodeHandlerTest: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setCurrentCode(event.target.value);
    const composedValue = `${event.target.value}${phonenumber}`.split('-').join('');
    event.target.value = composedValue;
    event.target.id = inputId;
    if (props.onChange) props.onChange(event);
  };

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPhoneNumber(event.target.value);
    const composedValue = `${currentCode}${event.target.value}`.split('-').join('');
    event.target.value = composedValue;
    if (props.onChange) props.onChange(event);
  };

  return (
    <StyledPhoneInput>
      {props.labelText && (
        <label className="phone-input-label" htmlFor={inputId} hidden={!props.labelText}>
          {props.labelText ? props.labelText : props.placeholder}
        </label>
      )}
      <div className="phone-input-container">
        <StyledCountyCodeSelect
          className={cn(props.disabled && 'disabled')}
          onClick={() => setShowCodeList(!showCodeList)}
        >
          <StyledSelectedCode>+{currentCode}</StyledSelectedCode>
          <StyledArrowButton
            type="button"
            className={`${showCodeList && 'flipped'}`}
            disabled={props.disabled}
          >
            <ArrowDownFilled />
          </StyledArrowButton>
        </StyledCountyCodeSelect>

        <StyledInputGroup>
          <StyledInput
            onBlur={props.onBlur}
            ref={inputRef}
            id={inputId}
            onChange={changeHandler}
            className={`${valid && 'valid'} ${props.inValid && 'invalid'}`}
            value={phonenumber}
            onFocus={() => setShowCodeList(false)}
            disabled={props.disabled}
          />
          {props.error && props.inValid && (
            <div className="input-error-message">{t(`forms.errors.${props.error}`)}</div>
          )}
        </StyledInputGroup>
        {showCodeList && (
          <StyledPhoneDropdown>
            <div className="scroll-container">
              {countriesEntries.map(([country, code]) => (
                <StyledCountryCode key={country + code} className="p3">
                  <input
                    hidden
                    id={code}
                    type="radio"
                    onChange={selectCodeHandlerTest}
                    value={code}
                    checked={currentCode === code}
                  ></input>
                  <label htmlFor={code}>
                    <span className="code">+{code}</span> <span className="country">{country}</span>
                  </label>
                </StyledCountryCode>
              ))}
            </div>
          </StyledPhoneDropdown>
        )}
      </div>
    </StyledPhoneInput>
  );
};
