import styled from 'styled-components';
export const StyledAdminEditUsers = styled.div`
  .admin-edit-users-controls {
    display: flex;
    gap: 1rem;

    &__right {
      display: flex;
      gap: 1.2rem;

      margin-left: auto;
    }
  }
`;
