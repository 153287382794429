import { AddWorker, Footer, Layout, UserDataLayoutContainer } from 'components';
import { WorkersListContainer } from 'routes/workers/components';

import { StyledContent } from './StyledContent';

export const WorkersPage = () => {
  return (
    <Layout>
      <UserDataLayoutContainer pageName="workersPage">
        <StyledContent>
          <div className="add-worker-dialog-container">
            <AddWorker />
          </div>
          <WorkersListContainer />
          <Footer smaller />
        </StyledContent>
      </UserDataLayoutContainer>
    </Layout>
  );
};
