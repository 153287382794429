import { useEffect, useState } from 'react';
import { CreateWorkersGroupDialog, EditWorkersGroupDialog, Modal } from 'components';
import { CreateGroupButton, EditGroupButton, WorkersGroupsSelect } from 'routes/workers/components';
import { StyledWorkerGroups } from './styles';
import { IWorkerGroup } from 'types';
import { selectWorkers } from 'store/slices/userWorkersSlice';
import { useSelector } from 'react-redux';

type TGroupDialogs = 'createGroup' | 'editGroup';
export const WorkerGroups = () => {
  const [showGroupDialog, setShowGroupDialog] = useState<TGroupDialogs | null>(null);
  const [isMountModal, setIsMountModal] = useState(false);

  const { workers, normalizedGroups } = useSelector(selectWorkers);

  const cloaseModalHandler = () => {
    setShowGroupDialog(null);
  };
  const showModalHandler = (type: TGroupDialogs) => () => {
    setShowGroupDialog(type);
  };

  const selectGroupHandler = (groupId: IWorkerGroup['id'] | null) => {
    console.log('selected group', groupId);
  };

  useEffect(() => {
    if (showGroupDialog) {
      setIsMountModal(true);
    }

    if (!showGroupDialog) {
      setTimeout(() => setIsMountModal(false), 500);
    }
  }, [showGroupDialog]);
  return (
    <StyledWorkerGroups>
      <Modal show={isMountModal}>
        {showGroupDialog === 'createGroup' && (
          <CreateWorkersGroupDialog
            onClose={cloaseModalHandler}
            show={isMountModal}
            workers={workers}
          />
        )}
        {showGroupDialog === 'editGroup' && (
          <EditWorkersGroupDialog onClose={cloaseModalHandler} show={isMountModal} />
        )}
      </Modal>
      <WorkersGroupsSelect groups={normalizedGroups} onSelect={selectGroupHandler} />
      <CreateGroupButton onClick={showModalHandler('createGroup')} />
      <EditGroupButton onClick={showModalHandler('editGroup')} />
    </StyledWorkerGroups>
  );
};
