import { StyledButton } from 'components/shared/Button/styles';
import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledHeroSection = styled.section`
  ${StyledButton} {
    @media screen and (max-width: 767px) {
      padding: 0.6rem 1.5rem;
      font-size: 1.2rem;
    }
  }

  position: relative;

  margin: auto;
  margin-bottom: 8.5rem;

  max-width: 42.5rem;

  @media screen and (min-width: 768px) {
    max-width: 70rem;

    margin-bottom: 13.4rem;
  }

  @media screen and (min-width: 1024px) {
    max-width: 95rem;

    margin-bottom: 8rem;
  }

  @media screen and (min-width: 1440px) {
    max-width: 132rem;

    margin-bottom: 10rem;
  }

  .hero {
    position: relative;
    overflow: hidden;

    width: 100%;
    aspect-ratio: 1.65;

    margin: auto;

    padding: 1.4rem 1rem;

    border-radius: 15px;

    color: ${darkTheme.text1};

    @media screen and (min-width: 768px) {
      height: 48.8rem;

      padding: 4.5rem;
    }

    @media screen and (min-width: 1024px) {
      width: 95rem;
      height: 58.3rem;
    }

    @media screen and (min-width: 1440px) {
      width: 132rem;
      height: 68rem;

      padding: 6.4rem 6.2rem;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transform: translate3d(0, 0, 0);

      width: 100%;
      height: 100%;

      border-radius: 15px;
      background-color: #2b2468;

      @media screen and (min-width: 768px) {
        clip-path: path(
          'M0 30C0 15.8579 0 8.7868 4.3934 4.3934C8.7868 0 15.8579 0 30 0H350L670 0C684.142 0 691.213 0 695.607 4.3934C700 8.7868 700 15.8579 700 30V457.966C700 472.108 700 479.179 695.607 483.572C691.213 487.966 684.142 487.966 670 487.966H368.492C368.279 487.966 368.172 487.966 368.092 487.967C368.012 487.967 367.846 487.971 367.513 487.978C365.509 488.021 357.747 488.107 352.327 487.04C350.825 486.745 349.262 486.238 347.826 485.682C345.751 484.879 344.713 484.477 343.029 483.199C341.346 481.921 340.376 480.622 338.436 478.025L289.623 412.667C287.61 409.972 286.604 408.625 285.003 407.381C283.402 406.137 282.221 405.649 279.859 404.672C278.111 403.95 276.182 403.256 274.382 402.83C270.885 402.001 261.385 401.792 259.968 401.765C259.897 401.764 259.861 401.763 259.803 401.763C259.745 401.762 259.699 401.762 259.606 401.762L29.8932 400.943C15.7971 400.893 8.74905 400.868 4.37452 396.478C0 392.088 0 385.04 0 370.944V30Z'
        );
      }

      @media screen and (min-width: 1024px) {
        clip-path: path(
          'M0 30C0 15.8579 0 8.7868 4.3934 4.3934C8.7868 0 15.8579 0 30 0H475L920 0C934.142 0 941.213 0 945.607 4.3934C950 8.7868 950 15.8579 950 30V552.959C950 567.101 950 574.172 945.607 578.566C941.213 582.959 934.142 582.959 920 582.959H493.588C493.343 582.959 493.221 582.959 493.131 582.96C493.04 582.961 492.832 582.966 492.417 582.976C490.271 583.028 482.664 583.108 477.327 581.854C475.764 581.486 474.135 580.845 472.652 580.15C470.669 579.22 469.677 578.755 468.056 577.318C466.435 575.881 465.57 574.496 463.838 571.726L414.215 492.349C412.412 489.465 411.51 488.023 409.974 486.628C408.437 485.233 407.299 484.666 405.023 483.532C403.232 482.64 401.239 481.775 399.382 481.25C395.931 480.273 386.629 480.017 385.027 479.979C384.932 479.977 384.885 479.976 384.808 479.975C384.732 479.974 384.671 479.974 384.549 479.974L29.9138 478.955C15.8088 478.914 8.75634 478.894 4.37817 474.503C0 470.112 0 463.06 0 448.955V30Z'
        );
      }

      @media screen and (min-width: 1440px) {
        clip-path: path(
          'M0 30C0 15.8578 0 8.7868 4.3934 4.3934C8.7868 0 15.8579 0 30 0H660L1290 0C1304.14 0 1311.21 0 1315.61 4.3934C1320 8.7868 1320 15.8579 1320 30V649.945C1320 664.087 1320 671.158 1315.61 675.551C1311.21 679.945 1304.14 679.945 1290 679.945H678.796C678.499 679.945 678.351 679.945 678.243 679.946C678.136 679.948 677.827 679.956 677.21 679.974C674.818 680.043 667.504 680.096 662.327 678.446C660.484 677.859 658.55 676.757 656.87 675.626C655.148 674.466 654.287 673.886 652.953 672.318C651.618 670.749 650.97 669.345 649.675 666.538L598.355 555.299C596.999 552.359 596.321 550.89 595.049 549.365C593.777 547.841 592.786 547.145 590.804 545.753C588.826 544.364 586.514 542.939 584.382 542.122C580.998 540.824 571.991 540.465 570.13 540.404C569.998 540.4 569.933 540.398 569.819 540.396C569.706 540.394 569.619 540.393 569.445 540.393L29.9211 538.974C15.813 538.937 8.75893 538.919 4.37946 534.528C0 530.137 0 523.083 0 508.975V30Z'
        );
      }

      @media screen and (min-width: 2250px) {
        clip-path: none;
      }

      video {
        position: absolute;
        z-index: 0;
        top: 0;
        right: -2px;

        height: 100%;
        background-color: #2b2468;
      }
    }
  }
`;

export const StyledTitle = styled.h1`
  position: relative;

  margin-bottom: 1rem;

  font-size: 3rem;
  line-height: 96%;
  font-weight: 600;
  letter-spacing: -0.03rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 1.5rem;

    font-size: 6.5rem;
    line-height: 145%;
    letter-spacing: -0.65px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 0.2rem;

    font-size: 8rem;
    letter-spacing: -0.85px;
  }

  @media screen and (min-width: 1440px) {
    letter-spacing: -1px;
    line-height: 116px;
  }
`;

export const StyledSubTitle = styled.h2`
  position: relative;

  max-width: 18rem;

  margin-bottom: 1.5rem;

  font-size: 1.2rem;
  line-height: 150%;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    max-width: 49rem;

    margin-bottom: 2rem;

    font-size: 1.8rem;
    font-weight: 500;
  }
`;

export const StyledHeroBenefits = styled.div`
  position: absolute;
  bottom: -0.5rem;
  transform: translateY(100%);

  display: flex;
  gap: 1.5rem;

  width: 100%;
  height: 4rem;

  color: ${(props) => props.theme.text1};

  @media screen and (min-width: 768px) {
    transform: translateY(7.3rem);
    bottom: 0;

    flex-direction: column;
    justify-content: flex-end;
    gap: 0;

    height: auto;
    aspect-ratio: unset;

    padding-left: 2.5rem;
  }

  @media screen and (min-width: 1024px) {
    transform: none;

    justify-content: flex-start;
    flex-direction: row;

    padding-left: 2rem;
  }

  @media screen and (min-width: 1440px) {
    padding-left: 6.4rem;
    padding-bottom: 3.2rem;
  }
`;

export const StyledHeroMiners = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  height: 100%;

  margin-bottom: -1.4rem;

  @media screen and (min-width: 768px) {
    gap: 2rem;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const StyledHeroHashrate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  height: 100%;

  @media screen and (min-width: 768px) {
    gap: 2rem;
  }
  @media screen and (min-width: 1024px) {
    margin-left: -1.4rem;
  }
`;

export const StyledImageContainer = styled.div`
  height: 100%;
  aspect-ratio: 1;
  border-radius: 100%;

  @media screen and (min-width: 768px) {
    height: 7.3rem;
  }

  img {
    width: 100%;
    max-height: 100%;
  }
`;

export const StyledHeroBenefitsText = styled.p`
  display: none;
  font-size: 1rem;
  line-height: 150%;
  font-weight: ${(props) => (props.theme.id === 'dark' ? 700 : 400)};

  @media screen and (min-width: 768px) {
    max-width: 19rem;

    font-size: 1.8rem;
  }

  &.mobile {
    display: block;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    display: block;

    max-width: unset;
  }

  @media screen and (min-width: 1440px) {
    font-size: 2rem;
    font-weight: 600;
  }

  .colored {
    color: ${darkTheme.accent2};
    font-weight: ${(props) => (props.theme.id === 'dark' ? 700 : 800)};
  }
`;

export const StyledHeroBenefitsTexts = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  padding-left: 2rem;
`;
