import { v4 as uuid } from 'uuid';
import { IUserListElement } from 'types';
import { ITransactionListElement } from 'types/transactions';

export const generateMockUsersList = (quantity: number = 10): IUserListElement[] => {
  const list: IUserListElement[] = [...Array(quantity)].map((_, i) => ({
    id: uuid(),
    name: `username-${i}`,
    email: `uaeremIL${i}@google.com`,
    cost: Math.floor(Math.random() * 1000).toFixed(0),
    adjust: Math.floor(Math.random() * 1000).toFixed(0),
    link: 'http://somewhere.com',
    wallet: '0x1215991085d541A586F0e1968355A36E58C9b2b4',
    equipmentGroup: uuid(),
    currentGroup: uuid(),
    vip: Math.random() > 0.5,
    averageDailyHashrate: Math.floor(Math.random() * 1000).toFixed(0),
    averageWeeklyHashrate: Math.floor(Math.random() * 1000).toFixed(0),
    createdAt: new Date(),
    updatedAt: new Date(),
    comment: 'some comment',
  }));

  return list;
};

export const generateMockTransactionsList = (quantity: number = 10): ITransactionListElement[] => {
  const list: ITransactionListElement[] = [...Array(quantity)].map((_, i) => ({
    id: String(i),
    hashId: uuid(),
    confirmed: Math.random() > 0.5 ? 'confirmed' : 'pending',
    createdAt: new Date(),
    updatedAt: new Date(),
  }));
  return list;
};
