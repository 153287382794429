import styled from 'styled-components';
export const StyledWorkersGroupsSelect = styled.div`
  display: none;
  position: relative;
  z-index: 1;

  width: 18.7rem;
  flex-shrink: 0;

  @media screen and (min-width: 768px) {
    display: block;
  }

  .selected-group {
    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

    height: 4.1rem;

    border-radius: 5px;
    padding: 0.7rem 1.5rem;
    padding-right: 3.2rem;

    cursor: pointer;

    position: relative;
    z-index: 1;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */

    &-name {
      max-height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .drop-down-arrow {
    position: absolute;
    right: 1.5rem;
    top: 0;
    width: 1.7rem;
    height: 100%;
    transition: all 0.2s;

    > * {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
    transform: scaleX(1) scaleY(1);

    &.collapsed {
      transform: scaleY(-1) scaleX(-1);
    }
  }
`;
