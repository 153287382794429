import styled from 'styled-components';

export const StyledReferralHero = styled.div`
  position: relative;

  margin-bottom: 3.5rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;
  }

  .content {
    width: fit-content;

    margin: auto;

    padding-top: 1.5rem;
    padding-bottom: 18rem;

    @media screen and (min-width: 768px) {
      width: auto;

      margin: 0;

      padding: 3rem 4rem;
    }

    @media screen and (min-width: 1024px) {
      padding-top: 0;
      padding-bottom: 1.8rem;
    }
  }

  .referral-hero-heading {
    margin-bottom: 0.5rem;

    font-size: 2.2rem;
    font-weight: 700;
    line-height: 140%;

    @media screen and (min-width: 768px) {
      max-width: 35rem;

      margin-bottom: 0;

      font-size: 5.5rem;
      line-height: 126%;
    }
    @media screen and (min-width: 1024px) {
      max-width: 47rem;

      font-size: 4.2rem;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.8px;
    }
  }

  .referral-hero-subheading {
    max-width: 21.5rem;

    margin-bottom: 1.2rem;

    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%; /* 18px */

    @media screen and (min-width: 768px) {
      max-width: 32rem;

      margin-bottom: 1.8rem;

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 27px */
    }
  }

  .ice-cube {
    position: absolute;
    z-index: 1000;

    width: 23.8rem;
    height: 23.4rem;

    background-image: ${(props) => `url('/images/ice-cubes-${props.theme.id}.webp')`};
    background-size: 125%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (min-width: 768px) {
      bottom: 0;
      right: -13.5rem;

      width: 38.2rem;
      height: 37.6rem;
    }
    @media screen and (min-width: 1024px) {
      top: 40%;
      right: 7rem;

      transform: translateY(-50%);

      width: 35rem;
    }
    @media screen and (min-width: 1440px) {
      width: 34.2rem;
    }
  }
`;
