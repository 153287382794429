import { RoundButton } from 'components';
import styled from 'styled-components';
import { ReactComponent as LoginIcon } from 'assets/icons/login-icon.svg';

const StyledIconContainer = styled.div`
  width: 2.7rem;
  height: 2.4rem;
  padding-right: 0.3rem;
`;

interface ILoginButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
export const LoginButton = (props: ILoginButtonProps) => {
  return (
    <RoundButton {...props}>
      <StyledIconContainer>
        <LoginIcon />
      </StyledIconContainer>
    </RoundButton>
  );
};
