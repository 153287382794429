import styled from 'styled-components';
import { StyledAdminListHeaders } from '../../AdminListHeaderCell/styles/StyledAdminListHeaders';
import { StyledAdminUsersRowsWidth } from './StyledAdminUsersRowsWidth';

export const StyledAdminUsersListHeader = styled.div`
  ${StyledAdminListHeaders}

  .admin-list-header-cell-container {
    ${StyledAdminUsersRowsWidth}
  }
`;
