import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledNavContainer = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const StyledNavigation = styled.nav`
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const StyledNavigationList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4rem;
`;

export const StyledNavigationListElement = styled.li`
  transition: all 0.2s;
  text-transform: capitalize;

  &.buttoned {
    color: ${darkTheme.text1};

    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button2 : props.theme.button3};
    padding: 0.8rem 1.6rem;
    border-radius: 200px;

    &:hover {
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.accent1 : props.theme.button1};
      > * {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.text2)};
      }
    }
  }
`;
