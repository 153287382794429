export const apiGateway = 'https://api.pool.rutbot.com';

export const apiRoutes = {
  apiSignInPath() {
    return `${apiGateway}/login`;
  },
  apiSignUpPath() {
    return `${apiGateway}/signup`;
  },
  apiFetchHashrate() {
    return `${apiGateway}/pool/hashrate`;
  },
  apiFetchUserPath() {
    return `${apiGateway}/user/info`;
  },
  apiFetchUserHashRate() {
    return `${apiGateway}/user/hashrate`;
  },
  apiFetchUserStat() {
    return `${apiGateway}/user/stat`;
  },
  apiAddWorker() {
    return `${apiGateway}/user/worker/add`;
  },
  apiDeleteWorker() {
    return `${apiGateway}/user/worker/delete`;
  },
  apiFetchWorkers() {
    return `${apiGateway}/user/workers`;
  },
  apiFetchWorkerHashrate(workerId: number) {
    return `${apiGateway}/user/worker/hashrate/${workerId}`;
  },
  apiFetchUserUrlsPath() {
    return `${apiGateway}/user/links`;
  },
  apiFetchWorkersGroups() {
    return `${apiGateway}/user/worker/groups`;
  },
  apiAddWorkersGroup() {
    return `${apiGateway}/user/worker/group/add`;
  },
  apiDeleteWorkersGroup() {
    return `${apiGateway}/user/worker/group/delete`;
  },
  apiAssignWorkerGroupToWorker() {
    return `${apiGateway}/user/worker/group/assign`;
  },
  apiUnassignWorkerGroupToWorker() {
    return `${apiGateway}/user/worker/group/unassign`;
  },
  apiRenameWorkersGroup() {
    return `${apiGateway}/user/worker/group/rename`;
  },
  apiFetchPayouts() {
    return `${apiGateway}/user/payments`;
  },
};
