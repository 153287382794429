import { forwardRef, useId } from 'react';
import {
  StyledCheckBox,
  StyledCheckBoxGroup,
  StyledCheckBoxLabel,
  StyledCheckMark,
} from './styles';
import cn from 'classnames';
interface ICheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inValid?: boolean;
}

export const CheckBox = forwardRef<HTMLInputElement, ICheckBoxProps>(
  function MyCheckbox(props, ref) {
    const checkboxId = useId();
    return (
      <StyledCheckBoxGroup className={cn(props.disabled && 'disabled')}>
        <StyledCheckBoxLabel
          className={cn('checkbox-label', props.inValid && 'invalid')}
          htmlFor={checkboxId}
        >
          <StyledCheckBox
            {...props}
            id={checkboxId}
            type="checkbox"
            disabled={props.disabled}
            ref={ref}
          />
          <StyledCheckMark />
          <div className="checkbox-text">{props.label}</div>
        </StyledCheckBoxLabel>
      </StyledCheckBoxGroup>
    );
  },
);
