import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/icons/close-cross.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon.svg';

import {
  StyledAddWorkerContainer,
  StyledAddWorkerDialog,
  StyledAddWorkerOverlay,
  StyledCloseButton,
  StyledCopyButton,
  StyledField,
  StyledHeading,
  StyledInformationField,
  StyledLabel,
  StyledSubHeading,
  StyledWorkerInput,
} from './styles';
import { useSelector } from 'react-redux';
import { selectAuth, selectUser } from 'store/slices/authSlice';
import { useAppDispatch } from 'store/store';
import { selectWorkers } from 'store/slices/userWorkersSlice';
import { ESliceDataFetchingStatus } from 'types/common';
import { fetchUserPoolUrls } from 'store/thunks/userWorkersThunks';

export const AddWorkerDialog = ({ onClose, show }: { onClose: () => void; show?: boolean }) => {
  const dispatch = useAppDispatch();
  const { userPoolUrlData, userPoolUrlDataStatus } = useSelector(selectWorkers);

  const [copySuccess, setCopySuccess] = useState<string | null>(null);
  const [showContent, setShowContent] = useState(false);
  const [workerSubName, setWorkerSubName] = useState('');
  const [showDot, setShowDot] = useState(false);

  const user = useSelector(selectUser);
  const { token } = useSelector(selectAuth);

  const workerNameInput = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const poolsURls: string[] = userPoolUrlData ? Object.values(userPoolUrlData).slice(0, 3) : [];

  const workerName = userPoolUrlData?.worker;

  // TODO: https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-45
  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    // textArea.focus();
    textArea.focus({ preventScroll: true });
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  };

  const copyUrlHandler =
    (url: string): React.MouseEventHandler =>
    async () => {
      try {
        await navigator.clipboard.writeText(url);
        setCopySuccess(url);
        setTimeout(() => setCopySuccess(null), 300);
      } catch (error) {
        console.error('coping tp clipboard error', error);
      }
    };

  const copyWorkerNameHandler =
    (workerName: string): React.MouseEventHandler =>
    async () => {
      try {
        const workerFullName =
          workerSubName.length > 0 ? workerName + '.' + workerSubName : workerName;
        await navigator.clipboard.writeText(workerFullName);
        setCopySuccess(workerName + workerSubName);
        setTimeout(() => setCopySuccess(null), 300);
      } catch (error) {
        console.log('error', error);
      }
    };

  function closeHandler() {
    onClose();
    setShowContent(false);
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowContent(() => true), 100);
    }
  }, [show]);

  useEffect(() => {
    if (token && !userPoolUrlData && userPoolUrlDataStatus === ESliceDataFetchingStatus.initial) {
      dispatch(fetchUserPoolUrls(token));
    }
  }, []);

  return show ? (
    <StyledAddWorkerContainer>
      <StyledAddWorkerOverlay onClick={closeHandler} className={cn(showContent && 'show')} />
      {showContent && (
        <StyledAddWorkerDialog open={showContent}>
          <StyledCloseButton onClick={closeHandler}>
            <CloseIcon />
          </StyledCloseButton>
          <div>
            <StyledHeading>{t('modals.addWorker.heading')}</StyledHeading>
            <StyledSubHeading>{t('modals.addWorker.subheading')}</StyledSubHeading>
          </div>
          {poolsURls.map((url, index) => (
            <StyledInformationField key={url + index}>
              <StyledLabel>
                {t('modals.addWorker.pool')} {index + 1} URL
              </StyledLabel>
              <StyledField>
                {url}
                <StyledCopyButton onClick={copyUrlHandler(url + index)}>
                  <CopyIcon />
                </StyledCopyButton>
                {copySuccess === url + index && (
                  <div className="copy-success-message">{t('modals.addWorker.copyMessage')}</div>
                )}
              </StyledField>
            </StyledInformationField>
          ))}
          <StyledInformationField>
            <StyledLabel>{t('modals.addWorker.worker')}</StyledLabel>
            <StyledField>
              <div className="worker-name-input-container">
                <div>
                  {workerName}
                  {(showDot || workerSubName.length > 0) && <span>{'.'}</span>}
                </div>
                <StyledWorkerInput
                  ref={workerNameInput}
                  value={workerSubName}
                  onChange={(e) => setWorkerSubName(e.target.value)}
                  placeholder="click to add name suffix"
                  onFocus={() => setShowDot(true)}
                  onBlur={() => setShowDot(false)}
                ></StyledWorkerInput>
              </div>
              <StyledCopyButton
                onClick={copyWorkerNameHandler(workerName || 'unknown')}
                // disabled={workerSubName.length === 0}
              >
                <CopyIcon />
              </StyledCopyButton>
              {copySuccess === workerName + workerSubName && (
                <div className="copy-success-message">{t('modals.addWorker.copyMessage')}</div>
              )}
            </StyledField>
          </StyledInformationField>
        </StyledAddWorkerDialog>
      )}
    </StyledAddWorkerContainer>
  ) : null;
};
