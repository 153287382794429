import { StyledButton } from 'components/shared/Button/styles';
import styled from 'styled-components';
export const StyledServicePageHero = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1.8rem;
  padding: 1rem;

  border-radius: 12px;

  background-color: ${(props) => props.theme.bg6};
  background-image: ${(props) => `url('/images/bg/add-worker-bg-${props.theme.id}.webp')`};
  background-repeat: no-repeat;
  background-size: 140%;
  background-position: -20px 0;

  @media screen and (min-width: 768px) {
    height: 15.2rem;

    border-radius: 20px;
  }

  @media screen and (min-width: 1440px) {
    background-size: 110%;
  }

  @media screen and (min-width: 1920px) {
    background-position: -50px 0;
  }

  ${StyledButton} {
    font-size: 1.6rem;
    line-height: 150%;
    font-weight: 600;

    padding: 0.7rem;
    padding-left: 1.6rem;

    .button_icon {
      width: 2.6rem;
      height: 2.6rem;

      > svg {
        width: 100%;
        height: 100%;

        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.button2 : props.theme.text2)};
      }
    }

    @media screen and (min-width: 768px) {
      font-size: 2rem;
      line-height: 150%;
      font-weight: 600;

      padding: 0.6rem;
      padding-left: 2.4rem;

      .button_icon {
        width: 4rem;
        height: 4rem;

        > * {
          fill: red;
        }
      }
    }
  }
`;
