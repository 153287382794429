import { useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Routes } from 'lib';

import { ReactComponent as SmallLogoDark } from 'assets/logos/logo-square-dark.svg';
import { ReactComponent as LogoDark } from 'assets/logos/logo-dark.svg';
import { ReactComponent as SmallLogoLight } from 'assets/logos/logo-square-light.svg';
import { ReactComponent as LogoLight } from 'assets/logos/logo-light.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/long-arrow-right.svg';

import { AdminBarMenu, UserBarMenu } from './components';
import { StyledAuxButtons, StyledButton, StyledSidebar } from './styles';

export const Sidebar = ({ admin }: { admin?: boolean }) => {
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState<boolean>(false);
  const { id: themeId } = useTheme();

  const { t } = useTranslation();
  const topButtonHandler = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  const rightButtonHandler = () => {
    setIsSidebarMenuOpen((prev) => !prev);
    console.log('called sidebar rightButtonHandler');
  };
  const closeSidebarMenu = () => setIsSidebarMenuOpen(false);
  return (
    <StyledSidebar>
      <Link to={Routes.rootPath()}>
        <div className="logo-container small">
          {themeId === 'dark' ? <SmallLogoDark /> : <SmallLogoLight />}
        </div>
        <div className="logo-container">{themeId === 'dark' ? <LogoDark /> : <LogoLight />}</div>
      </Link>
      <div className="menu-container" onMouseLeave={closeSidebarMenu}>
        {admin ? <AdminBarMenu /> : <UserBarMenu open={isSidebarMenuOpen} />}
      </div>
      <StyledAuxButtons>
        <StyledButton className="right" onClick={rightButtonHandler}>
          <RightArrowIcon />
        </StyledButton>
        <StyledButton onClick={topButtonHandler}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            stroke="#FCFAFF"
          >
            <path d="M10 6L5.5 2L1 6" strokeWidth="1.81319" fill="none" />
          </svg>
          <div>{t('navigation.upButton')}</div>
        </StyledButton>
      </StyledAuxButtons>
    </StyledSidebar>
  );
};
