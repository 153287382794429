import { Footer, Layout, UserDataLayoutContainer } from 'components';
import {
  BTCAddress,
  ChangePassword,
  Contacts,
  Donats,
  CustomTimezoneSelect,
  TwoFactorAuth,
  LanguageSelect,
  ThemeSelect,
  Notifications,
} from './components';
import { StyledContent } from './styles';

export const SettingsPage = () => {
  return (
    <Layout>
      <UserDataLayoutContainer pageName="settingsPage">
        <StyledContent>
          <div className="settings-main">
            <Contacts />
            <ChangePassword />
            <BTCAddress />
            <TwoFactorAuth />
          </div>
          <div className="settings-aux">
            <Donats />
            <CustomTimezoneSelect />
            <LanguageSelect />
            <ThemeSelect />
          </div>
          <Notifications />
          <Footer smaller />
        </StyledContent>
      </UserDataLayoutContainer>
    </Layout>
  );
};
