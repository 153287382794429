import styled from 'styled-components';
import { StyledInput } from './StyledInput';

export const StyledBTCInput = styled.div`
  max-width: 54rem;
  ${StyledInput} {
    background-color: ${({ theme }) => (theme.id === 'dark' ? theme.text3 : theme.accent3)};
    border-radius: 6px;

    padding: 0.7rem 1rem;
    &::placeholder {
      color: ${({ theme }) => (theme.id === 'dark' ? theme.bg3 : theme.text4)};
    }

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;
