import styled from 'styled-components';
import { StyledSecondaryButton } from 'components/shared/SecondaryButton/styled';
import { darkTheme } from 'themes';

export const StyledInstructionArticle = styled.article`
  ${StyledSecondaryButton} {
    font-size: 1.2rem;
    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }
  }

  position: relative;

  width: 30rem;
  height: 32.1rem;

  margin: auto;

  padding: 4.2rem 1rem;

  color: ${darkTheme.text3};

  background-image: ${(props) => `url(/images/yellow-folder-${props.theme.id}-smallest.svg)`};
  background-repeat: no-repeat;

  @media screen and (min-width: 768px) {
    width: 70rem;
    height: 45rem;

    padding: 7.5rem 4.5rem;

    background-image: ${(props) => `url(/images/yellow-folder-${props.theme.id}-small.svg)`};
  }
  @media screen and (min-width: 1024px) {
    width: 95rem;
    height: 48rem;

    background-image: ${(props) => `url(/images/yellow-folder-${props.theme.id}-mid.svg)`};
  }
  @media screen and (min-width: 1440px) {
    width: 69rem;
    height: 48rem;

    margin: 0;

    background-image: ${(props) => `url(/images/yellow-folder-${props.theme.id}-lg.svg)`};
  }
`;

export const StyledInstructionsHeading = styled.header`
  margin-bottom: 1.2rem;

  font-size: 1.8rem;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.18px;

  @media screen and (min-width: 768px) {
    margin-bottom: 2.5rem;

    width: 30rem;
    line-height: 140%;
    font-size: 2.2rem;
    font-weight: 700;
  }

  @media screen and (min-width: 768px) {
  }
`;

export const StyledImgContainer = styled.div`
  position: absolute;
  bottom: 0;

  width: 17.4rem;
  right: 0.2rem;

  img {
    display: block;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 34.8rem;
  }
  @media screen and (min-width: 1024px) {
    width: 42.5rem;
    right: 0rem;
  }
`;
