import {
  BlogSection,
  CalculatorSection,
  FAQSection,
  HeroSection,
  Layout,
  MainBenefitsSection,
  OverallHashRateChart,
} from 'components';
import { StyledContent } from './styles';

export const MainPage = () => {
  return (
    <Layout home>
      <StyledContent>
        <HeroSection />
        <MainBenefitsSection />
        <CalculatorSection />
        <OverallHashRateChart />
        <BlogSection />
        <FAQSection />
      </StyledContent>
    </Layout>
  );
};
