import styled from 'styled-components';
export const StyledDisplayItemsPerPageSelect = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 1.5rem;

  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;

  .page-quantity-select-container {
    position: relative;

    width: 5.8rem;
    height: 2.9rem;

    .page-quantity-select {
      position: absolute;
      z-index: 1000;

      width: inherit;

      padding: 0 1rem;

      border: 1px solid
        ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent4)};
      border-radius: 5px;

      background: ${(props) => props.theme.bg2};

      transition: all 1s;

      .current-selected-container {
        display: flex;
        align-items: center;
      }
      .arrow-container {
        margin-left: auto;
        display: flex;
        align-items: center;

        > * {
          fill: currentColor;
        }
      }
      .page-quantity-select-dropdown {
        padding-bottom: 0.5rem;

        .dropdown-item {
          cursor: pointer;
          &.current {
            color: ${(props) => props.theme.text3};
          }
          &:hover:not(.current) {
            color: ${(props) => props.theme.accent1};
          }
        }
      }
    }
  }
`;
