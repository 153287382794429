import { ICharge } from 'types';
import { StyledChargeListElement } from './styles';
import { formateDate, formateTime } from 'utils';
interface IChargeListElementProps extends ICharge {
  children?: React.ReactNode;
}
export const ChargeListElement = ({
  date,
  hashRate,
  chargesPerUnit,
  generalAccural,
}: IChargeListElementProps) => {
  const elementDate = new Date(date);
  const formatedDate = formateDate(elementDate);
  const formatedTime = formateTime(elementDate);
  return (
    <StyledChargeListElement>
      <div className="charges-list-element-col date-time">
        {formatedDate}
        {<span className="time">{`-${formatedTime}`}</span>}
      </div>
      <div className="charges-list-element-col hashrate">{hashRate}</div>
      <div className="charges-list-element-col general-accruals">{generalAccural}</div>
      <div className="charges-list-element-col ">{chargesPerUnit}</div>
    </StyledChargeListElement>
  );
};
