import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledNavigation = styled.nav`
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const StyledNavigationList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3rem 2.5rem;

  list-style: none;

  @media screen and (min-width: 1024px) {
    gap: 4rem;
  }
`;

export const StyledNavigationListElement = styled.li`
  text-transform: capitalize;

  transition: all 0.2s;

  &.dark {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.button3 : props.theme.text2)};

    &:hover {
      color: inherit;
    }
  }

  &.buttoned {
    padding: 0.8rem 1.6rem;

    border-radius: 200px;

    color: ${darkTheme.text1};

    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button2 : props.theme.button3};

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.text2)};

      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.accent1 : props.theme.button1};
    }
  }
`;
