import styled from 'styled-components';

export const StyledCheckMark = styled.div`
  position: relative;
  display: block;
  width: 15px;
  height: 15px;
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent4};
  border-radius: 2px;
  transition: all 0.2s;

  &::after {
    position: absolute;
    top: -4px;
    left: -2px;
    content: '';
    display: block;
    visibility: hidden;
    width: 20px;
    height: 20px;
    background-image: url('/images/check-mark.svg');
    background-size: cover;
    transition: all 0.2s;
  }
`;
