import styled from 'styled-components';

export const StyledMenuContainer = styled.div`
  visibility: hidden;
  position: absolute;
  bottom: 0rem;
  right: 0;
  transform: translateY(100%);
  width: 15.5rem;
  opacity: 0;
  transition: all 0.2s;

  .divider {
    height: 1px;
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent4};

    width: 100%;
    margin: 0.5rem 0;
  }
`;
