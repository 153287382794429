import { useTranslation } from 'react-i18next';
import {
  StyledLangListContainer,
  StyledContainer,
  StyledToggler,
  StyledLangButton,
} from './styles';

export const LocaleToggler = ({ top }: { top?: boolean }) => {
  const { i18n } = useTranslation();

  const changeLangHandler = (lang: string) => () => {
    i18n.changeLanguage(lang);
  };

  const activeLang = i18n.language;
  const restLanguages = i18n.languages.filter((lg) => lg !== activeLang);

  return (
    <StyledToggler>
      <StyledContainer>
        <StyledLangListContainer className={`${top ? 'top' : ''}`}>
          {restLanguages.map((lg) => (
            <StyledLangButton key={lg} onClick={changeLangHandler(lg)} tabIndex={0}>
              {lg}
            </StyledLangButton>
          ))}
        </StyledLangListContainer>
        <StyledLangButton onClick={changeLangHandler(i18n.language)}>
          {i18n.language}
        </StyledLangButton>
      </StyledContainer>
    </StyledToggler>
  );
};
