import { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-full.svg';
import { StyledDisplayItemsPerPageSelect } from './styles';
interface IDisplayUnitPerPageSelectProps {
  children?: React.ReactNode;
  onChange: (itemsQuantity: number) => void;
  currentItemsPerPageQuantity?: number;
  itemsPerPageRates?: number[];
}
export const DisplayItemsPerPageSelect = ({
  onChange,
  currentItemsPerPageQuantity,
  itemsPerPageRates = [10, 20, 40],
}: IDisplayUnitPerPageSelectProps) => {
  const { t } = useTranslation();
  const [currentPerPageQuanity, setCurrentPerPageQuanity] = useState(
    currentItemsPerPageQuantity || itemsPerPageRates[0],
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownToggler = () => setShowDropdown((prev) => !prev);
  const closeDropdownHandler = () => setShowDropdown(false);
  const setCurrentPerPageQuantityHandler = (quantity: number) => () => {
    onChange(quantity);
    setCurrentPerPageQuanity(quantity);
  };
  return (
    <StyledDisplayItemsPerPageSelect>
      <label className="page-quantity-select-lable">{t('pagination.displayByPerPage')}</label>
      <div className="page-quantity-select-container" onMouseLeave={closeDropdownHandler}>
        <div className="page-quantity-select">
          <div className="current-selected-container" onClick={dropdownToggler}>
            <div className="page-quantity-current-selected">{currentPerPageQuanity}</div>
            <div className="arrow-container">
              <ArrowDown />
            </div>
          </div>
          {showDropdown && (
            <div className="page-quantity-select-dropdown">
              {itemsPerPageRates.map((rate) => (
                <div
                  key={rate}
                  className={cn('dropdown-item', currentPerPageQuanity === rate && 'current')}
                  onClick={setCurrentPerPageQuantityHandler(rate)}
                >
                  {rate}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </StyledDisplayItemsPerPageSelect>
  );
};
