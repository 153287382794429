import { useState } from 'react';
import {
  StyledHashrateInput,
  StyledHashrateInputContainer,
  StyledHashrateInputGroup,
  StyledHashrateInputLabel,
  StyledHashrateUnits,
} from './styles';

interface IHashrateInputProps {
  labelText?: string;
  units?: string;
  onChange: (amount: number) => void;
}
export const HashrateInput = (props: IHashrateInputProps) => {
  const { labelText, units } = props;
  const [inputValue, setInputValue] = useState('1');

  const changeInputHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
    props.onChange(Number(event.target.value));
  };
  return (
    <StyledHashrateInputGroup>
      <StyledHashrateInputLabel>{labelText}</StyledHashrateInputLabel>
      <StyledHashrateInputContainer>
        <StyledHashrateInput onChange={changeInputHandler} value={inputValue} />
        <StyledHashrateUnits>{units}</StyledHashrateUnits>
      </StyledHashrateInputContainer>
    </StyledHashrateInputGroup>
  );
};
