import { useState } from 'react';
import { StyledHashrateInputGroup } from './styles';

interface IHashrateInputProps {
  labelText?: string;
  units?: string;
  onChange: (value: number) => void;
}
export const HashrateInput = ({ labelText, units, onChange }: IHashrateInputProps) => {
  const [inputValue, setInputValue] = useState('1');
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setInputValue(event.target.value);
    onChange(Number(value));
  };
  return (
    <StyledHashrateInputGroup>
      <label className="label">{labelText}</label>
      <div className="input_container">
        <input className="input" value={inputValue} onChange={changeHandler} placeholder="0" />
        <div className="units">{units}</div>
      </div>
    </StyledHashrateInputGroup>
  );
};
