import styled from 'styled-components';

export const StyledUserAccount = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1.4rem;

  font-size: 1.8rem;
  font-weight: 600;
  line-height: 150%;

  cursor: pointer;

  @media screen and (min-width: 768px) {
    padding-right: 1.8rem;
  }
`;
