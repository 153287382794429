import styled from 'styled-components';
export const StyledMobileMenu = styled.div`
  position: absolute;

  background-color: ${(props) => props.theme.bg1};
  width: 100%;
  height: 100%;

  padding: 5.2rem 1rem 3.8rem;

  transition: all 0.5s;
  &.open {
    top: 0;
    opacity: 1;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 3rem;
`;

export const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  height: 5.2rem;
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 0.4rem;
  width: 4.2rem;
  height: 4.2rem;
  padding: 0.6rem;

  > * {
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  }
`;

export const StyledControls = styled.div`
  height: 4.3rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;

  @media screen and (min-width: 768px) {
    > *:not(:last-child) {
      display: none;
    }
  }
`;

export const StyledMenu = styled.div`
  flex-grow: 1;

  .temp-list {
    //TODO: fix color
    color: var(--dark-mode-font-5, #b1adff);
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 3.3rem */
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const StyledNavContainer = styled.div`
  @media screen and (min-width: 768px) {
    margin: auto;
    width: 10rem;
  }
  &.vertical {
    margin: auto;
    width: 10rem;
  }
`;
