import styled from 'styled-components';
export const StyledDataExportButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  border-radius: 9px;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2)};
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2)};
  gap: 0.6rem;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
  }

  @media screen and (min-width: 768px) {
    width: auto;
    padding: 0.8rem 1.6rem;
    padding-left: 1.2rem;
  }

  @media screen and (min-width: 1440px) {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: normal;
  }

  .title {
    display: none;
    @media screen and (min-width: 768px) {
      display: inline-block;
    }
  }

  .button-icon {
    @media screen and (min-width: 768px) {
      width: 2.4rem;
      height: 2.4rem;
    }
    *[fill] {
      fill: currentColor;
    }
  }
`;
