import { useTranslation } from 'react-i18next';
import {
  StyledPeriodSelect,
  StyledPeriodSelector,
  StyledRadioContainer,
  StyledSelectContainer,
} from './styles';
import { useState } from 'react';
import { ECalculationPeriods } from 'types';

interface ICurrenctSelecProps {
  labelText?: string;
  onChange: (period: ECalculationPeriods) => void;
}

export const PeriodSelector = (props: ICurrenctSelecProps) => {
  const { t } = useTranslation();
  const { labelText } = props;

  const [selected, setSelected] = useState<ECalculationPeriods>(ECalculationPeriods.Week);

  const periods: ECalculationPeriods[] = Object.values(ECalculationPeriods);
  const selectHandler = (period: ECalculationPeriods) => () => {
    setSelected(period);
    props.onChange(period);
  };

  return (
    <StyledPeriodSelector>
      <label className="period-select_label">{labelText}</label>
      <StyledSelectContainer>
        <StyledPeriodSelect>
          {periods.map((period) => (
            <StyledRadioContainer
              key={period}
              className={`${period === selected && 'selected'}`}
              onClick={selectHandler(period)}
            >
              {t(`calculator.${period}`)}
            </StyledRadioContainer>
          ))}
        </StyledPeriodSelect>
      </StyledSelectContainer>
    </StyledPeriodSelector>
  );
};
