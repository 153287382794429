import { apiRoutes } from 'lib';
import { apiClient } from 'lib/ApiClient';
import { EApiResponseStatus } from 'types/api';
import {
  IChangePAsswordResponse,
  IChangePasswordData,
  IFetchUserResponse,
  IRecoverPasswordData,
  IRecoverPasswordResponse,
  ISignInResponse,
  ISignInUserData,
  ISignUpUserData,
} from 'types/users';

export const AccountRepository = {
  signIn: async (signInUserData: ISignInUserData) => {
    const url = apiRoutes.apiSignInPath();
    const response = (await apiClient.post(url, signInUserData, {})) as ISignInResponse;
    return response;
  },
  signUp: (signUpUserData: ISignUpUserData) => {
    const url = apiRoutes.apiSignUpPath();
    return apiClient.post(url, signUpUserData, {});
  },
  fetchUser: async (token: string): Promise<IFetchUserResponse> => {
    const url = apiRoutes.apiFetchUserPath();
    const response = (await apiClient.get(url, {
      Authorization: `Bearer ${token}`,
    })) as IFetchUserResponse;
    return response;
  },
  changePassword: async (
    changePasswordData: IChangePasswordData,
  ): Promise<IChangePAsswordResponse> => {
    console.log(changePasswordData);
    return { status: EApiResponseStatus.FAILURE };
  },
  recoverPassword: async (
    recoverPasswordData: IRecoverPasswordData,
  ): Promise<IRecoverPasswordResponse> => {
    console.log(recoverPasswordData);
    return { status: EApiResponseStatus.SUCCESS };
  },
};
