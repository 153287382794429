import styled from 'styled-components';

export const StyledBurgerDropDown = styled.div`
  position: absolute;
  min-height: calc(100dvh);
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
`;

export const StyledBurgerOverlay = styled.div`
  position: absolute;
  opacity: 0;
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.accent2 : props.theme.text4};
  width: 100%;
  min-height: calc(100dvh);
  transition: opacity 0.5s;

  &.show {
    opacity: ${(props) => (props.theme.id === 'dark' ? '0.39' : '0.75')};
  }
`;

export const StyledBurgerDropdownContent = styled.div`
  position: absolute;

  width: 100%;
  min-height: calc(100dvh);
  background-color: ${(props) => props.theme.bg1};
  top: -100%;
  transition: all 0.8s;
  &.show {
    top: 0;
  }
`;
