import styled from 'styled-components';

export const StyledBurgerButton = styled.button`
  display: flex;
  align-items: center;
  svg {
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
    stroke: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  }
`;

export const StyledCloseIconContainer = styled.div`
  right: 1rem;
  top: 0.4rem;
  width: 4.2rem;
  height: 4.2rem;
  padding: 0.6rem;

  > * {
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  }

  @media screen and (min-widht: 768px) {
    position: relative;
  }
`;
