import styled from 'styled-components';
export const StyledMainSettingsComponent = styled.div`
  height: 100%;
  .h2 {
    font-size: 1.8rem;
    line-height: 150%;
    font-weight: 500;
    margin-bottom: 1.6rem;
    text-align: center;

    @media screen and (min-width: 768px) {
      text-align: start;
    }
  }
`;
