import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledFAQContent = styled.div`
  transition: all 0.5s;
  height: 0;
  overflow: hidden;

  padding: 0 1rem;

  border-radius: 10px;

  @media screen and (min-width: 768px) {
    padding: 0 2rem;
  }
`;

export const StyledDetailsContainer = styled.div`
  max-width: 30rem;

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.button3)};
  border-radius: 10px;

  color: ${darkTheme.text1};

  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.bg2 : props.theme.button3};

  transition: all 0.5s;

  &:hover,
  &:has(details[open]) {
    border: 1px solid ${darkTheme.accent1};
  }

  &:hover summary {
    color: ${darkTheme.accent1};
  }

  @media screen and (min-width: 768px) {
    max-width: 58rem;
  }

  .content,
  > * .hidden {
    font-size: 1.2rem;
    line-height: 150%;
    font-weight: 500;

    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
    }
  }
`;

export const StyledFAQElement = styled.details<{ $test: string }>`
  position: relative;

  padding: 0.6rem;

  border-radius: 10px;

  transition: all 1s;

  &[open] > summary::before {
    transform: rotate(180deg);
  }
  &[open] ~ .content {
    height: ${(props) => `${props.$test}`};
  }

  & > .hidden {
    position: absolute;

    visibility: hidden;

    height: max-content;

    padding: 0.5rem;
    padding-bottom: 1rem;

    @media screen and (min-width: 768px) {
      padding: 1rem;
      padding-bottom: 2rem;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0.6rem 2rem;
  }
`;

export const StyledFAQElementHeading = styled.summary`
  position: relative;

  display: flex;
  align-items: center;
  padding: 0.6rem;

  max-width: 28rem;

  margin: auto;

  border-radius: 10px;

  list-style: none;

  font-size: 1.4rem;
  font-weight: 500;
  line-height: 150%;

  transition: all 1s;

  @media screen and (min-width: 768px) {
    max-width: 66rem;

    padding: 0.6rem 2rem;

    font-size: 1.8rem;
  }

  @media screen and (min-width: 1024px) {
    max-width: 71rem;
  }

  @media screen and (min-width: 1440px) {
    max-width: 84rem;
  }

  &::-webkit-details-marker {
    display: none;
  }

  &::after {
    content: '';

    position: absolute;
    right: 2rem;

    display: block;

    width: 2rem;
    height: 2rem;

    border-radius: 100%;

    background-image: url('/images/detail-arrow-icon.svg');
    background-size: cover;
    background-position: center;

    transition: all 0.5s;
  }
`;
