import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Logo } from 'components/shared';
import { StyledConfirmation, StyledContainer, StyledMessage } from './styles';
import { DEFAULT_TIMEOUT_MS } from 'constants/appConstants';

interface IConfirmationProps {
  type?: 'registration' | 'passwordChange' | 'passwordRecovery';
}
export const Confirmation = ({ type = 'registration' }: IConfirmationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const messageStrings = t('modals.confirmation.message', { returnObjects: true }) as Array<string>;
  const passwordChangeMessageStrings = t('modals.confirmation.passwordChange', {
    returnObjects: true,
  }) as Array<string>;
  const passwordRecoveryMessageStrings = t('modals.confirmation.passwordRecovery', {
    returnObjects: true,
  }) as Array<string>;

  const [timer, setTimer] = useState(DEFAULT_TIMEOUT_MS / 1000);

  const deadlineTimeStamp = Date.now() + DEFAULT_TIMEOUT_MS;

  const startTimer = (deadLineTimeStamp: number) => {
    const remainMilleSeconds = deadlineTimeStamp - Date.now();
    if (remainMilleSeconds > 0) {
      setTimer(Math.floor(remainMilleSeconds / 1000));
      setTimeout(() => startTimer(deadLineTimeStamp), 500);
    } else navigate('/');
  };

  const toMainPageButtonHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate('/');
  };
  useEffect(() => {
    startTimer(deadlineTimeStamp);
  }, []);

  return (
    <StyledConfirmation>
      {type === 'registration' && (
        <>
          <StyledContainer>
            <Logo />
          </StyledContainer>
          <h3>{t('modals.confirmation.header')}</h3>
          <StyledMessage className="p2">
            <span>{messageStrings[0]}</span> <span>{timer}</span> <span>{messageStrings[1]}</span>
          </StyledMessage>
        </>
      )}
      {type === 'passwordChange' && (
        <>
          <StyledContainer>
            <Logo />
          </StyledContainer>
          <StyledMessage className="p2">
            <span>{passwordChangeMessageStrings[0]}</span> <span>{timer}</span>{' '}
            <span>{passwordChangeMessageStrings[1]}</span>
          </StyledMessage>
          <div className="to-main-button">
            <Button
              onClick={toMainPageButtonHandler}
              title={t('modals.confirmation.toMainPageButton')}
            />
          </div>
        </>
      )}
      {type === 'passwordRecovery' && (
        <>
          <StyledContainer>
            <Logo />
          </StyledContainer>
          <StyledMessage className="p2">
            <span>{passwordRecoveryMessageStrings[0]}</span> <span>{timer}</span>{' '}
            <span>{passwordRecoveryMessageStrings[1]}</span>
          </StyledMessage>
          <div className="to-main-button">
            <Button
              onClick={toMainPageButtonHandler}
              title={t('modals.confirmation.toMainPageButton')}
            />
          </div>
        </>
      )}
    </StyledConfirmation>
  );
};
