import { BorderedWrapper, StatisticsDataComponent } from 'components';
import {
  StyledAccouningFooterDataElement,
  StyledAccountInformation,
  StyledAccountingContentData,
} from './styles';
import { useTranslation } from 'react-i18next';
import { Routes } from 'lib';
import { useAppSelector } from 'store/store';
import { selectAccountInformation } from 'store/slices/userStatisticsSlice';
import { ESliceDataFetchingStatus } from 'types/common';
import { memo } from 'react';
export const AccountInformationCompnent = () => {
  const { t } = useTranslation();
  const { status, last24HoursProfit, totalIncome, balance } =
    useAppSelector(selectAccountInformation);

  const averageDailyProfit = '0.00000999 BTC/T (PPS 98.6%)';
  const currency = 'BTC';

  return (
    <StyledAccountInformation>
      <BorderedWrapper>
        {status === ESliceDataFetchingStatus.initial ? (
          'loading...'
        ) : (
          <StatisticsDataComponent
            link={{
              title: t('statisticsPage.accountInformation.payoutLink'),
              to: Routes.payoutPath(),
            }}
            heading={t('statisticsPage.accountInformation.heading')}
            footer={
              <>
                <StyledAccouningFooterDataElement>
                  <div className="data">
                    {/* <span>{totalIncome}</span> <span className="">{currency}</span> */}
                    {totalIncome !== null ? (
                      <>
                        <span>{totalIncome}</span> <span className="">{currency}</span>
                      </>
                    ) : (
                      'n/a'
                    )}
                  </div>

                  <div className="reference">
                    {t('statisticsPage.accountInformation.totalIncome')}
                  </div>
                </StyledAccouningFooterDataElement>
                <StyledAccouningFooterDataElement>
                  <div className="data">
                    {balance !== null ? (
                      <>
                        <span>{balance}</span> <span className="">{currency}</span>
                      </>
                    ) : (
                      'n/a'
                    )}
                  </div>
                  <div className="reference">{t('statisticsPage.accountInformation.balance')}</div>
                </StyledAccouningFooterDataElement>
              </>
            }
            data={
              <StyledAccountingContentData>
                <div className="profit24">
                  <h4 className="profit24_header">
                    {t('statisticsPage.accountInformation.profit24H')}
                  </h4>
                  <p className="profit24_data">
                    {last24HoursProfit !== null ? last24HoursProfit : 'n/a'}
                  </p>
                </div>
                <div className="average-profit">
                  {t('statisticsPage.accountInformation.averageDailyProfit')} {averageDailyProfit}
                </div>
              </StyledAccountingContentData>
            }
          />
        )}
      </BorderedWrapper>
    </StyledAccountInformation>
  );
};

export const AccountInformation = memo(AccountInformationCompnent);
