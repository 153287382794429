import { AddWorkerDialog, Button, Modal } from 'components';
import { ReactComponent as RoundCrossIcon } from 'assets/icons/round-cross.svg';
import { StyledAddWorker } from './styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export const AddWorker = () => {
  const { t } = useTranslation();
  const [isMountModal, setIsMountModal] = useState(false);
  const [showAddWorkerDialog, setShowAddWorkerDialog] = useState(false);
  const addWorkerHandler = () => {
    setShowAddWorkerDialog(true);
  };
  const cloaseModalHandler = () => {
    setShowAddWorkerDialog(false);
  };

  useEffect(() => {
    if (showAddWorkerDialog) {
      setIsMountModal(true);
    }

    if (!showAddWorkerDialog) {
      setTimeout(() => setIsMountModal(false), 500);
    }
  }, [showAddWorkerDialog]);
  return (
    <StyledAddWorker>
      <Modal show={isMountModal}>
        <AddWorkerDialog onClose={cloaseModalHandler} show={isMountModal} />
      </Modal>
      <Button
        onClick={addWorkerHandler}
        icon={<RoundCrossIcon />}
        title={t('serviceHero.addWorkerButton')}
      ></Button>
    </StyledAddWorker>
  );
};
