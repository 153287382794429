import styled from 'styled-components';
export const StyledPayoutListControls = styled.div`
  display: flex;
  gap: 0.5rem;

  margin-bottom: 0.6rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 1.9rem;
  }
  @media screen and (min-width: 1440px) {
    gap: 0.8rem;
  }
`;
