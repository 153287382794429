import styled from 'styled-components';
import { StyledAdminListHeaders } from '../../AdminListHeaderCell/styles/StyledAdminListHeaders';

export const StyledAdminEditPaymentsHeader = styled.div`
  ${StyledAdminListHeaders}

  .admin-list-header-cell-container {
    &.adjust {
      width: 19rem;
      flex-grow: initial;
    }
  }
`;
