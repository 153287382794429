import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectUser } from 'store/slices/authSlice';
import { useAppSelector } from 'store/store';

export const PrivateRoute = ({ element }: { element: React.ReactElement }) => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (!user) {
      timeout = setTimeout(() => navigate('/login'), 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [user]);

  if (!user) return <div>Loading...</div>;

  return element;
};
