import { BorderedWrapper, StatisticsDataComponent } from 'components';
import {
  StyledHashRateFooterDataElement,
  StyledHashrateData,
  StyledHashrateInformation,
} from './styles';
import { useTranslation } from 'react-i18next';
import { Routes } from 'lib';
import { useSelector } from 'react-redux';
import { selectAverageHashrate } from 'store/slices/userStatisticsSlice';
import { ESliceDataFetchingStatus } from 'types/common';
import { formateHashUnits } from 'utils';

interface IHashrateFooterData {
  value: string;
  currency: string;
  reference: string;
}

const normalisedAverageValue = (value: string | number | null) => {
  if (value === null) return 'n/a';
  return formateHashUnits(value);
};

const renderData = (averageValue: string | number | null, heading: string) => {
  return (
    <StyledHashrateData>
      <div className="average-rate">
        <h4 className="average-rate_header">{heading}</h4>
        <p className="average-rate_data">{normalisedAverageValue(averageValue)}</p>
      </div>
    </StyledHashrateData>
  );
};

const renderFooter = (data: IHashrateFooterData[]) => {
  return (
    <>
      {data.map(({ value, currency, reference }) => (
        <StyledHashRateFooterDataElement key={value + reference}>
          <div className="data">
            <span>{value}</span> <span className="">{currency}</span>
          </div>
          <div className="reference">{reference}</div>
        </StyledHashRateFooterDataElement>
      ))}
    </>
  );
};
export const HashrateInformation = () => {
  const { t } = useTranslation();
  const averageHashrate = useSelector(selectAverageHashrate);
  const { status, average10MinutesHashrate, averageDailyHashrate, averageHourlyHashrate } =
    averageHashrate;

  const hashrateFooterData: IHashrateFooterData[] = [
    {
      value: normalisedAverageValue(averageHourlyHashrate),
      currency: '',
      reference: t('statisticsPage.hashrate.hourlyHashrate'),
    },
    {
      value: normalisedAverageValue(averageDailyHashrate),
      currency: '',
      reference: t('statisticsPage.hashrate.dailyHashrate'),
    },
  ];
  return (
    <StyledHashrateInformation>
      <BorderedWrapper>
        {(status === ESliceDataFetchingStatus.initial ||
          status === ESliceDataFetchingStatus.loading) &&
          'Loading...'}
        {(status === ESliceDataFetchingStatus.success ||
          status === ESliceDataFetchingStatus.error) && (
          <StatisticsDataComponent
            link={{ to: Routes.settingsPath(), title: t('statisticsPage.hashrate.settingsLink') }}
            heading={t('statisticsPage.hashrate.heading')}
            data={renderData(average10MinutesHashrate, t('statisticsPage.hashrate.average'))}
            footer={renderFooter(hashrateFooterData)}
          />
        )}
      </BorderedWrapper>
    </StyledHashrateInformation>
  );
};
