import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CustomSearch } from 'components';
import { AdminList } from '../AdminList';
import { StyledAdminEditPaymentHistory } from './styles';
import { DataExportButton } from '../DataExportButton';
import { StyledAdminEditPaymentHistoryHeaders } from './styles/StyledAdminEditPaymentHistoryHeaders';
import { AdminListHeaderCell } from '../AdminListHeaderCell';
import { StyledAdminEditPaymentHistoryRow } from './styles/StyledAdminEditPaymentHistoryRow';
import { AdminListRowCell } from '../AdminListRowCell';
interface IAdminEditPaymentHistoryProps {
  children?: React.ReactNode;
}
export const AdminEditPaymentHistory = ({ children }: IAdminEditPaymentHistoryProps) => {
  return (
    <StyledAdminEditPaymentHistory>
      <AdminList
        renderControls={() => (
          <div className="admin-edit-history-controls">
            <CustomSearch />
            <DataExportButton />
          </div>
        )}
      >
        <AdminEditPaymentHistoryHeaders />
        <AdminEditPaymentHistoryRow />
        <AdminEditPaymentHistoryRow />
      </AdminList>
    </StyledAdminEditPaymentHistory>
  );
};

const adminEditPaymentHistoryHeadersTitles = ['id', 'hash', 'confirmation', 'created', 'updated'];
const AdminEditPaymentHistoryHeaders = () => {
  const [selected, setSelected] = useState<boolean>(false);

  const { t } = useTranslation();

  const selectRowHandler = () => {
    setSelected((prev) => !prev);
  };
  return (
    <StyledAdminEditPaymentHistoryHeaders>
      {adminEditPaymentHistoryHeadersTitles.map((title) => (
        <div key={title} className={cn('admin-list-header-cell-container', title.toLowerCase())}>
          <AdminListHeaderCell filter={!['id', 'hash'].includes(title)}>
            {t(`adminPage.editPaymentHistory.listHeaders.${title}`)}
          </AdminListHeaderCell>
        </div>
      ))}
    </StyledAdminEditPaymentHistoryHeaders>
  );
};

const AdminEditPaymentHistoryRow = () => {
  const data: { [id: string]: string } = {
    id: '221',
    hash: 'dad3a86d311b3066b9f0b937886f86efffd77144281dc4c899c49b4a4d6091a9',
    confirmation: 'Сonfirmed',
    created: '2023-12-22T05:27:28.7772',
    updated: '2023-12-22T05:27:28.7772',
  };
  return (
    <StyledAdminEditPaymentHistoryRow>
      {adminEditPaymentHistoryHeadersTitles.map((title) => (
        <div key={title} className={cn('admin-list-cell-container', title.toLowerCase())}>
          <AdminListRowCell>{data[title]}</AdminListRowCell>
        </div>
      ))}
    </StyledAdminEditPaymentHistoryRow>
  );
};
