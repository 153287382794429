import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledCalculatorArticle = styled.article`
  position: relative;
  overflow: hidden;

  width: 30rem;
  height: 32.7rem;

  margin: auto;
  margin-top: -1.7rem;

  padding: 2.5rem 1rem 0.8rem;

  color: ${darkTheme.text1};

  background-image: ${(props) => `url(/images/purple-folder-${props.theme.id}-smallest.svg)`};
  background-repeat: no-repeat;

  @media screen and (min-width: 768px) {
    width: 70rem;
    height: 47rem;

    margin-top: -2rem;

    padding: 5rem 3.5rem;

    background-image: ${(props) => `url(/images/purple-folder-${props.theme.id}-small.svg)`};
  }
  @media screen and (min-width: 1024px) {
    width: 95rem;
    height: 42rem;

    margin-top: -2.5rem;

    padding: 7rem 3.5rem;

    background-image: ${(props) => `url(/images/purple-folder-${props.theme.id}-mid.svg)`};
  }
  @media screen and (min-width: 1440px) {
    width: 61rem;
    height: 48rem;

    margin: 0;
    margin-top: 0;

    padding: 7rem 4.5rem;

    background-image: ${(props) => `url(/images/purple-folder-${props.theme.id}-lg.svg)`};
  }
`;

export const StyledCalculatorHeading = styled.header`
  margin-bottom: 1.2rem;

  font-size: 1.8rem;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.18px;

  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;

    font-size: 2.8rem;
    line-height: 140%;
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 2.5rem;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 2rem;
  }
`;

export const StyledInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  margin-bottom: 0.8rem;

  @media screen and (min-width: 768px) {
    max-width: 48rem;

    margin-bottom: 6.8rem;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1440px) {
    flex-direction: column;
  }
`;

export const StyledAmountBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  .input-container {
    flex-basis: 80%;

    @media screen and (min-width: 768px) {
      flex-basis: auto;
    }
  }

  @media screen and (min-width: 768px) {
    gap: 2rem;
  }
  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }
  @media screen and (min-width: 1440px) {
    flex-wrap: wrap;
  }
`;
