import { Layout, MainBenefitsSection } from 'components';
import { AboutHero, AboutPlans } from './components';
import styled from 'styled-components';

const StyledContent = styled.div`
  max-width: 32rem;

  padding-top: 2.6rem;
  padding-bottom: 4rem;

  @media screen and (min-width: 768px) {
    max-width: 76.8rem;
  }
  @media screen and (min-width: 1024px) {
    max-width: 102.4rem;
    padding-bottom: 12rem;
  }
  @media screen and (min-width: 1440px) {
    max-width: 132rem;
  }
  margin: auto;
`;

export const AboutPage = () => {
  return (
    <Layout home>
      <StyledContent>
        <AboutHero />
        <MainBenefitsSection />
        <AboutPlans />
      </StyledContent>
    </Layout>
  );
};
