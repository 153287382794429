import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledGroupEditor = styled.div`
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

  &.error {
    border-color: ${darkTheme.errorRed};
  }

  /* height: 4.9rem; */

  font-weight: 700;
  line-height: 150%; /* 27px */
  font-size: 1.4rem;
  @media screen and (min-width: 768px) {
    font-size: 1.8rem;
  }

  display: flex;

  padding: 1rem;
  gap: 1rem;

  .group-editor-group-name {
    max-width: 30rem;
    flex-grow: 1;

    .group-editor-group-name-input {
      background-color: inherit;
      border: none;
      color: inherit;
      font: inherit;
      outline: none;
    }
  }

  .group-editor-worker-quantity {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
    color: ${(props) => props.theme.text3};
    max-width: 17rem;
    flex-grow: 1;
  }

  .group-editor-edit-button {
    margin-left: auto;
  }

  .group-editor-edit-button,
  .group-editor-delete-button {
    width: 2.4rem;
    height: 2.4rem;
    color: ${(props) => props.theme.text3};

    > * {
      width: 100%;
      height: 100%;

      *[fill] {
        fill: currentColor;
      }
      *[stroke] {
        stroke: currentColor;
      }
    }

    &:hover,
    &.active {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent4)};
    }
  }
`;
