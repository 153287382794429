import { BorderedWrapper } from 'components/shared';
import { StyledAccuralSchedule } from './styles';
import { useTranslation } from 'react-i18next';
interface IAccuralScheduleProps {
  children?: React.ReactNode;
}
export const AccuralSchedule = ({ children }: IAccuralScheduleProps) => {
  const { t } = useTranslation();
  const totalAccuredValue = '1.12312312';
  const creditFixTime = '00:00 GMT-1';
  return (
    <StyledAccuralSchedule className="accural-schedule">
      <BorderedWrapper>
        <h4 className="accural-schedule-heading">{t('accuralSchedule.heading')}</h4>
        <p className="accural-schedule-credits-fixed">
          {t('accuralSchedule.createdFixed')} {creditFixTime}
        </p>
        <div className="accural-schedule-diagram"></div>
        <p className="accural-schedule-total-accured">
          <span className="accural-schedule-total-accured__title">
            {t('accuralSchedule.totalAccured')}
          </span>
          <span className="accural-schedule-total-accured__value">{totalAccuredValue}</span>
        </p>
      </BorderedWrapper>
      {children}
    </StyledAccuralSchedule>
  );
};
