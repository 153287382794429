import { useTranslation } from 'react-i18next';
import {
  StyledArrowIcon,
  StyledMenuContainer,
  StyledUserAccount,
  StyledUserActionButton,
  StyledUserName,
  StyledAccessLink,
} from './styles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { DropDown } from 'components';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/slices/authSlice';
import { useAppDispatch } from 'store/store';
import { signOut } from 'store/thunks/authThunk';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'lib';

export const UserAccess = ({ onAction }: { onAction?: () => void }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(signOut());
    if (onAction) onAction();
  };

  const toSettingsHandler = () => {
    navigate(Routes.settingsPath());
  };
  const toAdmin = () => {
    navigate(Routes.adminPath());
  };

  const forwardHandler = (menuItem: string) => () => {
    switch (menuItem) {
      case 'statistics':
        navigate(Routes.statisticsPath());
        break;
      case 'workers':
        navigate(Routes.workersPath());
        break;
      case 'payout':
        navigate(Routes.payoutPath());
        break;
      case 'profit':
        navigate(Routes.profitPath());
        break;
      case 'settings':
        navigate(Routes.settingsPath());
        break;
      case 'referal':
        navigate(Routes.referralsPath());
        break;
      default:
        navigate(Routes.rootPath());
    }
  };

  return (
    <StyledUserAccount>
      {!user && (
        <>
          <StyledAccessLink to="/registration" onClick={onAction}>
            <span>{t('header.signUp')}</span>
          </StyledAccessLink>
          <span>|</span>
          <StyledAccessLink to="/login" onClick={onAction}>
            {t('header.logIn')}
          </StyledAccessLink>
        </>
      )}

      {user && (
        <StyledUserName>
          <p>{user.name}</p>
          <StyledArrowIcon>
            <ArrowDownIcon />
          </StyledArrowIcon>
          <StyledMenuContainer>
            <DropDown>
              <StyledUserActionButton onClick={forwardHandler('statistics')}>
                <p>{t('navigation.statistics')}</p>
              </StyledUserActionButton>
              <StyledUserActionButton onClick={forwardHandler('workers')}>
                <p>{t('navigation.workers')}</p>
              </StyledUserActionButton>
              <StyledUserActionButton onClick={forwardHandler('payout')}>
                <p>{t('navigation.payout')}</p>
              </StyledUserActionButton>
              <StyledUserActionButton onClick={forwardHandler('profit')}>
                <p>{t('navigation.profit')}</p>
              </StyledUserActionButton>
              <StyledUserActionButton onClick={forwardHandler('settings')}>
                <p>{t('navigation.settings')}</p>
              </StyledUserActionButton>
              <StyledUserActionButton onClick={forwardHandler('referal')}>
                <p>{t('navigation.referral')}</p>
              </StyledUserActionButton>
              <div className="divider"></div>
              <StyledUserActionButton onClick={toAdmin}>
                <p>{t('header.admin')}</p>
              </StyledUserActionButton>
              <StyledUserActionButton onClick={logoutHandler}>
                <p>{t('header.logout')}</p>
              </StyledUserActionButton>
            </DropDown>
          </StyledMenuContainer>
        </StyledUserName>
      )}
    </StyledUserAccount>
  );
};
