import { useId, useState, forwardRef, useEffect, useRef } from 'react';
import { StyledInput, StyledInputGroup } from './styles';
import { useTranslation } from 'react-i18next';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inValid?: boolean;
  error?: string;
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(function MyInput(props, ref) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [valid] = useState(false);
  const inputId = useId();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <StyledInputGroup>
      <label htmlFor={inputId} hidden>
        {props.placeholder}
      </label>
      <StyledInput
        onBlur={props.onBlur}
        ref={ref}
        id={inputId}
        name={props.name}
        onChange={props.onChange}
        className={`${valid && 'valid'} ${props.inValid && 'invalid'}`}
        disabled={props.disabled}
        value={props.value}
        placeholder={props.placeholder}
      />
      {props.error && props.inValid && (
        <div className="input-error-message">{t(`forms.errors.${props.error}`)}</div>
      )}
    </StyledInputGroup>
  );
});
