import styled from 'styled-components';
import { StyledInput } from './StyledInput';

export const StyledEmailInput = styled.div`
  .email-input-label {
    display: block;

    padding: 0 1rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      margin-bottom: 0.2rem;
    }
  }

  ${StyledInput} {
    &::placeholder {
      color: ${({ theme }) => (theme.id === 'dark' ? theme.bg3 : theme.text4)};
    }
  }
`;
