import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from 'assets/icons/cross-icon.svg';
import { StyledCreateGroupButton } from './styles';
interface ICreateGroupButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}
export const CreateGroupButton = (props: ICreateGroupButtonProps) => {
  const { t } = useTranslation();
  return (
    <StyledCreateGroupButton {...props}>
      <div className="icon">
        <CrossIcon />
      </div>
      <span className="title">{t('workersPage.groups.createGroupButton')}</span>
    </StyledCreateGroupButton>
  );
};
