import { AUTH_TOKEN_STORAGE_NAME } from 'constants/authConstants';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
export const getToken = (): string | null => {
  const token = window.localStorage.getItem(AUTH_TOKEN_STORAGE_NAME);
  return token;
};
export const setToken = (token: string): void => {
  window.localStorage.setItem(AUTH_TOKEN_STORAGE_NAME, token);
  if (token === 'test-token') {
    const cookie = new Cookies();
    cookie.set('jwt', token);
    return;
  }
  const decoded = jwtDecode(token);
  const expires = decoded.exp ? new Date(decoded.exp * 1000) : undefined;
  const cookie = new Cookies();
  cookie.set('jwt', token, { expires });
};

export const removeToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN_STORAGE_NAME);
  const cookie = new Cookies();
  cookie.remove('jwt');
};
