import { BorderedWrapper, OutlinedButton } from 'components';
import { StyledReferralHero } from './styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'lib';
interface IReferralHeroProps {
  children?: React.ReactNode;
}
export const ReferralHero = ({ children }: IReferralHeroProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const findmoreButtonHandler = () => {
    navigate(Routes.faqPath());
  };
  return (
    <StyledReferralHero>
      <BorderedWrapper>
        <div className="content">
          <h1 className="referral-hero-heading">{t('referralsPage.hero.heading')}</h1>
          <p className="referral-hero-subheading">{t('referralsPage.hero.subheading')}</p>
          <OutlinedButton onClick={findmoreButtonHandler} square>
            {t('referralsPage.hero.findMoreButton')}
          </OutlinedButton>

          <div className="ice-cube"></div>
        </div>
      </BorderedWrapper>
    </StyledReferralHero>
  );
};
