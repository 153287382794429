import styled from 'styled-components';
export const StyledCurrencySelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  width: 4.1rem;

  @media screen and (min-width: 768px) {
    width: 8rem;
  }

  @media screen and (min-width: 1024px) {
    align-items: flex-start;

    width: 11.4rem;
  }

  .label {
    padding: 0 1rem;

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: ${(props) => props.theme.text4};

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
  }

  .select {
    width: 3.2rem;
    height: 3.2rem;

    padding: 0.3rem;

    border-radius: 80px;
    border: 1px solid ${(props) => (props.theme.id === 'dark' ? props.theme.bg6 : props.theme.bg5)};

    background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg6 : props.theme.accent3)};

    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;

      width: 3.9rem;
      height: 3.9rem;

      padding: 0.2rem;
    }

    @media screen and (min-width: 1024px) {
      width: 9.3rem;
    }
  }

  .option {
    width: 2.6rem;
    height: 2.6rem;

    @media screen and (min-width: 768px) {
      display: flex;
      gap: 1rem;

      width: 3.5rem;
      height: 3.5rem;
    }

    .icon {
      width: 100%;
      height: 100%;
      flex-shrink: 0;

      > * {
        width: 100%;
        height: 100%;

        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : 'currentColor')};
      }
    }

    .value {
      display: none;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : 'currentColor')};

      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 600;

      @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
      }
    }
  }
`;
