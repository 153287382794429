import { StyledDisplayItemsPerPageSelect } from 'components/DisplayItemsPerPageSelect/styles';
import { StyledBorderedWrapper } from 'components/shared/BorderedWrapper/styles';
import styled from 'styled-components';
export const StyledWorkersListContainer = styled.section`
  ${StyledBorderedWrapper} {
    padding: 1.2rem;
  }

  position: relative;

  margin-bottom: 6rem;
  padding-top: 0.8rem;

  .loader {
    position: absolute;
    translate: 0 -100%;
  }

  .workers-error {
    padding: 1rem 0;

    font-size: 1.6rem;
    color: red;
  }

  .pagination-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
      justify-content: space-between;
    }

    ${StyledDisplayItemsPerPageSelect} {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
      }
    }
  }
`;
