import { useId } from 'react';
import { ReactComponent as BitCoinIcon } from 'assets/icons/bitcoin-icon.svg';
import { StyledCurrencySelect, StyledIcon, StyledSelect, StyledSelectOption } from './styles';

interface ICurrenctSelecProps {
  labelText?: string;
}
export const CurrencySelect = (props: ICurrenctSelecProps) => {
  const { labelText } = props;
  const selectId = useId();
  return (
    <StyledCurrencySelect>
      <label className="currency-select_label" htmlFor={selectId}>
        {labelText}
      </label>
      <StyledSelect id={selectId}>
        <StyledSelectOption>
          <StyledIcon>
            <BitCoinIcon />
          </StyledIcon>
          <span className="value">BTC</span>
        </StyledSelectOption>
      </StyledSelect>
    </StyledCurrencySelect>
  );
};
