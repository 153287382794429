import styled from 'styled-components';
export const StyledFAQContainer = styled.div`
  .faqs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;

    padding-bottom: 1.2rem;

    @media screen and (min-width: 768px) {
      padding-bottom: 4rem;
    }
  }
  .faq-paginator {
    display: flex;
    justify-content: center;
  }
`;
