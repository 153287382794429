import styled from 'styled-components';
export const StyledWell = styled.section`
  margin-bottom: 4rem;

  padding: 1.2rem;

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.bg5 : props.theme.accent3)};
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2)};
  border-radius: 15px;

  background-color: ${(props) => (props.theme.id === 'dark' ? 'inherit' : props.theme.bg2)};

  @media screen and (min-width: 768px) {
    margin-bottom: 3.4rem;
  }

  .content {
    padding: 1.2rem;

    background-color: inherit;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0 3rem;
    }

    @media screen and (min-width: 1024px) {
      padding: 0 7rem;
    }
    @media screen and (min-width: 1440px) {
      padding: 0 13rem;
    }
    @media screen and (min-width: 1920px) {
      padding: 0 25rem;
    }
  }
  .icon {
    width: 2.1rem;
    height: 2.1rem;

    @media screen and (min-width: 768px) {
      width: 3rem;
      height: 3rem;
    }

    > * {
      width: 100%;
      height: 100%;
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2)};
    }
  }
  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;

    margin-bottom: 1.4rem;

    font-size: 1.8rem;
    font-style: normal;
    line-height: 150%;
    font-weight: 700;

    @media screen and (min-width: 768px) {
      gap: 1rem;

      margin-bottom: 0;

      font-size: 2rem;
      line-height: 150%;
      font-weight: 600;
    }
  }

  .value {
    font-size: 1.8rem;
    line-height: 150%;
    font-weight: 500;
    font-style: normal;
    text-align: center;

    @media screen and (min-width: 768px) {
      font-size: 2rem;
      font-weight: 500;
      line-height: 150%;
    }
  }
`;
