import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  margin: auto;
  margin-bottom: 1.4rem;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 768px) {
    max-width: 65.8rem;

    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 6rem;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 4rem;
  }
  @media screen and (min-width: 1920px) {
    margin-bottom: 4.8rem;
  }

  @media screen and (min-width: 1024px) {
    max-width: 91.4rem;
  }
  @media screen and (min-width: 1440px) {
    max-width: 110rem;
  }
  @media screen and (min-width: 1920px) {
    max-width: 150.5rem;
  }
`;
