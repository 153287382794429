import styled from 'styled-components';
import { StyledCalendarContainer } from 'components/CustomCalendar/styles';
import { StyledDisplayItemsPerPageSelect } from 'components/DisplayItemsPerPageSelect/styles';
export const StyledReferralUsersList = styled.div`
  margin-bottom: 2rem;

  ${StyledCalendarContainer} {
    .calendar-back-layer {
      @media screen and (min-width: 768px) {
        right: 0;
      }
    }
  }
  .referral-list-controls-container {
    display: flex;
    align-items: center;

    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 1.8rem;
    }
  }

  .referral-list-heading {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
  }

  .referral-list-controls {
    display: flex;
    gap: 1.5rem;

    max-height: 3.4rem;

    margin-left: auto;

    @media screen and (min-width: 768px) {
      max-height: 4.1rem;
    }
  }

  .referral-list-container {
    .referral-list-header {
      display: flex;

      margin-bottom: 0.2rem;

      border-radius: 10px 10px 0px 0px;

      font-size: 1rem;
      line-height: 150%;
      font-weight: 500;

      background-color: ${(props) => props.theme.bg1};

      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
        line-height: 150%;
      }

      .referral-list-header-element {
        flex-grow: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 7rem;

        padding: 1rem;

        @media screen and (min-width: 768px) {
          width: 15rem;
          padding: 1rem;
        }

        @media screen and (min-width: 1024px) {
          width: 26.1rem;
        }
        @media screen and (min-width: 1440px) {
          width: 26.5rem;
        }
        @media screen and (min-width: 1920px) {
          width: 36.1rem;
        }
        &:not(:last-child) {
          border-right: 1px solid
            ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};
        }
        &.email {
          @media screen and (min-width: 768px) {
            width: 21rem;
          }
          @media screen and (min-width: 1024px) {
            width: 22rem;
          }
          @media screen and (min-width: 1440px) {
            width: 26.5rem;
          }
          @media screen and (min-width: 1920px) {
            width: 36.1rem;
          }
        }

        &.current-hashrate {
          @media screen and (min-width: 768px) {
            width: 12rem;
          }
          @media screen and (min-width: 1024px) {
            width: 14rem;
          }
          @media screen and (min-width: 1440px) {
            width: 26.5rem;
          }
          @media screen and (min-width: 1920px) {
            width: 36.1rem;
          }
        }

        .sort-by-button {
          .sort-icon {
            fill: currentColor;
          }
        }
      }
    }
    .referral-list-body {
      margin-bottom: 1.2rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 1.8rem;
      }

      .referral-list-row {
        display: flex;

        font-size: 0.8rem;
        line-height: 150%;
        font-weight: 500;

        @media screen and (min-width: 768px) {
          font-size: 1.4rem;
          line-height: 150%;
        }

        &:not(:last-child) {
          margin-bottom: 0.2rem;
        }

        .referral-list-element {
          flex-grow: 1;

          display: flex;
          justify-content: center;
          align-items: center;

          width: 7rem;

          padding: 0.5rem;

          word-break: break-all;

          background-color: ${(props) => props.theme.bg2};

          @media screen and (min-width: 768px) {
            width: 15rem;

            padding: 1rem;
          }

          @media screen and (min-width: 1024px) {
            width: 26.1rem;
          }

          @media screen and (min-width: 1440px) {
            width: 26.5rem;
          }

          @media screen and (min-width: 1920px) {
            width: 36.1rem;
          }

          &:not(:last-child) {
            border-right: 1px solid
              ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};
          }

          &.email {
            @media screen and (min-width: 768px) {
              width: 21rem;
            }
            @media screen and (min-width: 1024px) {
              width: 22rem;
            }
            @media screen and (min-width: 1440px) {
              width: 26.5rem;
            }
            @media screen and (min-width: 1920px) {
              width: 36.1rem;
            }
          }

          &.current-hashrate {
            @media screen and (min-width: 768px) {
              width: 12rem;
            }
            @media screen and (min-width: 1024px) {
              width: 14rem;
            }
            @media screen and (min-width: 1440px) {
              width: 26.5rem;
            }
            @media screen and (min-width: 1920px) {
              width: 36.1rem;
            }
          }
          &.date {
            @media screen and (min-width: 768px) {
              display: flex;
              flex-wrap: wrap;

              word-break: normal;
            }

            .time {
              display: none;

              @media screen and (min-width: 768px) {
                display: inline;
              }
            }
          }
        }
      }
    }
    .pagination-container {
      display: flex;
      justify-content: center;

      @media screen and (min-width: 768px) {
        justify-content: space-between;
      }

      ${StyledDisplayItemsPerPageSelect} {
        display: none;

        @media screen and (min-width: 768px) {
          display: flex;
        }
      }
    }
  }
`;
