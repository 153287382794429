import { StyledCurrencySelect } from './styles';
import { ReactComponent as BitCoinIcon } from 'assets/icons/bitcoin-icon.svg';

interface ICurrenctSelecProps {
  labelText?: string;
}
export const CurrencySelect = (props: ICurrenctSelecProps) => {
  const { labelText } = props;
  return (
    <StyledCurrencySelect>
      <label className="label">{labelText}</label>
      <div className="select">
        <div className="option">
          <div className="icon">
            <BitCoinIcon />
          </div>
          <span className="value">BTC</span>
        </div>
      </div>
    </StyledCurrencySelect>
  );
};
