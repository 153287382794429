import styled from 'styled-components';

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  height: 5.6rem;

  margin-top: auto;
  padding-top: 1.2rem;

  border-top: 1px solid ${(props) => props.theme.bg5};

  @media screen and (min-width: 768px) {
    justify-content: space-evenly;
  }

  @media screen and (min-width: 1920px) {
    gap: 4.2rem;
  }
`;
