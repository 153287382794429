import { EditableEmailInput } from 'components';
import { StyledTwoFactorAuth } from './styles';
import { useTranslation } from 'react-i18next';
import { EditablePhoneInput } from 'components/EditablePhoneInput';
import { MainSettingsComponent } from '../MainSettingsComponent';
interface ITwoFactorAuthProps {
  children?: React.ReactNode;
}
export const TwoFactorAuth = ({ children }: ITwoFactorAuthProps) => {
  const { t } = useTranslation();

  return (
    <StyledTwoFactorAuth>
      <MainSettingsComponent heading={t('settingsPage.twoFactorAuth.heading')}>
        <div className="two-factor-auth-inputs">
          <EditablePhoneInput
            labelText={t('settingsPage.twoFactorAuth.phone.label')}
            placeholderText={t('settingsPage.twoFactorAuth.phone.placeholder')}
          />
          <EditableEmailInput
            labelText={t('settingsPage.twoFactorAuth.email.label')}
            placeholderText={t('settingsPage.twoFactorAuth.email.placeholder')}
          />
        </div>
      </MainSettingsComponent>
    </StyledTwoFactorAuth>
  );
};
