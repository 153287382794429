import styled from 'styled-components';

export const StyledArticle = styled.article`
  position: relative;

  flex-basis: 30%;

  max-width: 30rem;

  margin: auto;

  text-align: center;

  @media screen and (min-width: 768px) {
    max-width: 45rem;
  }
  @media screen and (min-width: 1440px) {
    max-width: 37rem;

    padding: 0 0rem;
  }
`;

export const StyledImageContainer = styled.figure`
  width: 5rem;
  margin: auto;

  margin-bottom: 0.6rem;

  img {
    width: 100%;
    max-height: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 8.8rem;

    margin-bottom: 3rem;
  }
`;

export const StyledHeading = styled.h3`
  margin-bottom: 0.6rem;

  font-size: 1.8rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.18px;

  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;

    font-size: 2.6rem;
  }
`;

export const StyledText = styled.p`
  font-size: 1.2rem;
  line-height: 150%;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    font-size: 1.8rem;
    font-weight: 500;
  }
`;
