import { useEffect, useId, useMemo, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import {
  StyledInputGroup,
  StyledSelectContainer,
  StyledWorkerSelect,
  StyledWorkerSelectDropdown,
} from './styles';
import { IWorker } from 'types';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross-icon.svg';

import cn from 'classnames';
interface IWorkersSelectProps {
  children?: React.ReactNode;
  label: string;
  workersList: IWorker[];
  onSelect: (workersIDs: IWorker['id'][]) => void;
}

export const WorkersSelect = ({ label, workersList, onSelect }: IWorkersSelectProps) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [selectedWorkersIds, setSelectedWorkerIds] = useState<IWorker['id'][]>([]);

  const worker: { allIDs: IWorker['id'][]; byID: { [id: string]: IWorker } } = useMemo(
    () => ({
      allIDs: workersList.map((worker) => worker.id),
      byID: workersList.reduce<{ [id: string]: IWorker }>((acc, val) => {
        if (!acc[val.id]) acc[val.id] = val;
        return acc;
      }, {}),
    }),
    [workersList],
  );

  const selectWorkerHandler = (workerId: IWorker['id'] | null) => () => {
    if (workerId) {
      const updatedWorkersIds = selectedWorkersIds.includes(workerId)
        ? selectedWorkersIds.filter((id) => id !== workerId)
        : [...selectedWorkersIds, workerId];
      setSelectedWorkerIds(updatedWorkersIds);
      onSelect(updatedWorkersIds);
    } else {
      setSelectedWorkerIds([]);
    }
  };

  const toggleDropdownHandler: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement> = () =>
    setIsShowDropdown((prev) => !prev);

  const closeDropdownHandler: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsShowDropdown(false);
  };

  return (
    <StyledInputGroup>
      <div className="new-group-label">{label}</div>
      <StyledSelectContainer
        onMouseLeave={closeDropdownHandler}
        className={cn('container', isShowDropdown && 'expanded')}
      >
        {isShowDropdown && (
          <StyledWorkerSelectDropdown
            className={cn(isShowDropdown && 'open')}
            onMouseLeave={closeDropdownHandler}
          >
            <div
              className="worker-select-dropdown-element clear"
              onClick={selectWorkerHandler(null)}
            >
              none
            </div>
            {workersList.map((worker) => (
              <div key={worker.id} className="worker-select-dropdown-element">
                <input
                  hidden={true}
                  id={worker.id + worker.name}
                  className="worker-select-dropdown-element-input"
                  type="checkbox"
                  onChange={selectWorkerHandler(worker.id)}
                  checked={selectedWorkersIds.includes(worker.id)}
                />
                <label
                  htmlFor={worker.id + worker.name}
                  className="worker-select-dropdown-element-label"
                >
                  <div className="worker-name">{worker.name}</div>
                  {selectedWorkersIds.includes(worker.id) ? (
                    <CheckMarkIcon className="select-element-label-icon" />
                  ) : (
                    <CrossIcon className="select-element-label-icon" />
                  )}
                </label>
              </div>
            ))}
          </StyledWorkerSelectDropdown>
        )}
        <StyledWorkerSelect
          onClick={toggleDropdownHandler}
          className={cn(isShowDropdown && 'open')}
        >
          {selectedWorkersIds.length === 0
            ? '---'
            : selectedWorkersIds.map((id) => worker.byID[id].name).join(', ')}
          <button type="button" className={cn('select-dropdown-icon', isShowDropdown && 'active')}>
            <ArrowDownIcon />
          </button>
        </StyledWorkerSelect>
      </StyledSelectContainer>
    </StyledInputGroup>
  );
};
