import { TPoint } from 'types/hashrate';

export const roundToHundred = (number: number) => {
  if (number < 100) {
    return Number((number / 10).toFixed(0)) * 10;
  }
  return Number(Math.ceil(number / 100).toFixed(0)) * 100;
};

export const getFormatedDate = (dateObject: Date) => {
  const date = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const fullDate = `${date < 10 ? '0' : ''}${date}`;
  const fullMonth = `${month < 10 ? '0' : ''}${month}`;
  const shortYear = String(year).slice(2);
  return `${fullDate}.${fullMonth}.${shortYear}`;
};

export const getFormatedServerDateTime = (dateObject: Date) => {
  const date = dateObject.toLocaleDateString();
  const time = dateObject.toLocaleTimeString();
  return `${date} ${time}`;
};

export const line = (pointA: TPoint, pointB: TPoint): { angle: number; length: number } => {
  const lengthX = pointB.x - pointA.x;
  const lengthY = pointB.y - pointA.y;

  const length = Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2));

  const angle = Math.atan2(lengthY, lengthX);

  return { angle, length };
};

export const getControlPoint = (
  pointMode: 'start' | 'end',
  previousPoint: TPoint,
  currentPoint: TPoint,
  nextPoint: TPoint,
): TPoint => {
  const oppositeLine = line(previousPoint, nextPoint);
  const controlLineAngle = oppositeLine.angle - (pointMode === 'end' ? Math.PI : 0);
  const controlLineLength = oppositeLine.length * 0.2;

  const x = currentPoint.x + Math.cos(controlLineAngle) * controlLineLength;
  const y = currentPoint.y + Math.sin(controlLineAngle) * controlLineLength;
  return { x, y };
};

export const getCubicBezierCommand = (
  height: number,
  {
    current,
    prePrevious,
    previous,
    next,
  }: {
    current: TPoint;
    prePrevious: TPoint;
    previous: TPoint;
    next: TPoint;
  },
) => {
  const controlPointStart = getControlPoint('start', prePrevious, previous, current);
  console.log('controlPointStart', controlPointStart);
  // const verticalAmendmentRate = 1; // TODO: questionable, but makes the chart line better. Need experiments.
  const verticalAmendmentRate = 0.95; // TODO: questionable, but makes the chart line better. Need experiments.
  const controlPointStartToDraw: TPoint = {
    x: controlPointStart.x,
    y: height - (controlPointStart.y < 0 ? 0 : controlPointStart.y) * verticalAmendmentRate,
  };

  const controlPointEnd = getControlPoint('end', previous, current, next);
  const controlPointEndToDraw: TPoint = {
    x: controlPointEnd.x,
    y: height - controlPointEnd.y * verticalAmendmentRate,
  };
  const currentPointToDraw: TPoint = {
    x: current.x,
    y: height - current.y * verticalAmendmentRate,
  };
  console.log(current.y, height);
  console.log(
    'controlPointStartToDraw.y',
    controlPointStartToDraw.y,
    controlPointEndToDraw.y,
    currentPointToDraw.y,
  );
  return `C ${controlPointStartToDraw.x},${controlPointStartToDraw.y} ${controlPointEndToDraw.x},${controlPointEndToDraw.y} ${currentPointToDraw.x},${currentPointToDraw.y}`;
};

export const getCurlyPath = (
  zeros: { xEnd: number; yZero: number },
  points: TPoint[],
  fieldHeight: number,
  filled: boolean = false,
) => {
  if (points.length == 0) return 'M0,0';
  const curve = points.map((point, i) =>
    getCubicBezierCommand(fieldHeight, {
      current: point,
      prePrevious: points[i - 2] || point,
      previous: points[i - 1] || point,
      next: points[i + 1] || point,
    }),
  );
  const lastPoint = points[points.length - 1];
  const start = `M${0},${filled ? zeros.yZero : zeros.yZero - points[0]?.y}`;

  const endingXOffset = 15;
  const ending = `L${lastPoint.x + endingXOffset},${zeros.yZero - lastPoint.y}`;
  return `${start} ${curve} ${ending} ${filled ? `L${zeros.xEnd + 3},${zeros.yZero} Z` : ''}`;
};

export const getCurlyPath2 = (
  zeros: { xEnd: number; yZero: number },
  points: TPoint[],
  fieldHeight: number,
) => {
  console.log('get curlypath 2', zeros, 'height', fieldHeight);
  const endingXOffset = 0;
  const lastPoint = points[points.length - 1];

  const curve = points.map((point, i) =>
    getCubicBezierCommand(fieldHeight, {
      current: point,
      prePrevious: points[i - 2] || point,
      previous: points[i - 1] || point,
      next: points[i + 1] || point,
    }),
  );

  const start = `M${0},${zeros.yZero - points[0]?.y}`;
  const ending = `L${lastPoint.x + endingXOffset},${zeros.yZero - lastPoint.y}`;

  return `${start} ${curve} ${ending}`;
};

export const getPointsForMiddleXAxis = (
  values: number[],
  chartWidth: number,
  chartHeight: number,
  maxYValue: number,
): TPoint[] => {
  if (values.length === 0) {
    return [{ x: 0, y: 0 }];
  }
  const yOffset = chartHeight * 0.1;
  const res: TPoint[] = [];

  const xDistance = chartWidth / values.length;
  const xOffSet = xDistance / 2;
  for (let i = 0; i < values.length; i++) {
    const point = {
      x: i * xDistance + xOffSet,
      y: values[i] * (chartHeight / (maxYValue === 0 ? 1 : maxYValue)) + yOffset,
    };

    res.push(point);
  }

  res.push({
    x: chartWidth + 5,
    y: values[values.length - 1] + yOffset * (values[values.length - 1] > maxYValue / 2 ? -1 : 1),
  });

  return res;
};

export const formateHashUnits = (hashValue: string | number): string => {
  try {
    const bigint = BigInt(hashValue);
    const hashvalueString = String(hashValue);

    switch (true) {
      case bigint > 1e18: {
        const integerExaString = String(hashValue).slice(0, -18);
        return `${integerExaString + '.' + hashvalueString.slice(integerExaString.length, -15)} EH`;
      }
      case bigint > 1e15: {
        const integerPetaString = String(hashValue).slice(0, -15);
        return `${
          integerPetaString + '.' + hashvalueString.slice(integerPetaString.length, -12)
        } PH`;
      }
      case bigint > 1e12: {
        const integerTeraString = String(hashValue).slice(0, -12);
        return `${
          integerTeraString + '.' + hashvalueString.slice(integerTeraString.length, -9)
        } TH`;
      }
      case bigint > 1e9: {
        const integerGigaString = String(hashValue).slice(0, -9);
        return `${
          integerGigaString + '.' + hashvalueString.slice(integerGigaString.length, -6)
        } GH`;
      }
      case bigint > 1e6: {
        const integerMegaString = String(hashValue).slice(0, -6);
        return `${
          integerMegaString + '.' + hashvalueString.slice(integerMegaString.length, -3)
        } MH`;
      }
      case bigint > 1e3: {
        const integerMegaString = String(hashValue).slice(0, -3);
        return `${integerMegaString + '.' + hashvalueString.slice(integerMegaString.length)} KH`;
      }
      case bigint >= 0: {
        return `${hashValue} H`;
      }

      default:
        throw Error('cant convert to hash value');
    }
  } catch (error) {
    console.error('Cant formateHashUnits: wrong data', error);
    return 'n/a';
  }
};
