import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { IUserPayoutState } from 'store/slices/userPayoutSlice';
import { IPayout, TPayoutQuery } from 'types';

export const fetchPayoutsList = createAsyncThunk<
  { payouts: IPayout[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userPayouts: IUserPayoutState } }
>('userPayouts/fetchPayoutsList', async (_, { getState }) => {
  const {
    auth: { token },
    userPayouts: { currentPage, itemsPerPage, filterByConfirmationStatus },
  } = getState();

  if (!token) throw Error('no token');

  const params: TPayoutQuery = { page: String(currentPage - 1), size: String(itemsPerPage) };
  if (filterByConfirmationStatus) params.status = filterByConfirmationStatus;
  const payoutsListResponse = await UserRepository.getPayouts(token, params);

  if (payoutsListResponse.status === 'failed') throw Error(payoutsListResponse.error);

  return {
    payouts: payoutsListResponse.data.result,
    count: payoutsListResponse.data.count,
    size: payoutsListResponse.data.size,
  };
});
