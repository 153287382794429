export type THTTPHeaders = Record<string, string>;

export type THTTPqueryParams = Record<string, string>;

export type TApiRequestStatus = 'success' | 'failed';

export enum EApiResponseStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface IApiConResponse {
  status: EApiResponseStatus;
  error?: string;
}

export interface IApiResponse {
  status: 'success' | 'failed';
  error?: string;
  data?: unknown;
}
export interface IApiPostResponse extends IApiConResponse {}
