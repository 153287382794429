import { StyledButton } from 'components/shared/Button/styles';
import { StyledSecondaryButton } from 'components/shared/SecondaryButton/styled';
import styled from 'styled-components';
export const StyledAdminEditUsersList = styled.div`
  margin-bottom: 2rem;
  .admin-user-list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    @media screen and (min-width: 768px) {
      margin-bottom: 3.2rem;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 4.4rem;
    }
    .heading {
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 18px */

      @media screen and (min-width: 768px) {
        font-size: 1.6rem;
        line-height: 150%; /* 24px */
      }

      @media screen and (min-width: 1440px) {
        font-size: 2rem;
        line-height: 150%; /* 30px */
      }
    }
    .refresh-button {
      display: block;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.button1 : props.theme.text1)};

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */

      &:hover {
        text-decoration: underline;
      }

      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
        line-height: 150%; /* 27px */
      }

      @media screen and (min-width: 1440px) {
        font-size: 2rem;
        line-height: 150%; /* 30px */
      }
    }
  }

  .list-controls {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.2rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 1.6rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (min-width: 1440px) {
      margin-bottom: 2.8rem;
    }
  }
  .list-controls-group {
    display: flex;

    gap: 0.6rem;
    @media screen and (min-width: 768px) {
      gap: 1.2rem;
    }

    ${StyledButton}, ${StyledSecondaryButton} {
      flex-grow: 1;
      flex-basis: 50%;
      border-radius: 9px;
      display: flex;
      justify-content: center;

      font-size: 1.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 18px */
      text-transform: capitalize;

      @media screen and (min-width: 768px) {
        flex-basis: unset;

        font-size: 1.4rem;
        line-height: 150%; /* 21px */
      }

      @media screen and (min-width: 1440px) {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: normal;
      }
    }
    ${StyledButton} {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
      padding: 0.9rem 1.6rem;
    }

    ${StyledSecondaryButton} {
      padding: 0.8rem 1.6rem;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2)};
      border-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};

      &:hover {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
        background-color: ${(props) =>
          props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
      }
    }
  }
  .list-body {
    .list-body-pagination {
      padding: 2.8rem 1rem 0;
      display: flex;
      gap: 3.2rem;
      justify-content: flex-end;
    }
  }
`;
