import styled from 'styled-components';

export const StyledAdminBarMenu = styled.nav`
  position: relative;

  .sidebar-item-label {
    @media screen and (min-width: 768px) {
      display: none;
    }

    @media screen and (min-width: 1440px) {
      display: inline-block;
    }
  }

  .sidebar-nav-list {
    padding: 0 0.6rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 2rem;
    }

    @media screen and (min-width: 1440px) {
      padding: 0 3.7rem;
      margin-bottom: 5rem;
    }

    .sidebar-nav-list-element {
      padding: 0.8rem 1.2rem;
      border-radius: 12px;
      color: ${(props) => props.theme.text1};

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      &:hover,
      &:hover.selected {
        color: ${(props) => props.theme.accent1};
      }

      &.selected {
        color: #6f33ff;
        background-color: rgba(74, 36, 165, 0.2);
      }

      @media screen and (min-width: 768px) {
        &:not(:last-child) {
          margin-bottom: 0.4rem;
        }
      }
      @media screen and (min-width: 1440px) {
        padding: 0.8rem 2.2rem;
      }

      .sidebar-nav-list-element-button {
        width: 100%;

        display: flex;
        justify-content: start;
        align-items: center;
        gap: 1.5rem;

        color: currentColor;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 150%; /* 21px */

        @media screen and (min-width: 768px) {
          justify-content: center;

          font-size: 2.5rem;
          line-height: 150%; /* 21px */
        }

        @media screen and (min-width: 1440px) {
          justify-content: start;
        }

        .button-icon {
          *[fill] {
            fill: currentColor;
          }
        }
      }
    }
  }
  .user-data {
    height: 6.5rem;
    padding: 0 1.2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${(props) => props.theme.text1};
    font-size: 1.4rem;
    line-height: 150%; /* 21px */
    font-weight: 600;
    text-transform: capitalize;

    .button-icon {
      &:hover {
        color: ${(props) => props.theme.accent1};
      }
      *[fill] {
        fill: currentColor;
      }
      *[stroke] {
        stroke: currentColor;
      }
    }

    @media screen and (min-width: 768px) {
      max-width: 28rem;
      font-size: 2.2rem;
      line-height: 150%; /* 33px */
      justify-content: center;
    }

    @media screen and (min-width: 1440px) {
      padding: 0 3.7rem;
      justify-content: space-between;
    }
  }
`;
