import styled from 'styled-components';
export const StyledWorkerControls = styled.div`
  display: flex;
  gap: 0.5rem;

  margin-bottom: 0.6rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 1.6rem;
  }

  @media screen and (min-width: 1440px) {
    gap: 2rem;
  }
`;
