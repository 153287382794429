import { useSelector } from 'react-redux';
import { StyledAmountContainer, StyledDailyIncome } from './styles';
import { RootState } from 'store/store';
import { useTranslation } from 'react-i18next';

interface IDailyIncomeProps {
  thAmount: number;
}
export const DailyIncome = (props: IDailyIncomeProps) => {
  const { t } = useTranslation();
  const { thAmount } = props;
  const { btcToThRate, btcToUsdRate } = useSelector((state: RootState) => state.commonData);

  const cryptoCurrency = 'BTC';
  const fiatCurrency = '$';
  const cryptoAmount = btcToThRate ? btcToThRate * thAmount : null;
  const fiatAmount = btcToUsdRate && cryptoAmount ? btcToUsdRate * cryptoAmount : null;

  const getAmountStringValue = (
    cryptoAmount: number,
    cryptoCurrency: string,
    fiatAmount: number,
    fiatCurrency: string,
  ) => {
    return `${cryptoAmount.toFixed(10)} ${cryptoCurrency} = ${fiatAmount.toFixed(3)}
        ${fiatCurrency}`;
  };

  return (
    <StyledDailyIncome>
      <label className="daily-income_label">{t('calculator.dailyIncome')}</label>
      <StyledAmountContainer>
        {!cryptoAmount || !fiatAmount
          ? 'amount unavailable'
          : getAmountStringValue(cryptoAmount, cryptoCurrency, fiatAmount, fiatCurrency)}
      </StyledAmountContainer>
    </StyledDailyIncome>
  );
};
