import styled from 'styled-components';
export const StyledHashrateInformation = styled.section`
  margin-bottom: 1.2rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 1024px) {
    flex-grow: 1;
    flex-basis: 45%;
  }

  @media screen and (min-width: 1200px) {
    flex-basis: 30%;

    max-width: 33%;

    margin-bottom: 0;
  }
`;

export const StyledHashrateData = styled.div`
  .average-rate {
    font-weight: 700;
    text-align: center;

    .average-rate_header {
      margin-bottom: 0.2rem;

      font-size: 1rem;
      line-height: 150%; /* 15px */
      font-weight: 400;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text4 : props.theme.accent1)};

      @media screen and (min-width: 768px) {
        font-size: 1.6rem;
        line-height: 150%; /* 24px */
      }
    }

    .average-rate_data {
      font-size: 1.8rem;
      line-height: 150%;
      letter-spacing: 0.18px;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

      @media screen and (min-width: 768px) {
        font-size: 2.2rem;
        line-height: 150%;
        font-weight: 500;
      }
    }
  }
`;

export const StyledHashRateFooterDataElement = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 150%;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 1.6rem;
    line-height: 150%;

    .data {
      margin-bottom: 0.2rem;
    }
  }
  @media screen and (min-width: 1024px) {
    width: 17rem;

    text-align: start;
  }

  &.row {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    padding: 0 0.6rem;
  }

  .reference {
    font-size: 1rem;
    line-height: 150%;
    font-weight: 500;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text4 : props.theme.accent1)};

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
      line-height: 150%;
      font-weight: 600;
    }
  }
`;
