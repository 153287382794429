import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledCurrencySelect = styled.div`
  width: 4.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  @media screen and (min-width: 768px) {
    width: 11.4rem;
    align-items: start;
  }

  @media screen and (min-width: 1024px) {
    width: 6.6rem;
    align-items: center;
  }

  @media screen and (min-width: 1440px) {
    width: 11.4rem;
    align-items: start;
  }

  .currency-select_label {
    color: ${darkTheme.text2};

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    padding: 0 1rem;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }
  }
`;
