import { useTranslation } from 'react-i18next';
import { StyledDailyIncome } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface IDailyIncomeProps {
  labelText?: string;
  thAmount: number;
}
export const DailyIncome = ({ labelText, thAmount }: IDailyIncomeProps) => {
  const { t } = useTranslation();
  const { btcToThRate, btcToUsdRate } = useSelector((state: RootState) => state.commonData);
  const cryptoCurrency = 'BTC';
  const fiatCurrency = '$';
  const cryptoAmount = btcToThRate ? btcToThRate * thAmount : null;
  const fiatAmount = btcToUsdRate && cryptoAmount ? btcToUsdRate * cryptoAmount : null;

  const getAmountStringValue = (
    cryptoAmount: number,
    cryptoCurrency: string,
    fiatAmount: number,
    fiatCurrency: string,
  ) => {
    return `${cryptoAmount.toFixed(10)} ${cryptoCurrency} = ${fiatAmount.toFixed(3)}
        ${fiatCurrency}`;
  };
  return (
    <StyledDailyIncome>
      <label className="label">
        {labelText ? labelText : t('calculator.dailyIncome')}
        <div className="hint">?</div>
      </label>
      <div className="value">
        {!cryptoAmount || !fiatAmount
          ? 'amount unavailable'
          : getAmountStringValue(cryptoAmount, cryptoCurrency, fiatAmount, fiatCurrency)}
      </div>
    </StyledDailyIncome>
  );
};
