import styled from 'styled-components';

export const StyledLangListContainer = styled.div`
  visibility: hidden;

  position: absolute;
  top: 1.5rem;

  width: inherit;
  overflow: hidden;

  padding-top: 1.3rem;

  border-radius: 0 0 500px 500px;

  background-color: inherit;
  opacity: 0;

  transition: all 0.3s;

  &.top {
    top: -4.1rem;
    bottom: 2.1rem;

    padding-top: 0;
    padding-bottom: 1.7rem;

    border-radius: 500px 500px 0 0;
  }
`;
