import { useTranslation } from 'react-i18next';
import {
  StyledArticle,
  StyledHeading,
  StyledImageContainer,
  StyledSection,
  StyledText,
} from './styles';
import { useTheme } from 'styled-components';

export const MainBenefitsSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledSection>
      <div className="benefits-background" />
      <StyledArticle>
        <StyledImageContainer>
          <img src={theme.id === 'dark' ? '/images/star.webp' : '/images/star-light.webp'} />
        </StyledImageContainer>
        <StyledHeading>{t('benefits.confidence.title')}</StyledHeading>
        <StyledText>{t('benefits.confidence.description')}</StyledText>
      </StyledArticle>
      <StyledArticle>
        <StyledImageContainer>
          <img src={theme.id === 'dark' ? '/images/lock.webp' : '/images/lock-light.webp'} />
        </StyledImageContainer>
        <StyledHeading>{t('benefits.safety.title')}</StyledHeading>
        <StyledText>{t('benefits.safety.description')}</StyledText>
      </StyledArticle>
      <StyledArticle>
        <StyledImageContainer>
          <img src={theme.id === 'dark' ? '/images/curly.webp' : '/images/curly-light.webp'} />
        </StyledImageContainer>
        <StyledHeading>{t('benefits.conditions.title')}</StyledHeading>
        <StyledText>
          {String(t('benefits.conditions.description', { returnObjects: true })).replaceAll(
            '&ldquo',
            '"',
          )}
        </StyledText>
      </StyledArticle>
    </StyledSection>
  );
};
