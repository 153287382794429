import styled, { keyframes } from 'styled-components';
import { ReactComponent as Moon } from 'assets/icons/moon.svg';
import { ReactComponent as Sun } from 'assets/icons/sun.svg';

const fadeOut = (direction: 'left' | 'right') => keyframes`
  from {
    ${direction === 'right' ? 'left' : 'right'}: 0.4rem; 
    display: block;
    opacity: 1;
  }
  to {
    ${direction === 'right' ? 'left' : 'right'}: 15%;
    display: none;
    opacity: 0;
}
`;

const fadeIn = (direction: 'left' | 'right') => keyframes`
  from {
    opacity: 0;
    display: none;
    ${direction === 'right' ? 'left' : 'right'}: 15%;
  }
  to {
    ${direction === 'right' ? 'left' : 'right'}: 0.4rem;
    opacity: 1;
    display: block;
  }
`;

export const StyledMoon = styled(Moon)<{ hidden: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  fill: ${(props) => props.theme.accent2};
  left: ${(props) => (props.hidden ? '15%' : '0.4rem')};
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  opacity: ${(props) => (props.hidden ? '0' : '1')};
  animation: ${(props) => (props.hidden ? fadeOut('right') : fadeIn('right'))} 0.2s ease-out;
  animation-fill-mode: forwards;
`;

export const StyledSun = styled(Sun)<{ hidden: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  fill: ${(props) => props.theme.accent3};
  right: ${(props) => (props.hidden ? '15%' : '0.4rem')};
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  opacity: ${(props) => (props.hidden ? '0' : '1')};
  animation: ${(props) => (props.hidden ? fadeOut('left') : fadeIn('left'))} 0.2s ease-out;
  animation-fill-mode: forwards;
`;
