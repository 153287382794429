import cn from 'classnames';
import { Header, Footer, Sidebar } from 'components';
import { Content } from './styles';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Layout = ({
  children,
  home,
  admin,
}: {
  children: React.ReactNode;
  home?: boolean;
  admin?: boolean;
}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      {!home && <Sidebar admin={admin} />}
      {}
      <Header home={home} />
      <Content className={cn(home && 'home')}>{children}</Content>
      {home && <Footer />}
    </>
  );
};
