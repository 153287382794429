import styled from 'styled-components';
import { StyledAdminListRow } from '../../AdminListHeaderCell/styles/StyledAdminListRow';

export const StyledAdminEditPaymentHistoryRow = styled.div`
  ${StyledAdminListRow}

  .admin-list-cell-container {
    &.id,
    &.confirmation,
    &.created,
    &.updated {
      flex-basis: initial;
      flex-grow: 0;
    }
    &.id {
      width: 10.4rem;
    }
    &.confirmation {
      width: 13.4rem;
    }
    &.created,
    &.updated {
      width: 23.8rem;
    }
  }
`;
