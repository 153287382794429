import styled from 'styled-components';
import { StyledCheckMark } from './StyledCheckMark';
import { darkTheme } from 'themes';

export const StyledCheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  left: 0;

  &:checked ~ ${StyledCheckMark} {
    transition: all 0.2s;
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent4};

    &::after {
      visibility: visible;
    }
  }
`;

export const StyledCheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  &.invalid {
    color: ${darkTheme.errorRed};
  }

  .checkbox-text {
    /* max-width */
    width: 89%;
    text-align: start;

    /* white-space: normal; */
    /* max-width: 24.5rem; */
  }
`;

export const StyledCheckBoxGroup = styled.div`
  position: relative;
  width: 100%;

  &.disabled {
    opacity: 0.5;
  }
`;
