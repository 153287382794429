import { ICharge, TSortChargeByHeaderTitle } from 'types';
import { v4 as uuid4 } from 'uuid';
import { StyledChargeList } from './styles';
import { ChargeListHeader } from '../ChargeListHeader';
import { useState } from 'react';
import { ChargeListElement } from '../ChargeListElement';
interface IChargeListProps {
  children?: React.ReactNode;
  charges: ICharge[];
  onSort: (sortBy: { sortBy: keyof ICharge; incr: boolean }) => void;
}
export const ChargeList = ({ onSort, charges }: IChargeListProps) => {
  const [sortIncr, setSortIncr] = useState(true);
  const sortHandler = (sortBy: TSortChargeByHeaderTitle) => {
    onSort({ sortBy, incr: !sortIncr });
    setSortIncr((prev) => !prev);
  };
  return (
    <StyledChargeList>
      <ChargeListHeader onSort={sortHandler} sortIncr={sortIncr} />
      {charges.map((charge) => (
        <ChargeListElement key={uuid4()} {...charge} />
      ))}
    </StyledChargeList>
  );
};
