import { useEffect, useRef, useState } from 'react';
import {
  StyledDetailsContainer,
  StyledFAQContent,
  StyledFAQElement,
  StyledFAQElementHeading,
} from './styles';

interface IFAQElementProps {
  text: string;
  title: string;
}

export const FAQElement = (props: IFAQElementProps) => {
  const [currentHight, setCurrentHight] = useState(0);
  const { text, title } = props;
  const testref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (testref.current) setCurrentHight(testref.current?.scrollHeight);
  }, [testref.current]);

  return (
    <StyledDetailsContainer>
      <StyledFAQElement $test={testref.current?.offsetHeight ? `${currentHight}px` : 'fit-content'}>
        <StyledFAQElementHeading>{title}</StyledFAQElementHeading>
        <div className="hidden" ref={testref}>
          {text}
        </div>
      </StyledFAQElement>
      <StyledFAQContent className="content">{text}</StyledFAQContent>
    </StyledDetailsContainer>
  );
};
