import { StyledSecondaryButton } from 'components/shared/SecondaryButton/styled';
import styled from 'styled-components';
export const StyledAddminAddTransaction = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  .heading {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 150%; /* 18px */
    margin-bottom: 0.8rem;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 150%; /* 24px */
      margin-bottom: 1.8rem;
    }

    @media screen and (min-width: 1440px) {
      font-size: 2rem;
      line-height: 150%; /* 30px */
      margin-bottom: 2.4rem;
    }
  }

  ${StyledSecondaryButton} {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: normal;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2)};
    border-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
    padding: 0.8rem 1.6rem;
    border-radius: 9px;
    gap: 0.5rem;

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
    }

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
    @media screen and (min-width: 1440px) {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }

    .button-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .form {
    display: flex;
    gap: 0.8rem;

    @media screen and (min-width: 1440px) {
      align-items: center;
      gap: 1.4rem;
    }

    .input {
      flex-grow: 1;
      display: block;
      width: 100%;
      max-width: 56rem;

      border-radius: 6px;

      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 1rem 0.8rem;

      @media screen and (min-width: 1440px) {
        padding: 1.6rem;
        padding-right: 1.2rem;
        font-size: 1.6rem;
      }
    }
  }
`;
