import cn from 'classnames';
import { StyledModalOverlay } from './styles';
interface IModalOverlayProps {
  children?: React.ReactNode;
  showContent?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export const ModalOverlay = ({ children, onClick, showContent }: IModalOverlayProps) => {
  return (
    <StyledModalOverlay onClick={onClick} className={cn(showContent && 'show')}>
      {children}
    </StyledModalOverlay>
  );
};
