import { BorderedWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon.svg';
import { StyledReferralBalance } from './styles/StyledReferralBalance';

interface IReferralBalanceProps {
  children?: React.ReactNode;
}
export const ReferralBalance = ({ children }: IReferralBalanceProps) => {
  const { t } = useTranslation();
  const currentBalance = 0;
  const currency = 'BTC';
  const linkAddress = 'stratum+tcp://btc.viabtc.io:3333';
  const currentInterest = '0.05 %';
  const copyUrlHandler = () => {
    navigator.clipboard.writeText(linkAddress);
  };
  return (
    <StyledReferralBalance>
      <BorderedWrapper>
        <div className="content">
          <h2 className="balance-heading">{t('referralsPage.balance.heading')}</h2>
          <div className="balance">
            <span className="balance-value">{currentBalance}</span>
            <span className="balance-currnecy">{currency}</span>
          </div>
          <p className="update-time">{t('referralsPage.balance.updateTime')}</p>
          <div className="referal-link-container">
            <div className="referal-link">
              <p className="referal-link-heading">{t('referralsPage.balance.referalLink.link')}</p>
              <p className="referal-link-value">
                <span className="referal-link-address">{linkAddress}</span>
                <button className="copy-link-button" onClick={copyUrlHandler}>
                  <CopyIcon className="copy-link-button-icon" />
                </button>
              </p>
            </div>
            <div className="referal-link-interest">
              <p className="referal-link-interest-heading">
                {t('referralsPage.balance.referalLink.interest')}
              </p>
              <p className="referal-link-interest-value">{currentInterest}</p>
            </div>
          </div>
        </div>
      </BorderedWrapper>
    </StyledReferralBalance>
  );
};
