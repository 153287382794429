import { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close-cross.svg';
import cn from 'classnames';
import {
  StyledCloseButton,
  StyledReferalCalculatorDialog,
  StyledReferalCalculatorDialogContainer,
  StyledReferalCalculatorDialogOverlay,
} from './styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { PeriodSelector } from 'components/shared';
import { ECalculationPeriods } from 'types';

interface IReferalCalculatorDialogProps {
  children?: React.ReactNode;
  onClose: () => void;
  show?: boolean;
}
export const ReferalCalculatorDialog = ({ onClose, show }: IReferalCalculatorDialogProps) => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(false);
  const closeHandler = () => {
    onClose();
    setShowContent(false);
  };

  const [, setSelectedPeriod] = useState<ECalculationPeriods>(ECalculationPeriods.Week);
  useEffect(() => {
    if (show) {
      setTimeout(() => setShowContent(() => true), 100);
    }
  }, [show]);
  return (
    <StyledReferalCalculatorDialogContainer>
      <StyledReferalCalculatorDialogOverlay
        onClick={closeHandler}
        className={cn(showContent && 'show')}
      />
      {showContent && (
        <StyledReferalCalculatorDialog open={showContent}>
          <StyledCloseButton onClick={closeHandler}>
            <CloseIcon />
          </StyledCloseButton>
          <div className="calculator-dialog-heading">
            {t('referralsPage.levels.calculatorDialog.heading')}
          </div>
          <div className="calculator-dialog-references">
            <div className="calculator-dialog-reference">
              <label className="calculator-dialog-reference-label">
                {t('referralsPage.levels.calculatorDialog.references.hashrate')}
              </label>
              <div className="calculator-dialog-reference-input-group">
                <input className="calculator-dialog-reference-input" defaultValue={9999999} />
                <div className="calculator-dialog-reference-input-units">TH</div>
              </div>
            </div>
            <div className="calculator-dialog-reference">
              <label className="calculator-dialog-reference-label">
                {t('referralsPage.levels.calculatorDialog.references.level')}
              </label>
              <div className="calculator-dialog-reference-input-group">
                <input className="calculator-dialog-reference-input" defaultValue={1} />
                <div className="calculator-dialog-reference-input-units">
                  <ArrowDownIcon className="" />
                </div>
              </div>
            </div>
            <div className="calculator-dialog-reference">
              <label className="calculator-dialog-reference-label">
                {t('referralsPage.levels.calculatorDialog.references.percent')}
              </label>
              <div className="calculator-dialog-reference-input-group">
                <input className="calculator-dialog-reference-input" defaultValue={'0.5'} />
                <div className="calculator-dialog-reference-input-units">%</div>
              </div>
            </div>
          </div>
          <div className="calculator-dialog-period">
            <PeriodSelector onChange={setSelectedPeriod} labelText={t('calculator.period')} />
          </div>
          <div className="calculator-dialog-income">
            <div className="calculator-dialog-income-label">
              {t('referralsPage.levels.calculatorDialog.income.heading')}
            </div>
            <div className="calculator-dialog-income-amount">
              <span className="calculator-dialog-income-amount-btc">1.12312312 BTC</span>
              <span className="calculator-dialog-income-amount-usd">≈999 999$</span>
            </div>
            <div className="calculator-dialog-income-note">
              {t('referralsPage.levels.calculatorDialog.income.note')}
            </div>
          </div>
        </StyledReferalCalculatorDialog>
      )}
    </StyledReferalCalculatorDialogContainer>
  );
};
