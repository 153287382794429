import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledVideoDialogContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 1rem;
  padding-top: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledVideoDialogOverlay = styled.div`
  position: absolute;
  opacity: 0;
  background: ${darkTheme.accent2};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transition: opacity 0.5s;
  &.show {
    opacity: ${(props) => (props.theme.id === 'dark' ? '0.39' : '0.75')};
  }
`;

export const StyledVideoDialog = styled.dialog`
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 31px 20px 0px rgba(0, 0, 0, 0.25);

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};

  background-color: ${(props) => props.theme.bg3};

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    max-width: 90vw;
    max-height: 80vh;
  }

  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
`;
