import { apiRoutes } from 'lib';
import { apiClient } from 'lib/ApiClient';
import { IApiResponse } from 'types/api';

export const CommonDataRepository = {
  hashRate: async (from: number, to: number) => {
    const url = apiRoutes.apiFetchHashrate();
    const params = new URLSearchParams({
      from: String(from),
      to: String(to),
    });
    return apiClient.get(`${url}?${params.toString()}`);
  },
  btcToThRate: async (): Promise<IApiResponse> => {
    //TODO: add api call
    return { status: 'success', data: 0.0000001 };
  },
  btcToUsdRate: async (): Promise<IApiResponse> => {
    return { status: 'failed', data: 480000 };
  },
};
