import styled from 'styled-components';
export const StyledWorkersListHeader = styled.header`
  display: flex;

  max-height: 6.8rem;

  margin-bottom: 0.2rem;

  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
  }

  .workers-list-header-element {
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    width: 8rem;

    padding: 0.4rem 0.5rem;

    border-right: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};

    background-color: ${(props) => props.theme.bg1};

    @media screen and (min-width: 768px) {
      width: 15.5rem;
      gap: 0.5rem;
      padding: 0.2rem 0.8rem;
    }

    &.select-all {
      position: relative;

      justify-content: center;
      align-items: start;

      width: auto;

      padding: 0;
      padding-right: 1rem;

      border-right: none;

      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};

      background: none;

      .select-all-input {
        position: absolute;
        width: 0;
        height: 0;
        border: none;
        visibility: hidden;

        &:checked ~ {
          .select-all-label {
            color: ${(props) =>
              props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2};

            > * {
              *[fill] {
                fill: currentColor;
              }
            }
          }
        }
      }
      .select-all-label {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: start;
        color: inherit;
        width: 1.4rem;
        height: 1.4rem;

        @media screen and (min-width: 1440px) {
          width: 1.6rem;
          height: 1.6rem;
        }

        > * {
          width: 100%;
          height: 100%;

          *[stroke] {
            stroke: currentColor;
          }
        }
      }
      &:hover {
        .select-all-label {
          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2};
        }
      }
    }

    &:nth-child(2) {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-right: none;
      border-top-right-radius: 10px;
    }

    &.actions {
      width: 10rem;
    }

    &.last-activity {
      width: 21rem;
      flex-grow: 1;
    }

    &.hashrate {
      flex-direction: row;
      gap: 0.8rem;

      @media screen and (min-width: 768px) {
        gap: 1rem;
      }

      .hashrate-element-text {
        flex-grow: 1;

        &-sub {
          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.accent2 : props.theme.text1};
        }
      }
    }

    &.reject {
      width: 11rem;
    }

    .sort-by-button {
      display: flex;
      align-items: center;

      width: 1rem;
      height: 0.7rem;

      margin: auto;

      color: inherit;

      > * {
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }
  }
`;
