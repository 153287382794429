import { useTranslation } from 'react-i18next';
import { StyledLanguageSelect } from './styles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { useState } from 'react';
import cn from 'classnames';
interface ILanguageSelectProps {
  children?: React.ReactNode;
}
export const LanguageSelect = ({ children }: ILanguageSelectProps) => {
  const { t, i18n } = useTranslation();
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  const changeLangHandler = (lang: string) => () => {
    i18n.changeLanguage(lang);
  };

  const activeLang = i18n.language;
  const restLanguages = i18n.languages;

  const toggleDroprown = () => {
    setIsShowDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsShowDropdown(false);
  };

  return (
    <StyledLanguageSelect className="language-select" onMouseLeave={closeDropdown}>
      {<label className="language-select-label">{t('settingsPage.languageSelector.label')}</label>}
      <div className="language-select-container">
        <div className="language-select-value-container">
          <button className="language-select-value" onClick={toggleDroprown}>
            <span className="language-select-value__hint">
              {t('settingsPage.languageSelector.hint')}
            </span>
            <span className="language-select-value__value">
              {t(`settingsPage.languageSelector.${activeLang}`)}
            </span>
          </button>
          <button className="language-select-dropdown-button" onClick={toggleDroprown}>
            <ArrowDownIcon className="icon" />
          </button>
        </div>
        <div className={cn('language-select-dropdown-container', isShowDropdown && 'active')}>
          {restLanguages.map((lang) => (
            <button
              key={lang}
              className={cn('language-select-dropdown-element', activeLang === lang && 'selected')}
              onClick={changeLangHandler(lang)}
            >
              {t(`settingsPage.languageSelector.${lang}`)}
            </button>
          ))}
        </div>
      </div>
    </StyledLanguageSelect>
  );
};
