import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledForm = styled('form').withConfig({
  shouldForwardProp: (prop) => prop !== 'header',
})`
  position: relative;
  overflow: hidden;
  padding: 2.5rem 1rem 3.2rem;
  border-radius: 15px;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg3)};

  text-align: center;

  @media screen and (min-width: 768px) {
    padding-bottom: 3.9rem;
  }
`;

export const StyledFormHeader = styled.div`
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
`;

export const StyledFromModal = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .form-modal-overlay {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg3)};
    opacity: 0.8;
  }
  .form-modal-close-button {
    position: absolute;
    z-index: 1;
    right: 1rem;
    top: 1rem;
  }
  .form-modal-content {
    position: relative;
    /* background-color: red; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.5rem;

    .form-modal-status {
      &.error {
        color: ${darkTheme.errorRed};
      }
    }

    .form-modal-dialog {
      background: transparent;
      border: none;
      margin: auto;
      color: inherit;
    }

    .form-modal-message {
      padding: 2rem 0;
      min-width: 27rem;
      .form-modal-error-message {
        font-size: 1.8rem;
        text-align: center;
        padding: 1rem;
      }
    }

    .form-modal-back-button {
      padding: 2rem;
      justify-content: center;
      display: flex;
    }
  }
`;
