import { useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  StyledHideButton,
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledStatus,
  StyledStatusString,
  StyledStatusbar,
  StyledStatusbarFiller,
} from './styles';
import { ReactComponent as HideIcon } from 'assets/icons/hide.svg';
import { ReactComponent as UnhideIcon } from 'assets/icons/unhide.svg';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  inValid?: boolean;
  error?: string;
}

export const NewPasswordInput = (props: IInputProps) => {
  const { t } = useTranslation();
  const [valid] = useState(false);

  const [passwordSecurityRatio, setPasswordSecurityRatio] = useState(0);
  const [passwordSecurityStatus, setPasswordSecurityStatus] = useState<
    'danger' | 'insecure' | 'secure' | 'perfect'
  >('danger');

  const [passwordStatusString, setPasswordStatusString] = useState('');

  const [unhidePassword, setUnhidePassword] = useState(false);

  const passwordInputId = useId();

  const statusStringConstructor = (ratio: number = 0, toAdd?: string) => {
    return `${t('fields.password.security')} ${ratio}%${
      toAdd ? `, ${t(`fields.password.${toAdd}`)}` : ''
    }`;
  };

  useEffect(() => {
    const valueString = String(props.value);
    if (valueString.length < 8) {
      setPasswordSecurityRatio(0);
      return;
    }
    const containsOnlyDigits = /^\d+$/.test(valueString);
    const containsOnlyLetters = /^[a-z]+$/i.test(valueString);
    const containsOnlySymbols = /^[^a-zA-Z0-9]+$/.test(valueString);
    if (containsOnlyDigits || containsOnlySymbols || containsOnlyLetters) {
      setPasswordSecurityRatio(20);

      switch (true) {
        case containsOnlyDigits:
          setPasswordStatusString('onlyDigits');
          return;
        case containsOnlyLetters:
          setPasswordStatusString('onlyLetters');
          return;
        case containsOnlySymbols:
          setPasswordStatusString('onlySymbols');
          return;
        default:
          setPasswordStatusString('');
          return;
      }
    }

    const containsDigits = /\d/.test(valueString);
    const containsLowcaseLetters = /[a-z]/.test(valueString);
    const containsUppercaseLetters = /[A-Z]/.test(valueString);
    const containsSymbols = /[^a-zA-Z0-9]/.test(valueString);
    const isEnoughLength = valueString.length >= 15;

    const multiplier =
      +containsDigits +
      +containsLowcaseLetters +
      +containsUppercaseLetters +
      +containsSymbols +
      +isEnoughLength;

    setPasswordSecurityRatio(20 * multiplier);

    switch (true) {
      case !containsLowcaseLetters:
        setPasswordStatusString('addLowcaseLetters');
        return;
      case !containsDigits:
        setPasswordStatusString('addDigits');
        return;
      case !containsUppercaseLetters:
        setPasswordStatusString('addUpperCaseLetters');
        return;
      case !containsSymbols:
        setPasswordStatusString('addSymbols');
        return;
      case !isEnoughLength:
        setPasswordStatusString('makeLonger');
        return;
      default:
        setPasswordStatusString('');
    }
  }, [props.value]);

  useEffect(() => {
    switch (true) {
      case passwordSecurityRatio <= 25:
        setPasswordSecurityStatus('danger');
        return;
      case passwordSecurityRatio <= 50:
        setPasswordSecurityStatus('insecure');
        return;
      case passwordSecurityRatio <= 85:
        setPasswordSecurityStatus('secure');
        return;
      case passwordSecurityRatio <= 100:
        setPasswordSecurityStatus('perfect');
        return;
      default:
        setPasswordSecurityStatus('danger');
        return;
    }
  }, [passwordSecurityRatio]);

  return (
    <StyledInputGroup>
      {props.labelText && (
        <label
          className="new-password-input-label"
          htmlFor={passwordInputId}
          hidden={!props.labelText}
        >
          {props.labelText ? props.labelText : props.placeholder}
        </label>
      )}
      <StyledInputContainer>
        <StyledInput
          name={props.name}
          id={passwordInputId}
          type={unhidePassword ? 'text' : 'password'}
          onChange={props.onChange}
          value={props.value}
          className={`password ${valid && 'valid'} ${props.inValid && 'invalid'} status`}
          autoComplete="on"
          placeholder={props.placeholder}
          disabled={props.disabled}
          onBlur={props.onBlur}
        ></StyledInput>
        <StyledHideButton
          type="button"
          onClick={() => setUnhidePassword((prev) => !prev)}
          disabled={props.disabled}
        >
          {unhidePassword ? <UnhideIcon /> : <HideIcon />}
        </StyledHideButton>
      </StyledInputContainer>

      <StyledStatus className={cn(props.disabled && 'disabled')}>
        <StyledStatusbar>
          <StyledStatusbarFiller
            style={{ width: `${passwordSecurityRatio}%` }}
            className={cn(passwordSecurityStatus)}
          />
        </StyledStatusbar>
        {
          <StyledStatusString>
            {props.error && t(`forms.errors.${props.error}`)}
            {!props.error && statusStringConstructor(passwordSecurityRatio, passwordStatusString)}
          </StyledStatusString>
        }
      </StyledStatus>
    </StyledInputGroup>
  );
};
