import styled from 'styled-components';
export const StyledWorkersListFilters = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  width: 3.2rem;
  height: 3.2rem;

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  border-radius: 5px;

  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

  @media screen and (min-width: 768px) {
    width: 4.1rem;
    height: 4.1rem;

    padding: 0.8rem;
  }

  @media screen and (min-width: 1920px) {
    width: auto;
  }

  &:has(.filters-list-toggle:checked) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      border: 1px solid
        ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    }
  }

  .filters-list-toggle {
    position: absolute;
    width: 0;
    height: 0;
    border: 0;

    visibility: hidden;

    @media screen and (min-width: 1920px) {
      display: none;
    }

    &:checked ~ .filters-list-toggle-label {
      .open {
        display: none;
      }
      .close {
        display: block;
      }
    }
    &:checked ~ .workers-filter-container {
      &.smallest {
        display: block;
      }
      @media screen and (min-width: 768px) {
        display: block;
        &.smallest {
          display: none;
        }
      }
      @media screen and (min-width: 1920px) {
        display: flex;
      }
    }
  }

  .filters-list-toggle-label {
    display: block;

    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent1)};

    & > * {
      width: 2.4rem;
      height: 2.4rem;
    }

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent1)};
    }

    cursor: pointer;

    @media screen and (min-width: 1920px) {
      display: none;
    }

    .open {
      display: block;

      *[stroke] {
        stroke: currentColor;
      }
    }

    .close {
      display: none;

      *[fill] {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent4)};
      }
    }
  }

  .workers-filter-container {
    position: absolute;
    bottom: 0;
    right: -1px;
    z-index: 1;

    display: none;

    padding: 0.7rem 1.5rem;

    border: inherit;
    border-radius: 5px 0px 5px 5px;

    background-color: inherit;

    transform: translateY(100%);

    @media screen and (min-width: 1920px) {
      position: relative;

      display: flex;
      align-items: center;
      gap: 3rem;

      padding: unset;

      border: none;

      transform: unset;
    }

    &.smallest {
      display: none;

      min-width: 16.2rem;

      padding: 1.2rem 1.5rem;

      .workers-filter-heading {
        font-size: 1.2rem;
        line-height: 150%;
        font-weight: 400;
        text-align: start;
        color: ${(props) => props.theme.accent2};
      }
    }
  }
  .hint-container {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    z-index: 1;

    @media screen and (min-width: 1920px) {
      display: block;
    }

    .hint-dropdown {
      position: absolute;
      left: 0;

      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      width: 45rem;

      padding: 3rem 2.5rem;

      border-radius: 15px;
      border: 1px solid
        ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};

      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 500;

      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2};

      transform: translateX(-100%);

      &-heading {
        color: ${(props) => props.theme.accent1};
      }

      &-text {
        font-family: Mulish;
        font-size: 1.6rem;
        line-height: 150%;
        font-weight: 400;
      }
    }
  }

  .workerslist-filter-radio-input-group {
    display: flex;
    align-items: center;

    height: 4.1rem;

    @media screen and (min-width: 1920px) {
      height: auto;
    }

    .workerslist-filter_input {
      position: absolute;
      top: 0;

      width: 0;
      height: 0;

      border: none;

      opacity: 0;
    }

    .workerslist-filter-label {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      font-size: 1.6rem;
      font-weight: 400;
      line-height: 150%;

      cursor: pointer;

      color: ${(props) => props.theme.text1};

      &:hover,
      &.checked {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2)};
        .radio-icon {
          color: currentColor;
        }
      }
      &.checked {
        .radio-icon {
          > * {
            *[fill] {
              fill: currentColor;
            }
          }
        }
      }

      .sup {
        margin-top: -0.5rem;

        font-size: 1rem;
        vertical-align: super;
      }

      .radio-icon {
        display: flex;
        align-items: center;

        width: 2rem;
        height: 2rem;

        color: ${(props) => props.theme.text1};

        > * {
          width: 100%;
          height: 100%;

          transition: inherit;
          fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg1)};
          *[stroke] {
            stroke: currentColor;
          }
          *[fill] {
            fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg1)};
          }
        }
      }
    }
  }
`;
