import { RoundButton } from 'components';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-icon.svg';

import styled from 'styled-components';

const StyledIconContainer = styled.div`
  width: 2.7rem;
  height: 2.4rem;
  padding-right: 0.3rem;
`;

interface ILogoutButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
export const LogoutButton = (props: ILogoutButtonProps) => {
  return (
    <RoundButton {...props}>
      <StyledIconContainer>
        <LogoutIcon />
      </StyledIconContainer>
    </RoundButton>
  );
};
