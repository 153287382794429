import styled from 'styled-components';
export const StyledFilterRadioElement = styled.div`
  height: 3.8rem;
  display: flex;
  align-items: center;

  @media screen and (min-width: 1920px) {
    height: auto;
  }

  .filter-radio-element-input {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    opacity: 0;
    border: none;
  }

  .filter-radio-element-label {
    cursor: pointer;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */

    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: ${(props) => props.theme.text1};

    &:hover,
    &.checked {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2)};
      .radio {
        color: currentColor;
      }
    }
    &.checked {
      .radio-icon {
        *[fill] {
          fill: currentColor;
        }
      }
      .radio {
        > * {
          *[fill] {
            fill: currentColor;
          }
        }
      }
    }

    .radio-icon {
      display: block;
      width: 2.1rem;
      height: 2.1rem;
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg1)};

      color: currentColor;

      @media screen and (min-width: 1920px) {
        width: 1.8rem;
        height: 1.8rem;
      }

      *[stroke] {
        stroke: currentColor;
        transition: inherit;
      }
      *[fill] {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg1)};
        transition: inherit;
      }
    }

    .radio {
      width: 2.1rem;
      height: 2.1rem;
      @media screen and (min-width: 1920px) {
        width: 1.8rem;
        height: 1.8rem;
      }
      color: ${(props) => props.theme.text1};
      transition: all 0.2s;
      display: flex;
      align-items: center;

      > * {
        width: 100%;
        height: 100%;
        transition: inherit;
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg1)};
        *[stroke] {
          stroke: currentColor;
          transition: inherit;
        }
        *[fill] {
          fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg1)};
          transition: inherit;
        }
      }
    }

    .sup {
      vertical-align: super;
      font-size: 1rem;
      margin-top: -0.5rem;
    }
  }
`;
