import cn from 'classnames';
import { Button, CustomSearch } from 'components';
import { AdminList } from '../AdminList';
import { StyledAdminEditEquipmentGroups } from './styles';
import { DataExportButton } from '../DataExportButton';
import { useTranslation } from 'react-i18next';
import { StyledAdminEditEquipmentGroupsHeader } from './styles/StyledAdminEditEquipmentGroupsHeader';
import { AdminListCheckbox } from '../AdminListCheckbox';
import { useState } from 'react';
import { AdminListHeaderCell } from '../AdminListHeaderCell';
import { AdminListRowCell } from '../AdminListRowCell';
import { StyledEditEquipmentGroupsListRow } from './styles/StyledEditEquipmentGroupsListRow';
interface IAdminEditEquipmentGroupsProps {
  children?: React.ReactNode;
}
export const AdminEditEquipmentGroups = ({ children }: IAdminEditEquipmentGroupsProps) => {
  const { t } = useTranslation();
  return (
    <StyledAdminEditEquipmentGroups>
      <AdminList
        renderControls={() => (
          <div className="admin-edit-groups-controls">
            <Button title={t('adminPage.editGroups.changeGroup')} />
            <div className="admin-edit-groups-controls__right">
              <CustomSearch />
              <DataExportButton />
            </div>
          </div>
        )}
      >
        <AdminEditEquipmentGroupsHeader />
        <AdminEditEquipmentGroupsListRow />
        <AdminEditEquipmentGroupsListRow />
      </AdminList>
    </StyledAdminEditEquipmentGroups>
  );
};

const adminEditEquipmentGroupsHeaderTitles = [
  'id',
  'link',
  'group',
  'current',
  'test',
  'hour',
  'day',
  'week',
];
const AdminEditEquipmentGroupsHeader = () => {
  const [selected, setSelected] = useState<boolean>(false);

  const { t } = useTranslation();

  const selectRowHandler = () => {
    setSelected((prev) => !prev);
  };
  return (
    <StyledAdminEditEquipmentGroupsHeader>
      <div className="admin-list-header-cell-container select">
        <AdminListCheckbox checked={selected} onChange={selectRowHandler} />
      </div>
      {adminEditEquipmentGroupsHeaderTitles.map((title) => (
        <div key={title} className={cn('admin-list-header-cell-container', title.toLowerCase())}>
          <AdminListHeaderCell filter={title !== 'comment'}>
            {t(`adminPage.editGroups.listHeaders.${title}`)}
          </AdminListHeaderCell>
        </div>
      ))}
    </StyledAdminEditEquipmentGroupsHeader>
  );
};

const AdminEditEquipmentGroupsListRow = () => {
  const [selected, setSelected] = useState<boolean>(false);

  const selectRowHandler = () => {
    setSelected((prev) => !prev);
  };

  const data: { [id: string]: string } = {
    id: '000035',
    link: 'link',
    group: '< M30 whatsminer',
    current: 'S19 Bitmain',
    test: '1000',
    hour: '1000',
    day: '1000',
    week: '1000',
  };
  return (
    <StyledEditEquipmentGroupsListRow>
      <div className="admin-list-cell-container select">
        <AdminListCheckbox checked={selected} onChange={selectRowHandler} />
      </div>
      {adminEditEquipmentGroupsHeaderTitles.map((title) => (
        <div key={title} className={cn('admin-list-cell-container', title.toLowerCase())}>
          <AdminListRowCell>{data[title]}</AdminListRowCell>
        </div>
      ))}
    </StyledEditEquipmentGroupsListRow>
  );
};
