import { ReactComponent as OkStatusIcon } from 'assets/icons/ok-status-button.svg';
import { ReactComponent as NotOkStatusIcon } from 'assets/icons/not-ok-status-icon.svg';
import { IPayout } from 'types';
import { StyledPayoutListElement } from './styles';
import { formateDate, formateTime } from 'utils';
interface IPayoutListElementProps extends IPayout {
  children?: React.ReactNode;
}
export const PayoutListElement = ({ id, amount, date, confirmed }: IPayoutListElementProps) => {
  const elementDate = new Date(date);
  const formatedDate = formateDate(elementDate);
  const formatedTime = formateTime(elementDate);
  return (
    <StyledPayoutListElement>
      <div className="payout-list-element-col date-time">
        {formatedDate}
        {<span className="time">{`-${formatedTime}`}</span>}
      </div>
      <div className="payout-list-element-col amount">{amount}</div>
      <div className="payout-list-element-col id">
        <a
          href={`${process.env.REACT_APP_BLOCK_CHAIN_EXPLORER_GATEWAY}/${id}`}
          target="_blank"
          rel="noreferrer"
        >
          {id}
        </a>
      </div>
      <div className="payout-list-element-col confirmation">
        <div className="status-icon">{confirmed ? <OkStatusIcon /> : <NotOkStatusIcon />}</div>
      </div>
    </StyledPayoutListElement>
  );
};
