import styled from 'styled-components';
export const StyledPayoutListHeader = styled.header`
  display: flex;

  margin-bottom: 0.2rem;

  .payouts-list-header-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-basis: 17rem;
    gap: 0.5rem;

    padding: 0.4rem;

    border-right: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};

    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
    text-align: center;

    background-color: ${(props) => props.theme.bg1};

    & *:first-child {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    @media screen and (min-width: 768px) {
      &.amount {
        flex-basis: 14rem;
        flex-shrink: 0;
      }
      &.id {
        flex-basis: 20rem;
        flex-shrink: 0;
        flex-grow: 1;
      }

      &.confirmation {
        flex-basis: 9rem;
        flex-shrink: 0;
      }
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.4rem;
      &[class] {
        flex-basis: 36.1rem;
        flex-shrink: 1;
      }
    }

    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-right: none;
    }
  }

  .sort-by-button {
    display: flex;
    align-items: center;

    width: 1rem;
    height: 0.7rem;

    margin: auto;
    margin-top: auto;

    color: inherit;

    > * {
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
  }
`;
