import { useEffect, useState } from 'react';
import {
  StyledCloseButton,
  StyledQRCodeDialog,
  StyledQRDialogContainer,
  StyledQRDialogOverlay,
} from './styles';
import { ReactComponent as CloseIcon } from 'assets/icons/close-cross.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
interface IQRCodeDialogProps {
  children?: React.ReactNode;
  onClose: () => void;
  show?: boolean;
}
export const QRCodeDialog = ({ onClose, show }: IQRCodeDialogProps) => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(false);
  const closeHandler = () => {
    onClose();
    setShowContent(false);
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowContent(() => true), 100);
    }
  }, [show]);
  return (
    <StyledQRDialogContainer>
      <StyledQRDialogOverlay onClick={closeHandler} className={cn(showContent && 'show')} />
      {showContent && (
        <StyledQRCodeDialog open={showContent}>
          <StyledCloseButton onClick={closeHandler}>
            <CloseIcon />
          </StyledCloseButton>
          <h2 className="qr-dialog-heading">{t('modals.qr.heading')}</h2>
          <div className="qr-dialog-code-container">
            <img src={'/images/examp-qr.png'} alt="qr code" />
          </div>
        </StyledQRCodeDialog>
      )}
    </StyledQRDialogContainer>
  );
};
