import styled from 'styled-components';
export const StyledUsersListHeader = styled.div`
  display: flex;
  background-color: green;

  width: fit-content;

  border-radius: 6px;
  background-color: ${(props) => props.theme.bg1};

  height: 5rem;

  margin-bottom: 0.9rem;

  .list-col-element {
    &.header {
      padding: 0.4rem 0.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.2rem;
      overflow: visible;

      &.link {
        padding-right: 0;
      }
    }

    position: relative;
    overflow: unset;
    .list-col-element-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .list-col-element-filter {
      &:hover,
      &.active {
        color: ${(props) => props.theme.button3};

        .list-col-element-filter-button {
          color: ${(props) => props.theme.button3};
        }
      }
      display: flex;
      align-items: center;
      .list-col-element-filter-button {
        width: 1.8rem;
        height: 1.8rem;
        display: block;

        .button-icon {
          width: 100%;
          height: 100%;
          * [fill] {
            fill: currentColor;
          }
        }
      }

      .list-col-element-filter-body {
        position: absolute;
        z-index: 1000;
        border-radius: 8px;
        border: 2px solid ${(props) => props.theme.button3};

        background-color: ${(props) => props.theme.bg4};
        box-shadow: 0px 17px 44.8px -12px #000;
        color: white;

        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 100%);
        &.left {
          left: 0;
          transform: translate(0, 100%);
        }

        .list-col-element-filter-body-element {
          cursor: pointer;
          max-width: 23rem;
          padding: 6px 16px;

          &:not(:last-child) {
            border-bottom: 1px solid ${(props) => props.theme.button2};
          }
          overflow: hidden;
          font-feature-settings:
            'clig' off,
            'liga' off;
          text-overflow: ellipsis;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            background: var(--accent-20, rgba(74, 36, 165, 0.2));
          }
        }
      }
    }
  }
  .select-checkbox {
    width: 3rem;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-input {
      position: absolute;
      width: 0;
      height: 0;
      border: none;
      visibility: hidden;
    }

    &-label {
      width: 1.8rem;
      height: 1.8rem;
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: 2px solid ${(props) => props.theme.button2};
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
        *[stroke] {
          stroke: transparent;
        }
        *[fill] {
          fill: transparent;
        }
      }
      &.checked {
        svg {
          *[stroke] {
            stroke: white;
          }
          *[fill] {
            fill: ${(props) => props.theme.button2};
          }
        }
      }
    }
  }
  .id {
    width: 7.6rem;
  }
  .email {
    width: 9.4rem;
  }
  .cost {
    width: 8rem;
  }
  .adjust {
    width: 9.8rem;
  }
  .link {
    width: 5.2rem;
  }
  .wallet {
    width: 14.4rem;
  }
  .equipment-group {
    width: 17.1rem;
  }
  .current-group {
    width: 17.2rem;
  }
  .vip {
    width: 6.4rem;
  }
  .daily-hashrate {
    width: 17.2rem;
  }
  .weekly-hashrate {
    width: 17.2rem;
  }

  .created-at {
    width: 16.4rem;
  }
  .updated-at {
    width: 16.4rem;
  }
  .comments {
    width: 22.8rem;
  }
`;
