import { useEffect, useState } from 'react';
import cn from 'classnames';
import { StyledPaginator } from './styles';
import { ReactComponent as ArrowBack } from 'assets/icons/pagination-arrow-back-icon.svg';
import { ReactComponent as ArrowForward } from 'assets/icons/pagination-arrow-forward-icon.svg';

const generatePageNumbers = (
  pageQuantity: number,
  currentPage: number,
): { first: number | null; pages: number[]; last: number | null } => {
  if (pageQuantity <= 5)
    return { first: null, last: null, pages: [...Array(pageQuantity)].map((_, i) => i + 1) };
  const first = currentPage - 1 >= 3 ? 1 : null;
  const last = pageQuantity - currentPage >= 3 ? pageQuantity : null;

  const pages: number[] = [];

  switch (true) {
    case currentPage - 1 < 2:
      pages.push(...[...Array(pageQuantity)].map((_, i) => i + 1).slice(0, 3));
      break;
    case currentPage - 1 < 3:
      pages.push(...[...Array(pageQuantity)].map((_, i) => i + 1).slice(0, 4));
      break;
    case pageQuantity - currentPage < 2:
      pages.push(pageQuantity - 2, pageQuantity - 1, pageQuantity);
      break;
    case pageQuantity - currentPage < 3:
      pages.push(pageQuantity - 3, currentPage, pageQuantity - 1, pageQuantity);
      break;

    default:
      pages.push(currentPage - 1, currentPage, currentPage + 1);
  }

  return { first, pages, last };
};

interface IPaginatorProps {
  children?: React.ReactNode;
  pageQuantity: number;
  onChange: (currentPage: number) => void;
  currentPage: number;
}

export const Paginator = ({ onChange, currentPage, pageQuantity }: IPaginatorProps) => {
  const [localCurrentPage, setLocalCurrentPage] = useState(currentPage);

  const pages = generatePageNumbers(pageQuantity, localCurrentPage);

  const changeLocalCurrentPageHandler = (pageNum: number) => () => {
    setLocalCurrentPage(pageNum);
    onChange(pageNum);
  };
  const pageDownHandler = () => {
    if (localCurrentPage === 1) return;
    const previousPage = localCurrentPage - 1;
    setLocalCurrentPage(() => previousPage);
    onChange(previousPage);
  };
  const pageUpHandler = () => {
    if (localCurrentPage === pageQuantity) return;
    const nextPage = localCurrentPage + 1;
    setLocalCurrentPage(() => nextPage);
    onChange(nextPage);
  };

  useEffect(() => {
    setLocalCurrentPage(currentPage);
  }, [currentPage]);

  return (
    <StyledPaginator>
      <button className="page-arrow back" onClick={pageDownHandler}>
        <ArrowBack />
      </button>
      <div className="pages">
        {pages.first && (
          <span
            className={cn('pageNumber', localCurrentPage === pages.first && 'current')}
            onClick={changeLocalCurrentPageHandler(pages.first)}
          >{`${pages.first} ...`}</span>
        )}{' '}
        {pages.pages.map((e) => (
          <span
            key={e}
            className={cn('pageNumber', localCurrentPage === e && 'current')}
            onClick={changeLocalCurrentPageHandler(e)}
          >
            {e}
          </span>
        ))}{' '}
        {pages.last && (
          <span
            className={cn('pageNumber', localCurrentPage === pages.last && 'current')}
            onClick={changeLocalCurrentPageHandler(pages.last)}
          >{`... ${pages.last}`}</span>
        )}
      </div>

      <button className="page-arrow forward" onClick={pageUpHandler}>
        <ArrowForward />
      </button>
    </StyledPaginator>
  );
};
