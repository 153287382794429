import { Input } from './Input';
import { StyledEmailInput } from './styles';

interface IEmailInputProps {
  placeholder?: string;
  labelText?: string;
}
export const EmailInput = ({ labelText, placeholder }: IEmailInputProps) => {
  return (
    <StyledEmailInput>
      {labelText && <label className="email-input-label">{labelText}</label>}
      <Input placeholder={placeholder} />
    </StyledEmailInput>
  );
};
