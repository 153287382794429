import styled from 'styled-components';
export const StyledUsersListRow = styled.div`
  display: flex;

  width: fit-content;

  border-radius: 6px;
  background-color: ${(props) => props.theme.bg4};

  padding: 0.4rem 0;
  height: 3.2rem;

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }
  .list-col-element {
    flex-shrink: 0;
    position: relative;

    &:has(.hint):hover {
      overflow: visible;
      white-space: nowrap;
      .hint {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        background-color: ${(props) => props.theme.bg1};
        border: 1px solid ${(props) => props.theme.button2};
        padding: 0.2rem 0.5rem;
        height: 100%;
        width: max-content;
      }
    }
  }
  .select-checkbox {
    width: 3rem;
    position: relative;
    display: flex;
    align-items: center;

    &-input {
      position: absolute;
      width: 0;
      height: 0;
      border: none;
      visibility: hidden;
    }

    &-label {
      width: 1.8rem;
      height: 1.8rem;
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: 2px solid ${(props) => props.theme.button2};

      svg {
        width: 100%;
        height: 100%;
        *[stroke] {
          stroke: transparent;
        }
        *[fill] {
          fill: transparent;
        }
      }
      &.checked {
        svg {
          *[stroke] {
            stroke: white;
          }
          *[fill] {
            fill: ${(props) => props.theme.button2};
          }
        }
      }
    }
  }
  .id {
    width: 7.6rem;
  }
  .email {
    width: 9.4rem;
  }
  .cost {
    width: 8rem;
  }
  .adjust {
    width: 9.8rem;
  }
  .link {
    width: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.button3};

    &:hover {
      color: ${(props) => props.theme.button1};
    }

    .list-col-element-link {
      display: flex;
      align-items: center;
      color: currentColor;
    }
    .globe-icon {
      width: 2.4rem;
      height: 2.4rem;

      *[stroke] {
        stroke: currentColor;
      }
      *[fill] {
        fill: currentColor;
      }
    }
  }
  .wallet {
    width: 14.4rem;
  }
  .equipment-group {
    width: 17.1rem;
  }
  .current-group {
    width: 17.2rem;
  }
  .vip {
    width: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .vip-status-icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .daily-hashrate {
    width: 17.2rem;
  }
  .weekly-hashrate {
    width: 17.2rem;
  }

  .created-at {
    width: 16.4rem;
  }
  .updated-at {
    width: 16.4rem;
  }
  .comments {
    width: 22.8rem;
  }
`;
