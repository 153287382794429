import styled from 'styled-components';

export const StyledContent = styled.div`
  .settings-main {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    margin-bottom: 2.4rem;

    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 1440px) {
      margin-bottom: 3.6rem;
    }
  }

  .settings-aux {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    max-width: 43rem;

    margin: auto;
    margin-bottom: 2.4rem;

    @media screen and (min-width: 768px) {
      margin-left: 0;
    }

    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      row-gap: 3rem;

      max-width: 100%;
    }
  }
`;
