import styled from 'styled-components';
import { StyledAdminListRow } from '../../AdminListHeaderCell/styles/StyledAdminListRow';

export const StyledAdminEditPaymentsRow = styled.div`
  ${StyledAdminListRow}

  .admin-list-cell-container {
    &.adjust {
      width: 19rem;
      flex-grow: initial;
    }
  }
`;
