import { IPayout, TSortPayoutByHeaderTitle } from 'types';
import { PayoutListElement } from '../PayoutListElement';
import { PayoutListHeader } from '../PayoutListHeader';
import { StyledPayoutList } from './styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPayouts } from 'store/slices/userPayoutSlice';
interface IPayoutListProps {
  children?: React.ReactNode;
  onSort: (sortBy: { sortBy: keyof IPayout; incr: boolean }) => void;
}

export const PayoutList = ({ onSort }: IPayoutListProps) => {
  const [sortIncr, setSortIncr] = useState(true);
  const sortHandler = (sortBy: TSortPayoutByHeaderTitle) => {
    onSort({ sortBy, incr: !sortIncr });
    setSortIncr((prev) => !prev);
  };

  const payouts = useSelector(selectPayouts);

  return (
    <StyledPayoutList>
      <PayoutListHeader onSort={sortHandler} sortIncr={sortIncr} />
      {payouts.map((payout) => (
        <PayoutListElement key={payout.id} {...payout} />
      ))}
    </StyledPayoutList>
  );
};
