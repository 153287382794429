import { useState } from 'react';
import { StyledToggler } from './styles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
interface ITogglerProps {
  children?: React.ReactNode;
}
export const Toggler = ({ children }: ITogglerProps) => {
  const { t } = useTranslation();
  const [isOn, setIsOn] = useState(false);
  const toggleHandler = () => {
    setIsOn((prev) => !prev);
  };
  return (
    <StyledToggler className={cn('toggler', isOn && 'active')} onClick={toggleHandler}>
      <span className="toggler_label">{t(`toggler.${isOn ? 'on' : 'off'}`)}</span>
    </StyledToggler>
  );
};
