import { Layout } from 'components';
import { BlogCard } from 'components/BlogCard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { darkTheme } from 'themes';
import { v4 as uuid } from 'uuid';

const StyledContent = styled.div`
  max-width: 32rem;
  padding: 1.5rem 1rem 4rem;
  margin: auto;

  @media screen and (min-width: 768px) {
    max-width: 76.8rem;
    padding: 4rem 3.4rem 12rem;
  }

  @media screen and (min-width: 1024px) {
    max-width: 102.4rem;
    padding: 4rem 3.7rem 12rem;
  }

  @media screen and (min-width: 1440px) {
    max-width: 132rem;
    display: flex;
    gap: 10rem;
    padding: 4rem 0rem 12rem;
  }

  .article-content {
    @media screen and (min-width: 1440px) {
      max-width: 69.5rem;
    }
    @media screen and (min-width: 1920px) {
      max-width: 77rem;
    }
  }
  .article-heading {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    letter-spacing: 0.18px;
    margin-bottom: 0.6rem;

    @media screen and (min-width: 768px) {
      font-size: 3.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 52.5px */
      margin-bottom: 1rem;
    }
  }

  .article-info {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1)};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    margin-bottom: 1.2rem;
    & > :not(:last-child) {
      margin-bottom: 0.5rem;
    }
    @media screen and (min-width: 768px) {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 27px */
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 4.5rem;
    }
  }
  .article-image-container {
    border-radius: 6px 6px 0px 0px;
    overflow: hidden;
    margin-bottom: 1.2rem;
    @media screen and (min-width: 1024px) {
      margin-bottom: 4.5rem;
    }
  }
  .article-image {
    width: 100%;
  }
  .article-paragraphs {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 150%; /* 18px */
    margin-bottom: 4rem;
    & > :not(:last-child) {
      margin-bottom: 1.5rem;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
      line-height: 150%; /* 27px */
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 8rem;
    }
  }

  .blog-container {
    @media screen and (min-width: 1440px) {
      overflow: hidden;
      width: 45.6rem;
      flex-shrink: 0;
    }

    .blog-header {
      background-image: url('/images/bg/blog-folder-xs.svg');
      padding: 2.5rem 1rem 0;
      background-repeat: no-repeat;
      .blog-heading {
        font-size: 2.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 37.5px */
      }
      @media screen and (min-width: 768px) {
        background-image: url('/images/bg/blog-folder-sm.svg');
        padding: 3rem 2.5rem 0;
        .blog-heading {
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 52.5px */
        }
      }
      @media screen and (min-width: 1024px) {
        background-image: url('/images/bg/blog-folder-md.svg');
      }
      @media screen and (min-width: 1440px) {
        background-image: url('/images/bg/article-folder-lg.svg');
        padding: 2.5rem 3rem 0;
      }
    }
  }

  .blog-content {
    background-color: ${darkTheme.button3};
    padding: 1.2rem 1rem 1.5rem;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    @media screen and (min-width: 768px) {
      padding: 2.4rem 3rem;
      gap: 2.5rem;
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
    @media screen and (min-width: 1440px) {
      flex-wrap: wrap;
    }
  }
`;

export const ArticlesPage = () => {
  const [queryParam] = useSearchParams();
  const articleId = queryParam.get('id');

  const articleData = {
    title: 'Mining Prospects in Kyrgyzstan and Life in the Metaverse...',
    readingTime: 'Read about 3 minutes',
    date: '05.09.2022',
    image: '/images/blog/43edae8886dd8f20ff1cffb903d153b4.webp',
    paragraphs: [
      '“Digital Literacy 3.0” - the first blockchain forum to ever be held in Bishkek - happened on the 23rd of April, and focused on developing the technology and integrating it into everyday life as well as the business environment. Representatives of both government agencies and the creative blockchain industry as well as entrepreneurs, investors, and IT specialists came together to discuss the development prospect and the future of mining in Kyrgyzstan. The forum was organized by the business club “Entrepreneur” and the country’s Ministry of Digital Development.',
      'CBDO of EMCD Vyacheslav Nekrasov spoke about the possibilities for the development of mining in the Kyrgyz Republic. He expressed the opinion that there is huge potential for the country in terms of the development of the mining industry. In his words, renewable energy and the country’s natural resources are what provide these opportunities.',
      'According to UN data as well as from the Ministry of Energy of Kyrgyzstan, the republic currently only uses about 1.5% of the total potential power of small hydropower plants. Which leaves a huge amount of water resources available to be used for mining.',
      'Nekrasov noted that in order to develop the industry, they need investors who are interested in the construction of hydroelectric power stations to come to the country, but there has been some difficulty in this. Due to low taxes set for the population, returns on construction are not seen immediately, but are stretched out over a number of years.',
      'However, mining would allow investors to invest in projects that initially may have seemed unattractive to them. The fact is, in Kyrgyzstan there are regions where there is a shortage of electricity.',
    ],
  };

  const blogArticles: { image: string; header: string; text: string; date: string }[] = [
    {
      image: '/images/blog/43edae8886dd8f20ff1cffb903d153b4.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
  ];
  const navigate = useNavigate();

  const blogArticleHandler = (articleId: string) => () => {
    navigate(`/articles?id=${articleId}`);
  };
  const { t } = useTranslation();
  return (
    <Layout home>
      <StyledContent>
        <div className="article-content">
          <h1 className="article-heading">{articleData.title}</h1>
          <div className="article-info">
            <div className="article-reading-time">{articleData.readingTime}</div>
            <div className="article-date">{articleData.date}</div>
          </div>
          <div className="article-image-container">
            <img className="article-image" src={articleData.image} />
          </div>
          <div className="article-paragraphs">
            {articleData.paragraphs.map((paragrapth) => (
              <p key={uuid()} className="article-paragraph">
                {paragrapth}
              </p>
            ))}
          </div>
        </div>
        <div className="blog-container">
          <div className="blog-header">
            <h1 className="blog-heading">{t('articlesPage.blogSectionHeading')}</h1>
          </div>
          <div className="blog-content">
            {blogArticles.map((blogArticle, index) => (
              <BlogCard
                key={blogArticle.header}
                {...blogArticle}
                onClick={blogArticleHandler(String(index))}
              />
            ))}
          </div>
        </div>
      </StyledContent>
    </Layout>
  );
};
