import { StyledButton } from 'components/shared/Button/styles';
import styled from 'styled-components';
export const StyledAdminCalculatePayments = styled.div`
  flex-basis: 33%;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2.4rem;
    @media screen and (min-width: 1440px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .heading {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 150%; /* 18px */
    margin-bottom: 0.8rem;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 150%; /* 24px */
    }

    @media screen and (min-width: 1440px) {
      font-size: 2rem;
      line-height: 150%; /* 30px */
    }
  }

  .server-time {
    font-size: 1rem;
    font-weight: 400;

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
      line-height: 150%; /* 24px */
    }
    @media screen and (min-width: 1440px) {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: normal;
    }
  }
  ${StyledButton} {
    padding: 1rem 2rem;
    border-radius: 9px;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 150%; /* 18px */
    text-transform: capitalize;

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
    @media screen and (min-width: 1440px) {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }
  }
`;
