import { StyledOutlinedButton } from 'components/shared/OutlinedButton/styles';
import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledSupportRefs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }

  @media screen and (min-width: 1024px) {
    gap: 2rem;
  }

  .support-help,
  .support-media {
    position: relative;

    width: 30rem;
    height: 20rem;

    @media screen and (min-width: 768px) {
      width: 34.5rem;
      height: 31.6rem;
    }

    @media screen and (min-width: 1024px) {
      width: 46.5rem;
      height: 39.6rem;
    }

    @media screen and (min-width: 1440px) {
      width: 65rem;
      height: 48rem;
    }
  }

  .support-media {
    padding-top: 10.7rem;

    color: ${darkTheme.text3};

    background-image: url('/images/bg/support-folder-yellow-dark-xs.svg');
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px) {
      padding-top: 5.2rem;

      background-image: url('/images/bg/support-folder-yellow-dark-sm.svg');
    }

    @media screen and (min-width: 1024px) {
      padding-top: 8.9rem;

      background-image: url('/images/bg/support-folder-yellow-dark-md.svg');
    }
    @media screen and (min-width: 1440px) {
      padding-top: 8.5rem;

      background-image: url('/images/bg/support-folder-yellow-dark-lg.svg');
    }

    .support-media-girl {
      position: absolute;
      top: 1.3rem;
      left: 2.9rem;
      width: 6.2rem;
      height: 8.4rem;

      @media screen and (min-width: 768px) {
        margin: auto;
        position: relative;
        top: 0;
        left: 0;
        width: 14rem;
        height: 12.8rem;
        margin-bottom: 2rem;
      }

      @media screen and (min-width: 1024px) {
        width: 18rem;
        height: 14.2rem;
        margin-bottom: 3rem;
      }

      @media screen and (min-width: 1440px) {
        width: 31.5rem;
        height: 19rem;
        margin-bottom: 3.5rem;
      }

      .support-media-girl-image {
        display: block;
        height: 100%;
        @media screen and (min-width: 768px) {
          margin: auto;
        }
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        width: 9.2rem;
        border-bottom: 2px solid ${darkTheme.text3};
        @media screen and (min-width: 768px) {
          width: 100%;
        }
      }
    }

    .support-media-heading {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      line-height: 150%; /* 27px */
      margin-bottom: 0.8rem;
      @media screen and (min-width: 768px) {
        margin-bottom: 2rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 2.8rem;
        line-height: 140%; /* 39.2px */
      }

      @media screen and (min-width: 1440px) {
        font-size: 3.5rem;
        line-height: 150%; /* 52.5px */
        margin-bottom: 2.5rem;
      }
    }
    .support-media-buttons {
      display: flex;
      justify-content: center;
      gap: 0.4rem;
      color: currentColor;
      @media screen and (min-width: 768px) {
        gap: 1rem;
      }

      .support-media-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.1rem;
        height: 4.1rem;
        background-color: currentColor;
        border-radius: 100%;

        .support-media-button-icon {
          fill: ${darkTheme.accent1};
        }
      }
    }
  }

  .support-help {
    background-image: url('/images/bg/support-folder-purple-dark-xs.svg');
    background-repeat: no-repeat;
    background-size: cover;
    color: ${darkTheme.accent1};
    padding-top: 9.8rem;

    @media screen and (min-width: 768px) {
      padding-top: 6.2rem;

      background-image: url('/images/bg/support-folder-purple-dark-sm.svg');
    }

    @media screen and (min-width: 1024px) {
      padding-top: 8.9rem;

      background-image: url('/images/bg/support-folder-purple-dark-md.svg');
    }

    @media screen and (min-width: 1440px) {
      padding-top: 8.5rem;

      background-image: url('/images/bg/support-folder-purple-dark-lg.svg');
    }

    .support-help-guy {
      position: absolute;
      top: 1.3rem;
      right: 2.8rem;
      width: 10.2rem;
      height: 7.5rem;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        width: 9rem;
        border-bottom: 2px solid ${darkTheme.text3};
      }

      .support-help-guy-image {
        height: 100%;
      }
      @media screen and (min-width: 768px) {
        position: relative;
        width: 16rem;
        height: 12rem;
        top: 0;
        left: 0;
        transform: none;
        margin: auto;
        margin-bottom: 2rem;

        &::before {
          width: 100%;
        }
      }
      @media screen and (min-width: 1024px) {
        width: 19rem;
        height: 14.1rem;

        margin-bottom: 3rem;
      }

      @media screen and (min-width: 1440px) {
        width: 25rem;
        height: 18.5rem;
        margin-bottom: 3.5rem;
      }
    }

    .support-help-heading {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
      text-align: center;
      margin-bottom: 0.8rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 2rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 28px;
        font-weight: 700;
        line-height: 140%; /* 39.2px */
      }

      @media screen and (min-width: 1440px) {
        font-size: 3.5rem;
        line-height: 150%; /* 52.5px */
        margin-bottom: 2.5rem;
      }
    }
    ${StyledOutlinedButton} {
      padding: 0.5rem;
      padding-left: 1.5rem;
      margin: auto;
      color: ${darkTheme.accent1};
    }
    .email-button-title {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .email-button-icon-container {
        width: 3.1rem;
        height: 3.1rem;
        background-color: ${darkTheme.accent1};
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
