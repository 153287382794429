import { FAQElement, Paginator } from 'components';
import { StyledFAQContainer } from './styles';
import { useMemo, useState } from 'react';

export const FAQContainer = () => {
  const DEFAULT_ITEMS_PER_PAGE_QUANTITY = 10;
  const DEFAULT_PAGE_NUMBER = 1;

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);

  const changeCurrentPageHandler = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  //TODO: fetch faq from the api
  //https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-7
  const faqs: { title: string; text: string }[] = [
    {
      title: 'How to start mining1?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining2?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining3?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
    {
      title: 'How to start mining?',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur sapiente maxime harum aut quas omnis consectetur, beatae ratione modi ipsa dolorem, ipsum vitae nostrum. Voluptates perferendis corrupti deleniti ducimus quaerat! Culpa sed enim facilis. Earum neque ad nihil facere aperiam cumque totam dolores dolorum. Cupiditate modi perferendis possimus asperiores minus!',
    },
  ];

  const paginatedRewards = useMemo(
    () =>
      faqs.slice(
        DEFAULT_ITEMS_PER_PAGE_QUANTITY * (currentPage - 1),
        DEFAULT_ITEMS_PER_PAGE_QUANTITY * currentPage,
      ),
    [faqs, currentPage],
  );

  const pagesQuanity = Math.ceil(faqs.length / DEFAULT_ITEMS_PER_PAGE_QUANTITY);
  return (
    <StyledFAQContainer>
      <div className="faqs-container">
        {paginatedRewards.map((faq, index) => (
          <FAQElement key={faq.title + index} text={faq.text} title={faq.title} />
        ))}
      </div>
      <div className="faq-paginator">
        <Paginator
          currentPage={currentPage}
          pageQuantity={pagesQuanity}
          onChange={changeCurrentPageHandler}
        />
      </div>
    </StyledFAQContainer>
  );
};
