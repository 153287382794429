import { StyledSecondaryButton } from 'components/shared/SecondaryButton/styled';
import styled from 'styled-components';
export const StyledAdminCostAdjustment = styled.div`
  flex-basis: 32%;
  flex-grow: 1;

  .heading {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    margin-bottom: 0.8rem;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 150%; /* 24px */
      margin-bottom: 1.8rem;
    }

    @media screen and (min-width: 1440px) {
      font-size: 2rem;
      line-height: 150%; /* 30px */
      margin-bottom: 2.4rem;
    }
  }

  ${StyledSecondaryButton} {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2)};
    border-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
    padding: 0.8rem 1.6rem;
    border-radius: 9px;

    font-size: 1.2rem;
    font-weight: 600;
    line-height: normal;

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg1 : props.theme.text2)};
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
    }

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
    @media screen and (min-width: 1440px) {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
  }

  .form {
    display: flex;
    gap: 0.8rem;

    @media screen and (min-width: 1440px) {
      align-items: center;
      gap: 1.4rem;
    }

    .input-group {
      flex-grow: 1;
      position: relative;
      @media screen and (min-width: 1024px) {
        max-width: 37rem;
      }
      @media screen and (min-width: 1920px) {
        max-width: 30vw;
      }
    }
    .input {
      display: block;
      width: 100%;
      height: 100%;

      border-radius: 6px;

      padding: 0.8rem;
      padding-right: 3.8rem;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &[type='number'] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
      }
      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
        line-height: normal;
      }
      @media screen and (min-width: 1440px) {
        font-size: 1.6rem;
        line-height: normal;
        padding: 1.6rem;
        padding-left: 1.6rem;
        padding-right: 4rem;
      }
    }
    .number-adjust {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      right: 0;
      padding: 1px 8px;

      &-button {
        display: block;
        color: ${(props) => props.theme.button3};

        &:hover {
          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.button1 : props.theme.text2};
        }

        .button-icon {
          *[stroke] {
            stroke: currentColor;
          }
        }
      }
    }
  }
`;
