import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, CustomSearch, SecondaryButton } from 'components';
import { AdminList } from '../AdminList';
import { StyledAdminEditUsers } from './styles';
import { DataExportButton } from '../DataExportButton';
import { StyledAdminUsersListHeader } from './styles/StyledAdminUsersListHeader';
import { StyledAdminUsersListRow } from './styles/StyledAdminUsersListRow';
import { AdminListCheckbox } from '../AdminListCheckbox';
import { AdminListHeaderCell } from '../AdminListHeaderCell';
import { AdminListRowCell } from '../AdminListRowCell';
interface IAdminEditUsersProps {
  children?: React.ReactNode;
}
export const AdminEditUsers = ({ children }: IAdminEditUsersProps) => {
  const { t } = useTranslation();
  return (
    <StyledAdminEditUsers>
      <AdminList
        renderControls={() => (
          <div className="admin-edit-users-controls">
            <Button title={t('adminPage.editUsers.changeCost')} />
            <Button title={t('adminPage.changeAdjust')} />
            <Button title={t('adminPage.editUsers.toggleVip')} />
            <SecondaryButton title={t('adminPage.editUsers.ban')} outline />
            <div className="admin-edit-users-controls__right">
              <CustomSearch />
              <DataExportButton />
            </div>
          </div>
        )}
      >
        <AdminUsersListHeader />
        <AdminUsersListRow />
        <AdminUsersListRow />
      </AdminList>
    </StyledAdminEditUsers>
  );
};

const adminUsersListHeadersTitles = [
  'id',
  'email',
  'cost',
  'adjust',
  'link',
  'wallet',
  'vip',
  'daily',
  'weekly',
  'created',
  'updated',
  'comment',
];
const AdminUsersListHeader = () => {
  const [selected, setSelected] = useState<boolean>(false);

  const { t } = useTranslation();

  const selectRowHandler = () => {
    setSelected((prev) => !prev);
  };
  return (
    <StyledAdminUsersListHeader>
      <div className="admin-list-header-cell-container select">
        <AdminListCheckbox checked={selected} onChange={selectRowHandler} />
      </div>
      {adminUsersListHeadersTitles.map((title) => (
        <div key={title} className={cn('admin-list-header-cell-container', title.toLowerCase())}>
          <AdminListHeaderCell filter={title !== 'comment'}>
            {t(`adminPage.editUsers.listHeaders.${title}`)}
          </AdminListHeaderCell>
        </div>
      ))}
    </StyledAdminUsersListHeader>
  );
};

const AdminUsersListRow = () => {
  const [selected, setSelected] = useState<boolean>(false);

  const selectRowHandler = () => {
    setSelected((prev) => !prev);
  };
  const data: { [id: string]: string } = {
    id: 'b4miner',
    email: 'b4miner@privatpools.com',
    cost: '255',
    adjust: '-1234',
    link: 'link',
    wallet: '1DedHf3UB2HFWp7hSPzDXEnicBbBGC4wWG',
    vip: 'vip',
    daily: '>1000<3000',
    weekly: '>1000<3000',
    created: '2023-06-02Т13:5',
    updated: '2023-06-02Т13:5',
    comment: 'some comment',
  };
  return (
    <StyledAdminUsersListRow>
      <div className="admin-list-cell-container select">
        <AdminListCheckbox checked={selected} onChange={selectRowHandler} />
      </div>
      {adminUsersListHeadersTitles.map((title) => (
        <div key={title} className={cn('admin-list-cell-container', title.toLowerCase())}>
          <AdminListRowCell>{data[title]}</AdminListRowCell>
        </div>
      ))}
    </StyledAdminUsersListRow>
  );
};
