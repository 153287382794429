import { StyledSecondaryButton } from 'components/shared/SecondaryButton/styled';
import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledBlogSection = styled.section`
  position: relative;

  margin-bottom: 9.2rem;

  color: ${darkTheme.text1};

  @media screen and (min-width: 768px) {
    margin-bottom: 14rem;
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 10rem;
  }

  .blog-background {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;

    width: 100dvw;
    height: 140rem;

    display: ${(props) => (props.theme.id === 'dark' ? 'none' : 'block')};

    background-image: url('/images/bg/lines-3.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .watch-all-button {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -1.2rem;
    transform: translateY(100%);

    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
      bottom: unset;
      top: 1rem;
      right: 0;
      left: unset;
      transform: translate(0);
    }
  }

  ${StyledSecondaryButton} {
    font-size: 1.2rem;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }

    &:hover {
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.accent2 : props.theme.button2};
      .button-icon {
        *[fill] {
          fill: currentColor;
        }
        *[stroke] {
          stroke: ${(props) =>
            props.theme.id === 'dark' ? props.theme.accent2 : props.theme.button2};
        }
      }
    }

    .button-icon {
      *[fill] {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.button2)};
      }
    }
  }
`;

export const StyledBlogContent = styled.div`
  position: relative;

  max-width: 30rem;
  height: 76.4rem;

  margin: auto;
  margin-bottom: 1.2rem;

  padding: 1.5rem 1rem;

  background-image: ${() => `url('/images/bg/main-hero-blog-folder-${'dark'}-xm.svg')`};
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (min-width: 768px) {
    max-width: 70rem;
    height: 91rem;

    padding: 3rem 3.5rem;

    background-image: ${() => `url('/images/bg/main-hero-blog-folder-${'dark'}-sm.svg')`};
  }
  @media screen and (min-width: 1024px) {
    max-width: 95rem;
    height: 78rem;

    padding: 2.5rem 3rem;

    background-image: ${() => `url('/images/bg/main-hero-blog-folder-${'dark'}-md.svg')`};
  }
  @media screen and (min-width: 1440px) {
    max-width: 132rem;
    height: 78rem;

    padding: 2.5rem 4rem;

    background-image: ${() => `url('/images/bg/main-hero-blog-folder-${'dark'}-lg.svg')`};
  }
`;

export const StyledHeading = styled.h3`
  margin-bottom: 0.2rem;

  font-size: 2.5rem;
  line-height: 150%;
  font-weight: 700;

  @media screen and (min-width: 768px) {
    margin-bottom: 0.5rem;

    font-size: 3.5rem;
  }
`;

export const StyledSubHeading = styled.h4`
  max-width: 43rem;

  margin-bottom: 2.4rem;

  font-size: 1.4rem;
  line-height: 150%;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    margin-bottom: 3rem;

    font-size: 1.8rem;
    font-weight: 500;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 5rem;
  }

  @media screen and (min-width: 1920px) {
    margin-bottom: 6rem;
  }
`;

export const StyledCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  > *:nth-child(n + 3) {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    justify-content: center;
    flex-direction: row;
    gap: 3.5rem;
  }

  @media screen and (min-width: 1440px) {
    > *:nth-child(3) {
      display: flex;
    }
  }
`;
