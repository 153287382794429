import styled from 'styled-components';
import { StyledUserBarNavElement } from '../../UserBarNavElement/styles';

export const getTransitionTime = () => '0.2s';
export const StyledUserBarMenu = styled.nav`
  position: relative;

  width: 7rem;

  margin: auto;

  @media screen and (min-width: 1440px) {
    width: auto;
  }

  .sidebar-nav-list {
    @media screen and (max-width: 1439px) {
      &:hover,
      &.open {
        width: 27rem;

        padding: 0 1rem;

        ${StyledUserBarNavElement} {
          width: auto;

          .user-bar-nav-element-btn {
            justify-content: flex-start;

            padding-left: 1.5rem;
            .user-bar-nav-element-title {
              display: block;
            }
          }
        }
      }
    }

    &:has(> *:hover) > ${StyledUserBarNavElement}.active:not(:hover) {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text5 : props.theme.text3)};

      background-color: transparent;
      box-shadow: none;
    }
    @media screen and (min-width: 1440px) {
      width: 24.4rem;

      margin: auto;
    }
  }
`;
