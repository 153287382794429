import cn from 'classnames';
import {
  StyledHashRateChartElement,
  StyledHashrateDiagram,
  StyledHoverUpGroup,
  StyledTooltipGroup,
} from './styles';
import { formateHashUnits } from 'utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHashrateStatistics } from 'store/slices/userStatisticsSlice';
import { ESliceDataFetchingStatus, TNullable } from 'types/common';
import { IUserHashrateData, TPoint } from 'types/hashrate';
import * as d3 from 'd3';
interface IHashrateDiagramProps {
  children?: React.ReactNode;
  containerWidth: number;
}

export const HashrateDiagram = ({ containerWidth }: IHashrateDiagramProps) => {
  const [mode, setMode] = useState<1 | 7 | 30>(1);
  const userHashrateStatistics = useSelector(selectHashrateStatistics);

  //TODO: require refactoring before release
  const getCombinedHashrate = (
    hashRates: TNullable<IUserHashrateData[]>,
    width: number = 768,
    mode: 1 | 7 | 30,
  ) => {
    if (hashRates === null) return { ratesPeriods: [], acceptedRates: [], rejectRates: [] };

    if (mode === 1) {
      const periodsFrequency = width < 500 ? 6 : 12;
      const ratesPeriods: string[] = [...Array(periodsFrequency)].map(
        (_, i) => `${i * (width < 500 ? 4 : 2)}:00`,
      );

      const test = Array.from(hashRates);

      const result: { period: string; accepted: number; rejected: number }[] = [];

      for (let j = 0; j < ratesPeriods.length; j++) {
        const period = ratesPeriods[j];
        const nextPeriod = ratesPeriods[j + 1];

        const periodValue = period.split(':')[0];
        const nextPeriodValue = nextPeriod?.split(':')[0];

        let tempAccepterValue: number = 0;
        let tempRejectedValue: number = 0;

        for (let i = 0; i < test.length; i++) {
          const date = new Date(test[i].date);
          const hours = date.getUTCHours();

          if (Number(hours) < Number(nextPeriodValue) && Number(hours) >= Number(periodValue)) {
            tempAccepterValue = test[i].accepted;
            tempRejectedValue = test[i].rejected;
          }
        }
        result.push({ period, accepted: tempAccepterValue, rejected: tempRejectedValue });
      }

      const acceptedRates = result.map(({ accepted }) => accepted);
      const rejectRates: number[] = result.map(({ rejected, accepted }) =>
        accepted !== 0 ? Math.round((rejected / accepted) * 100) : 0,
      );
      return { ratesPeriods, acceptedRates, rejectRates };
    }
    const ratesPeriods: string[] = [...Array(mode)].map((_, i) => `${i + 1}`);
    const test = Array.from(hashRates);

    const result: { period: string; accepted: number; rejected: number }[] = [];

    const now = new Date();

    for (let j = 0; j < ratesPeriods.length; j++) {
      const period = ratesPeriods[j];

      const periodValue = new Date(now.getTime());
      periodValue.setDate(now.getDate() - mode + Number(ratesPeriods[j]));

      let tempAccepterValue: number = 0;
      let tempRejectedValue: number = 0;

      for (let i = 0; i < test.length; i++) {
        const date = new Date(test[i].date);

        if (date.getDate() === periodValue.getDate()) {
          tempAccepterValue = test[i].accepted;
          tempRejectedValue = test[i].rejected;
        }
      }
      result.push({ period, accepted: tempAccepterValue, rejected: tempRejectedValue });
    }

    const acceptedRates = result.map(({ accepted }) => accepted);
    const rejectRates: number[] = result.map(({ rejected, accepted }) =>
      accepted !== 0 ? Math.round((rejected / accepted) * 100) : 0,
    );
    return { ratesPeriods, acceptedRates, rejectRates };
  };

  const dailyRates = getCombinedHashrate(
    userHashrateStatistics.hashRateDailyStatistics,
    containerWidth,
    1,
  );

  const weeklyRates = getCombinedHashrate(
    userHashrateStatistics.hashRateWeeklyStatistics,
    containerWidth,
    7,
  );
  const monthlyRates = getCombinedHashrate(
    userHashrateStatistics.hashRateMonthlyStatistics,
    containerWidth,
    30,
  );

  const ratesMap = { '1': dailyRates, '7': weeklyRates, '30': monthlyRates };

  return (
    <StyledHashrateDiagram>
      <div className="modes-selector">
        <button
          className={cn('modes-selector-btn', mode === 1 && 'selected')}
          onClick={() => setMode(1)}
        >
          1 days
        </button>
        <button
          className={cn('modes-selector-btn', mode === 7 && 'selected')}
          onClick={() => setMode(7)}
        >
          7 days
        </button>
        <button
          className={cn('modes-selector-btn', mode === 30 && 'selected')}
          onClick={() => setMode(30)}
        >
          30 days
        </button>
      </div>
      {userHashrateStatistics.status === ESliceDataFetchingStatus.initial && 'Loading...'}
      {userHashrateStatistics.status === ESliceDataFetchingStatus.loading &&
        userHashrateStatistics.hashRateDailyStatistics === null &&
        'Loading...'}
      {userHashrateStatistics.status === ESliceDataFetchingStatus.error && (
        <div className="data-error">Data is unavailable</div>
      )}
      {userHashrateStatistics.hashRateDailyStatistics !== null && (
        <RenderChart width={containerWidth} slicedRates={ratesMap[mode]} height={250} />
      )}
      <div className="hashrate-chart-legend">
        <div className="hashrate-chart-legend-element hashrate">Hashrate</div>
        <div className="hashrate-chart-legend-element reject">Reject</div>
      </div>
    </StyledHashrateDiagram>
  );
};

const RenderChart = ({
  width,
  height,
  slicedRates,
}: {
  width: number;
  height: number;
  slicedRates: {
    ratesPeriods: string[];
    acceptedRates: number[];
    rejectRates: number[];
  };
}) => {
  const [hoveredPeriod, setHoveredPeriod] = useState<number | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<number | null>(null);

  const axisYUnitsFieldWidth = 50;
  const dataFiledPaddingTop = 13;
  const axisXUnitsFieldHeight = (height - axisYUnitsFieldWidth - dataFiledPaddingTop) / 4;

  const tooltipConfig: {
    width: number;
    height: number;
    marginTop: number;
    marginLeft: number;
    paddingLeft: number;
    paddingTop: number;
    lineHieght: number;
  } = {
    width: 80,
    height: 84,
    marginTop: 10,
    marginLeft: 10,
    paddingLeft: 5,
    paddingTop: 10,
    lineHieght: 22,
  };

  const unitXdataBlockConfig: { width: number; height: number } = { width: 38, height: 40 };

  const hashrates = slicedRates.acceptedRates.map<[number, number]>((h, i) => [i + 1, h]);
  hashrates.unshift([0, slicedRates.acceptedRates[0]]);
  hashrates.push([
    hashrates.length,
    slicedRates.acceptedRates[slicedRates.acceptedRates.length - 1],
  ]);

  const rejects = slicedRates.rejectRates.map<[number, number]>((r, i) => [i + 1, r]);
  rejects.unshift([0, slicedRates.rejectRates[0]]);
  rejects.push([rejects.length, slicedRates.rejectRates[slicedRates.rejectRates.length - 1]]);

  const hashratesMaxY = d3.max<number>(slicedRates.acceptedRates);

  const rejectsMaxY = 100;
  const maxX = hashrates.length;

  const x = (width - axisYUnitsFieldWidth * 2) / (maxX - 2) / 2;

  const periodsXScaler = d3.scaleLinear(
    [0, maxX - 1],
    [axisYUnitsFieldWidth - x, width - axisYUnitsFieldWidth + x],
  );
  const hashratesYScaler = d3.scaleLinear(
    [hashratesMaxY! > 0 ? hashratesMaxY! : 1, 0],
    [dataFiledPaddingTop, axisXUnitsFieldHeight * 4 + dataFiledPaddingTop],
  );
  const rejectsYScaler = d3.scaleLinear(
    [rejectsMaxY, 0],
    [dataFiledPaddingTop, axisXUnitsFieldHeight * 4 + dataFiledPaddingTop],
  );

  const buildLine = (data: [number, number][], yScaler: d3.ScaleLinear<number, number, never>) => {
    const lineBuilder = d3
      .line()
      .x((d) => periodsXScaler(d[0]))
      .y((d) => yScaler(d[1]))
      .curve(d3.curveMonotoneX);

    return lineBuilder(data);
  };

  const axisYUnitsSublinesCount = 5;
  const hashratesLine = buildLine(hashrates, hashratesYScaler);
  const rejectsLine = buildLine(rejects, rejectsYScaler);
  const rejectsYUnits = Array.from(Array(axisYUnitsSublinesCount).keys()).map(
    (_, i) => `${rejectsMaxY - (i * rejectsMaxY) / (axisYUnitsSublinesCount - 1)}%`,
  );

  const hashrateYUnits = Array.from(Array(axisYUnitsSublinesCount).keys()).map(
    (_, i) => hashratesMaxY! - Math.round((i * hashratesMaxY!) / (axisYUnitsSublinesCount - 1)),
  );

  const onChartFiledMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const periodOverMouse = Math.round(periodsXScaler.invert(mouseX + axisYUnitsFieldWidth));
    setHoveredPeriod(periodOverMouse);
  };
  const onChartFiledClick = (event: React.MouseEvent<SVGRectElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const periodOverMouse = Math.round(periodsXScaler.invert(mouseX + axisYUnitsFieldWidth));
    setSelectedPeriod((prev) => (periodOverMouse === prev ? null : periodOverMouse));
  };

  const onHoverPeriod = (period: number | null) => {
    setHoveredPeriod(period);
  };

  const onMouseLeaveChart = () => {
    setHoveredPeriod(null);
    setSelectedPeriod(null);
  };

  return (
    <StyledHashRateChartElement>
      <svg
        className="svg-hashrate-chart"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        onMouseLeave={onMouseLeaveChart}
      >
        <rect className="svg-hashrate-chart-bg" width={width} height={height} />
        {rejectsYUnits.map((d, i) => (
          <g key={d + i}>
            <path
              className="y-line"
              d={`M ${axisYUnitsFieldWidth},${i * axisXUnitsFieldHeight + dataFiledPaddingTop}
             H${width - axisYUnitsFieldWidth}`}
            ></path>
          </g>
        ))}

        <path className="data-line secondary" d={rejectsLine!} stroke="orange" />
        <path className="data-line primary" d={hashratesLine!} stroke="yellow" />

        {hoveredPeriod && (
          <HoverUpGroup
            dimmed={!!selectedPeriod}
            start={{
              x: periodsXScaler(hoveredPeriod),
              y: hashratesYScaler(hashrates[hoveredPeriod][1]),
            }}
            endY={rejectsYScaler(rejects[hoveredPeriod][1])}
            lineEndY={210}
            diameter={{ primary: 7.5, secondary: 5 }}
          >
            {!selectedPeriod && (
              <TooltipGroup
                containerWidth={width}
                axisYUnitsFieldWidth={axisYUnitsFieldWidth}
                start={{
                  x: periodsXScaler(hoveredPeriod),
                  y: hashratesYScaler(hashrates[hoveredPeriod][1]),
                }}
                config={tooltipConfig}
                periodValue={slicedRates.ratesPeriods[hoveredPeriod - 1]}
                hashrateValue={formateHashUnits(hashrates[hoveredPeriod][1])}
                rejectValue={`${rejects[hoveredPeriod][1]}%`}
              />
            )}
          </HoverUpGroup>
        )}

        {selectedPeriod && (
          <g className="tooltip-element">
            <HoverUpGroup
              start={{
                x: periodsXScaler(selectedPeriod),
                y: hashratesYScaler(hashrates[selectedPeriod][1]),
              }}
              endY={rejectsYScaler(rejects[selectedPeriod][1])}
              lineEndY={210}
              diameter={{ primary: 7.5, secondary: 5 }}
            >
              <TooltipGroup
                containerWidth={width}
                axisYUnitsFieldWidth={axisYUnitsFieldWidth}
                start={{
                  x: periodsXScaler(selectedPeriod),
                  y: hashratesYScaler(hashrates[selectedPeriod][1]),
                }}
                config={tooltipConfig}
                periodValue={slicedRates.ratesPeriods[selectedPeriod - 1]}
                hashrateValue={formateHashUnits(hashrates[selectedPeriod][1])}
                rejectValue={`${rejects[selectedPeriod][1]}%`}
              />
            </HoverUpGroup>
          </g>
        )}

        <rect
          className="svg-hashrate-chart-bg"
          x={0}
          width={axisYUnitsFieldWidth}
          height={height}
        />
        <rect
          className="svg-hashrate-chart-bg"
          x={width - axisYUnitsFieldWidth}
          width={axisYUnitsFieldWidth}
          height={height}
        />
        {rejectsYUnits.map((d, i) => (
          <g key={d + i} className="y-unit">
            <text
              className="y-unit-text"
              x={width - axisYUnitsFieldWidth + 5}
              y={i * axisXUnitsFieldHeight + dataFiledPaddingTop}
            >
              {d}
            </text>
            <text className="y-unit-text" x={0} y={i * axisXUnitsFieldHeight + dataFiledPaddingTop}>
              {formateHashUnits(hashrateYUnits[i])}
            </text>
          </g>
        ))}
        {slicedRates.ratesPeriods.map((d, i) => (
          <g
            className="x-unit"
            key={d + i}
            onClick={() => setSelectedPeriod(i + 1)}
            onMouseMove={() => onHoverPeriod(i + 1)}
          >
            <rect
              className={cn(
                'x-unit-bg',
                selectedPeriod && selectedPeriod !== i + 1 && 'other-selected',
              )}
              x={periodsXScaler(i + 1) - unitXdataBlockConfig.width / 2}
              y={height - unitXdataBlockConfig.height}
              width={unitXdataBlockConfig.width}
              height={unitXdataBlockConfig.height}
              rx={5}
              visibility={
                hoveredPeriod === i + 1 || selectedPeriod === i + 1 ? 'visible' : 'hidden'
              }
              pointerEvents={'all'}
            ></rect>
            <text
              className="x-unit-text"
              dominantBaseline="middle"
              x={periodsXScaler(i + 1)}
              y={height - unitXdataBlockConfig.height / 2}
            >
              {d}
            </text>
          </g>
        ))}

        <rect
          className="data-feild"
          y={dataFiledPaddingTop}
          x={axisYUnitsFieldWidth}
          width={width - axisYUnitsFieldWidth * 2}
          height={height - axisXUnitsFieldHeight - dataFiledPaddingTop}
          fill="cyan"
          onMouseMove={onChartFiledMouseMove}
          onClick={onChartFiledClick}
          visibility={'hidden'}
          pointerEvents={'all'}
        />
      </svg>
    </StyledHashRateChartElement>
  );
};

interface IHoverUpGroupProps {
  children: React.ReactNode;
  start: TPoint;
  endY: number;
  lineEndY: number;
  dimmed?: boolean;
  diameter: { primary: number; secondary: number };
}

const HoverUpGroup = ({
  start,
  endY,
  children,
  dimmed,
  lineEndY,
  diameter,
}: IHoverUpGroupProps) => {
  const { x, y } = start;

  const { primary, secondary } = diameter;
  return (
    <StyledHoverUpGroup className={cn(dimmed && 'dimmed')}>
      <path className="data-point-line" d={`M ${x},${y} ${x},${lineEndY}`} />
      <circle className="data-point secondary" cx={x} cy={endY} r={secondary} />
      <circle className="data-point primary" cx={x} cy={y} r={primary} />
      {children}
    </StyledHoverUpGroup>
  );
};

interface ITooltipGroupProps {
  containerWidth: number;
  axisYUnitsFieldWidth: number;
  start: TPoint;
  config: {
    width: number;
    height: number;
    marginTop: number;
    marginLeft: number;
    paddingLeft: number;
    paddingTop: number;
    lineHieght: number;
  };
  periodValue: number | string;
  hashrateValue: number | string;
  rejectValue: number | string;
}

const TooltipGroup = ({
  config,
  start,
  containerWidth,
  axisYUnitsFieldWidth,
  periodValue,
  hashrateValue,
  rejectValue,
}: ITooltipGroupProps) => {
  const { width, height, marginTop, marginLeft, paddingLeft, paddingTop, lineHieght } = config;

  const getTooltipY = (startY: number, tooltipHeight: number, marginTop: number) => {
    const offsetTop = startY > tooltipHeight ? tooltipHeight : 0;
    return startY - marginTop - offsetTop;
  };

  const getTooltipX = (
    startX: number,
    tooltipWidth: number,
    marginLeft: number,
    containerWidth: number,
    axisYUnitsFieldWidth: number,
  ) => {
    const offsetRight =
      containerWidth - axisYUnitsFieldWidth - startX < tooltipWidth
        ? -tooltipWidth - marginLeft
        : marginLeft;
    return startX + offsetRight;
  };

  const tooltipX = getTooltipX(start.x, width, marginLeft, containerWidth, axisYUnitsFieldWidth);

  const tooltipY = getTooltipY(start.y, height, marginTop);

  const periodPos: TPoint = {
    x: tooltipX + paddingLeft,
    y: tooltipY + marginTop + paddingTop,
  };

  const hashratePos: TPoint = {
    x: periodPos.x,
    y: periodPos.y + lineHieght,
  };

  const rejectPos: TPoint = {
    x: periodPos.x,
    y: periodPos.y + lineHieght * 2,
  };

  return (
    <StyledTooltipGroup className="tooltip-group">
      <rect className="tooltip-group-bg" width={80} height={84} x={tooltipX} y={tooltipY} rx={5} />
      <text className="tooltip-group-text" {...periodPos}>
        {periodValue}
      </text>
      <text className="tooltip-group-text hashrate" {...hashratePos}>
        {hashrateValue}
      </text>
      <text className="tooltip-group-text reject" {...rejectPos}>
        {rejectValue}
      </text>
    </StyledTooltipGroup>
  );
};
