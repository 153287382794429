import styled from 'styled-components';
export const StyledThemeToggler = styled.button`
  width: 6rem;
  height: 3.2rem;
  border-radius: 500px;
  border: 1px solid ${(props) => props.theme.button2};
  background-color: transparent;
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 5.6rem;
    height: 2.8rem;
    border-radius: 350px;
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button2 : props.theme.accent1};
    opacity: ${(props) => (props.theme.id === 'dark' ? '0.43' : '1')};
  }
`;
