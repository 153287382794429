import { AdminElementsWrapper, SecondaryButton } from 'components';
import { StyledAddminAddTransaction } from './styles';
import { useTranslation } from 'react-i18next';
import { useId } from 'react';
interface IAddminAddTransactionProps {
  children?: React.ReactNode;
}
export const AddminAddTransaction = () => {
  const { t } = useTranslation();

  const submitHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const { elements } = event.currentTarget;
    if ('transactionHash' in elements) {
      const transactionHash = elements.transactionHash as HTMLInputElement;
      console.log('transactionHash', transactionHash.value);
    }
  };
  const inputId = useId();
  const heading = t('adminPage.addTransaction.heading');
  return (
    <StyledAddminAddTransaction>
      <AdminElementsWrapper>
        <h3 className="heading">{heading}</h3>
        <form className="form" onSubmit={submitHandler}>
          <label htmlFor={inputId} hidden>
            {heading}
          </label>
          <input type="text" className="input" id={inputId} name="transactionHash" />
          <SecondaryButton
            type="submit"
            outline
            iconFirst
            title="Add"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M13 7.5H2M7.5 2V13"
                  stroke="white"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
        </form>
      </AdminElementsWrapper>
    </StyledAddminAddTransaction>
  );
};
