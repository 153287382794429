import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import {
  StyledHeroSection,
  StyledTitle,
  StyledSubTitle,
  StyledHeroBenefits,
  StyledHeroMiners,
  StyledHeroHashrate,
  StyledImageContainer,
  StyledHeroBenefitsText,
  StyledHeroBenefitsTexts,
} from './styles';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'lib';
import { useEffect, useRef } from 'react';
export const HeroSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const videoRef = useRef<HTMLVideoElement>(null);

  // TODO: get date from api
  // https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-4
  const minersQuantity = 2509;
  const hashRateLevel = 105;

  const letsStartButtonHandler = () => navigate(Routes.statisticsPath());

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.2;
    }
  }, []);

  return (
    <StyledHeroSection>
      <div className="hero">
        <div className="background">
          <video ref={videoRef} playsInline muted autoPlay>
            <source src="/images/video-bg.mp4" type="video/mp4" />
          </video>
        </div>
        <StyledTitle>{t('mainPage.hero.heading')}</StyledTitle>
        <StyledSubTitle>{t('mainPage.hero.subHeading')}</StyledSubTitle>
        <Button onClick={letsStartButtonHandler} title={t('mainPage.hero.startButton')}></Button>
      </div>
      <StyledHeroBenefits>
        <StyledHeroMiners>
          <StyledImageContainer>
            <img src="/images/miner.webp" />
          </StyledImageContainer>
          <StyledHeroBenefitsText className="mobile">
            <span className="colored">+ {minersQuantity}</span> {t('mainPage.hero.benefits.miners')}
          </StyledHeroBenefitsText>
        </StyledHeroMiners>
        <StyledHeroHashrate>
          <StyledImageContainer>
            <img src={`/images/grafic-${theme.id}.webp`} />
          </StyledImageContainer>
          <StyledHeroBenefitsText className="mobile">
            <span className="colored">+ {hashRateLevel} TH/s</span>{' '}
            {t('mainPage.hero.benefits.hasrate')}
          </StyledHeroBenefitsText>
        </StyledHeroHashrate>
        <StyledHeroBenefitsTexts>
          <StyledHeroBenefitsText>
            <span className="colored">+ {minersQuantity}</span> {t('mainPage.hero.benefits.miners')}
          </StyledHeroBenefitsText>
          <StyledHeroBenefitsText>
            <span className="colored">+ {hashRateLevel} TH/s</span>{' '}
            {t('mainPage.hero.benefits.hasrate')}
          </StyledHeroBenefitsText>
        </StyledHeroBenefitsTexts>
      </StyledHeroBenefits>
    </StyledHeroSection>
  );
};
