import styled from 'styled-components';

export const StyledStoresContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  margin-bottom: 3.6rem;
  @media screen and (min-width: 768px) {
    margin-bottom: 7.5rem;
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 4.8rem;
  }
`;
