import styled from 'styled-components';
export const StyledSearchInput = styled.div`
  flex-grow: 1;

  @media screen and (min-width: 768px) {
    width: 23.4rem;
  }
  .input-group {
    position: relative;
    height: 100%;
    .input {
      width: 100%;
      height: 100%;
      border-radius: 9px;

      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      padding: 0.8rem 1.2rem;
      padding-right: 3.6rem;
      align-items: center;

      outline: none;

      &:focus {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

        border-radius: 5px;
        border: 1px solid
          ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
        background-color: ${(props) =>
          props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2};
      }

      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }

      @media screen and (min-width: 1440px) {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: normal;
      }
    }

    .search-button {
      color: ${(props) => props.theme.text1};
      display: block;
      position: absolute;
      right: 0.8rem;
      top: 50%;
      transform: translateY(-50%);

      width: 1.6rem;
      height: 1.6rem;

      @media screen and (min-width: 768px) {
        width: 2.4rem;
        height: 2.4rem;
      }

      .button-icon {
        *[stroke] {
          stroke: currentColor;
        }

        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
`;
