import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledButton = styled.button`
  width: 4.1rem;
  height: 4.1rem;
  margin: auto;
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.text3 : props.theme.button2};
  border-radius: 5rem;
  color: ${darkTheme.text1};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.2s;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  > * {
    transition: all 0.2s;
    fill: ${darkTheme.text1};
    stroke: ${darkTheme.text1};
  }

  &:hover {
    background-color: ${darkTheme.accent1};
    color: ${darkTheme.text3};
    transition: all 0.2s;

    > * {
      transition: all 0.2s;
      fill: ${darkTheme.text3};
      stroke: ${darkTheme.text3};
    }
  }

  &.right {
    @media screen and (min-width: 1440px) {
      display: none;
    }
    > * {
      width: 2rem;
      height: 2rem;
    }
  }
`;
