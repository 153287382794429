import { Button } from 'components';
import cn from 'classnames';
import { AdminList } from '../AdminList';
import { StyledAdminEditPayments } from './styles';
import { DataExportButton } from '../DataExportButton';
import { useTranslation } from 'react-i18next';
import { StyledAdminEditPaymentsHeader } from './styles/StyledAdminEditPaymentsHeader';
import { AdminListCheckbox } from '../AdminListCheckbox';
import { useState } from 'react';
import { AdminListHeaderCell } from '../AdminListHeaderCell';
import { StyledAdminEditPaymentsRow } from './styles/StyledAdminEditPaymentsRow';
import { AdminListRowCell } from '../AdminListRowCell';
interface IAdminEditPaymentsProps {
  children?: React.ReactNode;
}
export const AdminEditPayments = ({ children }: IAdminEditPaymentsProps) => {
  const { t } = useTranslation();
  return (
    <StyledAdminEditPayments>
      <AdminList
        renderControls={() => (
          <div className="admin-edit-payments-controls">
            <Button title={t('adminPage.changeAdjust')} />
            <DataExportButton />
          </div>
        )}
      >
        <AdminEditPaymentsHeader />
        <AdminPaymentsRow />
        <AdminPaymentsRow />
      </AdminList>
    </StyledAdminEditPayments>
  );
};

const adminEditPaymentsHeadersTitles = ['id', 'normal', 'adjust', 'yesterday', 'payment'];
const AdminEditPaymentsHeader = () => {
  const [selected, setSelected] = useState<boolean>(false);

  const { t } = useTranslation();

  const selectRowHandler = () => {
    setSelected((prev) => !prev);
  };
  return (
    <StyledAdminEditPaymentsHeader>
      <div className="admin-list-header-cell-container select">
        <AdminListCheckbox checked={selected} onChange={selectRowHandler} />
      </div>
      {adminEditPaymentsHeadersTitles.map((title) => (
        <div key={title} className={cn('admin-list-header-cell-container', title.toLowerCase())}>
          <AdminListHeaderCell filter={title !== 'comment'}>
            {t(`adminPage.editPayments.listHeaders.${title}`)}
          </AdminListHeaderCell>
        </div>
      ))}
    </StyledAdminEditPaymentsHeader>
  );
};

const AdminPaymentsRow = () => {
  const [selected, setSelected] = useState<boolean>(false);

  const selectRowHandler = () => {
    setSelected((prev) => !prev);
  };

  const data: { [id: string]: string } = {
    id: '< M30 whatsminer',
    normal: '3000',
    adjust: '-0.234',
    yesterday: '2931',
    payment: '0,00747405',
  };
  return (
    <StyledAdminEditPaymentsRow>
      <div className="admin-list-cell-container select">
        <AdminListCheckbox checked={selected} onChange={selectRowHandler} />
      </div>
      {adminEditPaymentsHeadersTitles.map((title) => (
        <div key={title} className={cn('admin-list-cell-container', title.toLowerCase())}>
          <AdminListRowCell>{data[title]}</AdminListRowCell>
        </div>
      ))}
    </StyledAdminEditPaymentsRow>
  );
};
