import { useId, useState } from 'react';
import { StyledCustomCheckbox } from './styles';
import { ReactComponent as CheckBoxIcon } from 'assets/icons/check-box-checked.svg';
interface ICustomCheckboxProps {
  children?: React.ReactNode;
  onChange: (isChecked: boolean) => void;
  initialChecked?: boolean;
}
export const CustomCheckbox = ({ onChange, initialChecked }: ICustomCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(initialChecked);
  const customCheckBoxId = useId();
  const toggleCheckboxHandler = () => {
    if (isChecked) {
      setIsChecked(false);
      onChange(false);
    } else {
      setIsChecked(true);
      onChange(true);
    }
  };
  return (
    <StyledCustomCheckbox>
      <input
        id={customCheckBoxId}
        className="checkbox-input"
        type="checkbox"
        onChange={toggleCheckboxHandler}
        checked={isChecked}
      />
      <label htmlFor={customCheckBoxId} className="checkbox-label">
        <CheckBoxIcon className="checkbox-label__icon" />
      </label>
    </StyledCustomCheckbox>
  );
};
