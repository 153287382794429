import styled from 'styled-components';
export const StyledLocaleToggler = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${(props) => {
    return props.theme.id === 'dark' ? props.theme.accent2 : props.theme.button2;
  }};
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 500px;

  .btn {
    position: relative;
    z-index: 1;
    display: block;
    width: 4.3rem;
    height: 4.3rem;
    font-size: 1.8rem;
    font-weight: 800;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};

    &:hover {
      color: ${(props) => props.theme.button1};
    }
  }
`;
