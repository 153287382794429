import { Input } from 'components';
import { StyledDonats } from './styles';
import { useTranslation } from 'react-i18next';
interface IDonatsProps {
  children?: React.ReactNode;
}
export const Donats = ({ children }: IDonatsProps) => {
  const { t } = useTranslation();
  return (
    <StyledDonats>
      {<label className="donat-input-label">{t('settingsPage.donats.label')}</label>}
      <Input
        className="donat-input"
        placeholder={t('settingsPage.donats.placeholder')}
        value={'0%'}
      />
      <p className="donat-input-remark">{t('settingsPage.donats.remark')}</p>
    </StyledDonats>
  );
};
