import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledThemeSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  .theme-select-label {
    display: block;

    padding: 0 1rem;

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
  }

  .theme-select-toggler-container {
    border-radius: 8px;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.7rem 1rem;

      border: 1px solid
        ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};

      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2};
    }

    .theme-select-toggler-hint {
      display: none;

      font-size: 1.4rem;
      line-height: 150%;
      font-weight: 400;
      color: ${({ theme }) => (theme.id === 'dark' ? theme.accent2 : darkTheme.accent2)};

      @media screen and (min-width: 768px) {
        display: inline-block;
      }
    }
  }
`;
