import {
  StyledAmountBlock,
  StyledHeader,
  StyledIncomeCalculator,
  StyledInputBlock,
} from './styles';
import { useTranslation } from 'react-i18next';
import { CurrencySelect, DailyIncome, HashrateInput, PeriodSelector } from './components';
import { useState } from 'react';
import { ECalculationPeriods } from 'types';
export const IncomeCalculator = () => {
  const { t } = useTranslation();
  const [thAmount, setThAmount] = useState<number>(1);
  const [period, setPeriod] = useState<ECalculationPeriods>(ECalculationPeriods.Week);

  const periodMultiplierMap = {
    [ECalculationPeriods.Day]: 1,
    [ECalculationPeriods.Week]: 7,
    [ECalculationPeriods.Month]: 30,
  };

  return (
    <StyledIncomeCalculator>
      <StyledHeader>
        <h2 className="h2">{t('calculator.heading')}</h2>
      </StyledHeader>
      <StyledInputBlock>
        <StyledAmountBlock>
          <CurrencySelect labelText={t('calculator.currency')} />
          <HashrateInput
            labelText={t('calculator.hashrate')}
            units={t('calculator.hashrateUnits')}
            onChange={setThAmount}
          />
        </StyledAmountBlock>
        <PeriodSelector labelText={t('calculator.period')} onChange={setPeriod} />
      </StyledInputBlock>
      <DailyIncome thAmount={thAmount * periodMultiplierMap[period]} />
    </StyledIncomeCalculator>
  );
};
