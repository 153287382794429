import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledAccessButton = styled.button`
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 150%;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  &:hover {
    color: ${(props) => props.theme.button1};
  }
`;

export const StyledAccessLink = styled(Link)`
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 150%;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  &:hover {
    color: ${(props) => props.theme.button1};
  }
`;
