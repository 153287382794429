import styled from 'styled-components';
export const StyledAdminElementsWrapper = styled.div`
  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.bg3)};
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  padding: 1.2rem;
  border-radius: 12px;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 1440px) {
    padding: 2.4rem;
  }
`;
