import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledHashrateInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  flex-grow: 1;

  .label {
    padding: 0 1rem;

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: ${(props) => props.theme.text4};

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
  }

  .input_container {
    display: flex;
    gap: 0.2rem;
  }
  .input {
    max-width: 12.5rem;
    height: 3.2rem;

    padding: 0.6rem 1rem 0.7rem 2rem;

    border-radius: 94px 0px 0px 94px;
    border: none;

    outline: none;

    font-size: 1.2rem;
    line-height: 150%;
    font-weight: 500;
    color: currentColor;

    border: 1px solid ${(props) => (props.theme.id === 'dark' ? props.theme.bg6 : props.theme.bg5)};

    background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg6 : props.theme.accent3)};

    @media screen and (min-width: 768px) {
      max-width: 25rem;
      height: 3.9rem;

      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 500;
    }

    @media screen and (min-width: 1024px) {
      max-width: 19.7rem;
    }

    @media screen and (min-width: 1440px) {
      max-width: 24rem;
    }
    @media screen and (min-width: 1920px) {
      max-width: 24rem;
    }
  }

  .units {
    border-radius: 0 94px 94px 0;

    border: 1px solid ${(props) => (props.theme.id === 'dark' ? props.theme.bg6 : props.theme.bg5)};
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.bg6 : props.theme.accent3};
    padding: 5px 20px 5px 15px;

    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    width: 5.2rem;
    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 150%; /* 37.5px */
      width: 9.3rem;
    }
    @media screen and (min-width: 1024px) {
      max-width: 6rem;
    }

    @media screen and (min-width: 1440px) {
      /* max-width: 9.3rem; */
    }
    @media screen and (min-width: 1920px) {
      /* max-width: 9.3rem; */
    }
  }
`;
