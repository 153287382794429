import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledNavigation, StyledNavigationList, StyledNavigationListElement } from './styles';

export const Nvaigation = () => {
  const { t } = useTranslation();
  return (
    <StyledNavigation>
      <StyledNavigationList>
        <Link to="/faq">
          <StyledNavigationListElement className="p2">
            {t('header.navigation.faq')}
          </StyledNavigationListElement>
        </Link>
        <Link to="/about">
          <StyledNavigationListElement className="p2">
            {t('header.navigation.about')}
          </StyledNavigationListElement>
        </Link>
        <Link to="/support">
          <StyledNavigationListElement className="p2 dark">
            {t('header.navigation.support')}
          </StyledNavigationListElement>
        </Link>
      </StyledNavigationList>
    </StyledNavigation>
  );
};
