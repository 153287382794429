import { EmailInput, OutlinedButton, PhoneInput, TelegramInput } from 'components';
import { StyledContacts } from './styles';
import { useTranslation } from 'react-i18next';
import { MainSettingsComponent } from '../MainSettingsComponent';
interface IContactsProps {
  children?: React.ReactNode;
}
export const Contacts = ({ children }: IContactsProps) => {
  const { t } = useTranslation();

  const changePhoneSubmitHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    console.log('submit email');
  };
  const changeEmailSubmitHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    console.log('submit phone');
  };
  const changeTelegramSubmitHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    console.log('submit telegram');
  };
  return (
    <StyledContacts>
      <MainSettingsComponent heading={t('settingsPage.contacts.heading')}>
        <form className="settings-contacts-form" onSubmit={changePhoneSubmitHandler}>
          <div className="settings-contacts-form__input-group">
            <PhoneInput labelText={t('settingsPage.contacts.phoneInput.label')} />
          </div>
          <OutlinedButton
            type="submit"
            className="settings-contacts-form__submit-button long"
            square
          >
            {t('settingsPage.contacts.phoneInput.submitButton')}
          </OutlinedButton>
        </form>
        <form className="settings-contacts-form" onSubmit={changeEmailSubmitHandler}>
          <div className="settings-contacts-form__input-group">
            <EmailInput
              labelText={t('settingsPage.contacts.emailInput.label')}
              placeholder={t('settingsPage.contacts.emailInput.placeholder')}
            />
          </div>
          <OutlinedButton
            type="submit"
            className="settings-contacts-form__submit-button long"
            square
          >
            {t('settingsPage.contacts.emailInput.submitButton')}
          </OutlinedButton>
        </form>
        <form className="settings-contacts-form" onSubmit={changeTelegramSubmitHandler}>
          <div className="settings-contacts-form__input-group">
            <TelegramInput
              labelText={t('settingsPage.contacts.telegramInput.label')}
              placeholder={t('settingsPage.contacts.telegramInput.placeholder')}
            />
          </div>
          <OutlinedButton
            type="submit"
            className="settings-contacts-form__submit-button long"
            square
          >
            {t('settingsPage.contacts.telegramInput.submitButton')}
          </OutlinedButton>
        </form>
      </MainSettingsComponent>
    </StyledContacts>
  );
};
