import styled from 'styled-components';
export const StyledBTCAddress = styled.article`
  @media screen and (min-width: 1024px) {
    flex-basis: 45%;
    flex-grow: 1;

    max-width: 50%;
  }
  @media screen and (min-width: 1440px) {
    max-width: calc(50% - 1rem);
  }

  .btc-address-form {
    &__input {
      max-width: 37rem;

      margin: auto;
      margin-bottom: 1.6rem;

      @media screen and (min-width: 768px) {
        margin: 0;
        margin-bottom: 3rem;
      }

      @media screen and (min-width: 1024px) {
        max-width: 31.2rem;
      }

      @media screen and (min-width: 1440px) {
        max-width: 45rem;
      }
    }
    &__button {
      margin: auto;
      margin-bottom: 0.7rem;

      @media screen and (min-width: 768px) {
        margin: 0;
      }
    }
  }
`;
