import styled from 'styled-components';
import { StyledBorderedWrapper } from 'components/shared/BorderedWrapper/styles';
import { darkTheme } from 'themes';
export const StyledHashrateStatistics = styled.div`
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 1024px) {
    order: 1;

    width: 100%;

    ${StyledBorderedWrapper} {
      padding: 2rem 2.5rem;
    }
  }

  @media screen and (min-width: 1200px) {
    order: 0;
  }

  @media screen and (min-width: 1920px) {
    flex-grow: 1;
    flex-basis: 45%;

    max-width: 50%;
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  .h2 {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    opacity: 0.87;

    @media screen and (min-width: 768px) {
      font-weight: 600;
    }
  }

  .hint {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.3rem;
    height: 1.3rem;

    border-radius: 100%;

    font-family: Mulish;
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    color: ${darkTheme.bg3};

    background-color: ${darkTheme.text2};

    cursor: pointer;

    @media screen and (min-width: 768px) {
      top: -0.5rem;
    }
  }
`;
//
