import styled from 'styled-components';
export const StyledNotifications = styled.div`
  max-width: 50rem;
  margin: auto;
  margin-bottom: 4rem;

  @media screen and (min-width: 768px) {
    max-width: 100%;
    margin: 0;
    margin-bottom: 10rem;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 12rem;
  }

  .notifications {
    padding-bottom: 1.1rem;

    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;

    @media screen and (min-width: 768px) {
      padding: 1rem 0;

      font-size: 1.6rem;
      line-height: 150%;
    }

    @media screen and (min-width: 1024px) {
      padding: 1rem 2rem;
      padding-bottom: 3.7rem;
      max-width: 100rem;
    }

    .notification__row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &.header {
        @media screen and (min-width: 768px) {
          margin-bottom: 2.4rem;
        }
      }

      .notitication_element {
        &.header {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 150%;

          @media screen and (min-width: 768px) {
          }

          .short {
            @media screen and (min-width: 768px) {
              display: none;
            }
          }
          .full {
            display: none;
            @media screen and (min-width: 768px) {
              display: inline;
            }
          }
        }

        &.title {
          margin-right: auto;

          @media screen and (min-width: 768px) {
            max-width: 31.5rem;
          }
          @media screen and (min-width: 1024px) {
            max-width: 50rem;
          }
        }

        &.checkbox-container {
          flex-shrink: 0;
          display: flex;
          justify-content: flex-end;

          width: 3.6rem;

          margin-right: 1.4rem;

          @media screen and (min-width: 768px) {
            justify-content: center;

            &.first {
              width: 5rem;

              margin-right: 6rem;

              @media screen and (min-width: 1024px) {
                margin-right: 12rem;
              }

              @media screen and (min-width: 1440px) {
                margin-right: 19.4rem;
              }
            }
            &.second {
              width: 11rem;

              @media screen and (min-width: 1440px) {
                margin-right: 12.9rem;
              }
            }
          }
        }
      }
    }
  }
`;
