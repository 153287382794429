import styled from 'styled-components';

export const StyledChangePassword = styled.article`
  @media screen and (min-width: 1024px) {
    flex-basis: 50%;

    max-width: 50%;
  }

  @media screen and (min-width: 1440px) {
    max-width: calc(50% - 1rem);
  }

  .change-password-form {
    margin: auto;

    @media screen and (min-width: 1024px) {
      max-width: 100%;
    }

    &__input-groups {
      display: flex;
      flex-direction: column;
      justify-content: center;

      margin-bottom: 1.6rem;

      @media screen and (min-width: 1440px) {
        flex-direction: column;
      }
    }
    &__input-group {
      width: 32rem;
      max-width: 100%;

      margin: auto;

      &:first-child {
        margin-bottom: 1.6rem;
      }

      @media screen and (min-width: 768px) {
        margin: initial;
      }
    }

    &__submit-button {
      margin: auto;
      margin-bottom: 0.7rem;
      @media screen and (min-width: 768px) {
        margin: initial;
      }
    }
  }
`;
