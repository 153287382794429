import { useEffect, useState } from 'react';
import cn from 'classnames';
import { StyledBurgerDropDown, StyledBurgerDropdownContent, StyledBurgerOverlay } from './styles';
export const BurgerDropDown = ({
  show,
  children,
}: {
  show?: boolean;
  children?: React.ReactNode;
}) => {
  if (!show) {
    document.body.classList.remove('overflow-hidden');
  }
  if (show) document.body.classList.add('overflow-hidden');
  const [showContent, setShowContent] = useState(false);
  const [isMountDropdown, setIsMountDropdown] = useState(false);

  useEffect(() => {
    if (show) {
      setIsMountDropdown(() => true);
      setTimeout(() => setShowContent(() => true));
    }
    if (!show) {
      setShowContent(false);
      setTimeout(() => setIsMountDropdown(false), 500);
    }
  }, [show]);

  return isMountDropdown ? (
    <StyledBurgerDropDown>
      <StyledBurgerOverlay className={cn(showContent && 'show')} />
      <StyledBurgerDropdownContent className={cn(showContent && 'show')}>
        {children}
      </StyledBurgerDropdownContent>
    </StyledBurgerDropDown>
  ) : null;
};
