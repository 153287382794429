import styled from 'styled-components';
export const StyledAccountInformation = styled.section`
  margin-bottom: 1.2rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 1024px) {
    /* width: 43.2rem; */
    /* height: 31rem; */
    flex-grow: 1;
    flex-basis: 45%;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
    flex-grow: 1;
    flex-basis: 30%;
    max-width: 33%;
  }
  @media screen and (min-width: 1440px) {
    flex-grow: 1;
    max-width: 33%;
    /* width: 34rem; */

    /* height: 30.5rem; */

    margin-bottom: 0;
  }

  @media screen and (min-width: 1920px) {
    /* width: 45rem; */
    /* height: auto; */
  }
`;
