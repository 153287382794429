import styled from 'styled-components';
export const StyledTransactionsListRow = styled.div`
  display: flex;

  border-radius: 6px;
  background-color: ${(props) => props.theme.bg4};

  padding: 0.4rem 0;
  height: 3.2rem;

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }
  .list-col-element {
    flex-shrink: 0;
  }

  .hashId {
    flex-grow: 1;
  }
  .id {
    width: 10.4rem;
  }
  .confirmation {
    width: 13.4rem;
  }
  .created-at {
    width: 23.8rem;
  }
  .updated-at {
    width: 23.8rem;
  }
`;
