import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledAboutPlans = styled.div`
  margin: auto;

  max-width: 30rem;

  color: ${darkTheme.text1};

  @media screen and (min-width: 768px) {
    max-width: 70rem;
  }
  @media screen and (min-width: 1024px) {
    max-width: 95rem;
  }
  @media screen and (min-width: 1440px) {
    max-width: 132rem;
  }

  .about-plans-header {
    height: 7rem;
    background-image: url('/images/bg/about-plans-purple-dark-sx.svg');
    background-repeat: no-repeat;
    padding: 2.5rem 2rem 0;

    .about-plans-header-heading {
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 37.5px */
      letter-spacing: 0.25px;
    }
    @media screen and (min-width: 768px) {
      background-image: url('/images/bg/about-plans-purple-dark-sm.svg');
      height: 9rem;
      padding: 2.5rem 4.5rem 0;
      .about-plans-header-heading {
        font-size: 3.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 52.5px */
      }
    }
    @media screen and (min-width: 1024px) {
      background-image: url('/images/bg/about-plans-purple-dark-md.svg');
      height: 9rem;
      padding: 2.5rem 4rem 0;
    }
    @media screen and (min-width: 1440px) {
      background-image: url('/images/bg/about-plans-purple-dark-lg.svg');
      height: 9rem;
      padding: 2.5rem 4.5rem 0;
    }
  }

  .about-plans-content {
    background-image: linear-gradient(185deg, #563f97 0, rgba(116, 86, 202, 0.51) 231.02%);

    background-repeat: no-repeat;
    border-radius: 0 0 20px 20px;
    padding: 2.4rem 1rem;
    @media screen and (min-width: 768px) {
      background-color: #563f97;
      padding: 4.4rem 1rem;
    }
    @media screen and (min-width: 1024px) {
      padding-bottom: 6.6rem;
    }

    .about-plans-content-tabs {
      border-bottom: 1px solid
        ${(props) => (props.theme.id === ' dark' ? props.theme.text4 : props.theme.text1)};

      display: flex;

      overflow-x: auto;
      width: 28rem;
      height: 4.3rem;
      @media screen and (min-width: 768px) {
        width: auto;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .about-plans-content-tab {
        display: flex;
        align-items: start;
        justify-content: center;
        width: 11.4rem;
        flex-shrink: 0;

        color: ${(props) => (props.theme.id === 'dark' ? props.theme.text4 : props.theme.button2)};
        text-align: center;

        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */

        @media screen and (min-width: 768px) {
          width: 13.6rem;
        }
        @media screen and (min-width: 1024px) {
          width: 18rem;
        }
        @media screen and (min-width: 1440px) {
          width: 25.5rem;
        }

        &.active {
          color: ${darkTheme.text1};
          font-size: 2.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 37.5px */
          border-bottom: 3px solid currentColor;
        }
      }
    }
    .about-plans-content-tabs-content {
      padding-top: 2.4rem;
      @media screen and (min-width: 768px) {
        padding: 0 3.5rem;
        padding-top: 4rem;
      }
      @media screen and (min-width: 1024px) {
        padding-top: 10rem;
      }
      @media screen and (min-width: 1440px) {
      }

      .about-plans-content-tab-content {
        display: none;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        &.active {
          display: block;
        }
        @media screen and (min-width: 768px) {
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 27px */
        }

        @media screen and (min-width: 1024px) {
          justify-content: center;
          &.active {
            display: flex;
            gap: 8rem;
          }
        }

        .about-plans-content-tab-content-element {
          flex-basis: 50%;

          @media screen and (min-width: 1440px) {
            max-width: 43.5rem;
          }

          &:not(:last-child) {
            margin-bottom: 1.6rem;
            @media screen and (min-width: 768px) {
              margin-bottom: 3rem;
            }
            @media screen and (min-width: 1024px) {
              margin-bottom: 0;
            }
          }
        }
        .about-plans-content-tab-content-heading {
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 27px */
          margin-bottom: 0.5rem;

          @media screen and (min-width: 768px) {
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 37.5px */
            margin-bottom: 1rem;
          }
          @media screen and (min-width: 1024px) {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
`;
