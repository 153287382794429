import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledModalOverlay = styled.div`
  position: absolute;
  opacity: 0;
  background: ${darkTheme.accent2};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transition: opacity 0.5s;
  &.show {
    opacity: ${(props) => (props.theme.id === 'dark' ? '0.39' : '0.75')};
  }
`;
