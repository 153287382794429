import { BorderedWrapper, CustomCheckbox } from 'components';
import { StyledNotifications } from './styles';
import { useTranslation } from 'react-i18next';
interface INotificationsProps {
  children?: React.ReactNode;
}
export const Notifications = ({ children }: INotificationsProps) => {
  const { t } = useTranslation();

  const notificationElements = ['payouts', 'service', 'security', 'news', 'referral'];

  const onChangeCheckBoxHandler =
    (notificationElement: (typeof notificationElements)[number], source: 'mail' | 'tg') =>
    (isChecked: boolean) => {
      console.log(notificationElement, isChecked, source);
    };

  return (
    <StyledNotifications>
      <BorderedWrapper>
        <div className="notifications">
          <div className="notification__row header">
            <div className="notitication_element title header">
              {t('settingsPage.notifications.header.label')}
            </div>
            <div className="notitication_element header checkbox-container first">
              {t('settingsPage.notifications.header.mail')}
            </div>
            <div className="notitication_element header checkbox-container second">
              <span className="short">{t('settingsPage.notifications.header.telegramShort')}</span>
              <span className="full">{t('settingsPage.notifications.header.telegram')}</span>
            </div>
          </div>
          {notificationElements.map((notificationElement, index) => (
            <div key={notificationElement + index} className="notification__row">
              <div className="notitication_element title">
                {t(`settingsPage.notifications.elements.${notificationElement}`)}
              </div>
              <div className="notitication_element checkbox-container first">
                <CustomCheckbox
                  onChange={onChangeCheckBoxHandler(notificationElement, 'mail')}
                  initialChecked={false}
                />
              </div>
              <div className="notitication_element checkbox-container second">
                <CustomCheckbox
                  onChange={onChangeCheckBoxHandler(notificationElement, 'tg')}
                  initialChecked={false}
                />
              </div>
            </div>
          ))}
        </div>
      </BorderedWrapper>
    </StyledNotifications>
  );
};
