import styled from 'styled-components';

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.4rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }

  .h2 {
    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 500;

    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
      line-height: 150%;
    }
  }

  .payout-link {
    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 345px) {
      display: flex;
      align-items: center;

      &__text {
        display: inline-block;

        max-width: 12rem;
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .arrow {
      margin-left: 1rem;

      stroke: currentColor;

      &:hover {
        stroke: ${(props) => props.theme.button1};
      }
    }
  }
`;
