import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledDailyIncome = styled.div`
  .label {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    margin-bottom: 0.4rem;

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: ${(props) => props.theme.text2};

    .hint {
      position: relative;
      top: -3px;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 1.3rem;
      height: 1.3rem;

      border-radius: 100%;

      font-family: Mulish;
      font-size: 1rem;
      line-height: 150%;
      font-weight: 500;
      color: ${darkTheme.bg3};

      background-color: ${darkTheme.text2};

      cursor: pointer;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 0.7rem;

      font-size: 1.4rem;
      color: ${(props) => (props.theme.id === 'dark' ? darkTheme.text4 : props.theme.accent1)};
    }
  }
  .value {
    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 500;

    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 500;
    }
  }
`;
