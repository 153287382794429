import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledBlogCard = styled.div`
  position: relative;

  padding: 0.4rem;
  padding-bottom: 1.5rem;

  border: 1px solid ${darkTheme.accent2};
  border-radius: 10px;

  background-color: ${darkTheme.text3};

  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border: 1px solid ${darkTheme.accent1};

    box-shadow: 0px 32px 23px -14px rgba(0, 0, 0, 0.25);

    @media screen and (min-width: 1440px) {
      top: -2rem;
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    gap: 2rem;

    padding: 0.4rem;
    padding-right: 2rem;
  }

  @media screen and (min-width: 1024px) {
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 2.5rem;

    width: 39.5rem;
    height: 53.5rem;

    padding: 0.4rem;
  }
`;

export const StyldBlogCardImage = styled.figure`
  overflow: hidden;

  width: 100%;
  aspect-ratio: 1.8;

  margin-bottom: 0.4rem;

  border-radius: 0.6rem 0.6rem 0 0;

  img {
    display: block;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    flex-shrink: 0;

    width: 29rem;
    height: 32rem;
    aspect-ratio: unset;

    margin-bottom: 0;

    img {
      transform: translateX(-20%);

      width: unset;
      height: 100%;
    }
  }

  @media screen and (min-width: 1024px) {
    width: 38.5rem;
    height: 24.5rem;

    img {
      width: 100%;
      transform: translate(0);
    }
  }
`;

export const StyledBlogCardInfo = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;

    padding: 1.5rem 0 0.6rem;
  }

  @media screen and (min-width: 1024px) {
    flex-grow: 1;
    flex-basis: 20rem;

    padding: 0 1rem;
  }
`;

export const StyledBlogCardHeading = styled.h3`
  margin-bottom: 0.6rem;

  padding: 0 0.5rem;

  font-size: 1.6rem;
  line-height: 150%;
  font-weight: 700;

  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;

    font-size: 2.5rem;
    font-weight: 600;
  }
`;

export const StyledBlogCardText = styled.p`
  overflow: hidden;

  height: 5.4rem;

  margin-bottom: 1.2rem;

  padding: 0 0.5rem;

  font-size: 1.2rem;
  font-weight: 500;
  line-height: 150%;

  color: ${darkTheme.text2};

  @media screen and (min-width: 768px) {
    height: auto;

    font-size: 1.8rem;
    font-weight: 500;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 2rem;
    height: 10.8rem;
  }
`;
export const StyledBlogCardDate = styled.div`
  padding: 0 0.5rem;

  font-size: 1rem;
  line-height: 150%;
  font-weight: 300;
  color: ${darkTheme.text2};

  @media screen and (min-width: 768px) {
    margin-top: auto;

    font-size: 1.6rem;
  }
`;
