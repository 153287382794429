import { StyledCalendarContainer } from 'components/CustomCalendar/styles';
import { StyledDisplayItemsPerPageSelect } from 'components/DisplayItemsPerPageSelect/styles';
import styled from 'styled-components';
export const StyledReferralPayoutsList = styled.div`
  margin-bottom: 2rem;

  ${StyledCalendarContainer} {
    .calendar-back-layer {
      @media screen and (min-width: 768px) {
        right: 0;
      }
    }
  }

  .referral-list-controls-container {
    display: flex;
    align-items: center;

    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 1.8rem;
    }
  }

  .referral-list-heading {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
  }

  .referral-list-controls {
    display: flex;
    gap: 1.5rem;

    margin-left: auto;
    max-height: 3.4rem;

    @media screen and (min-width: 768px) {
      max-height: 4.1rem;
    }
  }

  .referral-list-container {
    .referral-list-header {
      display: flex;

      margin-bottom: 0.2rem;

      border-radius: 10px 10px 0px 0px;

      font-size: 1.4rem;
      font-weight: 500;

      background-color: ${(props) => props.theme.bg1};

      .referral-list-header-element {
        flex-basis: 50%;

        padding: 1rem;

        text-align: center;

        &:not(:last-child) {
          border-right: 1px solid
            ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};
        }

        .sort-by-button {
          .sort-icon {
            fill: currentColor;
          }
        }
      }
    }
    .referral-list-body {
      margin-bottom: 1.2rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 1.8rem;
      }

      .referral-list-row {
        display: flex;

        font-size: 1.2rem;
        font-weight: 500;
        line-height: 150%;

        @media screen and (min-width: 768px) {
          font-size: 1.4rem;
          line-height: 150%;
        }

        &:not(:last-child) {
          margin-bottom: 0.2rem;
        }

        .referral-list-element {
          flex-basis: 50%;

          display: flex;
          justify-content: center;
          align-items: center;

          padding: 1rem;

          background-color: ${(props) => props.theme.bg2};

          &:not(:last-child) {
            border-right: 1px solid
              ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};
          }

          &.date {
            .time {
              display: none;
              @media screen and (min-width: 768px) {
                display: inline;
              }
            }
          }
        }
      }
    }
    .pagination-container {
      display: flex;
      justify-content: center;

      @media screen and (min-width: 768px) {
        justify-content: space-between;
      }

      ${StyledDisplayItemsPerPageSelect} {
        display: none;

        @media screen and (min-width: 768px) {
          display: flex;
        }
      }
    }
  }
`;
