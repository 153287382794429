import { IReferralReward, IReferralPayout, IReferralUser } from 'types';

export const getSortedReferralRewords = (
  rewards: IReferralReward[],
  sortBy: keyof IReferralReward,
  incr: boolean,
) => {
  console.log('run sort rew', sortBy, incr);

  const sortedPayouts = [...rewards].sort((rewardA, rewardB) => {
    switch (true) {
      case sortBy === 'date':
        return incr ? rewardA.date - rewardB.date : rewardB.date - rewardA.date;
      case sortBy === 'sum':
        if (rewardA.sum > rewardB.sum) return incr ? 1 : -1;
        if (rewardA.sum < rewardB.sum) return incr ? -1 : 1;
        return 0;

      default:
        return 0;
    }
  });
  return sortedPayouts;
};

export const filterRewards = (
  rewards: IReferralReward[],
  filters: { date: { from: Date; to: Date } | null },
) => {
  if (!filters.date) return rewards;

  const dateFromTimestamp = filters.date?.from.getTime();
  const dateToTimestamp = filters.date?.to.getTime();

  return rewards.filter((reward) => {
    const isProperdate =
      !dateFromTimestamp ||
      !dateToTimestamp ||
      (reward.date >= dateFromTimestamp && reward.date <= dateToTimestamp)
        ? true
        : false;
    return isProperdate;
  });
};

export const getSortedReferralPayouts = (
  payouts: IReferralPayout[],
  sortBy: keyof IReferralPayout,
  incr: boolean,
) => {
  const sortedpayouts = [...payouts].sort((payoutA, payoutB) => {
    switch (true) {
      case sortBy === 'date':
        return incr ? payoutA.date - payoutB.date : payoutB.date - payoutA.date;
      case sortBy === 'sum':
        if (payoutA.sum > payoutB.sum) return incr ? 1 : -1;
        if (payoutA.sum < payoutB.sum) return incr ? -1 : 1;
        return 0;

      default:
        return 0;
    }
  });
  return sortedpayouts;
};

export const filterReferralPayouts = (
  payouts: IReferralPayout[],
  filters: { date: { from: Date; to: Date } | null },
) => {
  if (!filters.date) return payouts;

  const dateFromTimestamp = filters.date?.from.getTime();
  const dateToTimestamp = filters.date?.to.getTime();

  return payouts.filter((payout) => {
    const isProperdate =
      !dateFromTimestamp ||
      !dateToTimestamp ||
      (payout.date >= dateFromTimestamp && payout.date <= dateToTimestamp)
        ? true
        : false;
    return isProperdate;
  });
};

export const getSortedReferralUSers = (
  users: IReferralUser[],
  sortBy: keyof IReferralUser,
  incr: boolean,
) => {
  const sortedUsers = [...users].sort((userA, userB) => {
    switch (true) {
      case sortBy === 'email':
        if (userA.email > userB.email) return incr ? 1 : -1;
        if (userA.email < userB.email) return incr ? -1 : 1;
        return 0;
      case sortBy === 'registrationDate':
      case sortBy === 'monthlyHashRate':
      case sortBy === 'currnetHashRate':
        return incr
          ? Number(userA[sortBy]) - Number(userB[sortBy])
          : Number(userB[sortBy]) - Number(userA[sortBy]);
      default:
        return 0;
    }
  });
  return sortedUsers;
};

export const filterReferralUsers = (
  users: IReferralUser[],
  filters: { date: { from: Date; to: Date } | null },
) => {
  if (!filters.date) return users;

  const dateFromTimestamp = filters.date?.from.getTime();
  const dateToTimestamp = filters.date?.to.getTime();

  return users.filter((user) => {
    const isProperdate =
      !dateFromTimestamp ||
      !dateToTimestamp ||
      (user.registrationDate >= dateFromTimestamp && user.registrationDate <= dateToTimestamp)
        ? true
        : false;
    return isProperdate;
  });
};
