import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { StyledPeriodSelector } from './styles';
import { ECalculationPeriods } from 'types';
interface ICurrenctSelecProps {
  labelText?: string;
  onChange: (period: ECalculationPeriods) => void;
}

export const PeriodSelector = ({ labelText, onChange }: ICurrenctSelecProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<ECalculationPeriods>(ECalculationPeriods.Week);

  const periods: ECalculationPeriods[] = Object.values(ECalculationPeriods);

  const selectHandler = (period: ECalculationPeriods) => () => {
    setSelected(period);
    onChange(period);
  };
  return (
    <StyledPeriodSelector>
      <label className="label">{labelText}</label>
      <div className="select-container">
        <div className="select">
          {periods.map((period) => (
            <div
              key={uuidv4()}
              className={`select-element ${period === selected && 'selected'}`}
              onClick={selectHandler(period)}
            >
              {t(`calculator.${period}`)}
            </div>
          ))}
        </div>
      </div>
    </StyledPeriodSelector>
  );
};
