import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'components/shared';
import {
  StyledBlogContent,
  StyledBlogSection,
  StyledCards,
  StyledHeading,
  StyledSubHeading,
} from './styles';
import { ReactComponent as RightArrowIcon } from 'assets/icons/circle-right-arrow-icon.svg';
import { BlogCard } from 'components/BlogCard';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
export const BlogSection = () => {
  const { t } = useTranslation();

  //TODO: to get form api
  //https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-6
  const blogArticles: { image: string; header: string; text: string; date: string }[] = [
    {
      image: '/images/blog/43edae8886dd8f20ff1cffb903d153b4.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/2f9cebceaad4f5d22ca9c7fb1151c86f.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
    {
      image: '/images/blog/9df7d1c81a50fa2b6cf4f64ce9ba4089.webp',
      header: 'Best Cryptocurrency to Invest in 2021 - Top Cryptocurrencies',
      text: 'Are you looking for the best coins to invest in 2021? From the thousands of options to choose from, understand which cryptocurrency is the best investment for you',
      date: '05.09.2022',
    },
  ];
  const navigate = useNavigate();
  return (
    <StyledBlogSection>
      <div className="blog-background" />
      <StyledBlogContent>
        <div className="watch-all-button">
          <SecondaryButton outline icon={<RightArrowIcon />} onClick={() => navigate('/blog')}>
            {t('mainPage.blog.allNewsButton')}
          </SecondaryButton>
        </div>
        <StyledHeading>{t('mainPage.blog.heading')}</StyledHeading>
        <StyledSubHeading>{t('mainPage.blog.subHeading')}</StyledSubHeading>
        <StyledCards>
          {blogArticles.map((blogArticle) => (
            <BlogCard key={uuid()} {...blogArticle} onClick={() => navigate('/blog')} />
          ))}
        </StyledCards>
      </StyledBlogContent>
    </StyledBlogSection>
  );
};
