import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';
import { StyledAdminListHeaderCell } from './styles';
interface IAdminListHeaderCellProps {
  children?: React.ReactNode;
  filter?: boolean;
}
export const AdminListHeaderCell = ({ children, filter }: IAdminListHeaderCellProps) => {
  return (
    <StyledAdminListHeaderCell>
      <div className="admin-list-header-cell-title">{children}</div>
      {filter && (
        <button className="admin-list-header-cell-filter">
          <FilterIcon className="admin-list-header-cell-filter-icon" />
        </button>
      )}
    </StyledAdminListHeaderCell>
  );
};
