import styled from 'styled-components';
export const StyledPaginator = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  .page-arrow {
    width: 3.2rem;
    height: 3.2rem;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.accent1)};

    @media screen and (min-width: 768px) {
      width: 4rem;
      height: 4rem;
    }

    > * {
      display: block;

      width: 100%;
      height: 100%;

      rect[fill] {
        fill: currentColor;
        stroke: currentColor;
      }
      path[fill] {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};
      }
    }

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5)};
      > * {
        rect[stroke] {
          stroke: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.text4)};
        }
        path[fill] {
          fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.text4)};
        }
      }
    }
  }

  .pages {
    .pageNumber {
      display: inline-block;

      min-width: 2.4rem;

      padding: 0 0.6rem;

      font-size: 1.2rem;
      font-weight: 500;
      line-height: 150%;
      text-align: center;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.button2 : props.theme.text2)};

      cursor: pointer;

      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }

      &:hover {
        color: ${(props) => props.theme.text4};
      }

      &.current {
        color: ${(props) => props.theme.accent1};
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
`;
