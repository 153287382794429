import { BorderedWrapper, IncomeCalculator } from 'components';
import { StyledIncomeCalculator } from './styles';
export const IncomeCalculatorSection = () => {
  return (
    <StyledIncomeCalculator>
      <BorderedWrapper>
        <IncomeCalculator />
      </BorderedWrapper>
    </StyledIncomeCalculator>
  );
};
