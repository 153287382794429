import { useState } from 'react';
import cn from 'classnames';
import { WorkerGroupsDropdown, WorkerGroup } from 'routes/workers/components/';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { StyledWorkersGroupsSelect } from './styles';
import { IWorkerGroup, TNormalizedGroups } from 'types';
import { useTranslation } from 'react-i18next';
import { TNullable } from 'types/common';

interface IWorkersGroupsSelectProps {
  groups: TNormalizedGroups;
  currentGroupId?: TNullable<IWorkerGroup['id']>;
  onSelect: (groupId: IWorkerGroup['id'] | null) => void;
}

export const WorkersGroupsSelect = ({
  groups,
  onSelect,
  currentGroupId,
}: IWorkersGroupsSelectProps) => {
  const { t } = useTranslation();

  const [selectedGroupId, setCurrentGroupId] = useState<IWorkerGroup['id'] | null>(null);

  const selectGroupHandler = (id: IWorkerGroup['id'] | null) => () => {
    setCurrentGroupId(id);
    onSelect(id);
  };

  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleDropdownHandler: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsCollapsed((prev) => !prev);
  };

  const collapseDropdownHandler: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsCollapsed(true);
  };

  return (
    <StyledWorkersGroupsSelect>
      <div className="selected-group" onClick={toggleDropdownHandler}>
        <div className="selected-group-name">
          {selectedGroupId ? groups.byID[selectedGroupId]?.name : '---'}
        </div>
        <div className={cn('drop-down-arrow', isCollapsed && 'collapsed')}>
          <ArrowDownIcon />
        </div>
      </div>
      {!isCollapsed && (
        <WorkerGroupsDropdown onMouseLeave={collapseDropdownHandler}>
          {/* <WorkerGroup current={selectedGroupId === null} onClick={selectGroupHandler(null)}>
            {t('workersPage.groups.all')}
          </WorkerGroup> */}
          {groups.allIDs
            .filter((id) => id !== currentGroupId)
            .map((id) => (
              <WorkerGroup
                key={id}
                current={selectedGroupId === id || currentGroupId === id}
                onClick={selectGroupHandler(id)}
              >
                {groups.byID[id].name}
              </WorkerGroup>
            ))}
        </WorkerGroupsDropdown>
      )}
    </StyledWorkersGroupsSelect>
  );
};
