import { StyledGroupEditor } from './styles';
import cn from 'classnames';
interface IGroupEditorProps {
  children?: React.ReactNode;
  groupName: IWorkerGroup['name'];
  groupId: IWorkerGroup['id'];
  workersQuantity?: number;
  onEditing: (editingGroup: {
    groupId: IWorkerGroup['id'];
    newName?: IWorkerGroup['name'];
  }) => void;
  onDeleting: (groupToDelete: { groupId: IWorkerGroup['id']; toDelete: boolean }) => void;
  error?: boolean;
}

import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-icon.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IWorkerGroup } from 'types';
export const GroupEditor = ({
  groupName,
  workersQuantity,
  onEditing,
  groupId,
  onDeleting,
  error,
}: IGroupEditorProps) => {
  enum EEditorModes {
    'EDIT',
    'DELETE',
    'INITIAL',
  }
  const { t } = useTranslation();
  const groupNameInputValueRef = useRef<HTMLInputElement>(null);
  const [editorMode, setEditorMode] = useState<EEditorModes>(EEditorModes.INITIAL);

  const [editNameInputValue, setEditNameInputValue] = useState('');

  const changeEditNameInputValueHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setEditNameInputValue(value);

    onEditing(value === '' ? { groupId } : { groupId, newName: value });
  };

  const onBlurInputHandler = () => {
    if (editNameInputValue === groupName) setEditorMode(EEditorModes.INITIAL);
  };

  const editButtonHanler: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (editorMode === EEditorModes.EDIT) {
      setEditNameInputValue('');
      setEditorMode(EEditorModes.INITIAL);
      onEditing({ groupId });
    } else {
      setEditorMode(EEditorModes.EDIT);
      setEditNameInputValue(groupName);
    }
  };

  const deleteButtonHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (editorMode === EEditorModes.DELETE) {
      setEditorMode(EEditorModes.INITIAL);
      onDeleting({ groupId, toDelete: false });
    } else {
      setEditorMode(EEditorModes.DELETE);
      onDeleting({ groupId, toDelete: true });
    }
  };

  useEffect(() => {
    if (editorMode === EEditorModes.EDIT && groupNameInputValueRef.current)
      groupNameInputValueRef.current.focus();
  }, [editorMode]);

  return (
    <StyledGroupEditor className={cn(error && 'error')}>
      <div className="group-editor-group-name">
        {editorMode !== EEditorModes.EDIT && <div>{groupName}</div>}
        {editorMode === EEditorModes.EDIT && (
          <input
            ref={groupNameInputValueRef}
            className="group-editor-group-name-input"
            value={editNameInputValue}
            onChange={changeEditNameInputValueHandler}
            onBlur={onBlurInputHandler}
          />
        )}
      </div>
      <div className="group-editor-worker-quantity">
        {workersQuantity ? workersQuantity : 'n/a'}{' '}
        {t('workersPage.groups.editGroupModal.editor.workers')}
      </div>
      <button
        type="button"
        className={cn('group-editor-edit-button', editorMode === EEditorModes.EDIT && 'active')}
        onClick={editButtonHanler}
      >
        <EditIcon />
      </button>
      <button
        type="button"
        className={cn('group-editor-delete-button', editorMode === EEditorModes.DELETE && 'active')}
        onClick={deleteButtonHandler}
      >
        <DeleteIcon />
      </button>
    </StyledGroupEditor>
  );
};
