import { Input, Toggler } from 'components/shared';
import { StyledEditableEmailInput } from './styles';
import { ReactComponent as EditButton } from 'assets/icons/edit-icon.svg';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
interface IEditableEmailInputProps {
  children?: React.ReactNode;
  labelText?: string;
  placeholderText?: string;
}
export const EditableEmailInput = ({
  children,
  labelText,
  placeholderText,
}: IEditableEmailInputProps) => {
  const currentEmail = 'some@test.tu';

  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const editModeButtonHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (isEditing) {
      setIsEditing(false);
      return;
    }

    setIsEditing(true);
  };

  useEffect(() => {
    if (isEditing) inputRef.current?.focus();
  }, [isEditing]);

  return (
    <StyledEditableEmailInput>
      <div className="email-input-group">
        {labelText && <label className="email-input-label">{labelText}</label>}
        <div className="email-input-container">
          <Input
            ref={inputRef}
            placeholder={isEditing ? placeholderText : currentEmail}
            className="email-editable-input"
            disabled={!isEditing}
          />
          <button
            className={cn('email-editable-edit-button', isEditing && 'editing')}
            onClick={editModeButtonHandler}
          >
            <EditButton className="email-editable-edit-button__icon" />
          </button>
        </div>
      </div>
      <div className="email-input-controls">
        <Toggler />
      </div>
    </StyledEditableEmailInput>
  );
};
