import * as Yup from 'yup';
import {
  VALIDATION_ERROR_TERMSAGREE_SHOULD_ACCEPTED,
  VALIDATION_ERROR_USER_EMAIL_INVALID,
  VALIDATION_ERROR_USER_EMAIL_REQUIRED,
  VALIDATION_ERROR_USER_NAME_LONG,
  VALIDATION_ERROR_USER_NAME_OR_PHONE_REQUIRED,
  VALIDATION_ERROR_USER_NAME_REQUIRED,
  VALIDATION_ERROR_USER_NAME_SHORT,
  VALIDATION_ERROR_USER_PASSWORD_REQUIRED,
  VALIDATION_ERROR_USER_PASSWORD_SHORT,
  VALIDATION_ERROR_USER_PHONE_INVALID,
  VALIDATION_ERROR_USER_PHONE_REQUIRED,
  VALIDATION_ERROR_USER_REPEAT_PASSWORD_UNEQUAL,
} from 'constants/errorsConstants';
export const validationSchemaRegistration = Yup.object().shape({
  userName: Yup.string()
    .min(2, VALIDATION_ERROR_USER_NAME_SHORT)
    .max(20, VALIDATION_ERROR_USER_NAME_LONG)
    .required(VALIDATION_ERROR_USER_NAME_REQUIRED),
  userEmail: Yup.string()
    .email(VALIDATION_ERROR_USER_EMAIL_INVALID)
    .required(VALIDATION_ERROR_USER_EMAIL_REQUIRED),
  userPhone: Yup.number()
    .typeError(VALIDATION_ERROR_USER_PHONE_INVALID)
    .min(10000, VALIDATION_ERROR_USER_PHONE_REQUIRED)
    .required(VALIDATION_ERROR_USER_PHONE_REQUIRED),
  userNewPassword: Yup.string()
    .min(8, VALIDATION_ERROR_USER_PASSWORD_SHORT)
    .required(VALIDATION_ERROR_USER_PASSWORD_REQUIRED),
  userRepeatPassword: Yup.string()
    .equals([Yup.ref('userNewPassword')], VALIDATION_ERROR_USER_REPEAT_PASSWORD_UNEQUAL)
    .required(VALIDATION_ERROR_USER_REPEAT_PASSWORD_UNEQUAL),
  termsAgree: Yup.boolean().isTrue(VALIDATION_ERROR_TERMSAGREE_SHOULD_ACCEPTED),
});
export const validationSchemaChangePassword = Yup.object().shape({
  userNewPassword: Yup.string()
    .min(8, VALIDATION_ERROR_USER_PASSWORD_SHORT)
    .required(VALIDATION_ERROR_USER_PASSWORD_REQUIRED),
  userRepeatPassword: Yup.string()
    .equals([Yup.ref('userNewPassword')], VALIDATION_ERROR_USER_REPEAT_PASSWORD_UNEQUAL)
    .required(VALIDATION_ERROR_USER_REPEAT_PASSWORD_UNEQUAL),
});
export const validationSchemaSignIn = Yup.object().shape({
  userName: Yup.string().required(VALIDATION_ERROR_USER_NAME_REQUIRED),
  userPassword: Yup.string().required(VALIDATION_ERROR_USER_PASSWORD_REQUIRED),
});
export const validationSchemaRecovery = Yup.object().shape({
  userEmailPhone: Yup.string().required(VALIDATION_ERROR_USER_NAME_OR_PHONE_REQUIRED),
});
