import { StyledBurgerButton, StyledCloseIconContainer } from './styles/StyledBurgerButton';

import { ReactComponent as BurgerIcon } from 'assets/icons/burger-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-cross.svg';

interface IBurgerButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  close?: boolean;
}
export const BurgerButton = (props: IBurgerButtonProps) => {
  return (
    <StyledBurgerButton onClick={props.onClick}>
      {props.close ? (
        <StyledCloseIconContainer>
          <CloseIcon />
        </StyledCloseIconContainer>
      ) : (
        <BurgerIcon />
      )}
    </StyledBurgerButton>
  );
};
