import styled from 'styled-components';

export const StyledHashrateDiagram = styled.div`
  margin: auto;

  padding-top: 1.5rem;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  .data-error {
    padding: 1rem 0;

    color: red;
  }

  .modes-selector {
    display: flex;

    border-bottom: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.stroke2 : props.theme.bg5)};

    margin-bottom: 1.5rem;

    .modes-selector-btn {
      display: block;

      width: 22.3rem;

      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.7rem;
      letter-spacing: 0em;
      text-align: center;
      color: ${(props) => props.theme.accent4};

      &:hover {
        color: ${(props) => props.theme.accent1};
      }

      &.selected {
        position: relative;

        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        color: currentColor;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;

          width: 100%;

          transform: translateY(50%);

          border-bottom: 3px solid currentColor;
        }
      }
    }
  }

  .hashrate-chart-legend {
    display: flex;
    justify-content: center;
    gap: 3.6rem;

    padding: 0.6rem;

    &-element {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      font-size: 1rem;
      line-height: 150%;

      &::before {
        content: '';
        display: block;
        border-radius: 100%;
        width: 1.5rem;
        height: 1.5rem;

        &.secondary {
          stroke: ${(props) => props.theme.accent2};
        }
      }

      &.hashrate::before {
        background-color: ${(props) => props.theme.accent1};
      }
      &.reject::before {
        background-color: ${(props) => props.theme.accent2};
      }
    }
  }
`;

export const StyledHashRateChartElement = styled.article`
  .svg-hashrate-chart {
    font-weight: 600;
    line-height: 150%;
    &-bg {
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg4)};
    }

    .data-feild {
      cursor: pointer;
    }

    .y-unit {
      &-text {
        width: 4rem;
        height: 2.4rem;

        fill: currentColor;
        font-size: 1.6rem;
      }
    }

    .y-line {
      stroke: ${(props) => (props.theme.id === 'dark' ? props.theme.stroke2 : props.theme.bg6)};
      stroke-width: 1;
      stroke-dasharray: 6 6;
    }

    .x-unit {
      cursor: pointer;
      &-bg {
        fill: #cfb9ff;

        &.other-selected {
          fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg5 : props.theme.bg2)};
          stroke: ${(props) => props.theme.accent2};
          stroke-width: 1;
          opacity: 0.5;
        }
      }
      &-text {
        font-size: 1.2rem;
        fill: ${(props) => props.theme.button3};
        text-anchor: middle;
      }
    }
    .data-line {
      fill: none;
      stroke-width: 2;
      &.primary {
        stroke: ${(props) =>
          props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent1};
        /* fill: ${(props) =>
          props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent1}; */
      }

      &.secondary {
        stroke: ${(props) => props.theme.accent2};
      }
    }
  }
`;

export const StyledTooltipGroup = styled.g`
  .tooltip-group {
    &-bg {
      stroke: ${(props) => (props.theme.id === 'dark' ? props.theme.bg5 : props.theme.bg6)};
      stroke-width: 1;
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.bg5 : props.theme.bg2)};
      -webkit-filter: drop-shadow(0px 6px 5px #00000040);
      filter: drop-shadow(0px 6px 5px #00000040);
    }

    &-text {
      fill: ${(props) => (props.theme.id == 'dark' ? props.theme.text4 : 'currentColor')};

      font-size: 1.2rem;

      &.hashrate,
      &.reject {
        fill: ${(props) => (props.theme.id == 'dark' ? props.theme.text1 : 'currentColor')};
        font-size: 1.6rem;
      }
    }
  }
`;

export const StyledHoverUpGroup = styled.g`
  &.dimmed {
    opacity: 0.5;
  }

  .data-point {
    &-line {
      stroke: ${(props) => props.theme.accent2};
      stroke-width: 1;
    }
    &.primary {
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent1)};
    }

    &.secondary {
      fill: ${(props) => props.theme.accent2};
    }
  }
`;
