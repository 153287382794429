import { StyledInput } from 'components/shared/Input/styles';
import styled from 'styled-components';
export const StyledDonats = styled.div`
  ${StyledInput} {
    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

    /* font-size: 1.2rem; */
    /* padding: 0.8rem 1rem; */

    /* @media screen and (min-width: 768px) {
      font-size: 1.8rem;
      line-height: 150%;
      padding: 1rem;
    } */
  }

  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  .donat-input-label {
    display: block;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    padding: 0 1rem;

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      /* margin-bottom: 0.2rem; */
    }
  }

  .donat-input-remark {
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};
    opacity: 0.38;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */

    @media screen and (min-width: 768px) {
      opacity: 1;
      font-size: 1.2rem;
    }
  }
`;
