import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
import { StyledDataExportButton } from './styles';
import { useTranslation } from 'react-i18next';
interface IDataExportButtonProps {
  children?: React.ReactNode;
}
export const DataExportButton = ({ children }: IDataExportButtonProps) => {
  const { t } = useTranslation();
  return (
    <StyledDataExportButton>
      <DownloadIcon className="button-icon" />
      <span className="title">{t('adminPage.dataExportButton')}</span>
    </StyledDataExportButton>
  );
};
