import styled from 'styled-components';
export const StyledFAQHero = styled.div`
  margin-bottom: 4rem;

  .faq-hero-content {
    @media screen and (min-width: 768px) {
      padding-top: 5.8rem;
    }

    .faq-hero-heros {
      position: relative;
      overflow: hidden;

      width: 30rem;
      height: 18.5rem;

      margin: auto;
      margin-bottom: 1.2rem;

      padding: 3.6rem 1.5rem 0;

      background-image: url('/images/bg/faq-folder-yellow-dark-sx.svg');
      background-repeat: no-repeat;

      @media screen and (min-width: 768px) {
        width: 70rem;
        height: 37rem;

        margin-bottom: 4rem;

        padding: 7.1rem 7.5rem;

        background-image: url('/images/bg/faq-folder-yellow-dark-sm.svg');
      }

      @media screen and (min-width: 1024px) {
        width: 95rem;
        height: 37rem;

        padding: 9.1rem 10rem;

        background-image: url('/images/bg/faq-folder-yellow-dark-md.svg');
      }

      @media screen and (min-width: 1440px) {
        width: 132rem;

        margin-bottom: 5rem;

        padding: 11.1rem 5.4rem;

        background-image: url('/images/bg/faq-folder-yellow-dark-lg.svg');
      }

      .heading {
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 110%;
        letter-spacing: -0.32px;
        color: ${(props) => (props.theme.id === 'dakr' ? props.theme.text3 : props.theme.text3)};

        @media screen and (min-width: 768px) {
          font-size: 5.5rem;
          line-height: 126%;
        }

        @media screen and (min-width: 1024px) {
          font-size: 8rem;
          letter-spacing: -0.8px;
          text-align: start;
        }
      }

      .faq-hero-image {
        position: absolute;
        bottom: 0;
        right: 3.8rem;

        width: 17.3rem;
        height: 14.6rem;

        @media screen and (min-width: 768px) {
          right: 4.5rem;

          width: 37.7rem;
          height: 31.6rem;
        }

        @media screen and (min-width: 1024px) {
          right: 7.3rem;

          width: 46.3rem;
          height: 38.6rem;
        }
        @media screen and (min-width: 1440px) {
          right: 8.3rem;
        }
      }
    }
  }
`;
