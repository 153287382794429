import styled from 'styled-components';

export const StyledUserDataLayoutContainer = styled.div`
  padding: 0.5rem 1rem 2.5rem;

  .content-container {
    position: relative;
    overflow-x: hidden;

    max-width: 150.5rem;

    margin: auto;

    padding: 5.4rem 0.5rem 2.5rem;

    @media screen and (min-width: 768px) {
      padding: 5.4rem 2rem 2.5rem;
    }

    .bg-header {
      position: absolute;
      top: 0;
      left: 0;

      width: 34rem;
      height: 100%;

      background-color: ${(props) => props.theme.bg3};
      clip-path: path(
        'M0 24 C0 12 0 7 4 4 C7 0 12 0 24 0 H200 C200 0 203 0 206 1 C208 2 210 5 213 10 L224 24 C228 30 230 32 232 34 C235 35 238 35 244 35 H244 V100 H0 V24Z'
      );

      @media screen and (min-width: 768px) {
        clip-path: path(
          'M0 24 C0 12 0 7 4 4 C7 0 12 0 24 0 H300 C300 0 303 0 306 1 C308 2 310 5 313 10 L324 24 C328 30 330 32 332 34 C335 35 338 35 344 35 H344 V100 H0 V24Z'
        );
      }
    }
    .bg {
      position: absolute;
      top: 3.5rem;
      left: 0;

      width: 100%;
      height: calc(100% - 3.5rem);

      border-radius: 20px;

      background-color: ${(props) => props.theme.bg3};
    }

    .heading {
      position: absolute;
      top: 0.8rem;
      left: 2rem;

      display: flex;
      align-items: center;
      gap: 1.2rem;

      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 600;
      color: ${(props) => props.theme.text2};

      .dot {
        width: 0.5rem;
        height: 0.5rem;

        border-radius: 100%;

        background-color: currentColor;
      }
    }

    .content {
      position: relative;
    }
  }
`;
