import { ICharge } from 'types';

export const getSortedCharges = (charges: ICharge[], sortBy: keyof ICharge, incr: boolean) => {
  const sortedCharges = [...charges].sort((chargeA, chargeB) => {
    switch (true) {
      case sortBy === 'date':
        return incr ? chargeA.date - chargeB.date : chargeB.date - chargeA.date;
      case sortBy === 'hashRate':
      case sortBy === 'generalAccural':
      case sortBy === 'chargesPerUnit':
        if (chargeA[sortBy] > chargeB[sortBy]) return incr ? 1 : -1;
        if (chargeA[sortBy] < chargeB[sortBy]) return incr ? -1 : 1;
        return 0;

      default:
        return 0;
    }
  });
  return sortedCharges;
};

export const filterCharges = (
  charges: ICharge[],
  filters: { date: { from: Date; to: Date } | null },
) => {
  if (!filters.date) return charges;

  const dateFromTimestamp = filters.date?.from.getTime();
  const dateToTimestamp = filters.date?.to.getTime();

  return charges.filter((charge) => {
    const isProperdate =
      !dateFromTimestamp ||
      !dateToTimestamp ||
      (charge.date >= dateFromTimestamp && charge.date <= dateToTimestamp)
        ? true
        : false;
    return isProperdate;
  });
};
