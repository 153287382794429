import { createPortal } from 'react-dom';
import { StyledModal } from './styles';
import React from 'react';

export const Modal = ({ children, show }: { children: React.ReactNode; show?: boolean }) => {
  if (!show) {
    document.body.classList.remove('overflow-hidden');
    return null;
  }
  if (show) document.body.classList.add('overflow-hidden');

  return <>{createPortal(<StyledModal>{children}</StyledModal>, document.body)}</>;
};
