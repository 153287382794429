import { StyledContent } from './StyledContainer';
import { useTranslation } from 'react-i18next';
import { AddWorker, Footer, Layout, UserDataLayoutContainer } from 'components';
import {
  AccountInformation,
  HashrateInformation,
  HashrateStatistics,
  IncomeCalculatorSection,
  Miner,
  Well,
} from './components';
import {
  fetchUserHashrateStatistics,
  fetchUserMinersStatistics,
  fetchUserStat,
} from 'store/thunks/userStatisticsSliceThunks';
import { useAppDispatch } from 'store/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from 'store/slices/authSlice';

export const StatisticsPage = () => {
  const { t } = useTranslation();
  const { token } = useSelector(selectAuth);
  const [nextDifficultyChange, setNextDifficultyChange] = useState<string | null>(null);
  const [previousRetarget, setPreviousRetarget] = useState<string | null>(null);
  const [nextRetarget, setNextRetarget] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const UPDATE_MINERS_DATA_INTERVAL_MS = 15000;

  useEffect(() => {
    if (token) {
      //TODO: make geting token from state
      //https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20Dev%20Issues?issues=THASH-T-37
      dispatch(fetchUserHashrateStatistics(token));
      dispatch(fetchUserStat());
      dispatch(fetchUserMinersStatistics(token));
      const interval = setInterval(() => {
        dispatch(fetchUserMinersStatistics(token));
      }, UPDATE_MINERS_DATA_INTERVAL_MS);
      return () => clearInterval(interval);
    }
  }, [token]);

  useEffect(() => {
    fetch('https://mempool.space/api/v1/difficulty-adjustment')
      .then((res) => res.json())
      .then((data) => {
        const nextDifficultyChange = Number(data.difficultyChange).toFixed(4);
        const nextRetarget = data.estimatedRetargetDate as number;

        setNextDifficultyChange(`${nextDifficultyChange}%`);
        const nextRetargetDate = new Date(nextRetarget);
        const nextRetargetDateLocaleString = nextRetargetDate.toLocaleDateString('en-US', {
          day: 'numeric',
          year: 'numeric',
          month: 'long',
        });
        const nextRetargetTimeLocaleString = nextRetargetDate.toLocaleTimeString('en-US');

        setNextRetarget(`${nextRetargetDateLocaleString} at ${nextRetargetTimeLocaleString}`);

        const previousTime = data.previousTime as number;
        const previousRetargetDate = new Date(previousTime * 1000);
        const previousRetargetDateLocaleString = previousRetargetDate.toLocaleDateString('en-US', {
          day: 'numeric',
          year: 'numeric',
          month: 'long',
        });

        const previousRetargetTimeLocaleString = previousRetargetDate.toLocaleTimeString('en-US');

        const previousRetarget = Number(data.previousRetarget).toFixed(4);

        setPreviousRetarget(
          `${previousRetargetDateLocaleString} at ${previousRetargetTimeLocaleString} (${previousRetarget}%)`,
        );
      })
      .catch((error) => console.error('Failed to fetch difficulty', error));
  }, []);

  return (
    <Layout>
      <UserDataLayoutContainer pageName="statisticsPage">
        <StyledContent>
          <div className="add-worker-dialog-container">
            <AddWorker />
          </div>
          <div className="info-elements-container">
            <AccountInformation />
            <HashrateInformation />
            <Miner />
            <HashrateStatistics />
            <IncomeCalculatorSection />
          </div>
          <Well />
          <div className="aux-data-container">
            <div className="aux-data-group">
              <h2 className="h2">{t('statisticsPage.difficulty.heading')}</h2>
              <div className="aux-data-element">
                <p className="aux-data-element__heading">
                  {t('statisticsPage.difficulty.nextDifChange.heading')}
                </p>
                <p>{nextDifficultyChange ?? 'unavailable'}</p>
              </div>
              <div className="aux-data-element">
                <p className="aux-data-element__heading">
                  {t('statisticsPage.difficulty.nextRetarget.heading')}
                </p>
                <p>{nextRetarget ?? 'unavailable'}</p>
              </div>
              <div className="aux-data-element">
                <p className="aux-data-element__heading">
                  {t('statisticsPage.difficulty.prevRetarget.heading')}
                </p>
                <p>{previousRetarget ?? 'unavailable'}</p>
              </div>
            </div>
            <div className="aux-data-group">
              <h2 className="h2">{t('statisticsPage.payout.heading')}</h2>
              <div className="aux-data-element">
                <p className="aux-data-element__heading">
                  {t('statisticsPage.payout.system.heading')}
                </p>
                <p>{t('statisticsPage.payout.system.text')}</p>
              </div>
              <div className="aux-data-element">
                <p className="aux-data-element__heading">
                  {t('statisticsPage.payout.minAmount.heading')}
                </p>
                <p>{t('statisticsPage.payout.minAmount.text')}</p>
              </div>
              <div className="aux-data-element">
                <p className="aux-data-element__heading">0.00001 BTC</p>
                <p>{t('statisticsPage.payout.time.text')}</p>
              </div>
            </div>
          </div>
          <Footer smaller />
        </StyledContent>
      </UserDataLayoutContainer>
    </Layout>
  );
};
