import {
  CustomCalendar,
  DisplayItemsPerPageSelect,
  DownloadDataButton,
  Footer,
  Layout,
  Paginator,
  Search,
  ServicePageHero,
  UserDataLayoutContainer,
} from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PayoutList,
  PayoutListContainer,
  PayoutListControls,
  PayoutsListFilter,
} from './components';

import { IPayout } from 'types';

import { StyledContent } from './StyledContent';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'lib';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { fetchPayoutsList } from 'store/thunks/userPayoutThunks';
import { payoutsListSetPage, setItemsPerPayoutsListPage } from 'store/slices/userPayoutSlice';

import { GET_WORKERS_TIMEOUT_MS } from 'constants/appConstants';

export const PayoutPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changeWalletAddressHandler = () => {
    navigate(`${Routes.settingsPath()}?focus=walletaddress`);
  };

  const [searchFilter, setSearchFilter] = useState('');
  const [dateFilter, setDateFilter] = useState<{ from: Date; to: Date } | null>(null);

  const { pagesQuantity, currentPage, itemsPerPage } = useSelector(
    (state: RootState) => state.userPayouts,
  );

  const setSearchFilterHandler = (value: string) => {
    setSearchFilter(value);
  };

  const changeCurrentPageHandler = (nextPage: number) => {
    dispatch(payoutsListSetPage(nextPage));
    dispatch(fetchPayoutsList());
  };
  const changeItemsPerPageQuantityHAndler = (itemsQuantity: number) => {
    dispatch(setItemsPerPayoutsListPage(itemsQuantity));
    dispatch(fetchPayoutsList());
  };

  const changeDateRangeHandler = (range: { from: Date; to: Date }) => {
    setDateFilter(range);
  };

  const [sortBy, setSortBy] = useState<{ sortBy: keyof IPayout; incr: boolean }>({
    sortBy: 'date',
    incr: true,
  });

  const setSortHandler = (sortBy: { sortBy: keyof IPayout; incr: boolean }) => {
    setSortBy(sortBy);
  };

  const getPayoutsList = () => {
    dispatch(fetchPayoutsList());

    return setTimeout(() => {
      getPayoutsList();
    }, GET_WORKERS_TIMEOUT_MS);
  };

  useEffect(() => {
    const timeout = getPayoutsList();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Layout>
      <UserDataLayoutContainer pageName="payoutPage">
        <StyledContent>
          <ServicePageHero
            title={t('serviceHero.changeWalletButton')}
            onClickButton={changeWalletAddressHandler}
          ></ServicePageHero>
          <PayoutListContainer>
            <PayoutListControls>
              <DownloadDataButton onClick={() => console.log('download')} />
              <CustomCalendar onChange={changeDateRangeHandler} />
              <PayoutsListFilter />
              <Search onChange={setSearchFilterHandler} />
            </PayoutListControls>
            <PayoutList onSort={setSortHandler} />
            {pagesQuantity > 0 && (
              <div className="pagination-container">
                <Paginator
                  onChange={changeCurrentPageHandler}
                  pageQuantity={pagesQuantity}
                  currentPage={currentPage}
                />
                <DisplayItemsPerPageSelect
                  onChange={changeItemsPerPageQuantityHAndler}
                  currentItemsPerPageQuantity={itemsPerPage}
                />
              </div>
            )}
          </PayoutListContainer>
          <Footer smaller />
        </StyledContent>
      </UserDataLayoutContainer>
    </Layout>
  );
};
