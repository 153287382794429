import styled from 'styled-components';
import { StyledSCrollBar } from 'styles/scrollbar';
import { darkTheme } from 'themes';
export const StyledTimezoneSelect = styled.div`
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  margin-bottom: 1.3rem;

  .timezone-input-label {
    display: block;

    padding: 0 1rem;

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
  }

  .timezone-select-container {
    position: relative;
    /* z-index: 1; */
  }

  .timezone-select-value-container {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    gap: 0.2rem;

    padding: 0.7rem 1rem;
    padding-right: 3rem;

    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    border-radius: 8px;

    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;

    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      line-height: 150%;
    }

    .timezone-select-value-search-input {
      display: none;

      width: 0;
      max-width: 50%;

      border: none;

      font-size: 1.2rem;
      font-weight: 400;
      line-height: 150%;
      color: currentColor;

      outline: none;

      background: none;

      &::placeholder {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.text5)};
      }

      &.selecting {
        display: block;

        width: auto;
      }

      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
        line-height: 150%;
      }
    }

    .timezone-select-value-selected {
      flex-grow: 1;

      display: inline-block;

      text-align: start;

      transition: none;

      &.selecting {
        max-width: 10rem;
      }

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 1.4rem;
        line-height: 150%;

        &:not(.selecting) {
          .timezone-select-value-selected__hint {
            display: inline;
          }
        }
      }

      .timezone-select-value-selected__hint {
        display: none;

        color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : darkTheme.accent2)};
      }

      .timezone-select-value-selected__value {
        display: inline-block;
        overflow: hidden;

        max-width: 10rem;

        margin-left: auto;

        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .timezone-select-value-dropdown-button {
      position: absolute;
      top: 0;
      right: 1rem;

      display: flex;
      align-items: center;

      width: 1.1rem;
      height: 100%;

      .icon {
        width: 100%;

        fill: currentColor;
      }
    }
  }

  .timezone-select__dropdown-container {
    position: absolute;
    bottom: 1.2rem;
    z-index: 0;

    overflow: hidden;

    width: 100%;
    height: 0;

    padding: 1rem;
    padding-top: 0;

    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
    border-radius: 8px;

    background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

    transform: translateY(100%);

    &.selecting {
      height: auto;

      padding-top: 2rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      line-height: 150%;
    }
  }

  .timezone-select__dropdown {
    ${StyledSCrollBar}

    max-height: 23rem;
  }

  .timezone-select__option {
    display: block;

    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    text-align: start;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &:hover:not(.selected) {
      color: ${({ theme }) => (theme.id === 'dark' ? theme.accent1 : theme.accent5)};
    }
    &.selected {
      color: ${({ theme }) => (theme.id === 'dark' ? theme.accent2 : theme.text5)};
    }

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      line-height: 150%;
    }
  }
`;
