import styled from 'styled-components';
const StyledRoundButton = styled.button`
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.accent2 : props.theme.button2};
`;
interface IRoundButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
export const RoundButton = ({ children, onClick, type = 'button' }: IRoundButtonProps) => {
  return (
    <StyledRoundButton type={type} onClick={onClick}>
      {children}
    </StyledRoundButton>
  );
};
