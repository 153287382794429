import styled from 'styled-components';

export const StyledAccountingContentData = styled.div`
  .profit24 {
    margin-bottom: 1.8rem;

    font-weight: 500;
    text-align: center;

    .profit24_header {
      margin-bottom: 0.2rem;

      font-size: 1rem;
      line-height: 150%;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text4 : props.theme.accent1)};

      @media screen and (min-width: 768px) {
        margin-bottom: 0.5rem;

        font-size: 1.6rem;
        line-height: 150%;
        font-weight: 400;
      }
    }

    .profit24_data {
      font-size: 1.8rem;
      line-height: 150%;
      letter-spacing: 0.18px;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

      @media screen and (min-width: 768px) {
        font-size: 2.2rem;
        line-height: 150%;
        font-weight: 500;
      }
    }
  }

  .average-profit {
    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    text-align: center;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text4 : props.theme.accent1)};

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 150%;
    }
  }
`;

export const StyledAccouningFooterDataElement = styled.div`
  font-size: 1.4rem;
  line-height: 150%;
  font-weight: 500;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 1.6rem;
    line-height: 150%;

    .data {
      margin-bottom: 0.2rem;
    }
  }

  @media screen and (min-width: 1024px) {
    width: 17rem;
    text-align: start;
  }

  &.row {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    padding: 0 0.6rem;
  }

  .reference {
    font-size: 1rem;
    line-height: 150%;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text4 : props.theme.accent1)};

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
      line-height: 150%;
    }
  }
`;
