import styled from 'styled-components';

export const StyledStatisticFooterDataElement = styled.div`
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */

  &.row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 0.6rem;
  }

  .reference {
    color: ${(props) => props.theme.text4};
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%; /* 15px */
  }
`;
