import { AdminElementsWrapper, SecondaryButton } from 'components';
import { StyledAdminCostAdjustment } from './styles';
import { useTranslation } from 'react-i18next';
import { useId, useState } from 'react';
interface IAdminCostAdjustmentProps {
  children?: React.ReactNode;
  heading: string;
  onSubmit: (value: number) => void;
}
export const AdminCostAdjustment = ({ heading, onSubmit }: IAdminCostAdjustmentProps) => {
  const { t } = useTranslation();

  const [amount, setAmount] = useState(0);

  const buttonIncrementHandler = () => setAmount((prev) => (prev += 1));
  const buttonDecrementtHandler = () => setAmount((prev) => (prev -= 1));
  const changeAmountHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setAmount(Number(event.target.value));
  };

  const submitHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit(amount);
  };
  const inputId = useId();
  return (
    <StyledAdminCostAdjustment>
      <AdminElementsWrapper>
        <h3 className="heading">{heading}</h3>
        <form className="form" onSubmit={submitHandler}>
          <div className="input-group">
            <label htmlFor={inputId} hidden>
              {heading}
            </label>
            <input
              id={inputId}
              type="number"
              className="input"
              value={amount}
              onChange={changeAmountHandler}
            ></input>
            <div className="number-adjust">
              <button
                className="number-adjust-button incr"
                type="button"
                onClick={buttonIncrementHandler}
              >
                <svg
                  className="button-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                >
                  <path
                    d="M15 7L9.30158 2.11564C8.5526 1.47366 7.4474 1.47366 6.69842 2.11564L1 7"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                className="number-adjust-button decr"
                type="button"
                onClick={buttonDecrementtHandler}
              >
                <svg
                  className="button-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                >
                  <path
                    d="M15 1L9.30158 5.88436C8.5526 6.52634 7.4474 6.52634 6.69842 5.88436L1 0.999999"
                    strokeWidth="2"
                    strokeLinecap="round"
                    stroke="white"
                  />
                </svg>
              </button>
            </div>
          </div>
          <SecondaryButton type="submit" title={t('adminPage.saveButton')} outline />
        </form>
      </AdminElementsWrapper>
    </StyledAdminCostAdjustment>
  );
};
