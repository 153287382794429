import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountRepository } from 'repositories/AccountRepository';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { IUserBalanceData, IWorker } from 'types';
import { EApiResponseStatus } from 'types/api';
import { IUserHashrateDataResponse, IUserStat } from 'types/hashrate';

export const fetchUserHashrateStatistics = createAsyncThunk<IUserHashrateDataResponse[], string>(
  'userStatistics/fetchUserHashrateStatistics',
  async (token) => {
    // TODO: Test purpose only
    if (token === 'test-token') {
      const now = new Date('2024-05-26T00:00:00Z');
      const nowISOString = now.toISOString();
      const fromDate = new Date(nowISOString);
      fromDate.setDate(now.getDate() - 1);
      const dailyFromDateString = fromDate.toISOString();
      fromDate.setDate(now.getDate() - 8);
      const weeklyFromDateString = fromDate.toISOString();
      fromDate.setDate(now.getDate() - 30);
      const monthlyFromDateString = fromDate.toISOString();

      const dailyHashrateResponse = UserRepository.hashRate(
        { from: dailyFromDateString, to: nowISOString },
        token,
      );
      const weeklyHashrateResponse = UserRepository.hashRate(
        { from: weeklyFromDateString, to: nowISOString },
        token,
      );
      const monthlyHashrateResponse = UserRepository.hashRate(
        { from: monthlyFromDateString, to: nowISOString },
        token,
      );

      return Promise.all([dailyHashrateResponse, weeklyHashrateResponse, monthlyHashrateResponse]);
    }
    // TODO: Test purpose only
    //TODO: refactoring, separate into handler:
    //https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20Dev%20Issues?issues=THASH-T-10
    const now = new Date();

    const nowISOString = now.toISOString();
    const fromDate = new Date(nowISOString);
    fromDate.setDate(now.getDate() - 1);
    const dailyFromDateString = fromDate.toISOString();
    fromDate.setDate(now.getDate() - 8);
    const weeklyFromDateString = fromDate.toISOString();
    fromDate.setDate(now.getDate() - 30);
    const monthlyFromDateString = fromDate.toISOString();

    const dailyHashrateResponse = UserRepository.hashRate(
      { from: dailyFromDateString, to: nowISOString },
      token,
    );
    const weeklyHashrateResponse = UserRepository.hashRate(
      { from: weeklyFromDateString, to: nowISOString },
      token,
    );
    const monthlyHashrateResponse = UserRepository.hashRate(
      { from: monthlyFromDateString, to: nowISOString },
      token,
    );

    return Promise.all([dailyHashrateResponse, weeklyHashrateResponse, monthlyHashrateResponse]);
  },
);

export const fetchUserStat = createAsyncThunk<IUserStat[], void, { state: { auth: IAuthState } }>(
  'userStatistics/fetchUserStats',
  async (_, { getState }) => {
    const {
      auth: { token },
    } = getState();
    if (!token) throw Error('no token');
    const userStatResponse = await UserRepository.getUserStat(token);
    if (userStatResponse.status === EApiResponseStatus.FAILURE) throw Error(userStatResponse.error);
    return userStatResponse.data;
  },
);

export const fetchUserMinersStatistics = createAsyncThunk<IWorker[], string>(
  'userStatistics/fetchUserMinersStatistics',
  async (token) => {
    const getCountResponse = await UserRepository.getWorkers(token, { page: '0', size: '10' });

    if (getCountResponse.status === 'failed') return [];

    const allMinersResponse = await UserRepository.getWorkers(token, {
      page: '0',
      size: String(getCountResponse.data.count),
    });

    return allMinersResponse.data.result;
  },
);
