import { StyledEditablePhoneInput } from 'components/EditablePhoneInput/styles';
import styled from 'styled-components';
export const StyledTwoFactorAuth = styled.article`
  @media screen and (min-width: 1024px) {
    flex-basis: 50%;
    max-width: 50%;
  }
  @media screen and (min-width: 1440px) {
    max-width: calc(50% - 1rem);
  }

  .two-factor-auth-inputs {
    > *:not(:last-child) {
      margin-bottom: 1.6rem;
    }
    margin-bottom: 0.5rem;
  }
`;
