import styled from 'styled-components';
export const StyledPayoutsListFilter = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  width: 3.2rem;
  height: 3.2rem;

  margin-left: auto;

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  border-radius: 5px;

  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

  @media screen and (min-width: 768px) {
    width: 4.1rem;
    height: 4.1rem;

    padding: 0.8rem;
  }

  @media screen and (min-width: 1440px) {
    width: auto;
  }

  .filters-container {
    display: none;
    position: absolute;
    bottom: 0;
    right: -1px;
    transform: translateY(100%);
    border: inherit;
    background-color: inherit;
    border-radius: 5px 0px 5px 5px;
    padding: 0.7rem 1.5rem;
    display: none;

    @media screen and (min-width: 1440px) {
      position: relative;
      transform: unset;
      display: flex;
      align-items: center;
      gap: 3rem;
      border: none;
      padding: unset;
    }

    &.smallest {
      display: none;
      padding: 1.2rem 1.5rem;
      min-width: 16.2rem;
      .workers-filter-heading {
        color: ${(props) => props.theme.accent2};
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        text-align: start;
      }
    }
  }

  .filters-list-toggle {
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    visibility: hidden;

    @media screen and (min-width: 1400px) {
      display: none;
    }

    &:checked ~ .filters-list-toggle-label {
      .open {
        display: none;
      }
      .close {
        display: block;
      }
    }
    &:checked ~ .filters-container {
      &.smallest {
        display: block;
      }
      @media screen and (min-width: 768px) {
        display: block;
        &.smallest {
          display: none;
        }
      }
      @media screen and (min-width: 1440px) {
        display: flex;
      }
    }
  }

  .filters-list-toggle-label {
    display: block;

    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent1)};

    cursor: pointer;

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent1)};
    }

    @media screen and (min-width: 1440px) {
      display: none;
    }

    .open {
      display: block;
      *[stroke] {
        stroke: currentColor;
      }
    }
    .close {
      display: none;

      *[fill] {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent4)};
      }
    }

    & > * {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .hint-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    display: none;

    transform: translate(50%, -50%);
    @media screen and (min-width: 1440px) {
      display: block;
    }

    .hint-dropdown {
      position: absolute;
      left: 0;

      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      width: 45rem;

      padding: 3rem 2.5rem;

      border: 1px solid
        ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
      border-radius: 15px;

      font-size: 1.8rem;
      font-weight: 500;
      line-height: 150%;

      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2};

      transform: translateX(-100%);

      &-heading {
        color: ${(props) => props.theme.accent1};
      }

      &-text {
        font-family: Mulish;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
`;
