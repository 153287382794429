import { BorderedWrapper } from 'components';
import { StyledChargesListContainer } from './styles';
interface IChargesListContainerProps {
  children?: React.ReactNode;
}
export const ChargesListContainer = ({ children }: IChargesListContainerProps) => {
  return (
    <StyledChargesListContainer>
      <BorderedWrapper>{children}</BorderedWrapper>
    </StyledChargesListContainer>
  );
};
