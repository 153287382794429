import styled from 'styled-components';

export const StyledPhoneInput = styled.div`
  .phone-input-label {
    display: block;

    padding: 0 1rem;

    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: ${({ theme }) => (theme.id === 'dark' ? theme.text2 : theme.text1)};

    @media screen and (min-width: 768px) {
      margin-bottom: 0.2rem;

      font-size: 1.4rem;
    }
  }

  .phone-input-container {
    position: relative;

    display: flex;
    gap: 0.5rem;
  }
`;

export const StyledCountyCodeSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  width: 7.6rem;

  padding: 0.7rem;

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent3)};
  border-radius: 6px;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.bg2)};

  @media screen and (min-width: 768px) {
    width: 9.6rem;
  }

  &.disabled {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};

    cursor: not-allowed;
    &::placeholder {
      color: currentColor;
    }
    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.bg3)};
    box-shadow: none;

    .button_icon {
      > * {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};
      }
    }
  }
`;

export const StyledSelectedCode = styled.p`
  overflow: hidden;

  max-width: 4rem;

  margin-right: auto;

  font-size: 1.4rem;
  line-height: 150%;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const StyledArrowButton = styled.button`
  display: flex;
  align-items: center;
  width: 2rem;
  height: 1.1rem;

  > * {
    width: 100%;
    height: 100%;
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  }

  &.flipped {
    transform: scaleY(-1) scaleX(-1);
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const StyledPhoneDropdown = styled.div`
  position: absolute;
  bottom: -0.3rem;
  z-index: 10;
  height: 15rem;
  overflow-y: auto;

  transform: translateY(100%);

  padding: 0 1rem;

  border: 1px solid ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.bg2)};
  border-bottom-width: 1rem;
  border-top-width: 1rem;
  border-radius: 6px;

  text-align: start;
  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.bg2)};

  .scroll-container {
    overflow-y: scroll;
  }
`;

export const StyledCountryCode = styled.p`
  padding: 0 0.3rem;

  border-radius: 6px;

  font-size: 1.4rem;
  line-height: 150%;

  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button3 : props.theme.button2};
  }
  .code {
    display: inline-block;
    width: 7rem;
  }
  .country {
    display: inline-block;
  }
`;
