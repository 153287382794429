import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledPeriodSelector = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;

  @media screen and (min-width: 1024px) {
    max-width: 47rem;
  }

  @media screen and (min-width: 1440px) {
    max-width: 100%;
  }

  .period-select_label {
    text-align: start;
    color: ${darkTheme.text2};

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    padding: 0 1rem;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }
  }
`;

export const StyledSelectContainer = styled.div`
  max-width: 100%;
  border-radius: 7px;
  background-color: ${(props) =>
    props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent1};

  @media screen and (min-width: 768px) {
    border-radius: 94px;
  }
`;

export const StyledPeriodSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 2rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0;
  }
`;

export const StyledRadioContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */

  width: 25.9rem;
  height: 3.7rem;

  padding: 0.5rem 1rem;

  border-radius: 94px;

  transition: all 0.3s;

  &:hover {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5)};
  }
  &.selected {
    background: ${darkTheme.text1};
    color: ${darkTheme.text3};
  }

  @media screen and (min-width: 768px) {
    height: 4.9rem;
    font-size: 2.5rem;
    font-weight: 600;
  }
`;
