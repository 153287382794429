import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountRepository } from 'repositories/AccountRepository';
import { EPayoutStatus } from 'types';
import { EApiResponseStatus } from 'types/api';
import { ISignInResponseData } from 'types/auth';
import { IFetchUserResponse, ISignInResponse, ISignInUserData } from 'types/users';
import { removeToken, setToken } from 'utils';

export const fetchUser = createAsyncThunk<IFetchUserResponse, string>(
  'auth/fetchUser',
  async (token: string) => {
    // TODO: Test purpose only
    if (token === 'test-token') {
      const response: IFetchUserResponse = {
        status: EApiResponseStatus.SUCCESS,
        data: {
          id: 123,
          name: 'name=test',
          password: 'test-password',
          token: 'test-token',
          status: 'test-status',
          email: 'test-email',
          phone: 'test-phone',
        },
      };
      return response;
    }
    // TODO: Test purpose only

    try {
      const response = await AccountRepository.fetchUser(token);
      return response;
    } catch (error) {
      console.error('signIn error', error);
      if (error instanceof Error) {
        return { status: EApiResponseStatus.FAILURE, error: error.message };
      }
      return { status: EApiResponseStatus.FAILURE, error: 'unknown error' };
    }
  },
);

export const signIn = createAsyncThunk<ISignInResponseData, { userData: ISignInUserData }>(
  'auth/signIn',
  async ({ userData }, { dispatch }) => {
    const { password, name, isRemember } = userData;
    // TODO: Test purpose only
    if (name === 'test-token-name' && password === 'test-token-password') {
      const token = 'test-token';
      console.warn('TEST AUTH, TOKEN: ', token);
      dispatch(fetchUser(token));
      if (isRemember) setToken(token);
      return { token };
    }
    // TODO: Test purpose only
    const response = await AccountRepository.signIn({ password, name });
    if (response.status === EApiResponseStatus.FAILURE || !response.data) {
      throw Error(response.error);
    }
    dispatch(fetchUser(response.data.token));
    if (isRemember) setToken(response.data.token);
    return response.data;
  },
);

export const signOut = createAsyncThunk('auth/signOut', async () => {
  removeToken();
});
