import { useTranslation } from 'react-i18next';
import { StyledSupportRefs } from './styles';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as DiscordIcon } from 'assets/icons/discord.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email-icon.svg';
import { ExternalRoute } from 'lib';
import { OutlinedButton } from 'components';
interface ISupportRefsProps {
  children?: React.ReactNode;
}
export const SupportRefs = ({ children }: ISupportRefsProps) => {
  const { t } = useTranslation();

  const mediaButtonClickHandler = (link: string) => () => {
    window.open(link, '_blank');
  };
  return (
    <StyledSupportRefs>
      <div className="support-media">
        <div className="support-media-girl">
          <img
            alt="a girl looking at her phone"
            src="/images/support-girl.webp"
            className="support-media-girl-image"
          />
        </div>
        <h2 className="support-media-heading">{t('supportPage.media')}</h2>
        <div className="support-media-buttons">
          <button
            className="support-media-button"
            onClick={mediaButtonClickHandler(ExternalRoute.telegramPath())}
          >
            <TelegramIcon className="support-media-button-icon" />
          </button>
          <button
            className="support-media-button"
            onClick={mediaButtonClickHandler(ExternalRoute.discordPath())}
          >
            <DiscordIcon className="support-media-button-icon" />
          </button>
        </div>
      </div>
      <div className="support-help">
        <div className="support-help-guy">
          <img
            src="/images/support-guy.webp"
            alt="a guy with load speaker"
            className="support-help-guy-image"
          />
        </div>
        <h2 className="support-help-heading">{t('supportPage.support')}</h2>
        <OutlinedButton>
          <div className="email-button-title">
            {t('supportPage.emailButton')}
            <div className="email-button-icon-container">
              <EmailIcon />
            </div>
          </div>
        </OutlinedButton>
      </div>
    </StyledSupportRefs>
  );
};
