import cn from 'classnames';
import { StyledOutlinedButton } from './styles';
interface IOutlinedButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  square?: boolean;
}
export const OutlinedButton = ({
  children,
  type = 'button',
  onClick,
  className,
  title,
  disabled,
  square,
}: IOutlinedButtonProps) => {
  return (
    <StyledOutlinedButton
      title={title}
      type={type}
      onClick={onClick}
      className={cn(className, square && 'square')}
      disabled={disabled}
    >
      {children}
    </StyledOutlinedButton>
  );
};
