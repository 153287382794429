import styled from 'styled-components';
import { StyledArrowIcon } from './StyledArrowIcon';
import { StyledMenuContainer } from './StyledMenuContainer';
export const StyledUserName = styled.div`
  padding: 0.5rem 0;
  position: relative;
  display: flex;
  gap: 1.5rem;
  text-transform: uppercase;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  &:hover {
    ${StyledArrowIcon} {
      > * {
        transform: scaleX(-1) scaleY(-1);
        top: 0;
      }
    }

    ${StyledMenuContainer} {
      visibility: visible;
      opacity: 1;
    }
  }
`;
