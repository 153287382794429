export const darkTheme = {
  id: 'dark',
  mainBackground: '#160935',
  mainText: '#FCFAFF',
  bg1: '#160935',
  bg2: '#1E0B4D',
  bg3: '#240F59',
  bg4: '#240E59',
  bg5: '#401E93',
  bg6: '#2E1271',
  bg7: '#563F97',
  bgFooter: '#100627',
  text1: '#FCFAFF',
  text2: '#CFB9FF',
  text3: '#483480',
  text4: '#B59DFB',
  text5: '#B1ADFF',
  accent1: '#D5FD95',
  accent2: '#7456CA',
  accent3: '#65C6EB',
  accent4: '#CFBEFF',
  accent5: '#BAFF63',
  accent6: '#6F33FF',
  button1: '#8DE24A',
  button2: '#4A24A5',
  button3: '#7456CA',
  errorRed: '#f35959',
  stroke2: '#401E93',
};
