import { IWorker, TWorkerFilterStatus } from 'types';

// TODO: Remove after sorted is getting from API
// https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-35
export const getSortedWorkers = (
  workers: IWorker[],
  sortBy: keyof IWorker,
  incr: boolean,
): IWorker[] => {
  const sortedWorkes = [...workers].sort();
  return sortedWorkes;
};

export const filterWorkersList = (
  workers: IWorker[],
  filters: { status?: TWorkerFilterStatus | null; groupId?: IWorker['groupId'] | null },
): IWorker[] => {
  if (!filters.status && !filters.groupId) return workers;

  const filteredWorkers = workers.filter((worker) => {
    const isProperStatus = !filters.status ? true : worker.status === filters.status;
    const isProperGroupId = !filters.groupId ? true : worker.groupId === filters.groupId;
    return isProperGroupId && isProperStatus;
  });
  return filteredWorkers;
};

export const normaliseDateElement = (value: number) => (value < 10 ? '0' + value : value);

export const formateLastActivityDate = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${formateDate(date)}-${normaliseDateElement(hours)}:${normaliseDateElement(
    minutes,
  )}:${normaliseDateElement(seconds)}`;
};

export const formateDate = (date: Date) => {
  const year = date.getFullYear();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${normaliseDateElement(day)}.${normaliseDateElement(month)}.${year}`;
};

export const formateTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${normaliseDateElement(hours)}:${normaliseDateElement(minutes)}:${normaliseDateElement(
    seconds,
  )}`;
};
