import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledHideButton, StyledInput, StyledInputGroup } from './styles';
import { ReactComponent as HideIcon } from 'assets/icons/hide.svg';
import { ReactComponent as UnhideIcon } from 'assets/icons/unhide.svg';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  inValid?: boolean;
  error?: string;
}

export const PasswordInput = (props: IInputProps) => {
  const { t } = useTranslation();
  const [valid] = useState(false);

  const [unhidePassword, setUnhidePassword] = useState(false);
  const passwordInputId = useId();

  return (
    <StyledInputGroup>
      {props.labelText && (
        <label className="password-input-label" htmlFor={passwordInputId} hidden={!props.labelText}>
          {props.labelText ? props.labelText : props.placeholder}
        </label>
      )}
      <div style={{ position: 'relative' }}>
        <StyledInput
          name={props.name}
          id={passwordInputId}
          type={unhidePassword ? 'text' : 'password'}
          className={`password ${valid && 'valid'} ${props.inValid && 'invalid'}`}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          autoComplete="on"
          placeholder={props.placeholder}
          disabled={props.disabled}
        ></StyledInput>
        <StyledHideButton
          type="button"
          onClick={() => setUnhidePassword((prev) => !prev)}
          disabled={props.disabled}
        >
          {unhidePassword ? <UnhideIcon /> : <HideIcon />}
        </StyledHideButton>
      </div>
      {props.error && props.inValid && (
        <div className="input-error-message">{t(`forms.errors.${props.error}`)}</div>
      )}
    </StyledInputGroup>
  );
};
