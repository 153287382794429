import { useTranslation } from 'react-i18next';
import { StyledAdminTransactionsList } from './styles';
import { AdminElementsWrapper, DisplayItemsPerPageSelect, Paginator } from 'components';
import { SearchInput } from '../SearchInput';
import { DataExportButton } from '../DataExportButton';
import { generateMockTransactionsList } from 'data/mockUserList';
import { TransactionsList } from './components';
import { useMemo, useState } from 'react';
interface IAdminTransactionsListProps {
  children?: React.ReactNode;
}
const transactionsList = generateMockTransactionsList(50);
export const AdminTransactionsList = ({ children }: IAdminTransactionsListProps) => {
  const { t } = useTranslation();
  const DEFAULT_ITEMS_PER_PAGE_QUANTITY = 10;
  const DEFAULT_PAGE_NUMBER = 1;

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE_QUANTITY);
  const [searchFilter, setSearchFilter] = useState('');

  const filteredTransactions = transactionsList;
  const searchFilteredTransactions = useMemo(
    () =>
      searchFilter.length === 0
        ? filteredTransactions
        : filteredTransactions.filter((transaction) =>
            transaction.hashId.toLowerCase().includes(searchFilter.toLowerCase()),
          ),
    [searchFilter, filteredTransactions],
  );
  const sortedTransactionsList = searchFilteredTransactions;

  const paginatedTransactionsList = useMemo(
    () =>
      sortedTransactionsList.slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage),
    [currentPage, itemsPerPage, sortedTransactionsList],
  );
  const pagesQuanity = Math.ceil(sortedTransactionsList.length / itemsPerPage);

  const changeCurrentPageHandler = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const changeItemsPerPageQuantityHAndler = (itemsQuantity: number) => {
    setItemsPerPage(itemsQuantity);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const setSearchFilterHandler = (value: string) => {
    setSearchFilter(value);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };
  return (
    <StyledAdminTransactionsList>
      <AdminElementsWrapper>
        <header className="admin-user-list-header">
          <h3 className="heading">{t('adminPage.editPaymentHistory.heading')}</h3>
          <button className="refresh-button">{t('adminPage.refresh')}</button>
        </header>

        <div className="list-controls">
          <div className="list-controls-group">
            <SearchInput onSearch={setSearchFilterHandler} />
            <DataExportButton />
          </div>
        </div>
        <div className="list-body">
          <TransactionsList transactions={paginatedTransactionsList} />
          <div className="list-body-pagination">
            <DisplayItemsPerPageSelect
              onChange={changeItemsPerPageQuantityHAndler}
              currentItemsPerPageQuantity={itemsPerPage}
              itemsPerPageRates={[10, 15, 25, 50]}
            />
            <Paginator
              onChange={changeCurrentPageHandler}
              pageQuantity={pagesQuanity}
              currentPage={currentPage}
            />
          </div>
        </div>
      </AdminElementsWrapper>
    </StyledAdminTransactionsList>
  );
};
