import styled from 'styled-components';
import { StyledAdminListRow } from '../../AdminListHeaderCell/styles/StyledAdminListRow';

export const StyledEditEquipmentGroupsListRow = styled.div`
  ${StyledAdminListRow}

  .admin-list-cell-container {
    &.link {
      flex-grow: 0;
      flex-basis: initial;
      width: 7.2rem;
    }
  }
`;
