import styled from 'styled-components';
import { StyledSCrollBar } from 'styles/scrollbar';
export const StyledTransactionsList = styled.div`
  width: 27.6rem;

  @media screen and (min-width: 768px) {
    width: 63.8rem;
  }
  @media screen and (min-width: 1024px) {
    width: 87.4rem;
  }
  @media screen and (min-width: 1440px) {
    width: 104.8rem;
  }
  @media screen and (min-width: 1920px) {
    width: 155.2rem;
  }
  overflow: scroll;

  ${StyledSCrollBar};

  .list-col-element {
    padding: 0.2rem 0.6rem;
    overflow: hidden;
    position: relative;

    text-overflow: ellipsis;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */

    &:not(:last-child) {
      border-right: 1px solid ${(props) => props.theme.button2};
    }
  }
`;
