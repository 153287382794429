import { useTranslation } from 'react-i18next';
import { StyledTimezoneSelect } from './styles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { type ITimezone } from 'react-timezone-select';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
};

interface ITimezoneSelectProps {
  children?: React.ReactNode;
}
export const CustomTimezoneSelect = ({ children }: ITimezoneSelectProps) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const { t } = useTranslation();
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

  const selectTimezoneHandler = (value: ITimezone) => () => {
    console.log(parseTimezone(value));
    setSelectedTimezone(value);
  };

  const toggleSelectingHandler = () => {
    setIsSelecting((prev) => !prev);
  };
  const setNotInSelectingHandler = () => {
    setSearchValue('');
    setIsSelecting(false);
  };

  const searchFilterHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const filteredOptions = options.filter((option) =>
    option.value.toLowerCase().includes(searchValue.toLowerCase()),
  );

  useEffect(() => {
    if (isSelecting) inputRef.current?.focus();
  }, [isSelecting]);

  return (
    <StyledTimezoneSelect onMouseLeave={setNotInSelectingHandler}>
      <label className="timezone-input-label">{t('settingsPage.timezone.label')}</label>
      <div className="timezone-select-container">
        <div className="timezone-select-value-container">
          <input
            ref={inputRef}
            className={cn('timezone-select-value-search-input', isSelecting && 'selecting')}
            onChange={searchFilterHandler}
            value={searchValue}
            placeholder={t('settingsPage.timezone.searchPlaceholder')}
          />
          <button
            className={cn('timezone-select-value-selected', isSelecting && 'selecting')}
            onClick={toggleSelectingHandler}
          >
            <span className="timezone-select-value-selected__hint">
              {t('settingsPage.timezone.valueHint')}
            </span>
            <span className="timezone-select-value-selected__value">
              {parseTimezone(selectedTimezone).label.split(') ')[1]}
            </span>
          </button>
          <button
            className="timezone-select-value-dropdown-button"
            onClick={toggleSelectingHandler}
          >
            <ArrowDownIcon className="icon" />
          </button>
        </div>
        <div className={cn('timezone-select__dropdown-container', isSelecting && 'selecting')}>
          <div className="timezone-select__dropdown">
            {filteredOptions.map((option) => (
              <button
                key={option.value}
                className={cn(
                  'timezone-select__option',
                  parseTimezone(selectedTimezone).value === option.value && 'selected',
                )}
                onClick={selectTimezoneHandler(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </StyledTimezoneSelect>
  );
};
