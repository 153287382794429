import { BorderedWrapper, HashrateDiagram } from 'components';
import { StyledHashrateStatistics, StyledHeader } from './styles';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, useRef, useState } from 'react';
import { fetchCommonHashrate } from 'store/thunks/commonDataThunks';
import { useAppDispatch } from 'store/store';
const HashrateStatisticsComponent = () => {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();

  const componentRef = useRef<HTMLDivElement>(null);
  const [hashrateDiagramContainerWidth, setHashrateDiagramContainerWidth] = useState(50);

  // useEffect(() => {
  //   //TODO: set up dinamic dates for fetching
  //   // dispatch(fetchCommonHashrate({ from: 23232, to: 23232 }));
  // }, []);

  useEffect(() => {
    setHashrateDiagramContainerWidth(componentRef.current!.clientWidth);
    if (componentRef.current) {
      window.addEventListener('resize', () => {
        const currentWidth = componentRef.current?.clientWidth;
        if (currentWidth !== hashrateDiagramContainerWidth) {
          setHashrateDiagramContainerWidth((prev) =>
            componentRef.current ? componentRef.current.clientWidth : prev,
          );
        }
      });
    }

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <StyledHashrateStatistics>
      <BorderedWrapper>
        <StyledHeader>
          <h2 className="h2">{t('statisticsPage.hashRateStats.heading')}</h2>
          <div className="hint">?</div>
        </StyledHeader>
        <div className="content" ref={componentRef}>
          <HashrateDiagram containerWidth={hashrateDiagramContainerWidth} />
        </div>
      </BorderedWrapper>
    </StyledHashrateStatistics>
  );
};

export const HashrateStatistics = memo(HashrateStatisticsComponent);
