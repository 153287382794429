import { useTranslation } from 'react-i18next';
import { StyledAboutHero } from './styles';
import { v4 as uuid } from 'uuid';
interface IAboutHeroProps {
  children?: React.ReactNode;
}
export const AboutHero = ({ children }: IAboutHeroProps) => {
  const { t } = useTranslation();

  const heroTexts = t('aboutPage.hero.texts', { returnObjects: true }) as string[];
  return (
    <StyledAboutHero>
      <div className="about-hero-content">
        <div className="about-hero-heros">
          <h1 className="heading">{t('aboutPage.hero.heading')}</h1>
          <img
            className="about-hero-heros-team"
            src="/images/guys.webp"
            alt={t('aboutPage.hero.headingImagAlt')}
          />
        </div>
        <div className="about-hero-texts">
          {heroTexts.map((text) => (
            <p key={uuid()} className="about-hero-text">
              {text}
            </p>
          ))}
        </div>
      </div>
    </StyledAboutHero>
  );
};
