import styled from 'styled-components';
export const StyledAdminListHeaderCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;

  padding: 0.6rem;

  font-size: 1.4rem;
  line-height: 150%;

  .admin-list-header-cell-title {
    max-width: calc(100% - 3.6rem);
  }
  .admin-list-header-cell-filter {
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${(props) => props.theme.text1};

    &:hover,
    &.active {
      color: ${(props) => props.theme.accent6};
    }

    .admin-list-header-cell-filter-icon {
      > *[fill] {
        fill: currentColor;
      }
    }
  }
`;
