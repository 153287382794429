import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { fetchPayoutsList } from 'store/thunks/userPayoutThunks';
import { EPayoutStatus, IPayout } from 'types';
import { ESliceDataFetchingStatus } from 'types/common';

export interface IUserPayoutState {
  payouts: IPayout[];
  status: ESliceDataFetchingStatus;
  pagesQuantity: number;
  itemsPerPage: number;
  currentPage: number;
  errorMessage: string | null;
  filterByConfirmationStatus: EPayoutStatus | null;
}

const initialState: IUserPayoutState = {
  payouts: [],
  status: ESliceDataFetchingStatus.initial,
  pagesQuantity: 1,
  itemsPerPage: 10,
  currentPage: 1,
  errorMessage: null,
  filterByConfirmationStatus: null,
};

export const userPayoutsSlice = createSlice({
  name: 'userPayouts',
  initialState,
  reducers: {
    payoutsListSetPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setItemsPerPayoutsListPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
      state.currentPage = initialState.currentPage;
    },
    setFilterByConfirmationStatus: (
      state,
      action: PayloadAction<IUserPayoutState['filterByConfirmationStatus']>,
    ) => {
      state.filterByConfirmationStatus = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchPayoutsList.fulfilled, (state, { payload }) => {
        state.status = ESliceDataFetchingStatus.success;
        state.payouts = payload.payouts;
        state.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchPayoutsList.pending, (state) => {
        state.status = ESliceDataFetchingStatus.loading;
        state.errorMessage = null;
      })
      .addCase(fetchPayoutsList.rejected, (state, action) => {
        state.status = ESliceDataFetchingStatus.error;
        state.errorMessage = action.error.message || 'unknown fetch workerks list error';
      }),
});

export const selectPayouts = (state: RootState) => state.userPayouts.payouts;

export const { payoutsListSetPage, setFilterByConfirmationStatus, setItemsPerPayoutsListPage } =
  userPayoutsSlice.actions;
export const userPayoutsReducer = userPayoutsSlice.reducer;
