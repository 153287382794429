import styled from 'styled-components';

export const StyledLogoContainer = styled.div`
  width: 9.6rem;
  height: 3.2rem;

  > * {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 12.9rem;
    height: 4.4rem;
  }
`;
