import { Footer, Layout } from 'components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  AddminAddTransaction,
  AdminCalculatePayments,
  AdminCostAdjustment,
  AdminEditEquipmentGroups,
  AdminEditPaymentHistory,
  AdminEditPayments,
  AdminEditUsers,
} from './components';
import { Styledadmin } from './styles';
import { useRef, useState } from 'react';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-sharp.svg';

export const AdminPage = () => {
  const [activeTabId, setActiveTabId] = useState(0);
  const [scrollPosition, setScrollPosition] = useState<'start' | 'end'>('start');
  const { t } = useTranslation();
  const tabsHeaderRef = useRef<HTMLDivElement>(null);

  const submitYesterdayCostAdjustment = (amount: number) => {
    console.log('yesterday adjustment value:', amount);
  };
  const submitNewbeeCostAdjustment = (amount: number) => {
    console.log('yesterday newbee cost value:', amount);
  };
  const submitEditNewbeeAdjustment = (amount: number) => {
    console.log('yesterday newbe adjustment value:', amount);
  };

  const MAX_TABS_QUANTITY = 4;

  const switchTabHandlerLeft = () => setActiveTabId((prev) => (prev < 1 ? 0 : prev - 1));
  const switchTabHandlerRight = () =>
    setActiveTabId((prev) => (prev > MAX_TABS_QUANTITY - 2 ? MAX_TABS_QUANTITY - 1 : prev + 1));

  const switchTabHandlerSwitch = () => {
    console.log('switch cleck');
    if (tabsHeaderRef.current) {
      if (scrollPosition === 'start') {
        tabsHeaderRef.current.scrollLeft += tabsHeaderRef.current.clientWidth;
        setScrollPosition('end');
      } else {
        tabsHeaderRef.current.scrollLeft = 0;
        setScrollPosition('start');
      }
    }
  };

  return (
    <Layout admin>
      <Styledadmin>
        <div className="admin-controls">
          <AdminCalculatePayments />
          <AddminAddTransaction />
          <AdminCostAdjustment
            onSubmit={submitYesterdayCostAdjustment}
            heading={t('adminPage.yesterdayCost.heading')}
          />
          <AdminCostAdjustment
            onSubmit={submitNewbeeCostAdjustment}
            heading={t('adminPage.newbeeCost.heading')}
          />
          <AdminCostAdjustment
            onSubmit={submitEditNewbeeAdjustment}
            heading={t('adminPage.editNewbeeAdjust.heading')}
          />
        </div>
        <div className="admin-tabs">
          <div className="admin-tabs-header">
            <div className="admin-tabs-headers" ref={tabsHeaderRef}>
              <div
                className={cn('admin-tabs-header__container', `l-${activeTabId * 100}`)}
                data-background="lime"
              >
                <button
                  className={cn('admin-tabs-header__tab', activeTabId === 0 && 'active')}
                  onClick={() => setActiveTabId(0)}
                >
                  <div className="admin-tabs-header__tab__title">
                    {t('adminPage.editPayments.tabHeader')}
                  </div>
                </button>
                <button
                  className={cn('admin-tabs-header__tab', activeTabId === 1 && 'active')}
                  onClick={() => setActiveTabId(1)}
                >
                  <div className="admin-tabs-header__tab__title">
                    {t('adminPage.editUsers.tabHeader')}
                  </div>
                </button>
                <button
                  className={cn('admin-tabs-header__tab', activeTabId === 2 && 'active')}
                  onClick={() => setActiveTabId(2)}
                >
                  <div className="admin-tabs-header__tab__title">
                    {t('adminPage.editGroups.tabHeader')}
                  </div>
                </button>
                <button
                  className={cn('admin-tabs-header__tab', activeTabId === 3 && 'active')}
                  onClick={() => setActiveTabId(3)}
                >
                  <div className="admin-tabs-header__tab__title">
                    {t('adminPage.editPaymentHistory.tabHeader')}
                  </div>
                </button>
              </div>
            </div>
            <button className="admin-tabs-header-button left" onClick={switchTabHandlerLeft}>
              <ArrowRightIcon className="icon" />
            </button>
            <button className="admin-tabs-header-button right" onClick={switchTabHandlerRight}>
              <ArrowRightIcon className="icon" />
            </button>
            <button
              className={cn('admin-tabs-header-button switch', scrollPosition)}
              onClick={switchTabHandlerSwitch}
            >
              <ArrowRightIcon className="icon" />
            </button>
          </div>
          <div className={cn('admin-tabs-content', 'active')}>
            {activeTabId === 0 && <AdminEditPayments />}
            {activeTabId === 1 && <AdminEditUsers />}
            {activeTabId === 2 && <AdminEditEquipmentGroups />}
            {activeTabId === 3 && <AdminEditPaymentHistory />}
          </div>
        </div>
        <Footer smaller />
      </Styledadmin>
    </Layout>
  );
};
