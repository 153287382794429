import { ReactComponent as CheckIcon } from 'assets/icons/check-icon.svg';
import { StyledAdminListCheckbox } from './styles';
import React, { useId } from 'react';

interface IAdminListCheckboxProps {
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}
export const AdminListCheckbox = ({ onChange, checked }: IAdminListCheckboxProps) => {
  const checkboxInputId = useId();
  return (
    <StyledAdminListCheckbox>
      <input
        id={checkboxInputId}
        type="checkbox"
        className="checkbox-input"
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={checkboxInputId} className="checkbox-label">
        <CheckIcon className="checkbox-label-icon" />
      </label>
    </StyledAdminListCheckbox>
  );
};
