import styled from 'styled-components';
import { darkTheme } from 'themes';
import { StyledFooterNavigationList } from './StyledFooterNavigation';
export const StyledFooterContent = styled.div`
  max-width: 132rem;

  margin: auto;

  padding: 1.6rem 2.5rem 1.1rem;

  color: ${darkTheme.text1};

  &.smaller {
    padding: 1.6rem 0 1.1rem;
  }

  @media screen and (min-width: 768px) {
    padding: 2.5rem 2.5rem 1.2rem;

    &.smaller {
      padding: 6rem 3.6rem 1.1rem;

      ${StyledFooterNavigationList} {
        gap: 4rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 6rem 2.5rem 1.4rem;
    &.smaller {
      padding-top: 1.8rem;

      ${StyledFooterNavigationList} {
        gap: 7.5rem;
      }
    }
  }
`;
