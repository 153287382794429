import { StyledButton } from './styles';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

export const Button = (props: IButtonProps) => {
  const { title, children, icon } = props;
  return (
    <StyledButton {...props}>
      {title}
      {children}
      {icon && <div className="button_icon">{icon}</div>}
    </StyledButton>
  );
};
