import { css } from 'styled-components';

export const StyledAdminListRow = css`
  display: flex;

  padding: 0.4rem 0;
  border-radius: 0.6rem;

  background-color: ${(props) => props.theme.bg4};

  &:hover {
    background-color: ${(props) => props.theme.bg6};
  }

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }

  .admin-list-cell-container {
    flex-grow: 1;
    flex-basis: 10%;
    border-right: 1px solid ${(props) => props.theme.stroke2};
    overflow: hidden;
    &:last-child {
      border-color: transparent;
    }
    &.select {
      flex-grow: initial;
      flex-basis: initial;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > * {
      height: 100%;
      width: 100%;
    }
  }
`;
