import { Button } from 'components';
import { ReactComponent as RoundCrossIcon } from 'assets/icons/round-cross.svg';
import { StyledServicePageHero } from './styles';
interface IServicePageHeroProps {
  children?: React.ReactNode;
  title: string;
  onClickButton: () => void;
}
export const ServicePageHero = ({ children, onClickButton, title }: IServicePageHeroProps) => {
  return (
    <StyledServicePageHero>
      {children}
      <Button onClick={onClickButton} icon={<RoundCrossIcon />} title={title}></Button>
    </StyledServicePageHero>
  );
};
