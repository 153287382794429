import styled from 'styled-components';

export const StyledReferralBalance = styled.div`
  flex-grow: 1;
  flex-basis: 50%;

  .balance-heading {
    margin-bottom: 0.6rem;

    font-size: 1.4rem;
    font-weight: 700;
    line-height: 150%;
    text-align: center;

    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 500;
      margin-bottom: 1.6rem;
    }
  }

  .balance {
    position: relative;

    width: fit-content;

    margin: auto;
    margin-bottom: 1.6rem;

    text-align: center;

    @media screen and (min-width: 1920px) {
      margin-bottom: 2rem;
    }

    .balance-value {
      padding-right: 0.5rem;

      font-size: 2.2rem;
      line-height: 145%;
      font-weight: 500;
      letter-spacing: -0.32px;

      @media screen and (min-width: 768px) {
        font-size: 4rem;
        line-height: 150%;
        font-weight: 700;
      }
    }

    .balance-currnecy {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.14px;

      @media screen and (min-width: 768px) {
        position: absolute;
        right: -0.8rem;
        bottom: 0.7rem;

        transform: translateX(100%);

        font-size: 1.8rem;
        line-height: 150%;
        font-weight: 700;
      }
    }
  }
  .update-time {
    margin-bottom: 1.2rem;

    font-size: 1.2rem;
    font-weight: 500;
    line-height: 150%; /* 18px */
    text-align: center;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
    }
  }

  .referal-link-container {
    max-width: 25rem;

    margin: auto;
    padding: 1rem;

    border-radius: 10px;

    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};

    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.bg2 : props.theme.accent1};

    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      max-width: 100%;
    }

    @media screen and (min-width: 1024px) {
      gap: 2rem;
    }
    @media screen and (min-width: 1440px) {
      gap: 4rem;
      padding: 1rem 2rem;
    }

    .referal-link {
      flex-basis: 55%;
    }
    .referal-link-heading,
    .referal-link-value,
    .referal-link-interest-heading,
    .referal-link-interest-value {
      opacity: 0.8;
    }
    .referal-link-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0.8;

      font-size: 1.2rem;
      font-weight: 600;
      line-height: 150%;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent3)};

      @media screen and (min-width: 768px) {
        justify-content: flex-start;
        gap: 0.5rem;

        margin-bottom: 0;

        font-size: 1.4rem;
        font-weight: 700;
        line-height: 150%;
      }

      @media screen and (min-width: 1024px) {
        gap: 2rem;
      }

      @media screen and (min-width: 1440px) {
        .referal-link-address {
          word-break: break-all;
        }
      }

      @media screen and (min-width: 1920px) {
        width: auto;
        .referal-link-address {
          width: auto;
        }
      }

      .referal-link-address {
        overflow: hidden;

        max-width: 85%;

        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .copy-link-button {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};

        &:hover {
          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.button1 : props.theme.button1};
        }
        .copy-link-button-icon {
          color: currentColor;
          stroke: currentColor;
        }
      }
    }

    .referal-link-interest {
      flex-grow: 1;
      flex-basis: 25%;
    }

    .referal-link-interest-heading {
      margin-bottom: 0.5rem;
    }

    .referal-link-interest-value {
      font-size: 1.2rem;
      line-height: 150%;
      font-weight: 500;

      @media screen and (min-width: 768px) {
        font-size: 1.6rem;
        line-height: 150%;
      }
    }
  }
`;
