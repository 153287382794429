import styled from 'styled-components';

export const StyledFolderContainer = styled.div`
  background-color: yellow;
  position: relative;
  &::before {
    display: block;
    content: '';
    background-color: ${(props) => props.theme.bg1};
    background-image: ${(props) => `url('/images/bg/folder-header-sx-${props.theme.id}.svg')`};
    height: 5rem;

    @media screen and (min-width: 768px) {
      background-image: ${(props) => `url('/images/bg/folder-header-sm-${props.theme.id}.svg')`};
      height: 8.5rem;
    }
    @media screen and (min-width: 1024px) {
      background-image: ${(props) => `url('/images/bg/folder-header-md-${props.theme.id}.svg')`};
      height: 8.5rem;
    }
    @media screen and (min-width: 1440px) {
      background-image: ${(props) => `url('/images/bg/folder-header-lg-${props.theme.id}.svg')`};
      height: 8.5rem;
    }
    @media screen and (min-width: 1920px) {
      background-image: ${(props) => `url('/images/bg/folder-header-xl-${props.theme.id}.svg')`};
      height: 8.5rem;
    }
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.bg3)};
  border-radius: 10px;

  padding: 1rem;

  @media screen and (min-width: 768px) {
    padding: 2rem 1.5rem 2.8rem;
    border-radius: 15px;
  }

  @media screen and (min-width: 1024px) {
    border-radius: 20px;
  }

  @media screen and (min-width: 1440px) {
    border-radius: 30px;
    padding: 2rem 2rem 2.8rem;
  }

  @media screen and (min-width: 1920px) {
    padding: 2rem 3rem 2.8rem;
  }

  .heading {
    position: relative;
    z-index: 1;
    color: ${(props) => props.theme.text2};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 1.5rem;
    padding-left: 1rem;

    .dot {
      width: 0.5rem;
      height: 0.5rem;
      background-color: ${(props) => props.theme.text2};
      border-radius: 100%;
    }

    @media screen and (min-width: 768px) {
      padding-left: 1.5rem;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 37.5px */
      margin-bottom: 5rem;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 8rem;
    }
  }

  .data-container {
    @media screen and (min-width: 1024px) {
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 1440px) {
      gap: 5rem 2rem;
    }
    @media screen and (min-width: 1920px) {
      justify-content: center;
      gap: 4.7rem;
    }
  }
  .aux-data-container {
    @media screen and (min-width: 1440px) {
      display: flex;
      gap: 2rem;
    }
    @media screen and (min-width: 1920px) {
      display: flex;
      gap: 12rem;
      margin-bottom: 2rem;
    }
  }
`;
