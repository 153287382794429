import styled from 'styled-components';

export const StyledFAQSection = styled.section`
  position: relative;

  margin-bottom: 4rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 10rem;
  }
`;

export const StyledFAQHeading = styled.h3`
  margin-bottom: 1.2rem;

  font-size: 2.5rem;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.25px;
  text-align: center;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;

    font-size: 35px;
  }

  @media screen and (min-width: 1440px) {
    margin-bottom: 3rem;
  }
`;

export const StyledFAQList = styled.div`
  width: fit-content;

  margin: auto;

  > *:not(:last-child) {
    margin-bottom: 0.4rem;
  }
`;
