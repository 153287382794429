import styled from 'styled-components';
export const StyledMiner = styled.section`
  @media screen and (min-width: 1024px) {
    flex-grow: 1;
    flex-basis: 45%;
  }

  @media screen and (min-width: 1200px) {
    flex-grow: 1;
    flex-basis: 30%;

    max-width: 33%;
  }

  @media screen and (min-width: 1440px) {
    flex-grow: 1;

    margin-bottom: 0;
  }
`;

export const StyledMinerData = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .miner-value {
    font-style: normal;
    font-weight: 700;
    text-align: center;

    .miner-value_header {
      margin-bottom: 0.2rem;

      font-size: 1rem;
      line-height: 150%;
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text4 : props.theme.accent1)};

      @media screen and (min-width: 768px) {
        margin-bottom: 0.5rem;

        font-size: 1.6rem;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .miner-value_data {
      font-size: 1.8rem;
      line-height: 150%; /* 15px */
      letter-spacing: 0.18px;

      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
      @media screen and (min-width: 768px) {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
`;

export const StyledMinerFooterDataElement = styled.div`
  flex-grow: 1;

  font-size: 1.4rem;
  font-weight: 600;
  line-height: 150%;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 1.6rem;
    line-height: 150%;
  }

  &.row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.3rem 1rem;

    padding: 0 0.6rem;

    @media screen and (min-width: 768px) {
      gap: 2rem;
    }

    @media screen and (min-width: 1024px) {
      display: block;
    }
  }

  .data {
    margin-bottom: 0.2rem;
  }

  .reference {
    font-size: 1rem;
    line-height: 150%; /* 15px */
    font-weight: 500;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text4 : props.theme.accent1)};

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
      line-height: 150%; /* 24px */
      font-weight: 600;
    }
  }
`;
