import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Hint } from 'components';
import { ReactComponent as RadioEmptyIcon } from 'assets/icons/radio-empty.svg';
import { ReactComponent as FiltesIcon } from 'assets/icons/filters-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon-small.svg';
import { EWorkerStatus, IWorkerGroup, TWorkerFilterStatus } from 'types';
import { StyledWorkersListFilters } from './styles';
import { selectWorkers, selectWorkersQuantityByStatus } from 'store/slices/userWorkersSlice';

export const WorkersListFilters = () => {
  type TFilterConditions = TWorkerFilterStatus | 'all';

  const [isShowHint, setIsShowHint] = useState(false);
  const [checkedId, setCheckedId] = useState<TFilterConditions>('all');
  const [currentGroupId, setCurrentGroupId] = useState<IWorkerGroup['id']>(0);

  const { t } = useTranslation();

  const { groups } = useSelector(selectWorkers);
  const quantity = useSelector(selectWorkersQuantityByStatus);

  const workerslistFilterElements: TFilterConditions[] = Object.values(EWorkerStatus).filter(
    (el) => el !== EWorkerStatus.ONLINE,
  );

  const hintTexts = t('workersPage.listFilter.hint', { returnObjects: true }) as {
    heading: string;
    text: string;
  }[];

  const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value as TFilterConditions;
    setCheckedId(value);
  };

  const selectGroupHandler = (id: IWorkerGroup['id']) => () => {
    setCurrentGroupId(id);
  };
  const toggleHintHandler = () => setIsShowHint((prev) => !prev);
  const hideHindHandler = () => setIsShowHint(false);

  return (
    <StyledWorkersListFilters>
      <input id="filterTogglerId" type="checkbox" className="filters-list-toggle" />
      <label htmlFor="filterTogglerId" className="filters-list-toggle-label">
        <FiltesIcon className="open" />
        <CloseIcon className="close" />
      </label>
      <div className="workers-filter-container smallest">
        <>
          <h4 className="workers-filter-heading">{t('workersPage.listFilter.heading')}</h4>
          {workerslistFilterElements.map((element, index) => (
            <div key={element + index} className="workerslist-filter-radio-input-group">
              <input
                type="radio"
                id={'worker-filter-el-' + element}
                value={element}
                checked={checkedId === element}
                className="workerslist-filter_input"
                onChange={inputChangeHandler}
              />

              <label
                htmlFor={'worker-filter-el-' + element}
                className={cn('workerslist-filter-label', checkedId === element && 'checked')}
              >
                <div className={cn('radio-icon', checkedId === element && 'checked')}>
                  <RadioEmptyIcon />
                </div>
                {t(`workersPage.listFilter.${element}`)}{' '}
                <span className="sup">{quantity[element]}</span>
              </label>
            </div>
          ))}
          <div className="workerslist-filter-radio-input-group">
            <input
              type="radio"
              id={'worker-filter-el-all'}
              value={'all'}
              checked={checkedId === 'all'}
              className="workerslist-filter_input"
              onChange={inputChangeHandler}
            />

            <label
              htmlFor={'worker-filter-el-all'}
              className={cn('workerslist-filter-label', checkedId === 'all' && 'checked')}
            >
              <div className={cn('radio-icon', checkedId === 'all' && 'checked')}>
                <RadioEmptyIcon />
              </div>
              {t('workersPage.listFilter.all')} <span className="sup">{quantity['all']}</span>
            </label>
          </div>
        </>
        <>
          <h4 className="workers-filter-heading">{t('workersPage.listFilter.heading')}</h4>
          {groups.map((group, index) => (
            <div key={group.id + index} className="workerslist-filter-radio-input-group">
              <input
                type="radio"
                id={'group-filter-el-' + group.id}
                value={group.id}
                checked={currentGroupId === group.id}
                className="workerslist-filter_input"
                onChange={selectGroupHandler(group.id)}
              />

              <label
                htmlFor={'group-filter-el-' + group.id}
                className={cn('workerslist-filter-label', currentGroupId === group.id && 'checked')}
              >
                <div className={cn('radio-icon', currentGroupId === group.id && 'checked')}>
                  <RadioEmptyIcon />
                </div>
                {group.name}
              </label>
            </div>
          ))}
        </>
      </div>
      <div className="workers-filter-container">
        {workerslistFilterElements.map((element, index) => (
          <div key={element + index} className="workerslist-filter-radio-input-group">
            <input
              type="radio"
              id={'worker-filter-el-' + element}
              value={element}
              checked={checkedId === element}
              className="workerslist-filter_input"
              onChange={inputChangeHandler}
            />

            <label
              htmlFor={'worker-filter-el-' + element}
              className={cn('workerslist-filter-label', checkedId === element && 'checked')}
            >
              <div className={cn('radio-icon', checkedId === element && 'checked')}>
                <RadioEmptyIcon />
              </div>
              {t(`workersPage.listFilter.${element}`)}{' '}
              <span className="sup">{quantity[element]}</span>
            </label>
          </div>
        ))}
        <div className="workerslist-filter-radio-input-group">
          <input
            type="radio"
            id={'worker-filter-el-all'}
            value={'all'}
            checked={checkedId === 'all'}
            className="workerslist-filter_input"
            onChange={inputChangeHandler}
          />

          <label
            htmlFor={'worker-filter-el-all'}
            className={cn('workerslist-filter-label', checkedId === 'all' && 'checked')}
          >
            <div className={cn('radio-icon', checkedId === 'all' && 'checked')}>
              <RadioEmptyIcon />
            </div>
            {t('workersPage.listFilter.all')} <span className="sup">{quantity['all']}</span>
          </label>
        </div>
      </div>
      <div className="hint-container" onClick={toggleHintHandler}>
        <Hint>?</Hint>
        {isShowHint && (
          <div className="hint-dropdown" onMouseLeave={hideHindHandler}>
            {hintTexts.map(({ heading, text }, index) => (
              <div key={heading + index}>
                <div className="hint-dropdown-heading">{heading}</div>
                <div className="hint-dropdown-text">{text}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </StyledWorkersListFilters>
  );
};
