import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledHashrateInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledHashrateInputLabel = styled.label`
  padding: 0 1rem;

  font-size: 1rem;
  line-height: 150%;
  font-weight: 400;
  color: ${darkTheme.text2};

  @media screen and (min-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const StyledHashrateInputContainer = styled.div`
  display: flex;
  gap: 0.2rem;
`;

export const StyledHashrateInput = styled.input`
  width: 100%;
  height: 3.2rem;

  border-radius: 94px 0px 0px 94px;
  border: none;

  padding: 0.6rem 1rem 0.7rem 2rem;

  outline: none;

  color: ${darkTheme.text3};
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 150%;

  @media screen and (min-width: 768px) {
    width: 25rem;
    height: 5rem;

    padding: 0.6rem 4rem 0.7rem 2.5rem;

    font-size: 2.5rem;
  }

  @media screen and (min-width: 1024px) {
    width: 20.5rem;
  }
  @media screen and (min-width: 1440px) {
    width: 25rem;
  }
`;

export const StyledHashrateUnits = styled.div`
  width: 5.2rem;

  padding: 7px 20px 5px 15px;

  border-radius: 0 94px 94px 0;

  font-size: 1.2rem;
  line-height: 150%;
  font-weight: 600;
  text-align: center;
  color: ${darkTheme.text3};

  background-color: ${darkTheme.text1};

  @media screen and (min-width: 768px) {
    width: 9.3rem;

    font-size: 2.5rem;
  }
`;
