import styled from 'styled-components';
export const StyledSidebar = styled.aside`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;

  display: none;

  width: 8rem;

  padding: 2rem 0;

  background-color: ${(props) => props.theme.bg1};

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 1440px) {
    width: 30rem;

    padding: 0;
    padding-top: 2rem;
  }

  .logo-container {
    display: none;

    width: 4.3rem;
    height: 4.3rem;

    margin: auto;
    margin-bottom: 9rem;

    @media screen and (min-width: 1440px) {
      display: block;

      width: 12.8rem;
      height: 4.4rem;

      margin-bottom: 7rem;
    }

    &.small {
      display: block;
      @media screen and (min-width: 1440px) {
        display: none;
      }
    }

    > * {
      width: 100%;
      height: 100%;
    }
  }

  .menu-container {
    position: relative;
    z-index: 1;

    background-color: ${(props) => props.theme.bg1};
    margin-bottom: 12rem;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    position: absolute;
    left: 0;
    bottom: 2.7rem;

    width: 100%;
  }
`;
