import styled from 'styled-components';
export const StyledChargesListControls = styled.div`
  display: flex;
  gap: 0.5rem;

  margin-bottom: 1.2rem;

  & > *:last-child {
    margin-left: auto;
  }
`;
