import styled from 'styled-components';

export const StyledContacts = styled.div`
  @media screen and (min-width: 1024px) {
    flex-basis: 45%;
    flex-grow: 1;

    max-width: 50%;
  }
  @media screen and (min-width: 1440px) {
    max-width: calc(50% - 1rem);
  }

  .settings-contacts-form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 1rem 2rem;

    max-width: 60rem;

    margin: auto;
    margin-bottom: 1.6rem;

    &:last-child {
      margin-bottom: 0.8rem;
    }

    @media screen and (min-width: 768px) {
      justify-content: flex-start;

      max-width: 100%;
    }

    @media screen and (min-width: 1440px) {
      flex-wrap: nowrap;
    }

    &__input-group {
      width: 31rem;

      @media screen and (min-width: 768px) {
        width: 35.5rem;

        margin-bottom: 0;
      }

      @media screen and (min-width: 1024px) {
        flex-grow: 1;

        max-width: 31rem;
      }

      @media screen and (min-width: 1440px) {
        max-width: initial;
      }
    }

    &__submit-button {
      min-width: 18rem;

      @media screen and (min-width: 768px) {
        width: 18rem;

        margin: 0;
      }
    }
  }
`;
