import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledSecondaryButton = styled('button').withConfig({
  shouldForwardProp: (prop) => prop !== 'icon',
})`
  border-radius: 500px;
  border: 1px solid ${darkTheme.text3};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding: 0.4rem;
  padding-left: 1.4rem;
  .button-icon {
    width: 3.1rem;
    height: 3.1rem;
    display: flex;
    align-items: center;

    > svg {
      width: 100%;
      height: 100%;
      *[fill] {
        fill: currentColor;
      }
      *[stroke] {
        stroke: currentColor;
      }
    }
  }

  &.outline {
    border-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.accent1 : props.theme.buttons};
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.button2)};
  }

  &.icon-first {
    flex-direction: row-reverse;
    padding: 0.4rem;
    padding-right: 1.4rem;
  }
`;
