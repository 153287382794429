import { useTranslation } from 'react-i18next';
import { StyledDownloadDataButton } from './styles';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
interface IDownloadDataButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}
export const DownloadDataButton = ({ onClick }: IDownloadDataButtonProps) => {
  const { t } = useTranslation();
  return (
    <StyledDownloadDataButton onClick={onClick}>
      <DownloadIcon className="icon" />
      <span className="title">{t('payoutPage.controls.dataExportButton')}</span>
    </StyledDownloadDataButton>
  );
};
