import { Layout } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const policy = `General
This privacy statement sets out the privacy policy of web-platform belonging to EMCD TECH LIMITED with registered office at Flat/RM 1207A, Officeplus @ Prince Edward 794-802 Nathan Road, Prince Edward, Hong Kong (hereinafter referred to as “EMCD” and/or “we”, “us”, “our”), located at: https://emcd.io and all sub-domains (hereinafter referred to as “Website”). EMCD is the data controller of users who access and uses the services of the Website. This Privacy policy uses terms and definitions specified at Terms of Service. EMCD provides services to individuals and legal entities (hereinafter referred to as “Customer”, “you”, “yours”). This Privacy policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. General Data Protection Regulation ((EU) 2016/679) (“GDPR”) provides rules for how we can collect, use and disclose this information. Using the Website you are agreeing to be legally bound by this Privacy policy, so please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. If any term in this Privacy Policy is unacceptable to you, please do not visit, access or use the Website.
What information do we collect?
As a rule, the Website can be used without having to provide any personal data. We collect personal information about you when you use our products or services, or deal with us in some way.
We collect your information directly received from you, such as:
• Country;
• E-mail;
• Phone number;
• Telegram ID;
• Other information and your private data you deem necessary to submit to us during
communication through the Website.
This includes collecting information when you:
• Visit the Website;
• Contact us.
The information we collect from you may include:
• Information about your identity — including your name, country, e-mail and other
information and your private date you deem necessary to submit to us during
communication through the Website;
• Information about your actual address and residency;
• Cryptocurrency transaction information;
• When you visit the Website — your location information, IP address and any third-party
websites you access;
• Other personal information, such as details of your interactions with us.
How do we collect your information?
Direct Collection
We collect much of the information listed above directly from you when you submit it on the Website.
This includes information such as contact information, registration information and service inquiries.
If you do not want to share your information, you can choose not to participate in a particular service
or activity.
Indirect Collection – Cookies and Other Technology
As part of offering and providing personalized services, we use cookies and local device storage.
We may use these technologies to:
• Provide you with personalized content based on your use of the Website;
• Enable you to more easily use the Website by remembering and using contact
information, purchasing information, and registration information;
• Evaluate, monitor and analyze the use of the Website and its traffic patterns to help
improve the Website and services;
• Assist us with ad reporting functions such as to learn which ads are bringing users to the
Website;
The types of technologies we use include:
• Cookies
A cookie is a small amount of data that is sent to your browser from a Web server and stored on
your computer’s hard drive. Cookies enable us to identify your browser as a unique user. Cookies
may involve the transmission of information from us to you and from you to us. Cookies may also be
used by another party on our behalf to transfer information to us in accordance with their privacy
policy. Some cookies are "persistent cookies". They are used by us each time you access the
Website. Other cookies are called "session cookies". “Session cookies”, also called “session
variables”, are used only during a specific browsing session and expire after a pre-determined
amount of time. We may use a session cookie, for example, to remember that you have already
navigated through a particular menu. We may also use "analytics cookies" that allow web analytics
services to recognize your browser or device and, for example, identify whether you have visited the
Website before, what you have previously viewed or clicked on, and how you found us. This
information is provided anonymously for statistical analysis only. Analytics cookies are usually
persistent cookies. You may disable browser cookies in your browser or set your browser to warn
you when a cookie is being sent. You may lose some features or functionality when you disable
cookies. Remember, also, that disabling cookies is browser specific.
• Log Files
Like most standard website servers, we use log files. Log files track Internet protocol (IP) addresses,
browser type, Internet service provider (ISP), referring/exit pages, platform type, date/time stamp,
and number of clicks. We utilize this information to analyze trends, administer the site, prevent fraud,
track website navigation in the aggregate, and gather broad demographic information for aggregate
use.
How do we use your information?
We are careful about how we use your information. We use it to deliver our products and provide
our services. We also use your information for other reasons, such as to better understand you and
your needs, and to let you know about other products and services you might be interested in. We
collect, use and exchange your information so we can:
• Assess your application for different issue;
• Design, manage, price and provide our products and services;
• Manage our relationship with you;
• Minimize risks and identify or investigate fraud and other illegal activities;
• Contact you;
• Improve our service to you and your experience with us;
• Comply with laws, and assist government or law enforcement agencies;
• Manage our businesses.
We shall use the personal data in compliance with the confidentiality and only use and retain such
data as far and as long as this is necessary for the purposes of the Website utilization, rendering of
services on the Website and for keeping Website users informed of our services. This shall include
the following purposes, among others:
• Surveillance and improvement of the Website and services rendered by EMCD;
• Creation and operation of Website access;
• Prevention or detection of fraud and other criminal offences or abuse of the Website;
• Creation of anonymous statistics with the help of personal data;
• Tailoring of marketing communications to ensure that the most relevant information reaches
Website users.
To whom do we disclose your information?
We may disclose information collected from customers, visitors and surfers to affiliates, independent
contractors and business partners who may use the information for marketing, advertising or other
uses.
We may also disclose aggregate, individual or anonymous data, based on information collected from
customers, visitors and surfers, to investors and potential partners or must or may be disclosed to
law enforcement authorities in response to a valid emergency disclosure request. Finally, we may
transfer information collected in connection with a sale of our product and services. We will not
disclose any of your personally identifiable information except when we have your permission or
under special circumstances, such as when we believe in good faith that the law requires it or under
the circumstances described below.
We may share your Personal Data with law enforcement, data protection authorities,
government officials, and other authorities when:
• Compelled by court order, or other legal procedure.
• Disclosure is necessary to report suspected illegal activity.
How long do we store your data?
We store your personal data as long as it is required:
1. For provision of our services to the customers;
2. To comply with the respective legislation.
Where do we store your data?
We store all the personal data on our own protected servers.
Rights of the customer with respect to the personal data
You have certain rights with respect to your personal data according to GDPR as specified
below and you can use them by contacting us at support@emcd.io:
1. Correction of the personal data. If you consider your personal data as incomplete or
inaccurate, you can request the correction thereof;
2. Erasure of the personal data. You have the right to ask us to delete your personal data except
in cases when it is restricted by respective regulations;
3. Restriction of the processing of the personal data. You have the right to suspend the
processing of your personal data of any of its part;
4. Objection of the processing of the personal data. You have the right to object to the
processing of your personal data which may be used, for example for direct marketing
purposes;
5. Transfer of the personal data. You have the right to request to transfer your personal data to
a new service provider or to another party;
6. Another rights granted to you by GDPR.
The rights specified hereinabove may be restricted in some cases, e.q. when the processing and
storage of the personal data is subject to regulation of applicable legislation.
Privacy policy amendments
We reserve right to amend, modify, update and change any of the terms and conditions of this
Privacy policy for a number of reasons, including commercial, legal (to comply with new laws or
regulations) without prior notice. We will notify you of any such amendment, modification or change
by publishing the new version of the Privacy policy on the Website. It is the responsibility of the
customer to make sure that he is aware of the up-to-date Privacy policy and we advise you to check
for the updates on a regular basis.
For more information about the Privacy policy please contact us at: support@emcd.io. `;

const StyledContent = styled.section`
  padding: 1.5rem 1rem 4rem;

  font-size: 1.2rem;
  line-height: 150%;
  font-weight: 500;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  @media screen and (min-width: 768px) {
    padding: 4rem 3.7rem 18rem;
    font-size: 1.8rem;
  }
  @media screen and (min-width: 1024px) {
    padding: 4rem 8.7rem 18rem;
    font-size: 1.8rem;
  }
  .heading {
    margin-bottom: 1.2rem;

    font-size: 2.5rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.01rem;
    text-align: center;

    @media screen and (min-width: 768px) {
      margin-bottom: 2rem;
      font-size: 6rem;
      letter-spacing: -0.03em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 8rem;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 6rem;
    }
  }

  .content {
    max-width: 85rem;
    margin: auto;
  }
`;

export const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  return (
    <Layout home>
      <StyledContent>
        <h1 className="heading">{t('policyPage.heading')}</h1>
        <div className="content">{policy}</div>
      </StyledContent>
    </Layout>
  );
};
